import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  offersData as setOffersData,
  dateTimeGlobal as setDateTimeGlobal,
  spendingLimitGlobal as setSpendingLimitGlobal,
  batchDataGlobal as setBatchDataGlobal,
  segmetsData as setSegmentsData,
  dataSourceData as setDataSourceData,
} from '../GlobalStore';
import { Box, Select, MenuItem } from '@mui/material';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import DateTimeInput from '../../Utils/DateTimeInput/DateTimeInput';
import SpendingLimit from '../../Utils/SpendingLimit/SpendingLimit';
import OfferChannel from './OfferChannel/OfferChannel';
import SummaryBox from '../../Utils/SummaryBox/SummaryBox';
import { getSegmentOffers } from '../../../services/CampaignService';
import { newcampaign4 } from '../../../Language/language';
import ErrorPage from '../../../ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import CustomStepper from '../Components/Header/CustomStepper/CustomStepper';
import { getCampaignDataSourceDetails } from '../../../services/OffersService';
import { initalSpendingLimit, initialBatchGlobal, initialDataTimeGlobal } from '../../../common/constant';

const Offers = () => {
  const navigate = useNavigate();
  const { sk, pk } = useParams();
  const offersData = useReactiveVar(setOffersData);
  const dateTimeGlobal = useReactiveVar(setDateTimeGlobal);
  const spendingLimitGlobal = useReactiveVar(setSpendingLimitGlobal);
  const batchDataGlobal = useReactiveVar(setBatchDataGlobal);
  const [getCampaignSummaryData, setCampaignSummary] = useState(null);
  const [numberOfOffers, setNumberOfOffers] = useState(null);
  const [channelRows, setChannelRows] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [boxLoader, setboxLoader] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const [dateTime, setDateTime] = useState(initialDataTimeGlobal);
  useEffect(() => {
    setDateTime(dateTimeGlobal);
  }, [dateTimeGlobal]);

  const [batchData, setBatchData] = useState(initialBatchGlobal);

  useEffect(() => {
    setBatchData(batchDataGlobal);
  }, [batchDataGlobal]);

  const [spendingLimit, setSpendingLimit] = useState(initalSpendingLimit);

  useEffect(() => {
    setSpendingLimit(spendingLimitGlobal);
  }, [spendingLimitGlobal]);

  const handleDateTimeChange = (dateObj) => {
    setDateTime(dateObj);
    setDateTimeGlobal(dateObj);
  };

  const DataSize = offersData?.length;
  let isNextEnabled = DataSize === numberOfOffers;

  const handleNextButtonClick = () => {
    navigate(`/create-campaign/review/${sk}/${pk}`);
  };

  const handlePrevButtonClick = () => {
    navigate(`/create-campaign/segment/${sk}/${pk}`);
  };

  const handleLimitChange = (limitData) => {
    setSpendingLimit(limitData);
    setSpendingLimitGlobal(limitData);
  };

  const handleBatchSizeChange = (event) => {
    setBatchDataGlobal({
      ...batchDataGlobal,
      batch_size: event.target.value,
    });
  };

  const handleBatchPeriodChange = (event) => {
    setBatchData((prevState) => ({
      ...prevState,
      batch_type: event.target.value,
    }));
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _sk = `campaign#${sk}`;
      const _pk = `campaign#${pk}`;
      const response = await getCampaignDataSourceDetails(_sk, _pk);
      if (response?.status === 200) {
        setLoading(false);
        setCampaignSummary(response?.data?.response?.summary);

        const sourceData = Object.keys(response?.data?.response?.data_sources).map((e) => {
          return {
            name: e,
            document_pk: response?.data?.response?.data_sources[e]?.pk,
            document_sk: response?.data?.response?.data_sources[e]?.sk,
          };
        });
        const dataSourceData = setDataSourceData();
        setDataSourceData({
          ...dataSourceData,
          AIData: [...sourceData],
        });

        const selectedSegment = Object.keys(response?.data?.response?.selected_segment).map((e) => {
          return {
            name: e,
            segment_pk: response?.data?.response?.selected_segment[e].pk,
            segment_sk: response?.data?.response?.selected_segment[e].sk,
          };
        });

        const currentSegmentsData = setSegmentsData();
        setSegmentsData({
          ...currentSegmentsData,
          channelType: response?.data?.response?.segments_type,
          AIData: response?.data?.response?.segments_type === 'manual' ? [] : [...selectedSegment],
          manualData: response?.data?.response?.segments_type === 'manual' ? [...selectedSegment] : [],
        });
        const isGlobalSetting = response?.data?.response?.segment_offer_global_settings;
        if (!!isGlobalSetting) {
          const dateAndTime = {
            startDate: isGlobalSetting?.startDate,
            startTime: isGlobalSetting?.startTime,
            endDate: isGlobalSetting?.endDate,
            endTime: isGlobalSetting?.endTime,
            isEndDate: isGlobalSetting?.isEndDate ?? false,
          };

          const batchDetails = {
            batch_type: isGlobalSetting?.batch_type ?? 'daily',
            batch_size: isGlobalSetting?.batch_size ?? '',
          };

          const limitData = {
            noLimit: isGlobalSetting?.noLimit,
            period: isGlobalSetting?.period,
            min: isGlobalSetting?.min,
            max: isGlobalSetting?.max,
          };

          setBatchDataGlobal(batchDetails);
          setSpendingLimitGlobal(limitData);
          setDateTimeGlobal(dateAndTime);
        }

        const isOfferData = response?.data?.response?.channel_segment_offers;

        if (!!isOfferData) {
          setOffersData(isOfferData);
        }
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setBatchDataGlobal(batchData);
  }, [batchData]);

  useEffect(() => {
    setboxLoader(true);
    if (!isLoading) {
      (async () => {
        const response = await getSegmentOffers(`campaign#${pk}`, `campaign#${sk}`);
        if (response?.status == 200) {
          setNumberOfOffers(response.data.response.length);
          setChannelRows(response.data.response);
          setLoading(false);
          setboxLoader(false);
        } else {
          setboxLoader(false);
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
          setLoading(false);
        }
      })();
    }
  }, [isLoading]);

  return (
    <>
      <CustomStepper type={3} />
      <SummaryBox CampaignSummary={getCampaignSummaryData} which={3} setLoading={setLoading} />
      {!isLoading ? (
        <div className="campaign-container">
          <div className="customHR"></div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                padding: '0px 15px',
              }}
            >
              {channelRows && (
                <div style={{ display: 'grid', gap: '20px' }}>
                  <div className="headerText"> {newcampaign4.headlineText1}</div>
                  <div className="normalText">{newcampaign4.subTitle1}</div>
                  <div className="customHR"></div>

                  <div className="headerText"> {newcampaign4.headlineText2}</div>
                  <div className="normalText" style={{ textTransform: 'capitalize' }}>
                    {newcampaign4.subTitle2}
                  </div>

                  <DateTimeInput onDateTimeChange={handleDateTimeChange} dateTimeData={dateTime} />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="normalText">Send in Batches</div>
                    <input
                      value={batchData.batch_size}
                      onChange={handleBatchSizeChange}
                      placeholder=" Batch size"
                      type="number"
                      style={{
                        marginLeft: '50px',
                        padding: '1px 1px 1px 4px',
                        height: '31px',
                        width: '130px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        appearance: 'none',
                      }}
                    />
                    <Select
                      value={batchData.batch_type}
                      onChange={handleBatchPeriodChange}
                      className="normalText"
                      style={{
                        width: '130px',
                        height: '35px',
                        marginLeft: '20px',
                      }}
                    >
                      <MenuItem value="daily" className="normalText" style={{ fontSize: '10px' }}>
                        Daily
                      </MenuItem>
                      <MenuItem value="monthly" className="normalText" style={{ fontSize: '10px' }}>
                        Monthly
                      </MenuItem>
                      <MenuItem value="quarterly" className="normalText" style={{ fontSize: '10px' }}>
                        Quarterly
                      </MenuItem>
                    </Select>
                  </div>

                  <div style={{ display: 'grid' }}>
                    <div className="normalText">Budget</div>
                    <SpendingLimit onLimitChange={handleLimitChange} spendingLimit={spendingLimit} />
                  </div>

                  <div className="customHR"></div>
                  <div className="normalText">{newcampaign4.subTitle3}</div>

                  <OfferChannel globalDateTime={dateTime} globalSpendingLimit={spendingLimit} globalBatchData={batchDataGlobal} channelRows={channelRows} boxLoader={boxLoader} />

                  <div style={{ marginTop: '20px' }}>
                    <CustomButton
                      isDisabled={false}
                      onClick={handlePrevButtonClick}
                      buttonValue="Prev step"
                      style={{
                        color: '#852598',
                        backgroundColor: '#ffffff',
                        border: '1px solid #852598',
                        marginLeft: '0',
                      }}
                    />
                    <CustomButton isDisabled={!isNextEnabled} onClick={handleNextButtonClick} buttonValue="Next" style={{}} />
                  </div>
                </div>
              )}
            </div>
            <div>
              <Box>{getCampaignSummaryData && <SummaryBox campaignSummaryData={getCampaignSummaryData} />}</Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            padding: '70px 0px',
          }}
        >
          <LoadingScreen gifType={3} />
        </div>
      )}
      {showModal && <ErrorPage closingFlag={3} closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

export default Offers;
