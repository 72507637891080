import React from 'react';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Profilestep4, CommonLanguage } from '../../../../Language/language';
import SettingFront from '../../SettingFront';

const Security = () => {
  return (
    <div>
      {/* <SettingFront /> */}
      <Typography
        style={{
          color: '#414446',
          fontFamily: 'IBM Plex Serif',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingBottom: '12px',
          marginTop: '25px',
        }}
      >
        {Profilestep4.pagetitle}
      </Typography>
      <Box
        component="div"
        sx={{
          borderRadius: '5px',
          border: '1px solid #EBE8F1',
          background: '#FFFFFF',
          padding: '20px 26px',
          width: { lg: '679px', sm: '100%' },
          marginBottom: '20px',
          marginRight: '20px',
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: '#000',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            textTransform: 'capitalize',
          }}
        >
          {Profilestep4.headlineText1}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            width: { lg: '544px', sm: '100%' },
            color: '#414446',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '21.166px',
            textTransform: 'capitalize',
            padding: '18px 0px 21px 0px',
          }}
        >
          {Profilestep4.subTitle1}
        </Typography>
        <FormControl>
          <RadioGroup name="radio-buttons-group" defaultValue="Authenticator app (Recommended)" style={{ display: 'flex' }}>
            <FormControlLabel control={<Radio />} label="Authenticator app (Recommended)" value="Authenticator app (Recommended)" style={{ fontSize: '14px' }} />
            <Typography sx={{ paddingLeft: '30px', fontSize: '12px' }}>{Profilestep4.expText}</Typography>

            <FormControlLabel control={<Radio />} label="SMS" value="SMS" style={{ marginTop: '20px' }} />
            <Typography sx={{ paddingLeft: '30px', fontSize: '12px' }}>{Profilestep4.expText}</Typography>
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
          sx={{
            width: '146px',
            fontSize: '16px',
            textTransform: 'capitalize',
            marginTop: '45px',
            marginBottom: '30px',
            display: 'block',
          }}
        >
          {CommonLanguage.nextbtn}
        </Button>
      </Box>

      <Box
        component="div"
        sx={{
          borderRadius: '5px',
          border: '1px solid #EBE8F1',
          background: '#F6F5F8',
          padding: '20px 26px',
          width: { lg: '679px', xxl: '679px', sm: '100%' },
          marginRight: '20px',
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: '#000',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            textTransform: 'capitalize',
          }}
        >
          {Profilestep4.headlineText2}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: '#414446',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '21.166px',
            textTransform: 'capitalize',
            padding: '24px 0px 21px 0px',
          }}
        >
          {Profilestep4.subTitle2}
        </Typography>

        <Typography
          variant="body2"
          style={{
            color: '#414446',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            textTransform: 'capitalize',
          }}
        >
          {Profilestep4.subTitle3}
        </Typography>
      </Box>
    </div>
  );
};

export default Security;
