import { createSlice } from '@reduxjs/toolkit';

export const initialCustomeCreateOffer = {
  name: '',
  description: '',
  offerSourceList: [],
  selectedOfferSource: 'offerSource',
  offerConfig: {},
  where_condition: '',
  sql_where_condition: '',
  totalRecords: '',
  dateTime: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
};

const initialState = {
  offerList: [],
  offerInfo: {},
  offerAICrafetdList: [],
  aICraftedClickedItems: {},
  offerAction: 'Add',
  create: initialCustomeCreateOffer,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOfferList(state, action) {
      state.offerList = action.payload.offerList;
    },

    setAICraftedList(state, action) {
      state.offerAICrafetdList = action.payload.offerAICrafetdList;
    },

    setOfferSourceList(state, action) {
      state.offerSourceList = action.payload.offerSourceList;
    },

    setOfferConfig(state, action) {
      state.offerConfig = action.payload.offerConfig;
    },

    setOfferSourceTotalRecords(state, action) {
      state.totalRecords = action.payload.totalRecords;
    },

    setCreateOfferDetails(state, action) {
      state.offerInfo = action.payload.response;
    },

    setOfferDetails(state, action) {
      state.offerInfo = action.payload.response;
    },

    setCopyOfferInfo(state, action) {
      state.offerInfo = action.payload.response;
    },

    setEditOfferInfo(state, action) {
      state.offerInfo = action.payload.response;
    },

    addOfferConditionDetails(state, action) {
      state.offerInfo = action.payload.response;
    },

    saveOfferDetails(state, action) {
      state.offerInfo = action.payload.response;
    },

    setOfferAction(state, action) {
      state.offerAction = action.payload;
    },

    setAICraftedClickedItems(state, action) {
      state.aICraftedClickedItems = {
        ...state.aICraftedClickedItems,
        ...action.payload,
      };
    },

    setCreateOfferInputFields(state, action) {
      const { name, value } = action.payload;
      state.create[name] = value;
    },

    setOfferCreate(state, action) {
      state.create = action.payload.response;
    },

    resetOffer(state, action) {
      state.create = initialCustomeCreateOffer;
    },
  },
});

export const offersActions = offersSlice.actions;
export const offersReducer = offersSlice.reducer;
