import { Box, Grid, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';

const MainSearch = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" sx={{ backgroundColor: { lg: '#F6F5F8', md: '#FAF8F7', xs: '#fff',marginLeft:'18px',height: "482px" } }}>
      <Box sx={{ backgroundColor: '#ffffff', width: '100%', height: '100%', borderRadius: '6px' }}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Total (272.8M)" value="1" />
                <Tab label="Net New (272.8M)" value="2" />
                <Tab label="Saved (2.7K)" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">Item One</TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Grid>
  );
};

export default MainSearch;
