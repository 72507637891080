import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import HoverChart from '../HoverChart/HoverChart';
import { LoanInterestRateService, getLoanInterestRateSegmentData } from '../../../services/LoanInterestRateService';
import { getCookie, getItem, setCookie, setItem } from '../../Utils/CommonFunction/CommonFunction';
import Loader from '../../integration/Components/Loader';
import Score from '../PortfolioPanel1/Score';
import { getlendingofferanylysisService } from '../../../services/GetLendingOfferAnalysisService';
import CreatePopup from '../../offers-and-segments/UI-Components/CreatePopup';
import { createofferScreen } from '../../../Language/DialogBoxLanguage';

const BarChart = ({ dataColors, isSegment, selectedTags, sliderValues, isScore }) => {
  const loanInterestData = getItem('loanInterestData');
  const loanInterest = getCookie('loanInterest');
  const [activeButton, setActiveButton] = useState('all');
  const [activeButtonFilter, setActiveButtonFilter] = useState('');
  const [ClickValue, SetClickValue] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [LoanRateData, setLoanRateData] = useState(loanInterestData?.result ? loanInterestData?.result : []);
  const [InterestedRate, setInterestedRate] = useState();
  const [titileChartLoader, settitileChartLoader] = useState(false);
  const [additionalInfoSend, setadditionalInfoSend] = useState();
  const [showModal, setShowModal] = useState(false);
  const [massageBox, setMassageBox] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    setMassageBox(false);
  };

  useEffect(() => {
    // setData(loanInterestData);
    settitileChartLoader(true);
    if (!loanInterest) {
      (async () => {
        const response = await LoanInterestRateService();
        setLoanRateData(response?.data?.result);
        setItem('loanInterestData', response?.data);
        setCookie('loanInterest', true, 1440);
      })();
    }
  }, []);
  const interestRates = LoanRateData?.map((item) => `${item.interest_rate}%`);
  const NumberOfLoan = LoanRateData?.map((item) => item.total);
  const formatAdditionalInfo = (dataPointIndex) => {
    if (!LoanRateData || LoanRateData.length === 0 || !LoanRateData[dataPointIndex]) {
      return 'No data available';
    }

    const additionalInfo = LoanRateData[dataPointIndex].additional_info;
    return additionalInfo.map((info) => `$${info.unpaid_balance.toLocaleString()}: ${info.count}`).join('<br/>');
  };
  useEffect(() => {
    if (!!loanInterest) {
      settitileChartLoader(false);
    }
  }, [loanInterest]);

  const backbtn = () => {
    SetClickValue(false);
  };

  const setScoresInApiData = (scores) => {
    const apiData = {};

    scores.forEach((scoreObject) => {
      Object.entries(scoreObject).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          const formattedKey = formatKey(key); // Function to format the key
          apiData[formattedKey] = value.toString();
        }
      });
    });

    return apiData;
  };
  // Function to format the key (e.g., converting space to underscore, lowercasing, etc.)
  const formatKey = (key) => {
    return key.toLowerCase().replace(/\s+/g, '_'); // Example: "PURCHASE SCORE" -> "purchase_score"
  };

  const ChangeDataButtonClick = async (dataKey, lowKey, highKey) => {
    // <Score dataKey={dataKey} lowKey={lowKey} highKey={highKey} />
    settitileChartLoader(true);
    if (isSegment) {
      const apiData = {
        unpaid_balance_low: lowKey,
        unpaid_balance_high: highKey,
        segments: selectedTags ? selectedTags?.join(',') : '',
      };
      const response = await getLoanInterestRateSegmentData(apiData);
      if (response?.status === 200) {
        settitileChartLoader(false);
        setItem(dataKey, response?.data?.result);
        setCookie(dataKey, true, 1440);
        setLoanRateData(response?.data?.result);
      } else {
        settitileChartLoader(false);
        setLoanRateData(loanInterestData?.result);
      }
    } else if (isScore) {
      const apiData = setScoresInApiData(sliderValues);
      apiData.unpaid_balance_low = lowKey;
      apiData.unpaid_balance_high = highKey;
      const response = await LoanInterestRateService(apiData);
      if (response?.status === 200) {
        settitileChartLoader(false);
        setItem(dataKey, response?.data?.result);
        setCookie(dataKey, true, 1440);
        setLoanRateData(response?.data?.result);
      } else {
        settitileChartLoader(false);
        setLoanRateData(loanInterestData?.result);
      }
    } else {
      const response = await LoanInterestRateService({
        unpaid_balance_low: lowKey,
        unpaid_balance_high: highKey,
      });
      if (response?.status === 200) {
        settitileChartLoader(false);
        setItem(dataKey, response?.data?.result);
        setCookie(dataKey, true, 1440);
        setLoanRateData(response?.data?.result);
      } else {
        settitileChartLoader(false);
        setLoanRateData(loanInterestData?.result);
      }
    }
    settitileChartLoader(false);
    setActiveButton(dataKey);
  };

  // Parses the JSON string of colors and transforms it into actual color values
  const getChartColorsArray = (colors) => {
    colors = JSON.parse(colors);
    return colors.map((value) => {
      let newValue = value.replace(' ', '');
      if (newValue.indexOf(',') === -1) {
        let color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
        return color.trim() ? color.trim() : newValue;
      } else {
        let val = value.split(',');
        if (val.length === 2) {
          let rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]).trim();
          return `rgba(${rgbaColor},${val[1]})`;
        } else {
          return newValue;
        }
      }
    });
  };

  // The array of colors for the chart
  var barchartCountriesColors = getChartColorsArray(dataColors);

  // Series data for the chart
  const series = [
    {
      data: NumberOfLoan ? NumberOfLoan.reverse() : [1010, 1640, 490, 1255, 1050], // The data for the bars
      name: '',
      categories: loanInterestData?.x_axis_range,
      // categories: loanInterestData?.x_axis_range
    },
  ];

  const handleClick = (event, chartContext, config) => {
    SetClickValue(true);
    const dataPointIndex = config.dataPointIndex;
    const clickedInterestRate = interestRates[dataPointIndex];
    setInterestedRate(clickedInterestRate);
    const additionalInfo = LoanRateData[dataPointIndex].additional_info;
    setadditionalInfoSend(additionalInfo);
    return additionalInfo.map((info) => `${info.unpaid_balance.toLocaleString()}: ${info.count}`).join('<br/>');
  };

  var options = {
    chart: {
      type: 'bar',

      height: isMobile ? 200 : 436,

      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleClick,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 15,
        borderRadiusApplication: 'end',
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: 'top',
        },
        barHeight: 35,
      },
    },

    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 38,
      style: {
        fontSize: '12px',
        fontWeight: 400,
        colors: ['#000000'],
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: interestRates ? interestRates.reverse() : ['6%', '5%', '4%', '3%', '2%'],
      labels: {
        style: {
          colors: '#000000', // Set x-axis labels to black (or any dark color of your choice)
        },
      },
      axisBorder: {
        color: '#000000', // Set x-axis border to black (or any dark color of your choice)
      },
      title: {
        text: 'Number of Loans',
        style: {
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#000000', // Set y-axis labels to black (or any dark color of your choice)
        },
      },
      axisBorder: {
        color: '#000000', // Set y-axis border to black (or any dark color of your choice)
      },
      title: {
        text: 'Interesr Rate',
        style: {
          fontSize: '14px',
        },
      },
      max: NumberOfLoan ? Math.max(...NumberOfLoan) + 200 : 0,
    },

    tooltip: {
      enabled: true,

      y: {
        formatter: (val, { seriesIndex, dataPointIndex, w }) => {
          return formatAdditionalInfo(dataPointIndex);
        },
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
    },
  };
  useEffect(() => {
    if (isSegment) {
      (async () => {
        const apiData = {
          segments: selectedTags ? selectedTags?.join(',') : '',
        };
        if (activeButtonFilter.lowKey) {
          apiData.unpaid_balance_low = activeButtonFilter.lowKey;
          apiData.unpaid_balance_high = activeButtonFilter.UpperKey;
        }

        const response = await getLoanInterestRateSegmentData(apiData);
        if (response?.status === 200) {
          settitileChartLoader(false);
          setItem(activeButton, response?.data?.result);
          setCookie(activeButton, true, 1440);
          setLoanRateData(response?.data?.result);
        } else {
          settitileChartLoader(false);
          setLoanRateData(loanInterestData?.result);
        }
      })();
    } else if (isScore) {
      (async () => {
        const apiData = setScoresInApiData(sliderValues);
        if (activeButtonFilter.lowKey) {
          apiData.unpaid_balance_low = activeButtonFilter.lowKey;
          apiData.unpaid_balance_high = activeButtonFilter.UpperKey;
        }

        const response = await LoanInterestRateService(apiData);
        const response2 = await getlendingofferanylysisService(apiData);
        if (response?.status === 200) {
          settitileChartLoader(false);
          setItem(activeButton, response?.data?.result);
          setCookie(activeButton, true, 1440);
          setLoanRateData(response?.data?.result);
        } else {
          settitileChartLoader(false);
          setLoanRateData(loanInterestData?.result);
        }
      })();
    }
  }, [sliderValues, selectedTags]);
  return (
    <React.Fragment>
      {ClickValue ? (
        <>
          <HoverChart backbtn={backbtn} InterestedRate={InterestedRate} additionalInfoSend={additionalInfoSend} />
        </>
      ) : (
        <Grid style={{ backgroundColor: '#EBE8F1', padding: '2%', borderRadius: '8px' }}>
          <Typography
            className="chart-title"
            sx={{
              color: '#414446',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              // margin: '2%',
            }}
          >
            Portfolio Metrics
          </Typography>

          {titileChartLoader ? (
            <>
              <Loader />
            </>
          ) : LoanRateData?.length > 0 ? (
            <>
              <div>
                <ReactApexChart dir="ltr" options={options} series={series} type="bar" height={310} className="apex-charts" />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '20px',
                }}
              >
                <button
                  className={`box ${activeButton === 'all' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
                  onClick={() => {
                    ChangeDataButtonClick('all');
                    setActiveButtonFilter('');
                  }}
                  style={{ height: isMobile ? '40px' : '25px', cursor: 'pointer' }}
                >
                  All
                </button>
                <button
                  className={`box ${activeButton === 'firstRange' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
                  onClick={() => {
                    ChangeDataButtonClick('firstRange', '100000', '200000');
                    setActiveButtonFilter({ lowKey: '100000', UpperKey: '200000' });
                  }}
                  style={{ height: isMobile ? '40px' : '25px', cursor: 'pointer' }}
                >
                  $100k-200K
                </button>
                <button
                  className={`box ${activeButton === 'secondRange' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
                  onClick={() => {
                    ChangeDataButtonClick('secondRange', '200000', '400000');
                    setActiveButtonFilter({ lowKey: '200000', UpperKey: '400000' });
                  }}
                  style={{ height: isMobile ? '40px' : '25px', cursor: 'pointer' }}
                >
                  $200k-400K{' '}
                </button>
                <button
                  className={`box ${activeButton === 'thirdRange' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
                  onClick={() => {
                    ChangeDataButtonClick('thirdRange', '400000', '600000');
                    setActiveButtonFilter({ lowKey: '400000', UpperKey: '600000' });
                  }}
                  style={{ height: isMobile ? '40px' : '25px', cursor: 'pointer' }}
                >
                  $400k-600K
                </button>
              </div>
            </>
          ) : (
            <CreatePopup closeModal={closeModal} navigateType="/create-offer" popupConfig={createofferScreen} />
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default BarChart;
