import React from 'react';
import { Utils as QbUtils } from '@react-awesome-query-builder/mui';
import PropTypes from 'prop-types';
import '@react-awesome-query-builder/mui/css/styles.css';

export const ruleInfoUtils = (rule, config) => {
  const { properties } = rule;
  let op = properties?.operator; // operator
  let ruleValue = Array.isArray(properties.value) && properties?.value[0] && properties?.value[0];
  return {
    field: rule.properties.field,
    operator: op,
    value: ruleValue,
    fieldConfig: QbUtils.ConfigUtils.getFieldConfig(config, rule.properties.field),
  };
};

const getChipHtml = (field, title) => `<span class="workflow-logic-chip-${title}">${field}</span>`;

const renderLogic = (expression, config, element, isLast = false) => {
  if (expression?.type === 'group') {
    const rules = expression.children1;
    rules.forEach((childRule, index) => {
      const isLastRule = index === rules.length - 1;
      if (childRule?.type === 'group') {
        const innerElement = document.createElement('div');
        innerElement.style.borderLeftStyle = 'solid';
        innerElement.style.marginLeft = '5px';
        element.appendChild(innerElement);
        renderLogic(childRule, config, innerElement, isLastRule);
      } else {
        renderLogic(childRule, config, element, isLastRule);
      }
      const last = rules.length - 1;
      if (index !== last) {
        const ruleOperator = expression.properties.conjunction;
        element.innerHTML = `${element.innerHTML}${getChipHtml(ruleOperator, 'operator')}<br />`;
      }
    });
  } else {
    const ruleInfo = ruleInfoUtils(expression, config);
    const { field, fieldConfig, operator, value } = ruleInfo;
    if (Array.isArray(value)) {
      element.innerHTML = `${element.innerHTML}${getChipHtml(fieldConfig?.label, 'label')} ${getChipHtml(operator, 'operator')}  ${value
        .map((val) => getChipHtml(val, 'value'))
        .join('')}`;
    } else {
      element.innerHTML = `${element.innerHTML} ${getChipHtml(fieldConfig?.label, 'label')} ${getChipHtml(operator, 'operator')} ${
        (value || value === 0) ? getChipHtml(value, 'value') : ''
      }`;
    }
    if (!isLast) {
      element.innerHTML += '<br />';
    }
  }
};

const Logic = ({ expression, currConfig, position }) => {
  const expressionLogicContainer = document.getElementById(`expression-logic-${position}`);
  expressionLogicContainer.innerText = '';
  renderLogic(expression, currConfig, expressionLogicContainer, false);
  return null;
};

Logic.propTypes = {
  expression: PropTypes.object,
  currConfig: PropTypes.object,
  position: PropTypes.string,
};

export default Logic;
