import axios from 'axios';
import { GET_HISTORICAL_EVENT_DATA, GET_MANAGE_ALL_CAMPAIGN_API, POST_MANAGE_ALL_CAMPAIGN_API, get_historical_event_data } from '../common/config';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';
const prepareHeaders = () => {
  var accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getManageAllCampaign = async (parameter) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_HISTORICAL_EVENT_DATA}?parameter=${parameter}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const postManageAllCampaign = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_MANAGE_ALL_CAMPAIGN_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};
