import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { DOWNLOD_S3_FILE } from '../../../common/config';
import { downloadS3File } from '../../../services/Exports';
import ErrorPage from '../../../ErrorPage';

const TableRow = ({ fileData }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  const downloadMedia = (url) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };

  const downloadFile = async (pk) => {
    setIsDownloading(true);
    const response = await downloadS3File(DOWNLOD_S3_FILE, pk);

    if (response.status === 200) {
      downloadMedia(response.data.url);
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
    }

    setIsDownloading(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '0.5rem 0',
      }}
    >
      <Box sx={{ width: '30%' }}>{fileData?.campaign_name}</Box>
      <Box sx={{ width: '20%' }}>{fileData?.created_datetime}</Box>
      <Box
        sx={{
          width: '20%',
        }}
      >
        {fileData?.channel_name}
      </Box>
      <Box
        sx={{
          width: '20%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {fileData?.total_records}
      </Box>
      <Box
        sx={{
          width: '10%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isDownloading ? (
          <LoadingButton
            loading
            loadingPosition="end"
            variant="outlined"
            sx={{
              width: 'fit-content',
            }}
          >
            <span style={{ marginRight: '0.5rem', width: '8rem' }}>Downloading</span>
          </LoadingButton>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DownloadRoundedIcon />}
            onClick={() => {
              downloadFile(fileData?.pk);
            }}
          />
        )}
      </Box>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </Box>
  );
};

export default TableRow;
