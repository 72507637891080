import React, { useState } from 'react';
import { Box, Card, Grid, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { samplefileService } from '../../services/samplefileService';
import ErrorPage from '../../ErrorPage';
import Loader from '../integration/Components/Loader';
import { DataFileModal } from '../../Language/DialogBoxLanguage';

const DataSampleFileModal = ({ closeSampleData }) => {
  const ListStyle = {
    color: '#A35BB1',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '173.7%' /* 24.318px */,
    paddingTop: '12px',
    cursor:'pointer'
  };

  const [state, setstate] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [loading, setloading] = useState(false);

  const downloadMedia = (url) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };

  const handleClick = (e) => {
    setstate(e?.target?.outerText);
    downloadFile(e?.target?.outerText);
  };

  const downloadFile = async (state) => {
    setloading(true);
    const payload = { sample_file_name: state };
    const response = await samplefileService(payload);
    if (response.status === 200) {
      setloading(false);
      downloadMedia(response.data.url);
    } else {
      setShowModal(true);
      seterrormsg(response?.response?.data?.error);
    }
  };

  //   const handleCreateAICrafted = async (event_id) => {
  //     setIsAICraftedCreateLoading(true);
  //     const payload = { event_id };
  //     const response = await createAICraftedForOffer(payload);
  //     if (response.status === 200) {
  //       setIsAICraftedCreateLoading(false);
  //       dispatch(setOfferCreate({ response: response.data }));
  //       setIsPageType(4);
  //     } else {
  //       setIsAICraftedCreateLoading(false);
  //       setShowModal(true);
  //       seterrormsg(response?.response?.data?.error)
  //       // window.alert(response.response.data.error);
  //     }
  //   };

  return (
    <Modal open={true}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Card sx={{ my: 5, borderRadius: 2, padding: '6px', height: '569px', width:{lg: '674px',sm:'100%'}, position: 'static' }} className="modal">
          <Grid container>
            <Grid item xs={12} onClick={closeSampleData} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon />
            </Grid>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <Grid container>
                <Grid item xs={12} sx={{ borderBottom: '1px solid #EBE8F1', padding: '6px 67px 13px 67px' }}>
                  <Typography
                    sx={{
                      color: '#414446',
                      fontFamily: 'IBM Plex Serif',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '40px' /* 166.667% */,
                    }}
                  >
                    {DataFileModal.headlineText1}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#414446',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '151.188%',
                      paddingTop: '27PX',
                    }}
                  >
                    {DataFileModal.subtitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ padding: '0px 67px 40px 67px' }}>
                    <ul style={{ paddingLeft: '15px' }}>
                      <li
                        style={ListStyle}
                        name="Loan portfolio data"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText1}
                      </li>
                      <li
                        style={ListStyle}
                        name="Daily Rate Sheets"
                        onClick={(e) => {
                          handleClick(e);
                        }}
                      >
                        {DataFileModal.ListText2}
                      </li>
                      <li
                        style={ListStyle}
                        name="Leads from CRM/ LMS"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText3}
                      </li>
                      <li
                        style={ListStyle}
                        name="Application data from LOS"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText4}
                      </li>
                      <li
                        style={ListStyle}
                        name="Call records"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText5}
                      </li>
                      <li
                        style={ListStyle}
                        name="Web visit logs"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText6}
                      </li>
                      <li
                        style={ListStyle}
                        name="Borrower Demographic data"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText7}
                      </li>{' '}
                      <li
                        style={ListStyle}
                        name="Marketing Campaigns Engagement/ Dispositions"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText8}
                      </li>{' '}
                      <li
                        style={ListStyle}
                        name="Campaign Templates Repository with CTA"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText9}
                      </li>
                      <li
                        style={ListStyle}
                        name="Vendor information and their file layouts"
                        onClick={(e) => {
                          handleClick(e);
                          // downloadFile();
                        }}
                      >
                        {DataFileModal.ListText10}
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
        {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      </div>
    </Modal>
  );
};

export default DataSampleFileModal;
