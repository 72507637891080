import React, { useState } from 'react';
// import GlobalAnalytics from "./ManageApIntegrations/Components/GlobalAnalytics/GlobalAnalytics";
import { getImports } from '../../services/Imports';
import ManageImports from './ManageImports/ManageImports';
import CsvFileUpload from './ManageImports/FileUpload/CsvFileUpload';
import PopUp from './ManageImports/FileUpload/PopUp';
import { GET_HISTORICAL_EVENT_DATA, GET_TOP_PERFORMING_KPI } from '../../common/config';
import ErrorPage from '../../ErrorPage';

const Imports = () => {
  const [importedFiles, setImportedFiles] = useState([]);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({});
  const [selectedFilter, setSelectedFilter] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [displayDataList, setDisplayDataList] = useState([]);

  const togglePopUp = (value) => setIsPopUpOpen(value);

  const imports = async (baseUrl, paramter) => {
    setIsLoading(true);
    const response = await getImports(baseUrl, paramter);
    if (response.status === 200) {
      setImportedFiles(() => response.data);
      setIsLoading(false);
      setDisplayDataList(response.data);
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
      setIsLoading(false);
      setImportedFiles([]);
    }
  };

  const handleFilter = (filter, value) => {
    setSelectedFilter(filter);
    setFilterValue(value);
    if (filter === 'topPerforming') {
      imports(GET_TOP_PERFORMING_KPI, 'CAC_document');
    } else if (filter === 'historicalData') {
      imports(GET_HISTORICAL_EVENT_DATA, 'document');
    }
  };

  useState(() => {
    document.title = 'Imports';
    if (!importedFiles.length) imports(GET_HISTORICAL_EVENT_DATA, 'document');
  }, [toggle]);

  return (
    <>
      <ManageImports
        importedFiles={importedFiles}
        setImportedFiles={setImportedFiles}
        selectedFilter={selectedFilter}
        filterValue={filterValue}
        handleFilter={handleFilter}
        isLoading={isLoading}
        displayDataList={displayDataList}
      />
      <CsvFileUpload togglePopUp={togglePopUp} setUploadResponse={setUploadResponse} />
      <PopUp value="Upload file" togglePopUp={togglePopUp} isPopUpOpen={isPopUpOpen} uploadResponse={uploadResponse} imports={imports} setToggle={setToggle} toggle={toggle} />
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

export default Imports;
