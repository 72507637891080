import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Grid, Typography } from '@mui/material';
import './hoverchart.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const colors = ['#A35BB1'];

const Chart = ({ backbtn, InterestedRate, additionalInfoSend }) => {
  const NumberOfLoan = additionalInfoSend?.map((item) => item?.count);

  const UnpaidBalance = additionalInfoSend?.map((item) => item?.unpaid_balance);

  const series = [
    {
      data: NumberOfLoan,
      name: '',
    },
  ];

  const additionalData = [
    { label: '6%', value: 34 },
    { label: '5.75%', value: 430 },
    { label: '5.25%', value: 68 },
  ];

  const options = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '50px',
        distributed: true,
        vertical: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -15,
      style: {
        fontSize: '12px',
        fontWeight: 400,
        colors: ['#000000'],
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: UnpaidBalance,
      labels: {
        style: {
          colors: '#000000', // Set x-axis labels to black (or any dark color of your choice)
        },
      },
      title: {
        text: 'Amt Of Unpaid Balance',
        style: {
          fontSize: '14px',
        },
      },
      axisBorder: {
        color: '#000000', // Set x-axis border to black (or any dark color of your choice)
      },
    },
    yaxis: {
      // Show the Y-axis line and labels
      // Remove categories and let ApexCharts auto-calculate the range
      labels: {
        style: {
          colors: ['#000000'], // Change to a more visible color
          fontSize: '12px', // Ensure the font size is adequate
        },
      },
      title: {
        text: 'Number of Loans',
        style: {
          fontSize: '14px',
        },
      },
      axisBorder: {
        show: true, // Ensure the axis border is visible
        color: '#000000', // Keep the border color dark for visibility
      },
      axisTicks: {
        show: true, // Show axis ticks for clarity
      },
    },

    tooltip: {
      enabled: false,

      y: {
        formatter: (val, { seriesIndex, dataPointIndex, w }) => {
          // Access the additional data based on the dataPointIndex
          const dataString = additionalData.map((item) => `${item.label}:${item.value}`).join(' <br/>\n');
          return dataString;
        },
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
    },
  };

  return (
    <>
      <div id="chart">
        <Box sx={{ backgroundColor: '#EBE8F1', padding: '10px', width: '100%', borderRadius: '5px' }}>
          <Typography
            sx={{
              display: 'flex',
              color: '#852598',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              alignItems: 'center',
              marginBottom: '20px',
              cursor: 'pointer',
            }}
            onClick={backbtn}
          >
            <ArrowBackIcon /> Back
          </Typography>
          <Typography
            sx={{
              color: '#414446',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              margin: '25px',
            }}
          >
            {`${InterestedRate} Interest Loans with pending Balance`}
          </Typography>

          <Grid container>
            {/* <Grid item xs={0.8} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                  margin: isMobile ? '23px -25px' : '23px 0px',
                  transform: 'rotate(270deg)',
                  width: 'max-content',
                  position: 'absolute',
                }}
              >
                Number of Loans
              </Typography>
            </Grid> */}
            <Grid item xs={11.2} sx={{ marginLeft: '20px' }}>
              <ReactApexChart options={options} dir="ltr" series={series} type="bar" height={350} />
            </Grid>
          </Grid>

          {/* <Typography
            sx={{
              color: '#414446',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              textAlign: 'center',
              margin: '23px 0px',
            }}
          >
            Amt of unpaid Balance
          </Typography> */}
          {/* 
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px',
            }}
          >
            <button
              className={`box ${activeButton === '1D' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
              onClick={() => ChangeDataButtonClick('1D')}
              style={{ height: isMobile ? '40px' : 'auto' }}
            >
              All
            </button>
            <button
              className={`box ${activeButton === '3D' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
              onClick={() => ChangeDataButtonClick('3D')}
              style={{ height: isMobile ? '40px' : 'auto' }}
            >
              $100k-200K
            </button>
            <button
              className={`box ${activeButton === '7D' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
              onClick={() => ChangeDataButtonClick('7D')}
              style={{ height: isMobile ? '40px' : 'auto' }}
            >
              $200k-400K{' '}
            </button>
            <button
              className={`box ${activeButton === '1M' ? 'portfoliobtnactive' : 'portfoliobtnstyle'}`}
              onClick={() => ChangeDataButtonClick('1M')}
              style={{ height: isMobile ? '40px' : 'auto' }}
            >
              $400k-600K
            </button>
          </div> */}
        </Box>
      </div>
    </>
  );
};

export default Chart;
