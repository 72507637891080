import React, { useState } from "react";
import { Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import MainHeader from "../mainHeader/MainHeader";
import MainFooter from "../mainFooter/MainFooter";
import PasswordRecoveryStep1 from "./PasswordRecoveryStep1";
import SentPasswordRequest from "./SentPasswordRequest";
import CreateNewPasswordForm from "./CreateNewPasswordForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import LoginCard from "../../ui-components/cards/LoginCard";
import PasswordRecoveryVerificationCode from "./PasswordRecoveryVerificationCode";

const StepContent = ({ activeStep, handleNext, handleBack }) => {
  switch (activeStep) {
    case 0:
      return <PasswordRecoveryStep1 handleNext={handleNext}  handleBack={handleBack}/>;
    case 1:
      return (
        <PasswordRecoveryVerificationCode
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    // case 2:
    //   return (
    //     <SentPasswordRequest handleNext={handleNext} handleBack={handleBack} />
    //   );
    // // case 3:
    //   return (
    //     <CreateNewPasswordForm
    //       handleNext={handleNext}
    //       handleBack={handleBack}
    //     />
    //   );
    default:
      throw new Error("Unknown step");
  }
};

const PasswordRecoverySteps = () => {
  const navigate = useNavigate();
  const [recoveryPassActivePage, setRecoveryPassActivePage] = useState(0);

  const handleNext = () => {
    if (recoveryPassActivePage === 1) {
      navigate("/");
    }
    setRecoveryPassActivePage(recoveryPassActivePage + 1);
  };

  const handleBack = () => {
    if (recoveryPassActivePage === 0) {
      navigate("/");
    }
    setRecoveryPassActivePage(recoveryPassActivePage - 1);
  };
  const theme = useTheme();
  return (
    <>
      <MainHeader />
      <Grid container direction="row" sx={{ backgroundColor: "#2B0433" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={6}
          sx={{
            
            marginRight: "175px",  marginLeft:"0px",
            [theme.breakpoints.down("lg")]: {
              marginRight: "75px",
              marginLeft:"0px"
            },
            [theme.breakpoints.down("md")]: {
              marginRight: "55px",
              marginLeft: "55px",
            },
             [theme.breakpoints.down("sm")]: {
              marginRight: "35px",
              marginLeft: "35px",
            },
          }}
        >
          <LoginCard>
            {recoveryPassActivePage !== 0 && (
              <>
                <Grid item xs={1}>
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign="center" color="secondary" variant="h6">
                    Password Recovery
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item>
              <StepContent
                activeStep={recoveryPassActivePage}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            </Grid>
          </LoginCard>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={6}></Grid>
      </Grid>
      <MainFooter />
    </>
  );
};

export default PasswordRecoverySteps;
