import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import SynergiInsight from '../SynergiInsight/SynergiInsight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getlendingofferanylysisService } from '../../../../services/GetLendingOfferAnalysisService';
import { getCookie, getItem, setCookie, setItem } from '../../../Utils/CommonFunction/CommonFunction';
import { segment_offer_one_click_all_get_service } from '../../../../services/Segment_offer_one_click_all_get_Service';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { segment_offer_one_click_create_post_service } from '../../../../services/Segment_offer_one_click_create_post_Service';
import { useNavigate } from 'react-router-dom';

import { segmentsActions } from '../../../../store/slices/segments';
import { useDispatch } from 'react-redux';

const LeadingOffer = ({ setIsPageType }) => {
  const dispatch = useDispatch();
  const landingOfferData = getItem('landingOfferData');
  const landingOfferCall = getCookie('landingOfferCall');
  const [landingOffer, setLandingOffer] = useState(landingOfferData ? landingOfferData : []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const IsLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const getlandingOfferCall = getCookie('landingOfferCall');
  const [loading, setloading] = useState(false);
  const [SegmentButonLoading, setSegmentButonLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [oneclicksegment, setoneclicksegment] = useState();
  const [currentpost, setcurrentpost] = useState();
  const navigate = useNavigate();

  const { setSegmentCreate } = segmentsActions;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setloading(true);
    if (!landingOfferCall) {
      (async () => {
        const response = await getlendingofferanylysisService();
        if (response?.status === 200) {
          setloading(false);
          setLandingOffer(response?.data?.result);
          setItem('landingOfferData', response?.data?.result);
          setCookie('landingOfferCall', true, 1440);
        } else {
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setloading(true);
    (async () => {
      const response = await segment_offer_one_click_all_get_service();
      if (response?.status === 200) {
        setloading(false);
        setoneclicksegment(response?.data?.data);
        setItem('oneclicksegment', response?.data?.result);
        setCookie('oneclicksegment', true, 1440);
      } else {
      }
    })();
  }, []);

  useEffect(() => {
    if (!!currentpost) {
      (async () => {
        const data = {
          pk: currentpost?.pk,
          sk: currentpost?.sk,
          event_type: 'segment',
        };
        const response = await segment_offer_one_click_create_post_service(data);
        if (response?.status === 200) {
          const newResponse = {
            ...response?.data,
            segmentConfig: response?.data?.segmentConfig?.config_field,
          };
          dispatch(setSegmentCreate({ response: newResponse }));
          navigate(`/review-segment/${(response?.data?.sk).split('#')[1]}/${(response?.data?.pk).split('#')[1]}`, {
            state: {
              data: newResponse,
            },
          });
          setSegmentButonLoading(false);
        } else {
          setSegmentButonLoading(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentpost]);

  useEffect(() => {
    if (!!getlandingOfferCall) {
      setloading(false);
    }
  }, [getlandingOfferCall]);

  const currentData = landingOffer?.find((data) => data?.date_frequency === 'all');
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return loading ? (
    <>
      <Box sx={{ backgroundColor: '#F6F5F8' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </Box>
    </>
  ) : currentData ? (
    <>
      <div>
        <Grid container direction="column" sx={{ backgroundColor: '#F6F5F8' }}>
          <Grid item>
            <Grid container spacing={2} sx={{ padding: '20px 0px', marginTop: '0.2%' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  padding: '0px!important',
                }}
              >
                <section>
                  <Grid
                    container
                    sx={{
                      borderBottom: '2px solid #EBE8F1',
                      padding: '11px 22px',
                    }}
                  >
                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                          lineHeight: '40px',
                          color: '#000',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        Lending Offer Analysis
                      </Typography>
                      <img src={require('../../../../assets/title icon dashboard.png')} alt="" width={16} style={{ marginLeft: '10px' }} />
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <MoreVertIcon />
                    </Grid>
                  </Grid>
                </section>

                <section
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '1rem',
                  }}
                >
                  <Box>
                    <Grid container spacing={2} sx={{ marginTop: '4px' }} direction={isMobile || isTablet || IsLarge ? 'column' : 'row'}>
                      <Grid item xs={8.5} sm={8.5} md={6} lg={8.5} xl={8.5}>
                        <Table
                          sx={{
                            overflow: 'auto',
                          }}
                        >
                          <TableHead>
                            <TableRow className="tablerow">
                              <TableCell></TableCell>
                              {currentData?.items?.columns?.map((columns, subIndex) => (
                                <TableCell key={subIndex} align="center" className="title">
                                  {columns}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentData?.items?.rows?.map((row, index) => {
                              return (
                                <TableRow className="tablerow">
                                  {/* <TableCell className="tableHead">{row}</TableCell> */}
                                  <TableCell className="leadingTableRow">{row}</TableCell>
                                  {/* <TableCell ><div>{row}</div></TableCell> */}
                                  {currentData?.items?.items[index]?.map((value, subIndex) => (
                                    <TableCell key={subIndex} align="center" className="tableData" style={{ maxWidth: '100px' }}>
                                      {value ?? 0}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              );
                            })}

                            {/* <TableRow className="tablerow">
                            <TableCell className="tableHead">30 Y Fixed</TableCell>
                            {currentData?.items?.items?.map((channel, subIndex) => (
                              <TableCell key={subIndex} align="center" className="tableData" style={{ maxWidth: '100px' }}>
                                {numeral(channel.CPL).format('$0,0.0a')}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow className="tablerow">
                            <TableCell className="tableHead">20 Y Fixed</TableCell>
                            {currentData?.items?.items?.map((channel, subIndex) => (
                              <TableCell key={subIndex} align="center" className="tableData" style={{ maxWidth: '100px', overflow: 'hidden' }}>
                                {numeral(channel.quality_conversion_rate).format('0%')}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow className="tablerow">
                            <TableCell className="tableHead">15 Y Fixed</TableCell>
                            {currentData?.items?.items?.map((channel, subIndex) => (
                              <TableCell key={subIndex} align="center" className="tableData" style={{ maxWidth: '100px', overflow: 'hidden' }}>
                                {numeral(channel.quality_conversion_rate).format('0%')}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow className="tablerow">
                            <TableCell className="tableHead">10 Y Fixed</TableCell>
                            {currentData?.items?.items?.map((channel, subIndex) => (
                              <TableCell key={subIndex}  align="center" className="tableData" style={{ maxWidth: '100px', overflow: 'hidden' }}>
                                {numeral(channel.quality_conversion_rate).format('0%')}
                              </TableCell>
                            ))}
                          </TableRow> */}
                          </TableBody>
                        </Table>
                        {/* <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '14px',
                          }}
                        >
                          <button className={`box ${selectedData === 'all' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('all')}>
                            All
                          </button>
                          <button className={`box ${selectedData === '1d' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1d')}>
                            1D
                          </button>
                          <button className={`box ${selectedData === '3d' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3d')}>
                            3D
                          </button>
                          <button className={`box ${selectedData === '7d' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('7d')}>
                            7D
                          </button>
                          <button className={`box ${selectedData === '1m' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1m')}>
                            1M
                          </button>
                          <button className={`box ${selectedData === '3m' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3m')}>
                            3M
                          </button>
                        </div> */}
                      </Grid>

                      <Grid item xs={3} sm={3} md={5} lg={3} xl={3}>
                        <Grid>
                          <SynergiInsight />
                        </Grid>

                        <Grid>
                          <Slider {...settings}>
                            {oneclicksegment?.map((testimonial, index) => (
                              <Box
                                sx={{
                                  backgroundColor: '#F6F5F8',
                                  // padding: '16px 19px',
                                  borderRadius: '5px',
                                  margin: '0px 27px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={index}
                              >
                                <Typography
                                  sx={{
                                    color: '#000',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    marginBottom: '17px',
                                  }}
                                >
                                  Optimal Segment Refinancers
                                </Typography>
                                <Typography
                                  sx={{
                                    color: '#9853A6',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {testimonial?.name}
                                </Typography>
                                <Typography sx={{ marginBottom: '11px' }}>
                                  <span style={{ color: '#414446', fontFamily: 'IBM Plex Serif', fontSize: '24px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>
                                    {testimonial?.total_record}
                                  </span>
                                  <span style={{ color: '#707070', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: '18px' }}>
                                    {' '}
                                    prospects
                                  </span>
                                </Typography>
                                <Typography
                                  onClick={toggleExpansion}
                                  sx={{
                                    color: '#707070',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '18px',
                                    marginBottom: '23px',
                                    width: '250px', // Fixed width
                                    overflow: 'hidden',
                                    whiteSpace: isExpanded ? 'normal' : 'nowrap',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {testimonial?.description}
                                </Typography>
                                <Button
                                  sx={{
                                    color: '#A35BB1',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    padding: '0px',
                                    marginBottom: '9px',
                                  }}
                                  onClick={() => {
                                    setcurrentpost(testimonial);
                                    setSegmentButonLoading(true);
                                  }}
                                >
                                  {SegmentButonLoading ? <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} /> : 'Create Segment'}
                                </Button>
                              </Box>
                            ))}
                          </Slider>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </section>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Demo/> */}
      </div>
    </>
  ) : (
    ''
  );
};

export default LeadingOffer;
