import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ScaledPaper = (props) => {
  return (
    <Paper
      {...props}
      style={{
        ...props.style,
        color: "secondary",
      }}
    />
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#852598",
    },
  },
});

const CustomAutocomplete = ({ segment, selectedOffers, handleSelection }) => {
  // Find the offers for the specific segment
  const segmentOffers =
    selectedOffers.find((s) => s.segment_sk === segment.segment_sk)?.offers ||
    [];

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        className="reducedAutocomplete"
        size="small"
        key={segment.segment_sk}
        multiple
        isOptionEqualToValue={(option, value) =>
          option.offer_sk === value.offer_sk
        }
        options={segment.available_offers}
        getOptionLabel={(option) => option.offer_name}
        renderOption={(props, option, { index }) => (
          <div>
            <li {...props}>
              <h3
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  margin: "0px",
                }}
              >
                <h3>{option.offer_name}</h3>
                <h3>{option.offer_segment_count}</h3>
              </h3>
            </li>
            {index !== segment.available_offers.length - 1 && (
              <hr style={{ margin: "0px" }} />
            )}
          </div>
        )}
        value={segmentOffers}
        onChange={(_, values) => {
          if (values.length > 3) {
            values = values.slice(0, 3);
          }
          handleSelection(segment, values);
        }}
        disableCloseOnSelect
        PaperComponent={ScaledPaper}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={
              segmentOffers && segmentOffers.length > 0
                ? ""
                : "Start typing or click to select up to three offers"
            }
          />
        )}
      />
    </ThemeProvider>
  );
};

export default CustomAutocomplete;
