import TextField from "@mui/material/TextField";
import React from "react";


export const InputBox = ({
  className,
  placeholder,
  placeHolderStyles,
  rows,
  isMultiLine,
  onChange,
  value,
}) => {
  return (
    <TextField
      className={`inputBox-root ${className}`}
      multiline={isMultiLine}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        style: { ...placeHolderStyles, color: "black" },
      }}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={value}
      rows={rows}
    />
  );
};
