// LoadingScreen.js
import React from 'react';
import { Box, Modal } from '@mui/material';
import Loading1 from '../../../../assets/loading1.gif';
import Loading2 from '../../../../assets/loading2.gif';
import Loading3 from '../../../../assets/loading3.gif';
import Loading4 from '../../../../assets/loading4.gif';
import Loading5 from '../../../../assets/loading5.gif';
import { campaignScreen1, campaignScreen2, campaignScreen3, campaignScreen4, campaignScreen5 } from '../../../../Language/DialogBoxLanguage';

const LoadingScreen = ({ gifType }) => {
  return (
    <Modal open={true}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'Center', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 6,
            border: '1px solid #EBE8F1',
            background: '#FFF',
            boxShadow: '0px 16px 21px 0px rgba(0, 0, 0, 0.33)',
            width: { lg: '834px', md: '100%' },
            height: '371px',
            flexShrink: 0,
            marginBottom: '80px',
            p: 3,
          }}
        >
          {gifType === 1 && (
            <>
              <Box sx={{ padding: '75px' }}>
                <div className="manageAllCampaignText">{campaignScreen1.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {campaignScreen1.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading1} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
          {gifType === 2 && (
            <>
              <Box sx={{ padding: '75px' }}>
                <div className="manageAllCampaignText">{campaignScreen2.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {campaignScreen2.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading3} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
          {gifType === 3 && (
            <>
              <Box sx={{ padding: '75px' }}>
                <div className="manageAllCampaignText">{campaignScreen3.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {campaignScreen3.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading4} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
          {gifType === 4 && (
            <>
              <Box sx={{ padding: '75px' }}>
                <div className="manageAllCampaignText">{campaignScreen4.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {campaignScreen4.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading2} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
          {gifType === 5 && (
            <>
              <Box sx={{ padding: '75px' }}>
                <div className="manageAllCampaignText">{campaignScreen5.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {campaignScreen2.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading5} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
        </Box>
      </div>
    </Modal>
  );
};

export default LoadingScreen;
