import React, { useRef, useEffect, useState } from 'react';
// import "./Channels.css";
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Paper } from '@mui/material';
import { getItem } from '../../../Utils/CommonFunction/CommonFunction';
import { DASHBOARD_EMBED_URL_V2, dashboard_embed_url_v2 } from '../../../../common/config';

const CampaignsDashboard = () => {
  const dashboardRef = useRef(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);

  useEffect(() => {
    const loadEmbeddingContext = async () => {
      try {
        const context = await createEmbeddingContext();
        setEmbeddingContext(context);
      } catch (error) {
        console.error('Error creating embedding context:', error);
      }
    };

    loadEmbeddingContext().catch((error) => {
      console.error('Unhandled error in loadEmbeddingContext:', error);
    });
  }, []);

  useEffect(() => {
    if (!embeddingContext) return;

    const embedDashboard = async () => {
      try {
        const accessToken = getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found in localStorage');
        }

        const response = await fetch(`${DASHBOARD_EMBED_URL_V2}?dashboard_name=campaigns`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }

        const data = await response.json();
        const options = {
          url: data.EmbedUrl,
          container: dashboardRef.current,
          height: '80%',
          width: '80%',
          borderRadius: '6px',
        };

        await embeddingContext.embedDashboard(options);
        // embeddedDashboard.navigateToDashboard('9edef6ac-0bcf-4231-a669-5c9582b176af', {});
      } catch (error) {
        console.error('Error embedding dashboard:', error);
      }
    };

    embedDashboard().catch((error) => {
      console.error('Unhandled error in embedDashboard:', error);
    });
  }, [embeddingContext]);

  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        borderRadius: '6px',
      }}
    >
      <div className="dashboard-subtitle"> Campaigns</div>
      <div ref={dashboardRef} className="dashboard-embed" />
    </Paper>
  );
};

export default CampaignsDashboard;
