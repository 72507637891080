import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import CustomButton from './CustomButton';
import { documentUpdateWithSelectedChannels } from '../../../../services/Imports';
import { GET_HISTORICAL_EVENT_DATA } from '../../../../common/config';
import ErrorPage from '../../../../ErrorPage';
import { onboarding3, CommonLanguage } from '../../../../Language/language';

const First = {
  'Invalid Data Format': '2312',
  'Mismatched Data': '666',
  'Incompatible Characters': '56',
  'Removed from Import': '236',
};
const Second = {
  'UPB  >$20k': '28',
  'Under 60 Months': '56',
  'No AVM': '36',
  'Not Priced': '4,290',
  'Selected Dataset': '51,864',
};

const areAllFieldsSelected = () => {
  return true;
};

const Finalize = ({
  onDataSelected,
  importName,
  importDescription,
  fileKpi,
  applicationDataMatrix,
  documentId,
  togglePopUp,
  setPageType,
  imports,
  isDataUploading,
  setIsDataUploading,
  toggle,
  setToggle,
}) => {
  const scrollIntoImportListing = () => {
    const importLists = document.getElementById('import_lists');
    importLists?.scrollIntoView({ behavior: 'smooth' }, true);
  };

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [disabledFlag, setdisabledFlag] = useState(false);

  const handleClickNext = async () => {
    setdisabledFlag(true);
    // setIsDataUploading(true);
    const checkboxes = document.getElementsByName('checkbox');
    const selectedValues = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedValues.push(checkbox.value);
      }
    });
    onDataSelected(selectedValues);
    const response = await documentUpdateWithSelectedChannels(documentId, selectedValues);
    if (response.status === 200) {
      setdisabledFlag(false);
      setIsDataUploading(false);
      setPageType(3);
      setTimeout(() => {
        setPageType(1);
        togglePopUp(false);
      }, 5000);
      // scrollIntoImportListing();
      window.scrollTo(0, 0);
      imports(GET_HISTORICAL_EVENT_DATA, 'document');
    } else {
      setdisabledFlag(false);
      setIsDataUploading(false);
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
    }
  };

  const handleClickBack = () => {
    setPageType(1);
  };

  const formatField = (fieldName) => {
    return String(fieldName).replaceAll('_', ' ');
  };

  return (
    <div className="main-screen">
      <Typography
        sx={{
          color: ' #000000',
          fontFamily: 'Roboto',
          fontSize: '1.1rem',
          fontWeight: 'Medium',
          textTransform: 'capitalize',
        }}
      >
        {onboarding3.headlineText1}
      </Typography>
      <div className="grid-content">
        <Grid
          container
          spacing={1}
          className="content"
          style={{
            marginTop: '2vh',
            padding: '0.5rem',
          }}
        >
          {Object.entries(fileKpi).map(([key, value]) => (
            <Grid item xs={12 / Object.keys(First).length}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <Box
                  sx={{
                    color: '#414446',
                    fontFamily: 'Roboto',
                    fontSize: '0.6rem',
                    textTransform: 'capitalize',
                  }}
                  key={key}
                >
                  {formatField(key)}
                </Box>
                <Box className="grid-content-value" key={value}>
                  {value}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
      <Typography
        sx={{
          color: '#414446',
          fontFamily: 'Roboto',
          fontSize: '0.8rem',
          textAlign: 'right',
          lineHeight: '1.3rem',
        }}
      >
        {CommonLanguage.errordatamsg}
      </Typography>
      <hr
        style={{
          backgroundColor: '#d9d6e3',
          border: 'none',
          height: '1px',
          marginBottom: '2vh',
          marginTop: '2vh',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          fontFamily: 'Roboto',
        }}
      >
        <Typography
          sx={{
            color: '#00000',
            fontSize: '1.1rem',
            fontWeight: 'Medium',
            textTransform: 'capitalize',
          }}
        >
          {onboarding3.headlineText2}
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            color: '#000',
            lineHeight: '1.75rem',
          }}
        >
          {importName}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              lineHeight: '1.75rem',
              marginTop: '0.5rem',
            }}
          >
            {onboarding3.headlineText3}
          </Typography>
          <Typography
            sx={{
              color: '#414446',
              lineHeight: '1.5rem',
              fontSize: '0.9rem',
            }}
          >
            {importDescription}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'Roboto',
            fontSize: '0.9rem',
            lineHeight: '1.5rem',
            marginTop: '1rem',
          }}
        >
          {onboarding3.headlineText4}
        </Typography>
        <div className="grid-content">
          <Grid container spacing={1} className="content" sx={{ padding: '0.5rem' }}>
            {Object.entries(applicationDataMatrix).map(([key, value]) => (
              <Grid item xs={12 / Object.keys(Second).length}>
                <Box>
                  <div className="grid-content-key">{key}</div>
                  <br />
                  <div className="grid-content-value">{value}</div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>

      <hr
        style={{
          backgroundColor: '#d3abdb',
          border: 'none',
          height: '1px',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      />
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      <CustomButton
        areAllFieldsSelected={areAllFieldsSelected}
        handleClickBack={handleClickBack}
        handleClickNext={handleClickNext}
        disabled={disabledFlag}
        isDataUploading={isDataUploading}
      />
    </div>
  );
};

export default Finalize;