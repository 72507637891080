import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { snackbarReducer } from './slices/snackbar';
import { loginReducer } from './slices/login-slice';
import { segmentsReducer } from './slices/segments';
import { offersReducer } from './slices/offers';
import { dashboardReducer } from './slices/dashboard';
import UserUploadedFileReducer from '../ReduxCmp/Reducer/UserFileUploadedReducer';
import UserdashboardReducer from '../ReduxCmp/Reducer/UserdashboardReducer';
import CustomsetupReducer from '../ReduxCmp/Reducer/CustomsetupReducer';
import CustomcontactReducer from '../ReduxCmp/Reducer/CustomcontactReducer';
import getcustomerinformationReducer from '../ReduxCmp/Reducer/GetcustomerinfoReducer';
import customerdataintigrationpostReducer from '../ReduxCmp/Reducer/customerdataintigrationpostReducer';
import { samplefileReducer } from './slices/samplefileslice';
import { capableCampaignReducers } from './slices/capableCampaign';
// import { UsersReducer } from './slices/UsersSlice';

const allReduces = combineReducers({
  snackbar: snackbarReducer,
  login: loginReducer,
  sample: samplefileReducer,
  segments: segmentsReducer,
  offers: offersReducer,
  UserUploadedFileReducer: UserUploadedFileReducer,
  UserdashboardReducer: UserdashboardReducer,
  CustomsetupReducer: CustomsetupReducer,
  CustomcontactReducer: CustomcontactReducer,
  getcustomerinformationReducer: getcustomerinformationReducer,
  customerdataintigrationpostReducer: customerdataintigrationpostReducer,
  capableCampaign: capableCampaignReducers,
  // UsersReducer: UsersReducer,
});

const store = configureStore({
  reducer: allReduces,
});

export default store;
