import { Grid, Typography } from '@mui/material';
import React from 'react';
import MarketingAutomationData from './MarketingAutomationData';
import MarketingCard from './MarketingCard';

const MarketingAutomation = () => {
  return (
    <div>
      <Grid container direction="column" sx={{ backgroundColor: '#FAF8F7' }}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={8.6}
              md={8.6}
              lg={12}
              sx={{
                bgcolor: 'background.paper',
                m: 2,
                borderRadius: 2,
                padding: '0px!important',
              }}
            >
              <section>
                <Grid
                  container
                  sx={{
                    borderBottom: '1px solid #EBE8F1',
                    padding: '11px 22px',
                  }}
                >
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        fontFamily: 'IBM Plex',
                        fontSize: '24px',
                        lineHeight: '40px',
                        color: '#414446',
                      }}
                    >
                      Marketing Automation
                    </Typography>
                  </Grid>
                  {/* <Grid xs={2}>icon</Grid> */}
                </Grid>
              </section>
              <section>
                <Grid container>
                  <Grid
                    item
                    xs={8}
                    sm={8.6}
                    md={8.6}
                    lg={8}
                    sx={{
                      bgcolor: 'background.paper',

                      borderRadius: 2,
                      padding: '0px!important',
                    }}
                  >
                    <section
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '1rem',
                        padding: '28px 22px',
                      }}
                    >
                      <MarketingAutomationData />
                    </section>
                  </Grid>
                  <Grid item xs={4} lg={4} sx={{ padding: '0px 28px', borderLeft: '1px solid #EBE8F1', margin: '15px 0px' }}>
                    <MarketingCard />
                  </Grid>
                </Grid>
              </section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketingAutomation;
