import React, { useEffect, useState } from 'react';
import { Button, DialogContentText, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'otp-input-react';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import PasswordSuccessfully from './assets/PasswordSuccessfully.svg';
import { poolData } from '../Utils/CommonFunction/CommonFunction';
import { ForgotPasswordStep2 } from '../../Language/language';

const userPool = new CognitoUserPool(poolData);

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required!'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required!'),
});

const PasswordRecoveryVerificationCode = ({ handleNext, handleBack }) => {
  const [OTP, setOTP] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const forgetEmail = useSelector((state) => state.login.forget);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(
    // setting initial state
    () => () => {
      // eslint-disable-next-line no-use-before-define
      formik.resetForm({
        password: '',
        confirmPassword: '',
      });
    },
    []
  );
  const userData = {
    Username: forgetEmail, //#"ketulpadariya79@gmail.com", //registrationDetails.email, // the user's email
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema,

    onSubmit: async (values) => {
      setLoading(true);

      const { password } = values;

      try {
        cognitoUser.confirmPassword(OTP, password, {
          onSuccess: function (result) {
            setMessage('Password updated successfully');
            setDialogOpen(true);
            setLoading(false); // Clear loading when password update is successful
            // navigate('/login');
            // handleNext();
            navigate('/login');
          },
          onFailure: function (err) {
            setLoading(false); // Clear loading when password update fails
            setErrorMessage(err.message);
            // alert(err.message || JSON.stringify(err));
            return;
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  });
  const closeDialog = () => {
    setDialogOpen(false);
    navigate('/login');
    // Redirect or perform any action you want after closing the dialog
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12} sx={{ p: '2%' }}>
        <Typography variant="h6" textAlign="center">
          We have sent a password reset code by email to ({forgetEmail}) Enter it below to reset your password.
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" textAlign="center" sx={{ mb: '2%' }}>
          {ForgotPasswordStep2.otpTitle}
        </Typography>
        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />

        {errorMessage ? (
          <Grid item>
            <Typography
              sx={{
                color: 'red',
                textAlign: 'center',
                fontFamily: 'IBM Plex Serif',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '20px',
              }}
            >
              {errorMessage}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} sx={{ mt: 5 }}>
          <TextField
            id="enter-new-password"
            required
            label="Enter New Password"
            type={showPassword ? 'text' : 'password'}
            size="medium"
            sx={{ width: '350px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps('password')}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            id="confiem-new-password"
            required
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            size="medium"
            sx={{ width: '350px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle confirm password visibility" onClick={handleClickShowConfirmPassword} edge="end">
                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps('confirmPassword')}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          />
        </Grid>
        <Grid item sx={{ mt: 5, mb: 20 }}>
          <Button variant="contained" onClick={formik.handleSubmit} size="medium" fullWidth color="secondary" disabled={loading}>
            {loading ? <CircularProgress size={24} color="secondary" /> : 'UPDATE PASSWORD'}
          </Button>
        </Grid>
        {/* <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>Password Update Successful</DialogTitle>
          <DialogContent>
            <Typography>
              Your password has been updated successfully.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="secondary">
              Go to Login
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog alignContent="center" minWidth="md" open={dialogOpen} onClose={closeDialog}>
          <DialogContentText>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3} sx={{ p: 5 }}>
              <Grid item>
                <IconButton>
                  <img src={PasswordSuccessfully} alt="left move" width="150px" height="150px" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: '#132529',
                    textAlign: 'center',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '25px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  Password Updated <br /> Successfully
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: '#ffffff',
                    fontFamily: 'Roboto',
                    fontSize: '1rem',
                    fontWeight: 'Bold',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    width: '17rem',
                    height: '48px',
                    borderRadius: '6px',
                    backgroundColor: '#852598',
                    ':hover': {
                      backgroundColor: '#852598',
                    },
                  }}
                  onClick={closeDialog}
                >
                  Log in
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </Dialog>
      </form>
    </Grid>
  );
};

export default PasswordRecoveryVerificationCode;
