import { CUSTOM_INTIGRATION_SETUP_LOADING, IS_CUSTOM_INTIGRATION_SETUP_ERROR, IS_CUSTOM_INTIGRATION_SETUP_SUCCESS, RESET_IS_CUSTOM_INTIGRATION_SETUP } from "../Constant";

let initialState = {
    CustomsetupReducer: {
        loading: false,
        data: null,
        error: false,
        errors: null,
        success: false,
        pagination: {},
    },
};

export default function CustomsetupReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOM_INTIGRATION_SETUP_LOADING:
            return {
                ...state,
                CustomsetupReducer: {
                    ...state.CustomsetupReducer,
                    loading: true,
                    error: false,
                    errors: [],
                    success: false,
                },
            };

        case IS_CUSTOM_INTIGRATION_SETUP_SUCCESS:
            return {
                ...state,
                CustomsetupReducer: {
                    ...state.CustomsetupReducer,
                    loading: false,
                    error: false,
                    errors: [],
                    success: true,
                    data: action.response.data,
                },
            };

        case IS_CUSTOM_INTIGRATION_SETUP_ERROR:
            return {
                ...state,
                CustomsetupReducer: {
                    ...state.CustomsetupReducer,
                    loading: false,
                    error: true,
                    errors: action.errors,
                    success: false,
                    data: null,
                },
            };

        case RESET_IS_CUSTOM_INTIGRATION_SETUP:
            return {
                ...state,
                CustomsetupReducer: {
                    ...state.CustomsetupReducer,
                    loading: false,
                    error: false,
                    errors: null,
                    success: false,
                },
            };

        default:
            return state;
    }
}