import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
// import ChannelPopUp from "../ChannelPopUp/ChannelPopUp";
import { useReactiveVar } from '@apollo/client';
import channelIconMapping from '../../../../Utils/icons/channelIconMapping';
import { CustomizedIcon, SelectTemplateIcon } from '../../../../Utils/icons/icons';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { offersData as setOffersData } from '../../../GlobalStore';
import ChannelPopUp2 from '../ChannelPopUp/ChannelPopUp2';

const ChannelCard = ({ channelData, saveData, channel, globalDateTime, globalSpendingLimit, globalBatchData }) => {
  const [open, setOpen] = useState(false);
  const offersData = useReactiveVar(setOffersData);
  const [isCustomized, setIsCustomized] = useState(false);
  const [isSaved, setIsSaved] = useState(offersData?.some((offer) => offer.channel === channel) || false);
  const matchingChannel = offersData?.find((offer) => offer.channel === channelData.channel);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleSave = (segmentData) => {
    setIsSaved(true);
    saveData(channelData.channel, segmentData);
    handleClose();
  };

  const countOffers = (offersData) => {
    return matchingChannel?.segment_offers?.reduce((acc, segment) => {
      return acc + segment.offers.length;
    }, 0);
  };

  useEffect(() => {
    const doesSegmentMatchGlobalSettings = (segment) => {
      const startDateTime = `${globalDateTime.startDate} ${globalDateTime.startTime}`;
      const endDateTime = `${globalDateTime.endDate} ${globalDateTime.endTime}`;
      return (
        segment.segment_start_datetime === startDateTime &&
        segment.segment_end_datetime === endDateTime &&
        segment.segment_min_budget === globalSpendingLimit.min &&
        segment.segment_max_budget === globalSpendingLimit.max &&
        segment.segment_budget_type === globalSpendingLimit.period &&
        segment.segment_no_limit === globalSpendingLimit.noLimit &&
        segment.segment_batch_size === globalBatchData.batch_size &&
        segment.segment_batch_type === globalBatchData.batch_type
      );
    };

    const isAnySegmentCustomized = matchingChannel?.segment_offers?.some((segment) => !doesSegmentMatchGlobalSettings(segment));

    setIsCustomized(isAnySegmentCustomized);
  }, [globalDateTime, globalSpendingLimit, globalBatchData, isSaved, offersData]);

  return (
    <Card
      sx={{
        width: '215.12px',
        height: '143.675px',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #EBE8F1',
        background: '#FFF',
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
      }}
      onClick={handleOpen}
    >
      <CardContent>
        <div
          className="headerText"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '14px',
            textTransform: 'capitalize',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}> {channelData?.channel && channelIconMapping[channelData.channel.toLowerCase()]}</div>
            {channelData?.channel}
          </div>
          {isSaved && <CheckBoxIcon color="secondary" />}
        </div>

        <div className="customHR"></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '15px',
          }}
        >
          <div className="card-number" style={{ marginRight: '5px' }}>
            {matchingChannel ? countOffers(offersData?.segmentData?.[channel]) : channelData.total_offers}
          </div>
          <div className="normalText" style={{ marginRight: '5px' }}>
            {matchingChannel ? 'selected offers' : 'matched offers'}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isCustomized && (
            <>
              <CustomizedIcon />

              <div
                className="normalText"
                style={{
                  color: '#A35BB1',
                  marginLeft: '7px',
                  fontSize: '12px',
                }}
              >
                Customized Settings
              </div>
            </>
          )}

          {!isSaved && (
            <>
              <SelectTemplateIcon />

              <div
                className="normalText"
                style={{
                  color: '#A35BB1',
                  marginLeft: '7px',
                  fontSize: '12px',
                }}
              >
                Select Template
              </div>
            </>
          )}
        </div>
      </CardContent>
      {/* <ChannelPopUp
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        channelData={channelData}
        channel={channel}
        globalDateTime={globalDateTime}
        globalSpendingLimit={globalSpendingLimit}
      /> */}

      <ChannelPopUp2
        open={open}
        handleClose={handleClose}
        channelData={channelData}
        channel={channel}
        globalDateTime={globalDateTime}
        globalBatchData={globalBatchData}
        globalSpendingLimit={globalSpendingLimit}
        handleSave={handleSave}
      />
    </Card>
  );
};

export default ChannelCard;
