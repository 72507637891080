import React, { useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { Grid, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import MFADownloadApp from '../login/assets/MFADownloadApp.svg';
import { useNavigate } from 'react-router-dom';
import RegistrationLoadedDialog2 from './RegistrationLoadedDialog2';
import RegistrationLoadedDialog1 from './RegistrationLoadedDialog1';
import RegistrationLoadedDialog3 from './RegistrationLoadedDialog3';
import { useState } from 'react';
import { clearItem, getCookie, getItem, poolData } from '../Utils/CommonFunction/CommonFunction';

const userPool = new CognitoUserPool(poolData);

const MFAScreenRegister = () => {
  const navigate = useNavigate();
  const [currentDialog, setCurrentDialog] = useState(0);
  const [deviceName, setDeviceName] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const QRCodeURL = getItem('qrCodeURL');
  const QRCodeURLFromCookie = getCookie('qrCodeURL');
  const userEmail = getItem('userEmail');
  const userPassword = getItem('userPassword');
  const validationSchema = Yup.object({
    code: Yup.string()
      .required('Code is required!')
      .matches(/^[0-9]{6}$/, 'Please enter a valid 6-digit code'),
  });
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: userEmail,
        Password: userPassword,
      });

      const cognitoUser = new CognitoUser({
        Username: userEmail,
        Pool: userPool,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          cognitoUser.verifySoftwareToken(values.code, `${deviceName ? deviceName : cognitoUser.getUsername()}Device`, {
            onSuccess: (result) => {
              const firstTimer = setTimeout(() => setCurrentDialog(1), 0);
              const secondTimer = setTimeout(() => setCurrentDialog(2), 10000);
              const thirdTimer = setTimeout(() => setCurrentDialog(3), 20000);
              // Clear all timers when response is received
              clearTimeout(firstTimer);
              clearTimeout(secondTimer);
              clearTimeout(thirdTimer);
              cognitoUser.enableMFA((err, result) => {
                if (err) {
                  return err;
                }
              });
              if (result) {
                setCurrentDialog(0);
                setSuccessDialogOpen(true);
                clearItem();
                navigate('/login');
                return result;
              }
            },
            onFailure: (err) => {
              formik.setFieldError('code', err.message);
              return err;
            },
            mfaCodeDeliveryDetails: {
              Destination: userEmail,
            },
          });
        },
      });

      setSubmitting(false);
    },
  });
  useEffect(() => {}, [QRCodeURL]);
  return (
    <div>
      {currentDialog === 1 && <RegistrationLoadedDialog1 open={true} />}
      {currentDialog === 2 && <RegistrationLoadedDialog2 open={true} />}
      {currentDialog === 3 && <RegistrationLoadedDialog3 open={true} />}
      <Grid
        container
        spacing={2}
        sx={{
          pt: { xs: '10%', sm: '4%', md: '4%' },

          pb: '3%',
          width: '507px',
        }}
      >
        <Card>
          <CardContent>
            <Grid container direction="column" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sx={{ mb: '5%', mt: '5%' }} style={{ paddingTop: '5px' }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { lg: 30, md: 25, sm: 20, xs: 18 },
                    fontFamily: 'IBM Plex Serif',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    color: '#132529',
                  }}
                >
                  Set up Authenticator App MFA
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: '80%',
                  paddingTop: '19px',
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <Typography
                      style={{
                        color: '#112333',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        border: '1.5px solid #112333',
                        borderRadius: '50%',
                        width: '25px',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      1
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '14px',
                        fontFamily: 'IBM Plex Serif',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        color: '#132529',
                      }}
                    >
                      Install an authenticator app on your mobile device.
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <img src={MFADownloadApp} alt="MFADownloadApp" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                sx={{
                  mt: '5%',
                  width: '80%',
                  paddingTop: '19px',
                }}
              >
                <Grid item xs={1}>
                  <Typography
                    style={{
                      color: '#112333',
                      textAlign: 'center',
                      fontFamily: 'Roboto',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      border: '1.5px solid #112333',
                      borderRadius: '50%',
                      width: '25px',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    2
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'IBM Plex Serif',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      color: '#132529',
                    }}
                  >
                    Scan this QR code with your authenticator app. Alternatively, you can manually enter a secret key in your authenticator app..
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ display: 'flex', justifyContent: 'end' }}>
                  <QRCodeSVG value={QRCodeURL ? QRCodeURL : QRCodeURLFromCookie} />
                </Grid>
              </Grid>

              <Grid container style={{ paddingLeft: '45px', paddingTop: '19px' }}>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      color: '#112333',
                      textAlign: 'center',
                      fontFamily: 'Roboto',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      border: '1.5px solid #112333',
                      borderRadius: '50%',
                      width: '25px',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="flex-start">
                      <Grid item xs={12}>
                        <TextField
                          id="code"
                          type="text"
                          fullWidth
                          size="medium"
                          sx={{
                            width: '356px',
                          }}
                          placeholder="MFA code"
                          {...formik.getFieldProps('code')}
                          error={formik.touched.code && Boolean(formik.errors.code)}
                          helperText={formik.touched.code && formik.errors.code}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sx={{ m: '3%' }}>
                      <TextField
                        id="deviceName"
                        type="text"
                        fullWidth
                        size="medium"
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '45vh',
                            lg: '45vh',
                          }, // Adjust width for md and lg
                        }}
                        onChange={(e) => setDeviceName(e.target.value)}
                        placeholder="Your Device name"
                      />
                    </Grid> */}
                      <Grid item sx={{ mt: { xs: '2%', sm: '3%', lg: '4%' } }}>
                        <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: '356px' }}>
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default MFAScreenRegister;
