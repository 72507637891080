import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import MyModal from './MyModal';
import { connect } from 'react-redux';
import { customerdataintigrationpostAction } from '../../../ReduxCmp/UserAction/Useraction';
import { useNavigate } from 'react-router-dom';
import { DataIntelligenceScreen, CommonLanguage, DataInCard } from '../../../Language/language';

const CustomerData = (props) => {
  const DATA_MANAGMENT_LIST = [
    'Web Analytics & Engagement Data',
    'Campaign Reporting Data',
    'Call Center Data',
    'Chat Data',
    'Lead Management & Performance Data',
    'Application Pipeline Data',
    'Servicing Loan Data Snapshots',
    'Third Party Lead Data',
    'Pricing Engine Data',
    'Internal Customer Data from Other Lines of Businesses',
    'Customer Engagement Data History',
  ];

  const [checkedItems, setCheckedItems] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [customerintipost, setcustomerintipost] = useState();
  const navigate = useNavigate();

  const [CustomerData, setCustomerData] = useState({
    customer_data: '',
    customer_database: '',
  });

  const closeModal = () => setShowModal(false);

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handlecustomer = (e) => {
    const { name, value } = e.target;
    setCustomerData((preve) => ({
      ...preve,
      [name]: value,
    }));
  };

  const handlesubmit = () => {
    const details = {
      current_management_solutions: checkedItems,
      other_data: CustomerData?.customer_data,
      size_customer_db: CustomerData?.customer_database,
    };
    props.customerdataintigrationpostAction(details);
    setShowModal(true);
  };

  useEffect(() => {
    if (props?.customerdataintigrationpostReducer?.success) {
      setShowModal(false);
      setcustomerintipost(props.customerdataintigrationpostReducer?.data);
      navigate('/contactinfo');
    }
  }, [props.customerdataintigrationpostReducer]);

  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <section
            style={{
              padding: '0px 22px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                padding: '20px 0px',
                color: '#414446',
              }}
            >
              {DataIntelligenceScreen.subTitle1}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '#5D596C',
                paddingBottom: '15px',
              }}
            >
              {CommonLanguage.fieldrequiredText}
            </Typography>
          </section>
          <section
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              border: '1px solid #EBE8F1',
              borderRadius: '5px',
              height: '371px',
              width: '679px',
              marginLeft: '22px',
            }}
          >
            <Grid container spacing={1} sx={{ padding: '25px' }}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    paddingBottom: '25px',
                  }}
                >
                  {DataIntelligenceScreen.headlineText1}
                </Typography>
              </Grid>
              {DATA_MANAGMENT_LIST.map((dataText, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  style={{
                    paddingTop: '3px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={checkedItems[dataText] || false}
                          onChange={handleCheckboxChange}
                          name={dataText}
                          style={{
                            color: checkedItems[dataText] ? '#4CAF50' : 'inherit',
                          }}
                        />
                      }
                      label={<Typography style={{ fontSize: '12px' }}>{dataText}</Typography>}
                      style={{
                        marginRight: '5px',
                        color: checkedItems[dataText] ? '#4CAF50' : 'inherit',
                      }}
                    />
                    {/* <label style={{  fontSize: "12px" }}>{dataText}</label> */}
                  </div>
                </Grid>
              ))}
              <Grid item xs={12} style={{ paddingTop: '51px' }}>
                <TextField type="text" fullWidth size="medium" name="customer_data" placeholder="Other Customer Data" onChange={handlecustomer} />
              </Grid>
            </Grid>
          </section>
          <section
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              border: '1px solid #EBE8F1',
              borderRadius: '5px',
              height: '137px',
              width: '679px',
              marginLeft: '22px',
              marginTop: '18px',
            }}
          >
            <Grid container spacing={1} sx={{ padding: '25px' }}>
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                  }}
                >
                  {DataIntelligenceScreen.headlineText2}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: '20px' }}>
                <TextField type="text" name="customer_database" fullWidth size="medium" placeholder="Aproximate GB" onChange={handlecustomer} />
              </Grid>
            </Grid>
          </section>

          <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: { lg: '179px' }, margin: '51px 22px 29px 20px' }} onClick={handlesubmit}>
            {DataIntelligenceScreen.DataInbtn}
          </Button>
          {showModal && <MyModal closeModal={closeModal} />}
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            sx={{
              width: '385px',
              height: '226px',
              backgroundColor: '#F6F5F8',
              border: '1px solid #EBE8F1',
              borderRadius: '5px',
              marginLeft: '49px',
              marginTop: '19px',
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  borderBottom: '1px solid #D9D6E3',
                  padding: '17px 22px 19px 17px',
                }}
              >
                {DataInCard.headlineText1}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '19px',
                  padding: '15px 22px',
                  color: '#414446',
                }}
              >
                {DataInCard.subTitle1}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProp = (state) => ({
  customerdataintigrationpostReducer: state.customerdataintigrationpostReducer.customerdataintigrationpost,
});

const mapDispatchToProps = (dispatch) => ({
  customerdataintigrationpostAction: (details) => dispatch(customerdataintigrationpostAction(details)),
  // resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
});

export default connect(mapStateToProp, mapDispatchToProps)(CustomerData);
