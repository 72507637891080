import React, { useState, useEffect } from 'react';
import ChannelCard from './ChannelCard/ChannelCard';
import { offersData as setOffersData } from '../../GlobalStore';
import { useReactiveVar } from '@apollo/client';
import { Box, Grid, Skeleton } from '@mui/material';

const OfferChannel = ({ globalDateTime, globalSpendingLimit, channelRows, globalBatchData, boxLoader }) => {
  const offersData1 = useReactiveVar(setOffersData);

  const saveData = (channel, segementData) => {
    const currentState = [...offersData1];
    const totalOffers = segementData.reduce((acc, segment) => {
      return acc + (segment.offers ? segment.offers.length : 0);
    }, 0);

    const existingChannelIndex = currentState.findIndex((offer) => offer.channel === channel);

    if (existingChannelIndex > -1) {
      currentState[existingChannelIndex] = {
        ...currentState[existingChannelIndex],
        segment_offers: segementData,
        total_offers: totalOffers,
      };
    } else {
      currentState.push({
        channel: channel,
        segment_offers: segementData,
        total_offers: totalOffers,
      });
    }
    setOffersData(currentState);
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Grid container spacing={1}>
        {channelRows &&
          channelRows.map((rowChannels, rowIndex) => (
            <Grid item key={rowIndex}>
              <div>
                {boxLoader ? (
                  <Box sx={{ backgroundColor: '#F6F5F8' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={24} sx={{ width: 250 }}>
                        <Skeleton variant="rectangular" width="100%" height={150} />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <>
                    {/* <Grid container spacing={2}>
                      <Grid item xs={12}> */}
                    <ChannelCard
                      key={rowChannels.channel}
                      channelData={rowChannels}
                      channel={rowChannels.channel}
                      globalDateTime={globalDateTime}
                      globalSpendingLimit={globalSpendingLimit}
                      globalBatchData={globalBatchData}
                      saveData={saveData}
                    />
                    {/* </Grid>
                    </Grid> */}
                  </>
                )}
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default OfferChannel;
