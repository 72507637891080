import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const BulletChart = ({ data, size, color, dark_color }) => {
  const svgRef = useRef();

  useEffect(() => {
    const margin = { top: 10, right: 10, bottom: 10, left: 10 };
    const width = 150 - margin.left - margin.right; // This can be adjusted to your preference
    const height = 8; // The height of the bars
    const lineHeight = height * 2;

    // Function to calculate corner radius
    const calculateCornerRadius = (position, totalWidth) => {
      const maxCornerRadius = height / 2;
      // If the position is at the start or the end of the bar, use the max radius
      if (position === 0 || position === totalWidth) {
        return maxCornerRadius;
      }
      // No radius if the position is in the middle of the bar
      return 0;
    };
    // Clear previous contents before redrawing
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleLinear().domain([0, size]).range([0, width]);

    // Range Background with a right-side border radius
    svg.append('rect').attr('x', xScale(0)).attr('y', 0).attr('width', xScale(size)).attr('height', height).attr('rx', '3px').attr('ry', '3px').style('fill', '#ccc');

    // Calculate the width of the blue and red sections
    const blueWidth = xScale(Math.min(parseNumber(data?.forecast), parseNumber(data?.present)));
    const redWidth = xScale(parseNumber(data?.present)) - blueWidth;
    function parseNumber(str) {
      if (str) {
        return parseInt(str.replace(/,/g, ''), 10); // Remove commas and convert to integer
      }
      return 0; // Default to 0 if str is undefined or null
    }
    // Blue section with dynamic rounded corners
    svg.append('rect').attr('x', xScale(0)).attr('y', 0).attr('width', blueWidth).attr('height', height).attr('rx', '3px').attr('ry', '3px').style('fill', color);

    // Red section with dynamic rounded corners
    svg.append('rect').attr('x', blueWidth).attr('y', 0).attr('width', redWidth).attr('height', height).attr('rx', '3px').attr('ry', '3px').style('fill', dark_color);

    // Target Value Line
    if (data?.line != null) {
      svg
        .append('line')
        .attr('x1', xScale(data.line))
        .attr('x2', xScale(data.line))
        .attr('y1', -lineHeight / 4) // Adjust the starting y position based on the line height
        .attr('y2', height + lineHeight / 4) // Extend the line beyond the bar height
        .style('stroke', data.present > data.forecast ? 'black' : 'gray')
        .style('stroke-width', '1px'); // Increase the stroke width to make the line thicker
    }
  }, [data, size, color, dark_color]);

  return <svg ref={svgRef} />;
};

export default BulletChart;
