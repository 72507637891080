import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubHeader from '../ui-components/pageHeaders/SubHeader';
import MainFooter from '../view/mainFooter/MainFooter';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../view/integration/Components/Loader';
import { getItem } from '../view/Utils/CommonFunction/CommonFunction';
import Overview from '../assets/Overview.svg';
import Profile from '../assets/Profile.svg';
import Logout from '../assets/Logout.svg';
import { clearAllCookies, clearItem } from '../view/Utils/CommonFunction/CommonFunction';
import { logout, CommonLanguage } from '../Language/language';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { SETTINGS_GET_PROFILE_PICTURE } from '../common/config';
import axios from 'axios';

const DeskTopLayout = () => {
  const [file, setFile] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { sk, pk } = useParams();
  const accessToken = getItem('accessToken');
  useEffect(() => {
    if (location.pathname === '/segments' && location.state?.scrollToChannel) {
      const channelComponent = document.getElementById('channelComponent');
      if (channelComponent) {
        channelComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const getSubheaderTitle = () => {
    const pathname = location.pathname;
    const _pk = pk?.replaceAll(' ', '%20');
    switch (pathname) {
      case '/campaigns':
        return 'Campaigns';
      case '/create-campaign':
        return 'Campaigns';
      case `/create-campaign/data-source/${sk}/${_pk}`:
        return 'Campaigns';
      case `/create-campaign/segment/${sk}/${_pk}`:
        return 'Campaigns';
      case `/create-campaign/offer/${sk}/${_pk}`:
        return 'Campaigns';
      case `/create-campaign/review/${sk}/${_pk}`:
        return 'Campaigns';
      case '/app-integrations':
        return 'Integrations';
      case '/segments':
        return 'Segments';
      case '/create-segment':
        return 'Segments';
      case '/create-segment/custom':
        return 'Segments';
      case `/edit-segment/${sk}/${_pk}`:
        return 'Segments';
      case `/review-segment/${sk}/${_pk}`:
        return 'Segments';
      case `/copy-segment/${sk}/${_pk}`:
        return 'Segments';
      case '/offers':
        return 'Offers';
      case '/create-offer':
        return 'Offers';
      case `${'/review-offer/'}${sk}/${_pk}`:
        return 'Offers';
      case `${'/edit-offer/'}${sk}/${_pk}`:
        return 'Offers';
      case '/onboarding':
        return 'How Synergi works';
      case '/CustmerDataintelligence':
        return 'Custmer Data Intelligence';
      // case '/onboarding':
      //   return '360 Dashboard';
      case '/channels':
        return '360 Dashboard';
      case '/campaigns-dasboard':
        return '360 Dashboard';
      case '/industry-comparison':
        return '360 Dashboard';
      // case '/setting':
      //   return 'Account Management';
      case '/sales':
        return 'Portfolio Analytics Dashboard';
      case '/CrossSell':
        return 'Portfolio Analytics Dashboard';
      case '/portfolio':
        return 'Portfolio Analytics Dashboard';
      case '/marketing':
        return 'Marketing Analytics Dashboard';
      case '/setting/overview':
        return 'Overview';
      case '/setting/profile':
        return 'Profile';
      case '/setting/user':
        return 'Company Users';
      case '/setting/security':
        return 'Security';
      case '/setting/companyDetail':
        return 'Company Details';
      case '/setting/sftpUser':
        return 'SFTP User';
      case '/DatamissingPopup':
        return 'Campaigns';

      case '/setting':
        return 'Account Management';


      case '/machinerypartner':
        return 'PPC Deep Dive';
      // Add more cases as needed...
      default:
        return 'Integration'; // Default title
    }
  };

  const subheaderTitle = getSubheaderTitle();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogout = () => {
    setDialogOpen(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleProfileClick = () => {
    navigate('/setting/profile', {
      state: {
        profile: 'profile',
      },
    });
    handleMenuClose();
  };

  const handleNotificationsClick = () => {
    navigate('/setting/overview');
    handleMenuClose();
  };


  const handleLogoutConfirmed = async () => {
    setDialogOpen(false);
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_CLIENT_ID,
    });

    const currentUser = userPool.getCurrentUser();

    navigate('/login');
    clearAllCookies();
    clearItem();

    if (currentUser) {
      currentUser.signOut();
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
    }
  };
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (location.pathname === '/onboarding') {
      setValue(0);
    } else if (location.pathname === '/app-integrations' || location?.pathname === '/imports' || location?.pathname === '/exports') {
      setValue(1);
    } else if (
      location.pathname === '/segments' ||
      location?.pathname === '/create-segment' ||
      location.pathname === '/create-segment/custom' ||
      location?.pathname === `/edit-segment/${sk}/${pk}` ||
      location.pathname === `/review-segment/${sk}/${pk}` ||
      location.pathname === `/copy-segment/${sk}/${pk}`
    ) {
      setValue(2);
    } else if (
      location.pathname === '/offers' ||
      location?.pathname === '/create-offer' ||
      location?.pathname === `/review-offer/${sk}/${pk}` ||
      location?.pathname === `/edit-offer/${sk}/${pk}`
    ) {
      setValue(3);
    } else if (
      location.pathname === '/campaigns' ||
      location?.pathname === '/create-campaign' ||
      location.pathname === `/create-campaign/data-source/${sk}/${pk}` ||
      location.pathname === `/create-campaign/segment/${sk}/${pk}` ||
      location.pathname === `/create-campaign/offer/${sk}/${pk}` ||
      location.pathname === `/create-campaign/review/${sk}/${pk}`
    ) {
      setValue(4);
    } else if (location.pathname.includes('/setting/')) {
      setValue(null);
    }
    const accessToken = getItem('accessToken');
    if (accessToken) setIsAuthenticated(true);
    // else navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    // Fetch an image from the API
    axios
      .get(SETTINGS_GET_PROFILE_PICTURE, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      .then((response) => {
        // Set the retrieved image in the file state
        setFile(response.data);
      })

      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  }, [accessToken]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const menu = [
    {
      title: '360 Dashboard',
      name: 'dashboard',
      link: '/onboarding',

      menuItems: [
        {
          title: 'Portfolio Analytics',
          name: 'funnel',
          link: '/portfolio',
        },
        {
          title: 'Marketing Funnel',
          name: 'Marketing',
          link: '/marketing',
        },
        {
          title: 'Sales Performance (Coming Soon)',
          name: 'Sales',
          link: '/sales',
          disable: true,
        },
        {
          title: 'Cross-Sell Opportunities (Coming Soon)',
          name: 'Cross-Sell',
          link: '/CrossSell',
          disable: true,
        },
        {
          title: 'Machinery Partner',
          name: 'machinerypartner',
          link: '/machinerypartner',
          disable: false,
        },
      ],
    },
    {
      title: 'Integrations',
      name: 'integrations',
      link: '/app-integrations',

      menuItems: [
        {
          title: 'Automation',
          name: 'app-integrations',
          link: '/app-integrations',
        },
        { title: 'Data Import', name: 'imports', link: '/imports' },
        {
          title: 'Data Export',
          name: 'exports',
          link: '/exports',
        },
      ],
    },
    {
      title: 'Segments',
      name: 'segments',
      link: '/segments',

      menuItems: [
        {
          title: 'All Segments',
          name: 'segments',
          link: '/segments',
        },
        {
          title: 'Create Segment',
          name: 'new-segments',
          link: '/create-segment',
        },
      ],
    },
    {
      title: 'Offers',
      name: 'offers',
      link: '/offers',

      menuItems: [
        {
          title: 'All Offers',
          name: 'offers',
          link: '/offers',
        },
        {
          title: 'Create Offer',
          name: 'new-offers',
          link: '/create-offer',
        },
      ],
    },
    {
      title: 'Campaigns',
      name: 'Campaigns',
      link: '/campaigns',

      menuItems: [
        {
          title: 'All Campaigns',
          name: 'campaigns',
          link: '/campaigns',
        },
        {
          title: 'New Campaign',
          name: 'create-campaign',
          link: '/create-campaign',
        },
        {
          title: 'Draft',
          name: 'draft',
        },
        {
          title: 'Completed',
          name: 'completed',
        },
      ],
    },
  ];

  const profileData = [
    {
      title: 'Overview',
      name: 'overview',
      link: '/setting/overview',
    },
    {
      title: 'Profile',
      name: 'profile',
      link: '/setting/profile',
    },
    {
      title: 'Company Users',
      name: 'user',
      link: '/setting/user',
    },
    {
      title: 'Security',
      name: 'security',
      link: '/setting/security',
    },
    {
      title: 'Company Details',
      name: 'company-detail',
      link: '/setting/companyDetail',
    },
    {
      title: 'SFTP Details',
      name: 'sftp-details',
      link: '/setting/sftpUser',
    },
  ];

  return (
    <Grid container direction="column" sx={{ backgroundColor: '#FFF' }}>
      <Grid
        item
        sx={{
          py: '0px',
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item className="LeftSide" xs={2}>
                <Button
                  onClick={() => {
                    navigate('/onboarding');
                  }}
                >
                  <img
                    alt="header"
                    style={{
                      marginLeft: '1.7vh',
                      width: '135px',
                    }}
                    src="https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/image-255@2x.png"
                  />
                </Button>
              </Grid>

              <Grid item xs={9}>
                <Tabs value={value} onChange={handleChange} aria-label="nav tabs" sx={{ borderBottom: 'none !important' }}>
                  {menu.map((menu, menuIndex) => {
                    return (
                      <Tab
                        onClick={() => navigate(menu.link)}
                        label={
                          <Link
                            to={value === menuIndex}
                            style={{
                              textDecoration: 'none',
                              // color: 'inherit',
                              color: ' #414446',
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                              paddingRight: '11px',
                            }}
                          >
                            {menu.title}
                          </Link>
                        }
                      />
                    );
                  })}
                </Tabs>
              </Grid>

              <Grid item xs={1}>
                <Grid container justifyContent="flex-start">
                  <Grid item>
                    <Grid item sx={{ marginRight: '30px' }}>
                      <IconButton onClick={handleMenuOpen} color="secondary">
                        <img
                          src={file ? file : Profile}
                          alt="Setting"
                          style={{
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>{logout.headlineText1}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{logout.subTitle1}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDialog} color="secondary">
                    {CommonLanguage.canclebtn}
                  </Button>
                  <Button onClick={handleLogoutConfirmed} color="secondary">
                    {CommonLanguage.logoutbtn}
                  </Button>
                </DialogActions>
              </Dialog>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleNotificationsClick} style={{ marginBottom: '8px' }}>
                  <ListItemIcon>
                    <img src={Overview} alt="overview" />
                  </ListItemIcon>
                  <Typography variant="inherit">{CommonLanguage.Overview}</Typography>
                </MenuItem>
                <MenuItem onClick={handleProfileClick} style={{ marginBottom: '8px' }}>
                  <ListItemIcon>
                    <img src={Profile} alt="Profile" />
                  </ListItemIcon>
                  <Typography variant="inherit">{CommonLanguage.Profile}</Typography>
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <img src={Logout} alt="logout" />
                  </ListItemIcon>
                  <Typography variant="inherit">{CommonLanguage.LogOut}</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ background: '#F6F5F8', p: '15px' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          {/* sidebar section */}
          <Grid
            item
            xs={2}
            sx={{ p: '20px', backgroundColor: ' #FFFF', borderRadius: '5px' }}
            style={{
              position: 'sticky',
              top: '15px',
              bottom: '0px',
              height: '88vh',
            }}
          >
            {location.pathname.includes('/setting/')
              ? profileData.map((item, index) => {
                  const isActive = location.pathname === item.link;
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        whiteSpace: 'normal',
                      }}
                    >
                      <Link
                        to={item.link}
                        style={{
                          textDecoration: 'none',
                          color: isActive ? '#852598' : 'inherit',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          padding: '0px !important',
                          fontWeight: isActive ? 'bold' : '400',
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                          width: '100%',
                        }}
                      >
                        {item.title}
                      </Link>
                    </MenuItem>
                  );
                })
              : menu.map(
                  (menuItem, menuIndex) =>
                    value === menuIndex &&
                    menuItem.menuItems.map((item, index) => {
                      const isActive = location.pathname === item.link;
                      return (
                        <MenuItem
                          key={item.name}
                          onClick={handleClose}
                          sx={{
                            whiteSpace: 'normal',
                          }}
                          disabled={item.disable ? item.disable : false}
                        >
                          {item.link ? (
                            <Link
                              to={item.link}
                              style={{
                                textDecoration: 'none',
                                color: isActive ? '#852598' : 'inherit',
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                padding: '0px !important',
                                fontWeight: isActive ? 'bold' : '400',
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                                width: '100%',
                              }}
                            >
                              {item.title}
                            </Link>
                          ) : (
                            <span
                              style={{
                                textDecoration: 'none',
                                color: isActive ? '#852598' : 'inherit',
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: isActive ? 'bold' : '400',
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              {item.title}
                            </span>
                          )}
                        </MenuItem>
                      );
                    })
                )}
          </Grid>

          {/* </Grid> */}
          <Grid item xs={9.9}>
            <div style={{ margin: '20px 0px 23px 0px' }}>
              <SubHeader title={subheaderTitle} />
            </div>
            <div
              style={{
                minHeight: '71vh',
              }}
            >
              {isAuthenticated ? <Outlet /> : <Loader />}
            </div>
            <MainFooter />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item sx={{ paddingLeft: { lg: '35vh', md: '35vh', xs: '0vh' } }}></Grid> */}
    </Grid>
  );
};

export default DeskTopLayout;
