import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const Title = ({ title }) => (
  <Typography
    sx={{
      fontFamily: 'IBM Plex Serif',
      fontSize: '1.5rem',
    }}
  >
    {title}
  </Typography>
);

const Header = ({ heading }) => {
  const scrollIntoView = () => {
    const csvUpload = document.getElementById('csv_file_upload');
    csvUpload.scrollIntoView({ behavior: 'smooth' }, true);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        borderBottom: '1px solid #ebe8f1',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          color: '#414446',
          lineHeight: '1.5',
        }}
      >
        <Title title={heading} />
      </Box>
      {/* TODO: To add Auto Scroll - onClick - connect apps */}
      <Button
        type="submit"
        variant="contained"
        sx={{
          color: '#ffffff',
          fontFamily: 'Roboto',
          fontSize: '1rem',
          fontWeight: 'Bold',
          textAlign: 'center',
          textTransform: 'capitalize',
          width: '17rem',
          height: '48px',
          borderRadius: '6px',
          backgroundColor: '#852598',
          ':hover': {
            backgroundColor: '#852598',
          },
        }}
        onClick={scrollIntoView}
      >
        Import CSV
      </Button>
    </div>
  );
};

export default Header;
