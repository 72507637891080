import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import jsonData from './dashboard_data.json';
import numeral from 'numeral';
import PanelBodytitle from './PanelBodytitle';
import FunnelPannel from './FunnelMatrics/FunnelPannel';
import { getCookie, getItem, setCookie, setItem } from '../../Utils/CommonFunction/CommonFunction';
import { getFunnelMetricAnalysisService } from '../../../services/GetFunnelMetrics';
import { NumberSchema } from 'yup';

const PanelHead = () => {
  const funnelMetricsAnalysisData = getItem('funnelMetricsAnalysisData');
  const funnelMetricsAnalysisCall = getCookie('funnelMetricsAnalysisData');
  const [selectedData, setSelectedData] = useState('3M');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [activeButton, setActiveButton] = useState('3M');
  const [funnelMetricsAnalysis, setFunnelMetricsAnalysis] = useState(funnelMetricsAnalysisData ? funnelMetricsAnalysisData : []);
  const [loading, setloading] = useState(false);

  const ChangeDataButtonClick = (dataKey) => {
    setSelectedData(dataKey);
    setActiveButton(dataKey);
  };

  useEffect(() => {
    setloading(true);
    if (!funnelMetricsAnalysisCall) {
      (async () => {
        const response = await getFunnelMetricAnalysisService();
        if (response?.status == 200) {
          setloading(false);
          setFunnelMetricsAnalysis(response?.data?.result);
          setItem('funnelMetricsAnalysisData', response?.data?.result);
          setCookie('funnelMetricsAnalysisData', true, 1440);
        } else {
          setloading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!!funnelMetricsAnalysisCall) {
      setloading(false);
    }
  }, [funnelMetricsAnalysisCall]);

  const PanelHeaddata = funnelMetricsAnalysis[selectedData]?.map((item) => ({
    stage_name: item.stage_name,
    present: item.present,
    forecast: item.forecast,
    percentage: item.percentage,
    percentageClass: item.percentageClass,
    percentageIcon: item?.percentageIcon,
    color: item?.color,
  }));
  function parseNumber(str) {
    if (str) {
      return parseInt(str.replace(/,/g, ''), 10); // Remove commas and convert to integer
    }
    return 0; // Default to 0 if str is undefined or null
  }
  const groupData = funnelMetricsAnalysis[selectedData]?.map((item) =>
    item.channel.map((d, index) => ({
      name: d.name,
      key: index,
      values: [parseNumber(d.present), parseNumber(d.forecast)],
      color: d.color,
      darkColor: d.dark_color,
    }))
  );
  const [value, setValue] = useState(false);
  const [active, setActive] = useState('');
  const closeButton = (index) => {
    setValue(!value);
    setActive(index);
  };
  return (
    <div>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '5px 0px' }}>
        {PanelHeaddata?.map((data, index) => {
          return (
            <Grid
              item
              xs={2.2}
              style={{
                backgroundColor: '#ccc9c957',
                margin: '5px',
                padding: '10px',
                cursor: 'pointer',
                border: value && active === index ? '2px solid #a35bb1' : '',
              }}
              onClick={() => {
                closeButton(index);
                setSelectedTitle(data.stage_name);
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {data.stage_name}
                </Typography>

                <h5 className={'fs-14' + data.percentageClass} style={{ color: data?.color, paddingLeft: '10px', margin: '10px 4px' }}>
                  <i className={data?.percentageIcon} style={{ color: data?.color }}></i> {data?.percentage}
                </h5>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  borderBottom: '1px solid #E0DBE9',
                  paddingBottom: '9px',
                }}
              >
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: ' 400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {numeral(data.present).format('0,0')}
                </Typography>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: ' 400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    marginX: '10px',
                  }}
                >
                  Present
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  paddingTop: '9px',
                }}
              >
                <Typography
                  style={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: ' 400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {numeral(data.forecast).format('0,0')}
                </Typography>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: ' 400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    marginX: '10px',
                  }}
                >
                  Forecast
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {value ? (
        <>
          <FunnelPannel closeButton={closeButton} selectedTitle={selectedTitle} groupData={groupData[active]} />
        </>
      ) : (
        <PanelBodytitle ChangeDataButtonClick={ChangeDataButtonClick} activeButton={activeButton} selectedData={selectedData} funnelMetricsAnalysis={funnelMetricsAnalysis} />
      )}
    </div>
  );
};

export default PanelHead;
