import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import {
  campaignPageType as PageType,
  offersData as setOffersData,
  aboutCampaignData as setAboutCampaignData,
  dateTimeGlobal as setDateTimeGlobal,
  spendingLimitGlobal as setSpendingLimitGlobal,
  dataSourceData as setDataSourceData,
  segmetsData as setSegmetsData,
  snackBarMsg as setSnackBarMsg,
  batchDataGlobal,
} from '../GlobalStore';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import { Grid, Dialog, DialogContent, Box } from '@mui/material';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import { CustomEditIcon, CustomLargeTickIcon } from '../../Utils/icons/icons';
import { getSegmentOffers, getFinalizeAndConfirm, postSaveCampaign } from '../../../services/CampaignService';
import { newcampaign4 } from '../../../Language/language';
import ErrorPage from '../../../ErrorPage';
import Loading2 from '../../../assets/loading2.gif';
import { campaignScreen4 } from '../../../Language/DialogBoxLanguage';
import FinalizeOfferChannel from './FinalizeOfferChannel/FinalizeOfferChannel';
import CustomStepper from '../Components/Header/CustomStepper/CustomStepper';

const FinalizeConfirm = () => {
  const { sk, pk } = useParams();
  const isPageType = useReactiveVar(PageType);
  const offersData = useReactiveVar(setOffersData);
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);
  const dateTimeGlobal = useReactiveVar(setDateTimeGlobal);
  const spendingLimitGlobal = useReactiveVar(setSpendingLimitGlobal);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editCallback, setEditCallback] = useState(null);
  const [offerData, setOfferData] = useState(offersData?.segmentData || []);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const closeModal = () => {
    setShowModal(false);
  };
  const [LoadigFlag, setLoadigFlag] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [finalizeAndConfirmAPI, setFinalizeAndConfirmAPI] = useState(false);
  const [channelRows, setChannelRows] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [disabledFlag, setdisabledFlag] = useState(false);
  const [isbtnloading, setisbtnloading] = useState(false);

  const [spendingLimit, setSpendingLimit] = useState(spendingLimitGlobal);
  const [dateTime, setDateTime] = useState(dateTimeGlobal);

  const proceedToAllCampaigns = () => {
    setOpenDownloadDialog(false);
    PageType(1);
    setAboutCampaignData({});
    setDataSourceData({ manualData: [], AIData: [] });
    setSegmetsData({ manualData: [], AIData: [] });
    setOffersData([]);
    setDateTimeGlobal({
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    });
    setSpendingLimitGlobal({
      noLimit: true,
      period: 'daily',
      min: '',
      max: '',
    });
    setSnackBarMsg(false);
    batchDataGlobal({
      batch_size: '',
      batch_type: 'daily',
    });
    navigate('/campaigns');
  };

  const handleNextButtonClick = async () => {
    setLoading(true);
    setdisabledFlag(true);
    const response = await postSaveCampaign({
      campaign_pk: aboutCampaignData.campaign_pk,
      campaign_sk: aboutCampaignData.campaign_sk,
    });
    if (response?.status == 200) {
      setOpenDownloadDialog(true);
      setLoading(false);
      setdisabledFlag(false);
    } else {
      setOpenDownloadDialog(false);
      setShowModal(true);
      seterrormsg(response?.response?.data?.error);
      setdisabledFlag(false);
      setLoading(false);
    }
  };

  const handlePrevButtonClick = () => {
    navigate(`/create-campaign/offer/${sk}/${pk}`);
  };

  const editAboutCampaign = () => {
    setEditCallback(() => () => PageType(1));
    setOpenEditDialog(true);
  };
  const editSegments = () => {
    setEditCallback(() => () => PageType(3));
    setOpenEditDialog(true);
  };

  const editOffers = () => {
    setEditCallback(() => () => PageType(4));
    setOpenEditDialog(true);
  };

  const formatthis = (offerData) => {
    if (!offerData || !offerData.channel_segment_offer) return [];

    return offerData.channel_segment_offer.map((channelSegment) => {
      return {
        channel: channelSegment.channel,
        total_offers: channelSegment.total_offers,
        segment_offers: channelSegment.segment_offers.map((segmentOffer) => {
          return {
            ...segmentOffer,
            offers: segmentOffer.offers.map((offer) => {
              return {
                offer_pk: offer.offer_pk,
                offer_sk: offer.offer_sk,
                offer_name: offer.offer_name,
                vendor_template: {
                  template_name: offer.template_name,
                  template_pk: offer.template_pk,
                  template_sk: offer.template_sk,
                },
              };
            }),
          };
        }),
      };
    });
  };

  // Usage in the useEffect
  useEffect(() => {
    setisbtnloading(true);
    const raw = {
      campaign_pk: `campaign#${pk}`,
      campaign_sk: `campaign#${sk}`,
      channel_segment_offer: offersData,
    };
    (async () => {
      const response = await getFinalizeAndConfirm(raw);
      if (response?.status == 200) {
        setisbtnloading(false);
        setFinalizeAndConfirmAPI(response?.data);
      } else {
        setisbtnloading(false);
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setTimeout(() => {
          PageType(4);
        }, 5000);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getSegmentOffers(aboutCampaignData.campaign_pk, aboutCampaignData.campaign_sk);
      if (response?.status === 200) {
        let temp = [];
        for (let i = 0; i < response.data.response.length; i += 3) {
          temp.push(response.data.response.slice(i, i + 3));
        }
        setChannelRows(temp);
        setTimeout(() => {}, 2000);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setLoadigFlag(true);
        setTimeout(() => {
          setLoadigFlag(false);
        }, 5000);
      }
    })();
  }, []);

  return (
    <>
      <CustomStepper type={4} />
      {isbtnloading == true ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
          }}
        >
          <LoadingScreen gifType={4} />
        </div>
      ) : (
        <>
          {finalizeAndConfirmAPI && channelRows ? (
            <div className="campaign-container">
              <div className="customHR"></div>

              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div style={{ display: 'grid', gap: '20px' }}>
                    <div className="headerText" style={{ display: 'flex' }}>
                      Review Campaign
                      <div style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={editAboutCampaign}>
                        <CustomEditIcon />
                      </div>
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Campaign Name: {finalizeAndConfirmAPI.name}
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Campaign Description : {finalizeAndConfirmAPI.description}
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Marketing Channel: {finalizeAndConfirmAPI.channels.join(', ')}
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Objective: {finalizeAndConfirmAPI.objective}
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Vendors: {finalizeAndConfirmAPI.vendors.join(', ')}
                    </div>
                    <div className="normalText" style={{ width: '60%' }}>
                      Data Source: {finalizeAndConfirmAPI.data_sources.join(', ')}
                    </div>

                    <div className="customHR"></div>

                    <div className="headerText" style={{ display: 'flex' }}>
                      Segments
                      <div style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={editSegments}>
                        <CustomEditIcon />
                      </div>
                    </div>
                    <div className="normalText">{finalizeAndConfirmAPI.segments.join(', ')}</div>

                    <div className="customHR"></div>

                    <div className="headerText" style={{ display: 'flex' }}>
                      Offers
                      <div style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={editOffers}>
                        <CustomEditIcon />
                      </div>
                    </div>

                    <div className="normalText" style={{ width: '60%' }}>
                      Please note: Where you don't see customized settings on a channel card, date and budget parameters are shared and not unique to that specific channel.
                    </div>

                    <div className="customHR"></div>

                    <div>
                      <div className="normalText">
                        {dateTime?.startDate && dateTime?.startTime ? `Start Date ${dateTime.startDate} ${dateTime.startTime} EST` : 'No start date'}{' '}
                        {dateTime?.endDate && dateTime?.endTime ? `And End Date ${dateTime.endDate} ${dateTime.endTime} EST` : 'and no end date'}
                      </div>
                    </div>
                    <div className="normalText" style={{ color: '#E08029' }}>
                      {spendingLimit?.noLimit ? 'No Spending Limits' : `Spending limits: Period: ${spendingLimit?.period}, Min: ${spendingLimit.min}, Max: ${spendingLimit.max}`}
                    </div>
                    {dateTime && spendingLimit && (
                      <>
                        <div className="customHR"></div>
                        <FinalizeOfferChannel setOfferData={setOfferData} globalDateTime={dateTime} globalSpendingLimit={spendingLimit} channelRows={channelRows} />
                      </>
                    )}

                    <hr />

                    <h2 style={{ fontSize: '18px', color: '#000' }}>Export Your Campaign Data</h2>

                    <div className="normalText" style={{ width: '60%' }}>
                      Click 'Request Campaign Data' to export your channel data. Data preparation can take from minutes to hours. An email notification will arrive when your data
                      is ready for download.
                    </div>

                    <div style={{ marginTop: '20px' }}>
                      <CustomButton
                        onClick={handlePrevButtonClick}
                        buttonValue="Prev step"
                        style={{
                          color: '#852598',
                          backgroundColor: '#ffffff',
                          border: '1px solid #852598',
                          fontSize: '13px',
                        }}
                      />
                      <CustomButton onClick={handleNextButtonClick} buttonValue="Request Campaign Data" style={{ fontSize: '13px' }} isDisabled={disabledFlag} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <Dialog
        open={openDownloadDialog}
        maxWidth={false}
        fullWidth
        sx={
          Loading
            ? {
                '& .MuiDialog-paper': {
                  width: '834px',
                  height: '371px',
                },
              }
            : {
                '& .MuiDialog-paper': {
                  width: '834px',
                  height: '371px',
                  padding: '20px',
                },
              }
        }
      >
        {Loading ? (
          <DialogContent
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: 6,
              border: '1px solid #EBE8F1',
              background: '#FFF',
              boxShadow: '0px 16px 21px 0px rgba(0, 0, 0, 0.33)',
              width: 'auto',
              height: 'auto',
              flexShrink: 0,
            }}
          >
            <Box sx={{ padding: '75px' }}>
              <div className="manageAllCampaignText">{campaignScreen4.headlineText1}</div>
              <h3
                style={{
                  color: '#414446',
                  marginTop: '20px',
                  lineHeight: '25px',
                }}
              >
                {campaignScreen4.subTitle1}
              </h3>
            </Box>
            <Box>
              <img src={Loading2} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
            </Box>
          </DialogContent>
        ) : (
          <DialogContent style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginTop: '25px' }}>
              <div className="manageAllCampaignText">Request Submitted!</div>
              <h3
                style={{
                  color: '#414446',
                  marginTop: '20px',
                  lineHeight: '25px',
                }}
              >
                Your request to export campaign data has been successfully submitted. Generating your selected data may take from a few minutes to a few hours. Rest assured, you'll
                be notified via email as soon as your data is ready for download.
              </h3>
              <CustomButton
                onClick={proceedToAllCampaigns}
                buttonValue="Go to all Campaign"
                style={{
                  fontSize: '13px',
                  marginLeft: '0px',
                  marginTop: '20px',
                  color: '#852598',
                  backgroundColor: '#ffffff',
                  border: '1px solid #852598',
                }}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ transform: 'scale(1.3)', marginLeft: '80px' }}>
                <CustomLargeTickIcon />
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>

      <Dialog
        open={openEditDialog}
        maxWidth={false}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '734px',
            height: '271px',
            padding: '20px',
          },
        }}
      >
        <DialogContent style={{ display: 'flex' }}>
          <div style={{ flex: 1, marginTop: '25px' }}>
            <div className="manageAllCampaignText">Review Changes Alert!</div>
            <h3
              style={{
                color: '#414446',
                marginTop: '20px',
                lineHeight: '25px',
              }}
            >
              Editing will reset {isPageType} step's values. Continue with new inputs?
            </h3>
            <div style={{ marginTop: '30px' }}>
              <CustomButton
                onClick={() => {
                  setOpenEditDialog(false);
                  if (editCallback) {
                    editCallback();
                    setEditCallback(null);
                  }
                }}
                buttonValue="Proceed with Edit"
                style={{
                  fontSize: '13px',
                  marginRight: '10px',
                  marginLeft: '0px',
                }}
              />
              <CustomButton
                onClick={() => setOpenEditDialog(false)}
                buttonValue="Cancel & Return"
                style={{
                  fontSize: '13px',
                  marginLeft: '0px',
                  color: '#852598',
                  backgroundColor: '#ffffff',
                  border: '1px solid #852598',
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {showModal && <ErrorPage closingFlag={4} closeModal={closeModal} errormsg={errormsg} />}
      {/* {LoadigFlag && <LoadingScreen gifType={4} />} */}
    </>
  );
};

export default FinalizeConfirm;
