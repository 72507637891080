import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import jsonData from './newDashboard.json';
import BarChart from './BarChart';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Score from '../PortfolioPanel1/Score';
import Segment from '../PortfolioPanel2/Segment';
import { display } from '@mui/system';
import HoverChart from '../HoverChart/HoverChart';
import PriceAnalysis from '../PotfolioPanel3/PriceAnalysis';

const NewDashboardHead = (props) => {
  const { handleChange, selectedTab, setSelectedTab } = props;
  const [activeButton, setActiveButton] = useState('1D');
  const [data, setData] = useState('');
  const [selectedData, setSelectedData] = useState('1D');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ChangeDataButtonClick = (dataKey) => {
    setSelectedData(dataKey);
    setActiveButton(dataKey);
  };


  const titleData = [
    {
      Title: 'Score',
      value: 4,
    },
    {
      Title: 'Segments',
      value: 5,
    },
    // {
    //   Title: 'Product Offers',
    //   value: 8,
    // },
    // {
    //   Title: 'Price Analysis',
    //   value: 10,
    // },
  ];

  // const dummyData = [
  //   { interestRate: '100-250', value: 678, color: '#7fc97f' },
  //   { interestRate: '250-500', value: 788, color: '#beaed4' },
  //   { interestRate: '500-1000', value: 980, color: '#fdc086' },
  //   { interestRate: '1000-1500', value: 1250, color: '#ffff99' },
  //   { interestRate: '1500-2000', value: 540, color: '#386cb0' },
  // ];
  return (
    <div>
      <Grid container>
        <Grid item xs={24}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTab}>
              <Box onChange={handleChange} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {titleData.map((titleData, index) => {
                  return (
                    <Button>
                      <Box sx={{ marginLeft: '5px' }}>
                        <Box
                          onClick={() => {
                            setSelectedTab(index);
                          }}
                          sx={{
                            width: '117px',
                            height: '79px',
                            backgroundColor: selectedTab === index ? '#F6F5F8' : 'white',
                            borderRadius: '5px',
                            border: selectedTab === index ? '1px solid #A35BB1' : '1px solid #EBE8F1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0px 0px 0px 10px',
                          }}
                        >
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            sx={{
                              color: '#9853A6',
                              textAlign: 'center',
                              fontFamily: 'Roboto Condensed',
                              fontSize: '13px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '18px',
                              paddingBottom: '5px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {titleData.Title}
                          </Typography>
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            sx={{
                              color: '#414446',
                              textAlign: 'center',
                              fontFamily: 'IBM Plex Serif',
                              fontSize: '24px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '18px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {titleData.value}
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  );
                })}
              </Box>

              <TabPanel value={0}>
                <Score />
              </TabPanel>
              <TabPanel value={1}>
                <Segment />
              </TabPanel>
              <TabPanel value={2}>
                <HoverChart />
              </TabPanel>
              <TabPanel value={3}>
                <PriceAnalysis />
              </TabPanel>
              <TabPanel value={-1}>
                <Grid item>
                  <BarChart dataColors='["#A35BB1", "#7F4D90", "#852598", "#7A4B84", "#DFBBE5", "--vz-info"]' />
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewDashboardHead;
