import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableContainer, TableRow, Typography, TableBody, Button } from '@mui/material';
import axios from 'axios';
import Modal from './Component/AccountContactModal';
import { clearAllCookies, clearItem, getItem } from '../../../Utils/CommonFunction/CommonFunction';
import { SETTING_GET_COMPANY_DETAILS } from '../../../../common/config';
import { Profilestep5 } from '../../../../Language/language';
import ErrorPage from '../../../../ErrorPage';

const AccountContact = () => {
  let accessToken = getItem('accessToken');
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(false);
  const [displayError, setdisplayError] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    seterrorModal(false);
  };
  const [errormsg, seterrormsg] = useState();
  const [errorModal, seterrorModal] = useState(false);

  useEffect(() => {
    setloading(true);
    const headers = { Authorization: `Bearer ${accessToken}` };
    axios
      .get(SETTING_GET_COMPANY_DETAILS, { headers })
      .then((response) => {
        setdata(response.data);
        setdisplayError();
        setloading(false);
        setShowModal(false);
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
        setdisplayError(e?.response?.data?.error?.title);
        seterrormsg(e?.response?.data?.error);
        setloading(false);
        seterrorModal(true);
        return e;
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontSize: '32px',
            }}
          >
            <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" />
          </div>
        </>
      ) : (
        <>
          {data ? (
            <TableContainer>
              <Table sx={{ width: { lg: '650px', sm: '100%' }, marginBottom: '25px' }} size="small">
                <TableBody sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                  <TableRow>
                    <TableCell>{Profilestep5.Contactname}</TableCell>
                    <TableCell>
                      {data.primary_account_details.first_name}
                      {data.primary_account_details.last_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> {Profilestep5.CompanyOrganization} </TableCell>
                    <TableCell>
                      <Typography>{data.primary_account_details.company}</Typography>
                      <Typography>{data.primary_account_details.website_url}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{Profilestep5.Address}</TableCell>
                    <TableCell>
                      <Typography>{data.primary_account_details.contact_address}</Typography>
                      <Typography>
                        {data.primary_account_details.contact_city},{data.primary_account_details.contact_zip_code}
                      </Typography>
                      <Typography>{data.primary_account_details.contact_country}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{Profilestep5.Phone}</TableCell>
                    <TableCell>
                      <Typography>{data.primary_account_details.office_phone}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{Profilestep5.Email}</TableCell>
                    <TableCell>
                      <Typography>{data.primary_account_details.email_id_at_work}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{Profilestep5.TaxId}</TableCell>
                    <TableCell>
                      <Typography>{data.primary_account_details.email_id_at_work}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button
                type="submit"
                fullWidth
                onClick={() => setShowModal(true)}
                sx={{
                  width: '204px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  marginRight: '22px',
                  backgroundColor: 'transparent',
                  border: '1px solid #9C27B5',
                  color: '#9C27B5',
                }}
              >
                {Profilestep5.ContactInfobtn}
              </Button>
              {showModal && <Modal closeModal={closeModal} />}
            </TableContainer>
          ) : (
            <>{displayError}</>
          )}
        </>
      )}
      {errorModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} closingFlag={1} />}
    </div>
  );
};

export default AccountContact;
