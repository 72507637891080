export const SynergyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 55 55" fill="none" className="SynergyIcon">
    <rect x="0.371094" width="55.9735" height="55" rx="9.89189" fill="#3E004A" />
    <path
      d="M14.1133 32.7212L20.4954 32.1115C20.8795 34.2163 21.6551 35.7624 22.8222 36.7495C24.0041 37.7366 25.5922 38.2302 27.5866 38.2302C29.6992 38.2302 31.2874 37.7947 32.351 36.9237C33.4295 36.0382 33.9687 35.0075 33.9687 33.8317C33.9687 33.0768 33.7397 32.4381 33.2818 31.9155C32.8386 31.3784 32.0556 30.9139 30.9328 30.5219C30.1646 30.2606 28.4139 29.7961 25.6809 29.1283C22.1648 28.2719 19.6976 27.2194 18.2794 25.971C16.285 24.2145 15.2878 22.0733 15.2878 19.5475C15.2878 17.9216 15.7531 16.4046 16.6839 14.9965C17.6293 13.5739 18.9811 12.4925 20.7392 11.7521C22.512 11.0118 24.6467 10.6416 27.1434 10.6416C31.2209 10.6416 34.2864 11.5198 36.3399 13.2763C38.4081 15.0328 39.494 17.3772 39.5974 20.3096L33.038 20.5926C32.7573 18.9523 32.1516 17.7764 31.2209 17.0651C30.3049 16.3393 28.9236 15.9764 27.0769 15.9764C25.1712 15.9764 23.6791 16.3611 22.6006 17.1305C21.9062 17.624 21.5591 18.2845 21.5591 19.112C21.5591 19.8668 21.8841 20.5128 22.5341 21.0499C23.3614 21.7322 25.3706 22.4435 28.5617 23.1838C31.7527 23.9242 34.1091 24.6935 35.6307 25.4919C37.1672 26.2758 38.3638 27.3573 39.2207 28.7364C40.0923 30.1009 40.5281 31.7921 40.5281 33.8099C40.5281 35.639 40.011 37.3519 38.9769 38.9487C37.9428 40.5455 36.4802 41.7359 34.5892 42.5198C32.6982 43.2891 30.3419 43.6738 27.5201 43.6738C23.4131 43.6738 20.259 42.7448 18.0578 40.8867C15.8565 39.014 14.5417 36.2922 14.1133 32.7212Z"
      fill="white"
    />
  </svg>
);

export const SelectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="50%" cy="50%" r="8.9261" fill="#EEEEEE" stroke="#9853A6" stroke-width="2" />
    <circle cx="50%" cy="50%" r="4.02698" fill="#9853A6" />
  </svg>
);

export const UnselectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="50%" cy="50%" r="8.9261" fill="white" stroke="#D4D4D4" stroke-width="2" />
  </svg>
);

export const StartDateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_80_2203)">
      <path
        d="M14.25 17.25H3.75C2.1 17.25 0.75 15.9 0.75 14.25V6C0.75 4.35 2.1 3 3.75 3H14.25C15.9 3 17.25 4.35 17.25 6V14.25C17.25 15.9 15.9 17.25 14.25 17.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M4.5 0.75V5.1" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.5 0.75V5.1" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6 11.25H4.5C4.05 11.25 3.75 11.55 3.75 12V13.5C3.75 13.95 4.05 14.25 4.5 14.25H6C6.45 14.25 6.75 13.95 6.75 13.5V12C6.75 11.55 6.45 11.25 6 11.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_2203">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EndDateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_80_2202)">
      <path d="M9 4.5V9L11.25 11.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_2202">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DeleteIcon = () => {
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.8327 4.16675H4.16602V15.8334C4.16602 17.6667 5.66602 19.1667 7.49935 19.1667H12.4993C14.3327 19.1667 15.8327 17.6667 15.8327 15.8334V4.16675Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M2.5 4.16675H17.5" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.8333 0.833252H9.16667C8.25 0.833252 7.5 1.58325 7.5 2.49992V4.16659H12.5V2.49992C12.5 1.58325 11.75 0.833252 10.8333 0.833252Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M8.33398 8.33325V14.9999" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.666 8.33325V14.9999" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;
};

export const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_80_2301)">
      <path
        d="M6.66602 13.3333L7.83268 9.33326L15.7493 1.4166C16.166 0.999929 16.666 0.833252 17.166 0.833252C18.2494 0.833252 19.166 1.74991 19.166 2.83325C19.166 3.33325 18.916 3.91659 18.5827 4.24992L10.666 12.1666L6.66602 13.3333Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16732 2.5H4.16732C2.33398 2.5 0.833984 4 0.833984 5.83333V15.8333C0.833984 17.6667 2.33398 19.1667 4.16732 19.1667H14.1673C16.0007 19.1667 17.5007 17.6667 17.5007 15.8333V10.8333"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_2301">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const OpenMail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_80_2247)">
      <path
        d="M17.25 7.57498V14.25C17.25 15.9 15.9 17.25 14.25 17.25H3.75C2.1 17.25 0.75 15.9 0.75 14.25V7.57498C0.75 6.59998 1.2 5.70004 2.025 5.10004L7.65 1.19998C8.025 0.899982 8.55 0.75 9 0.75C9.525 0.75 9.975 0.899982 10.35 1.19998L15.975 5.10004C16.8 5.70004 17.25 6.59998 17.25 7.57498Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M16.3508 6.1499L10.8008 11.6999" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.20039 11.6999L1.65039 6.1499" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M2.09961 16.725L7.19961 11.625L7.72461 11.1C8.09961 10.725 8.54961 10.575 8.99961 10.575C9.44961 10.575 9.89961 10.725 10.2746 11.1L10.7996 11.625L15.8996 16.725"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_2247">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const CustomizedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6667 6.00008C14.1394 6.00008 15.3333 4.80617 15.3333 3.33341C15.3333 1.86066 14.1394 0.666748 12.6667 0.666748C11.1939 0.666748 10 1.86066 10 3.33341C10 4.80617 11.1939 6.00008 12.6667 6.00008Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00065 15.3333C9.47341 15.3333 10.6673 14.1394 10.6673 12.6667C10.6673 11.1939 9.47341 10 8.00065 10C6.52789 10 5.33398 11.1939 5.33398 12.6667C5.33398 14.1394 6.52789 15.3333 8.00065 15.3333Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.934 5.3335H3.33398C2.20065 5.3335 1.33398 4.46683 1.33398 3.3335C1.33398 2.20016 2.20065 1.3335 3.33398 1.3335H10.934"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.26731 14.6667H3.33398C2.20065 14.6667 1.33398 13.8001 1.33398 12.6667C1.33398 11.5334 2.20065 10.6667 3.33398 10.6667H6.26731"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.73438 10.6667H12.6677C13.801 10.6667 14.6677 11.5334 14.6677 12.6667C14.6677 13.2001 14.4677 13.7334 14.0677 14.0667C13.7344 14.4001 13.201 14.6667 12.6677 14.6667H9.73438"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SelectTemplateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g>
      <path
        d="M7.99935 4.33341V8.7334M7.99935 11.3334V10.9334M15.3327 8.00008C15.3327 12.0667 12.066 15.3334 7.99935 15.3334C3.93268 15.3334 0.666016 12.0667 0.666016 8.00008C0.666016 3.93341 3.93268 0.666748 7.99935 0.666748C12.066 0.666748 15.3327 3.93341 15.3327 8.00008Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 10L12 15L8 10" stroke="#5D596C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const CustomArrowUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16 14L12 9L8 14" stroke="#5D596C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const CustomExclamationMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_4374_6323)">
      <path
        d="M7.99935 4.33341V8.7334M7.99935 11.3334V10.9334M15.3327 8.00008C15.3327 12.0667 12.066 15.3334 7.99935 15.3334C3.93268 15.3334 0.666016 12.0667 0.666016 8.00008C0.666016 3.93341 3.93268 0.666748 7.99935 0.666748C12.066 0.666748 15.3327 3.93341 15.3327 8.00008Z"
        stroke="#E08029"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4374_6323">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomEditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_80_3614)">
      <path
        d="M6.66602 13.3333L7.83268 9.33326L15.7493 1.4166C16.166 0.999929 16.666 0.833252 17.166 0.833252C18.2494 0.833252 19.166 1.74991 19.166 2.83325C19.166 3.33325 18.916 3.91659 18.5827 4.24992L10.666 12.1666L6.66602 13.3333Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16732 2.5H4.16732C2.33398 2.5 0.833984 4 0.833984 5.83333V15.8333C0.833984 17.6667 2.33398 19.1667 4.16732 19.1667H14.1673C16.0007 19.1667 17.5007 17.6667 17.5007 15.8333V10.8333"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_3614">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomResetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_135_1751)">
      <path
        d="M0.666992 8.00008C0.666992 3.93341 3.93366 0.666748 8.00033 0.666748C10.867 0.666748 13.3337 2.26675 14.5337 4.66675"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M14.667 0.666748V4.66675H10.667" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.3335 8C15.3335 12.0667 12.0668 15.3333 8.00013 15.3333C5.13347 15.3333 2.6668 13.7333 1.4668 11.3333"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.33301 15.3333V11.3333H5.33301" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_135_1751">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomLargeTickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="142" height="142" viewBox="0 0 142 142" fill="none">
    <path
      d="M71.0013 136.085C106.946 136.085 136.085 106.946 136.085 71.0013C136.085 35.0568 106.946 5.91797 71.0013 5.91797C35.0568 5.91797 5.91797 35.0568 5.91797 71.0013C5.91797 106.946 35.0568 136.085 71.0013 136.085Z"
      stroke="#158C6F"
      stroke-width="7.71739"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M100.583 53.25L62.716 94.6667L40.8242 71" stroke="#158C6F" stroke-width="7.71739" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const CustomSaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_4796_6398)">
      <path
        d="M18.1673 3.16679L16.7506 1.75016C16.084 1.0835 15.2506 0.75 14.4173 0.75H4.16732C2.33398 0.75 0.833984 2.25 0.833984 4.08333V15.75C0.833984 17.5833 2.33398 19.0833 4.16732 19.0833H15.834C17.6673 19.0833 19.1673 17.5833 19.1673 15.75V5.50016C19.1673 4.66683 18.834 3.83346 18.1673 3.16679Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.75 7.49967H6.66667C5.75 7.49967 5 6.74967 5 5.83301V0.833008H14.4167V5.83301C14.4167 6.74967 13.6667 7.49967 12.75 7.49967Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8.33398 0.833008V7.49967" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.3333 12.5H6.66667C5.75 12.5 5 13.25 5 14.1667V19.1667H15V14.1667C15 13.25 14.25 12.5 13.3333 12.5Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4796_6398">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomRegenerateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_135_1756)">
      <path
        d="M0.733398 8.66675C1.06673 12.4001 4.20007 15.3334 8.00007 15.3334C11.8001 15.3334 15.0001 12.4001 15.3334 8.66675C15.3334 8.46675 15.3334 8.20008 15.3334 8.00008C15.3334 3.93341 12.0667 0.666748 8.00007 0.666748C5.1334 0.666748 2.66673 2.26675 1.46673 4.66675"
        stroke="white"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.33301 0.666748V4.66675H5.33301" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_135_1756">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomAlertIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_80_394)">
      <path
        d="M8.00033 4.33317V8.73315M8.00033 11.3332V10.9332M15.3337 7.99984C15.3337 12.0665 12.067 15.3332 8.00033 15.3332C3.93366 15.3332 0.666992 12.0665 0.666992 7.99984C0.666992 3.93317 3.93366 0.666504 8.00033 0.666504C12.067 0.666504 15.3337 3.93317 15.3337 7.99984Z"
        stroke="#8E0000"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_394">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomMailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <g clip-path="url(#clip0_80_3382)">
      <path
        d="M16.2913 7.15407V13.4583C16.2913 15.0166 15.0163 16.2916 13.458 16.2916H3.54134C1.98301 16.2916 0.708008 15.0166 0.708008 13.4583V7.15407C0.708008 6.23323 1.13301 5.38329 1.91217 4.81662L7.22467 1.13323C7.57884 0.849901 8.07467 0.708252 8.49967 0.708252C8.99551 0.708252 9.42051 0.849901 9.77467 1.13323L15.0872 4.81662C15.8663 5.38329 16.2913 6.23323 16.2913 7.15407Z"
        stroke="#5D596C"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.4419 5.80835L10.2002 11.05" stroke="#5D596C" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.80026 11.05L1.55859 5.80835" stroke="#5D596C" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M1.9834 15.7959L6.80006 10.9792L7.2959 10.4834C7.65006 10.1293 8.07507 9.98755 8.50007 9.98755C8.92507 9.98755 9.35007 10.1293 9.70423 10.4834L10.2001 10.9792L15.0167 15.7959"
        stroke="#5D596C"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_3382">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomKPIPostiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g clip-path="url(#clip0_135_1770)">
      <path d="M5.94629 7.875L12.6628 1.27534" stroke="#158C6F" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.6621 7.875L12.6621 1.27534L5.94564 1.27534" stroke="#158C6F" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_135_1770">
        <rect width="16" height="16.2832" fill="white" transform="matrix(-4.4485e-08 -1 -1 4.29512e-08 16.2832 16)" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomKPINegativeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g clip-path="url(#clip0_80_3406)">
      <path d="M5.94629 8.125L12.6628 14.7247" stroke="#8E0000" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.6621 8.125L12.6621 14.7247L5.94564 14.7247" stroke="#8E0000" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_80_3406">
        <rect width="16" height="16.2832" fill="white" transform="translate(16.2832) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomCreditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_179_2159)">
      <path
        d="M8.00033 11.6667V12.0001M8.00033 10.0001V9.86674C8.00033 9.33341 8.33366 8.8001 8.80033 8.6001C9.66699 8.26676 10.2003 7.46676 10.2003 6.46676C10.1337 5.33342 9.20032 4.40008 8.06699 4.33341C6.80032 4.26675 5.80033 5.26674 5.80033 6.53341M15.3337 8.00008C15.3337 12.0667 12.067 15.3334 8.00033 15.3334C3.93366 15.3334 0.666992 12.0667 0.666992 8.00008C0.666992 3.93341 3.93366 0.666748 8.00033 0.666748C12.067 0.666748 15.3337 3.93341 15.3337 8.00008Z"
        stroke="#E08029"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_179_2159">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomEMailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M11.084 11.6663H2.91732C1.63398 11.6663 0.583984 10.6163 0.583984 9.33301V4.66634C0.583984 3.38301 1.63398 2.33301 2.91732 2.33301H11.084C12.3673 2.33301 13.4173 3.38301 13.4173 4.66634V9.33301C13.4173 10.6163 12.3673 11.6663 11.084 11.6663Z"
      stroke="#5D596C"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.2832 3.0332L6.0082 7.75826C6.29987 8.04993 6.64987 8.16656 6.99987 8.16656C7.34987 8.16656 7.69987 8.04993 7.99154 7.75826L12.7165 3.0332"
      stroke="#5D596C"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M12.3671 11.258L8.40039 7.34961" stroke="#5D596C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.59948 7.34961L1.63281 11.258" stroke="#5D596C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const CustomDirectMailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6943)">
      <path
        d="M15.9742 0.75C16.6492 0.75 17.2492 1.34996 17.2492 2.02496C17.2492 2.17496 17.2492 2.32504 17.1742 2.47504L11.6242 16.35C11.3992 16.875 10.8742 17.25 10.3492 17.25C9.97421 17.25 9.59921 17.1 9.37421 16.875L1.19922 8.69998C0.974219 8.47498 0.824219 8.10004 0.824219 7.72504C0.824219 7.20004 1.19921 6.67498 1.72421 6.44998L15.5992 0.899963C15.6742 0.749963 15.8242 0.75 15.9742 0.75Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 1.125L4.5 12V16.2C4.5 16.8 4.95 17.25 5.55 17.25C5.85 17.25 6.15 17.1 6.3 16.875L7.72499 15.225"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_6943">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomTelemarketingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6944)">
      <path
        d="M6.45079 2.10004L6.90078 5.02496C6.97578 5.24996 6.97578 5.47498 6.90078 5.69998C6.82578 5.92498 6.75079 6.15002 6.60079 6.30002L5.17578 8.17502C6.15078 10.2 7.80079 11.775 9.82579 12.825L11.6258 11.475C11.7758 11.325 12.0008 11.175 12.2258 11.1C12.4508 11.025 12.7508 11.025 12.9758 11.1L15.9008 11.55C16.2758 11.625 16.5758 11.775 16.8008 12.075C17.0258 12.375 17.1758 12.675 17.1758 13.05V15.9C17.1758 16.275 17.0258 16.575 16.8008 16.8C16.5758 17.025 16.2008 17.175 15.9008 17.175C11.8508 17.175 8.02578 15.6 5.17578 12.75C2.32578 9.9 0.750786 6.07496 0.750786 2.02496C0.750786 1.64996 0.900786 1.35 1.12579 1.125C1.35079 0.9 1.72578 0.75 2.02578 0.75H4.87579C5.25079 0.75 5.62579 0.9 5.85079 1.125C6.15079 1.425 6.37579 1.72504 6.45079 2.10004Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_6944">
        <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18 0)" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomSmsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6945)">
      <path d="M4.5 6H13.5" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.5 9H11.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17.25 8.25V4.5C17.25 2.85 15.9 1.5 14.25 1.5H3.75C2.1 1.5 0.75 2.85 0.75 4.5V15.45C0.75 16.05 1.2 16.5 1.8 16.5C2.1 16.5 2.325 16.425 2.55 16.2L4.35 14.4C4.95 13.8 5.7 13.5 6.45 13.5H8.25H14.25C15.9 13.5 17.25 12.15 17.25 10.5V8.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_6945">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomSocialMediaIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6941)">
      <path
        d="M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12C13.5563 12 17.25 10.6569 17.25 9C17.25 7.34315 13.5563 6 9 6C4.44365 6 0.75 7.34315 0.75 9C0.75 10.6569 4.44365 12 9 12Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 17.25C10.6569 17.25 12 13.5563 12 9C12 4.44365 10.6569 0.75 9 0.75C7.34315 0.75 6 4.44365 6 9C6 13.5563 7.34315 17.25 9 17.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_6941">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomPaidSearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6942)">
      <path
        d="M7.5 14.25C11.2279 14.25 14.25 11.2279 14.25 7.5C14.25 3.77208 11.2279 0.75 7.5 0.75C3.77208 0.75 0.75 3.77208 0.75 7.5C0.75 11.2279 3.77208 14.25 7.5 14.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17.2508 17.2498L12.3008 12.2998" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_429_6942">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomPaidDisplayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_429_6946)">
      <path
        d="M14.25 17.25H3.75C2.1 17.25 0.75 15.9 0.75 14.25V3.75C0.75 2.1 2.1 0.75 3.75 0.75H14.25C15.9 0.75 17.25 2.1 17.25 3.75V14.25C17.25 15.9 15.9 17.25 14.25 17.25Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M0.75 5.25H17.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.25 8.25H3.75V14.25H14.25V8.25Z" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.75 3H8.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 3H11.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.5 3H14.25" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_429_6946">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomTempIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <circle cx="14" cy="14" r="13.5" stroke="#EBE8F1" />
    <path
      d="M14.2272 16.1349V19.0086M14.2272 8.99177V11.8655M18.621 11.5388L16.0421 12.9757M12.2783 15.1101L9.78316 16.5469M18.5708 16.5469L16.0756 15.1101M12.2275 12.9753L9.73236 11.5385M15.6828 7.59598C15.6828 8.36686 15.046 8.99177 14.2606 8.99177C13.4752 8.99177 12.8385 8.36686 12.8385 7.59598C12.8385 6.82511 13.4752 6.2002 14.2606 6.2002C15.046 6.2002 15.6828 6.82511 15.6828 7.59598ZM9.99429 10.7981C9.99429 11.569 9.35759 12.1939 8.57218 12.1939C7.78676 12.1939 7.15006 11.569 7.15006 10.7981C7.15006 10.0272 7.78676 9.4023 8.57218 9.4023C9.35759 9.4023 9.99429 10.0272 9.99429 10.7981ZM9.91064 17.2023C9.91064 17.9732 9.27393 18.5981 8.48852 18.5981C7.70311 18.5981 7.06641 17.9732 7.06641 17.2023C7.06641 16.4314 7.70311 15.8065 8.48852 15.8065C9.27393 15.8065 9.91064 16.4314 9.91064 17.2023ZM21.2039 10.7981C21.2039 11.569 20.5672 12.1939 19.7818 12.1939C18.9964 12.1939 18.3597 11.569 18.3597 10.7981C18.3597 10.0272 18.9964 9.4023 19.7818 9.4023C20.5672 9.4023 21.2039 10.0272 21.2039 10.7981ZM21.2039 17.2844C21.2039 18.0553 20.5672 18.6802 19.7818 18.6802C18.9964 18.6802 18.3597 18.0553 18.3597 17.2844C18.3597 16.5135 18.9964 15.8886 19.7818 15.8886C20.5672 15.8886 21.2039 16.5135 21.2039 17.2844ZM15.6828 20.4044C15.6828 21.1753 15.046 21.8002 14.2606 21.8002C13.4752 21.8002 12.8385 21.1753 12.8385 20.4044C12.8385 19.6335 13.4752 19.0086 14.2606 19.0086C15.046 19.0086 15.6828 19.6335 15.6828 20.4044ZM16.352 14.0002C16.352 15.1792 15.3782 16.1349 14.177 16.1349C12.9758 16.1349 12.002 15.1792 12.002 14.0002C12.002 12.8212 12.9758 11.8655 14.177 11.8655C15.3782 11.8655 16.352 12.8212 16.352 14.0002Z"
      stroke="#5D596C"
      stroke-width="1.2"
    />
  </svg>
);

export const CustomRightArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <g clip-path="url(#clip0_80_2908)">
      <path
        d="M15 28.75C7.40608 28.75 1.25 22.5939 1.25 15C1.25 7.40608 7.40608 1.25 15 1.25C22.5939 1.25 28.75 7.40608 28.75 15C28.75 22.5939 22.5939 28.75 15 28.75Z"
        // fill="#EEEEEE"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12.5 10L18.75 15L12.5 20" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_80_2908">
        <rect width="30" height="30" fill="white" transform="matrix(-1 0 0 1 30 0)" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomLeftArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <g clip-path="url(#clip0_80_2907)">
      <path
        d="M15 28.75C22.5939 28.75 28.75 22.5939 28.75 15C28.75 7.40608 22.5939 1.25 15 1.25C7.40608 1.25 1.25 7.40608 1.25 15C1.25 22.5939 7.40608 28.75 15 28.75Z"
        stroke="#A35BB1"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17.5 10L11.25 15L17.5 20" stroke="#A35BB1" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_80_2907">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CustomCrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="#A35BB1">
    <path d="M5 5L10 10" stroke="white" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 5L5 10" stroke="white" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const CustomFiltersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6647 5.99935C14.1375 5.99935 15.3314 4.80544 15.3314 3.33268C15.3314 1.85992 14.1375 0.666016 12.6647 0.666016C11.192 0.666016 9.99805 1.85992 9.99805 3.33268C9.99805 4.80544 11.192 5.99935 12.6647 5.99935Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.9987 15.3324C9.47146 15.3324 10.6654 14.1384 10.6654 12.6657C10.6654 11.1929 9.47146 9.99902 7.9987 9.99902C6.52594 9.99902 5.33203 11.1929 5.33203 12.6657C5.33203 14.1384 6.52594 15.3324 7.9987 15.3324Z"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.932 5.33301H3.33203C2.1987 5.33301 1.33203 4.46634 1.33203 3.33301C1.33203 2.19967 2.1987 1.33301 3.33203 1.33301H10.932"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.26536 14.666H3.33203C2.1987 14.666 1.33203 13.7993 1.33203 12.666C1.33203 11.5327 2.1987 10.666 3.33203 10.666H6.26536"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.73242 10.666H12.6658C13.7991 10.666 14.6658 11.5327 14.6658 12.666C14.6658 13.1993 14.4658 13.7327 14.0658 14.066C13.7324 14.3993 13.1991 14.666 12.6658 14.666H9.73242"
      stroke="#A35BB1"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
