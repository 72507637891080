import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { clearAllCookies, clearItem, getItem } from '../../../Utils/CommonFunction/CommonFunction';
import { Profilestep1, CommonLanguage } from '../../../../Language/language';
import { SETTINGS_OVERVIEW } from '../../../../common/config';
import ErrorPage from '../../../../ErrorPage';

const OverView = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedOrganizationAge, setSelectedOrganizationAge] = useState('');
  const [selectedTotalPeople, setSelectedTotalPeople] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [display, setdisplay] = useState(false);
  const [displayError, setdisplayError] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  const [loading, setLoading] = useState(false);
  let accessToken = getItem('accessToken');
  useEffect(() => {
    setIsButtonDisabled(selectedIndustry === '' && selectedOrganizationAge === '' && selectedTotalPeople === '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);

    setIsButtonDisabled(event.target.value === '' && selectedOrganizationAge === '' && selectedTotalPeople === '');
  };

  const handleOrganizationAgeChange = (event) => {
    setSelectedOrganizationAge(event.target.value);

    setIsButtonDisabled(selectedIndustry === '' && event.target.value === '' && selectedTotalPeople === '');
  };

  const handleTotalPeopleChange = (event) => {
    setSelectedTotalPeople(event.target.value);

    setIsButtonDisabled(selectedIndustry === '' && selectedOrganizationAge === '' && event.target.value === '');
  };

  useEffect(() => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${accessToken}` };
    axios
      .get(SETTINGS_OVERVIEW, { headers })
      .then((response) => {
        const settings = response.data.settings_overview;
        setSelectedIndustry(settings.your_industry);
        setSelectedOrganizationAge(settings.organization_age);
        setSelectedTotalPeople(settings.total_people);
        setLoading(false);
        setdisplay(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
          setLoading(false);
        }
        setdisplay(true);
        setShowModal(true);
        seterrormsg(error?.response?.data?.error);
        setdisplayError(error?.response?.data?.error?.title);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostRequest = () => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    const apiUrl = SETTINGS_OVERVIEW;
    const jsonPayload = {
      pk: 'settings',
      sk: 'overview',
      your_industry: selectedIndustry,
      organization_age: selectedOrganizationAge,
      total_people: selectedTotalPeople,
    };

    axios
      .post(apiUrl, jsonPayload, { headers })
      .then((response) => {
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
  return (
    <div>
      {/* <SettingFront /> */}
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '32px',
          }}
        >
          <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" />
        </div>
      ) : (
        <>
          {display ? (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: '20px',
                  marginTop: '15px',
                }}
              >
                {displayError}
              </div>
            </>
          ) : (
            <>
              <Box
                component="div"
                sx={{
                  borderRadius: '5px',
                  border: '1px solid #EBE8F1',
                  background: '#F6F5F8',
                  padding: '30px 26px',
                  margin: '10px',
                  width: { sm: '95%', lg: '679px' },
                }}
              >
                <Typography
                  variant="h3"
                  style={{
                    color: '#000',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {Profilestep1.headlineText1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: '#414446',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '21.166px',
                    textTransform: 'capitalize',
                    padding: '18px 0px 29px 0px',
                  }}
                >
                  {Profilestep1.subTitle1}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: '#414446',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '21.166px',
                    textTransform: 'capitalize',
                  }}
                >
                  {Profilestep1.headlineText2}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: '#414446',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '21.166px',
                    textTransform: 'capitalize',
                  }}
                >
                  {Profilestep1.subTitle2}
                </Typography>
              </Box>
              <div
                style={{
                  marginLeft: '10px',
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h3"
                      style={{
                        color: '#000',
                        fontFamily: 'Roboto,sans-serif',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        marginTop: '38px',
                      }}
                    >
                      {Profilestep1.headlineText3}
                    </Typography>
                    <Typography
                      variant="h3"
                      style={{
                        color: '#000',
                        fontFamily: 'Roboto,sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        marginTop: '22px',
                        marginBottom: '9px',
                      }}
                    >
                      {Profilestep1.ListText}
                    </Typography>
                    <FormControl sx={{ minWidth: 318 }}>
                      <Select value={selectedIndustry} onChange={handleIndustryChange}>
                        <MenuItem value="0-1 years">0-1 years</MenuItem>
                        <MenuItem value="1-2 years">1-2 years</MenuItem>
                        <MenuItem value="3-5 years">3-5 years</MenuItem>
                        <MenuItem value="6-10 years">6-10 years</MenuItem>
                        <MenuItem value="11+ years">11+ years</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h3"
                      style={{
                        color: '#000',
                        fontFamily: 'Roboto,sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        marginTop: '22px',
                        marginBottom: '9px',
                      }}
                    >
                      {Profilestep1.ListText2}
                    </Typography>
                    <FormControl sx={{ minWidth: 318 }}>
                      <Select value={selectedTotalPeople} onChange={handleTotalPeopleChange}>
                        <MenuItem value="0-50">0-50</MenuItem>
                        <MenuItem value="51-100">51-100</MenuItem>
                        <MenuItem value="101-200">101-200</MenuItem>
                        <MenuItem value="201+">201+</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h3"
                      style={{
                        color: '#000',
                        fontFamily: 'Roboto,sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        marginTop: '22px',
                        marginBottom: '9px',
                      }}
                    >
                      {Profilestep1.ListText3}
                    </Typography>
                    <FormControl sx={{ minWidth: 318 }}>
                      <Select value={selectedOrganizationAge} onChange={handleOrganizationAgeChange}>
                        <MenuItem value="0-1 years">0-1 years</MenuItem>
                        <MenuItem value="1-2 years">1-2 years</MenuItem>
                        <MenuItem value="3-5 years">3-5 years</MenuItem>
                        <MenuItem value="6-10 years">6-10 years</MenuItem>
                        <MenuItem value="11+ years">11+ years</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={handlePostRequest}
                      fullWidth
                      sx={{
                        width: '146px',
                        fontSize: '16px',
                        textTransform: 'capitalize',
                        marginTop: '45px',
                        marginBottom: '30px',
                      }}
                      disabled={isButtonDisabled}
                    >
                      {CommonLanguage.savebtn}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </>
      )}
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} closingFlag={1} />}
    </div>
  );
};

export default OverView;
