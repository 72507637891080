import { CUSTOMER_DATA_INTIGRATION_ERROR, CUSTOMER_DATA_INTIGRATION_LOADING, CUSTOMER_DATA_INTIGRATION_SUCCESS, RESET_CUSTOMER_DATA_INTIGRATION } from "../Constant";

let initialState = {
    customerdataintigrationpost: {
        loading: false,
        data: null,
        error: false,
        errors: null,
        success: false,
        pagination: {},
    },
};

export default function customerdataintigrationpostReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_DATA_INTIGRATION_LOADING:
            return {
                ...state,
                customerdataintigrationpost: {
                    ...state.customerdataintigrationpost,
                    loading: true,
                    error: false,
                    errors: [],
                    success: false,
                },
            };

        case CUSTOMER_DATA_INTIGRATION_SUCCESS:
            return {
                ...state,
                customerdataintigrationpost: {
                    ...state.customerdataintigrationpost,
                    loading: false,
                    error: false,
                    errors: [],
                    success: true,
                    data: action.response.data,
                },
            };

        case CUSTOMER_DATA_INTIGRATION_ERROR:
            return {
                ...state,
                customerdataintigrationpost: {
                    ...state.customerdataintigrationpost,
                    loading: false,
                    error: true,
                    errors: action.errors,
                    success: false,
                    data: null,
                },
            };

        case RESET_CUSTOMER_DATA_INTIGRATION:
            return {
                ...state,
                customerdataintigrationpost: {
                    ...state.customerdataintigrationpost,
                    loading: false,
                    error: false,
                    errors: null,
                    success: false,
                },
            };

        default:
            return state;
    }
}