import { loginActions } from "../slices/login-slice";

const { setRegister, setForgetEmail } = loginActions;

export const registerEmail = (values) => (dispatch) => {
  dispatch(setRegister(values));
};
export const forgetEmail = (values) => (dispatch) => {
  dispatch(setForgetEmail(values));
};
