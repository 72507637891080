import React from 'react';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import { Popover } from '@mui/material';
import moment from 'moment';

const ViewDialog = ({ popoverRef, popover, handlePopoverClose }) => {
  const created = `Created by ${popover?.content?.created_by_full_name} ${popover?.content?.created_datetime ? moment(popover?.content?.created_datetime).format('MMMM D, YYYY') : ''}`;
  return (
    <Popover
      open={popover.open}
      anchorEl={popover.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          border: '1px solid #D3BED7',
          background: '#F5F2F5',
          padding: '20px',
          boxShadow: '9px 9px 17px 0px rgba(0, 0, 0, 0.08)',
          width: '600px',
        },
      }}
    >
      <div ref={popoverRef} style={{ display: 'grid', gap: '10px' }}>
        <div className="headerText" style={{ color: 'var(--col-14, #A35BB1)' }}>
          {popover.content['name']}
        </div>
        <div className="normalText">{popover.content['description']}</div>
        <div
          className="normalText"
          style={{
            fontSize: '12px',
          }}
        >
          {created}
        </div>
        <div className="customHR"></div>
        <div className="headerText">Conditions</div>
        <div className="normalText">{popover.content['where_condition']}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButton
            onClick={handlePopoverClose}
            buttonValue={'close'}
            isDisabled={false}
            style={{
              background: '#fff',
              color: '#852598',
              border: '1px solid #852598',
              width: '105px',
              height: '42px',
            }}
          />
        </div>
      </div>
    </Popover>
  );
};

export default ViewDialog;
