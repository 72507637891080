import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, Skeleton } from '@mui/material';
import LeadingOffer from './Component/LeadingOffer/LeadingOffer';
import NewDashboardHead from './Component/NewDashboardHead';
import SegmentRefrence from './HoverChart/SegmentRefrence';
import { CampaignOneClickService, OneClickCreateService } from '../../services/CampaignOneClickService';
import {
  campaignPageType as PageType,
  offersData as setOffersData,
  aboutCampaignData as setAboutCampaignData,
  spendingLimitGlobal as setSpendingLimitGlobal,
  dataSourceData as setDataSourceData,
  segmetsData as setSegmetsData,
  snackBarMsg as setSnackBarMsg,
  batchDataGlobal,
} from '../Campaigns/GlobalStore';
import OneClickCampaign from './Component/Campaign/OneClickCampaign';

const NewDashBoard = () => {
  const [oneclicksegmentData, setoneclicksegmentData] = useState();
  const [loadingOneClick, setLoadingOneClick] = useState(false);
  const [selectedTab, setSelectedTab] = useState(-1);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    (async () => {
      setLoadingOneClick(true);
      const response = await CampaignOneClickService();
      if (response?.status == 200) {
        setLoadingOneClick(false);

        setoneclicksegmentData(response?.data);
      } else {
        alert('setoneclicksegmentData');
      }
    })();
  }, []);

  const oneClickCategories = (campaign_pk, campaign_sk) => {
    (async () => {
      const response = await OneClickCreateService(campaign_pk, campaign_sk);
      if (response?.status == 200) {
        navigate('/campaigns');
        setAboutCampaignData({
          name: response?.data?.name,
          description: response?.data?.description,
          objective: response?.data?.objective,
          channelType: response?.data?.channel_type,
          campaign_pk: response?.data?.pk,
          campaign_sk: response?.data?.sk,
          manualData: response?.data?.vendors,
          segments_type: response?.data?.segments_type,
        });

        const segmentData = setSegmetsData();
        if (response?.data?.channel_type == 'manual') {
          segmentData.manualData = [...response?.data?.segments];
        } else {
          segmentData.AIData = [...response?.data?.segments];
        }
        setSegmetsData(segmentData);

        setDataSourceData({ AIData: response?.data?.data_sources, manualData: [], data_sources_type: response?.data?.data_sources_type });
      } else {
        alert('setoneclicksegmentData');
      }
    })();
  };

  return (
    <div>
      <Grid container direction="column" sx={{ backgroundColor: '#F6F5F8', margin: 0 }}>
        <Grid item>
          <Grid container spacing={2} sx={{ padding: 0, margin: 0, width: '80vw!important', paddingBottom: '20px' }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                bgcolor: 'background.paper',

                borderRadius: 2,
                padding: '0px!important',
              }}
            >
              <section>
                <Grid
                  container
                  sx={{
                    borderBottom: '1px solid #EBE8F1',
                    padding: '11px 22px',
                    width: '80vw',
                  }}
                >
                  <Grid item xs={10} sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        lineHeight: '40px',
                        color: '#000',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Portfolio by Interest Rate & Unpaid Balance
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#414446',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginY: '10px ',
                        // paddingLeft: '13px',
                      }}
                    >
                      Hover over each interest rate segment to view the number of loans. Click for detailed loan balance breakdowns.
                    </Typography>
                  </Grid>
                </Grid>
              </section>
              <section style={{ marginTop: '10px', width: '80vw' }}>
                <NewDashboardHead handleChange={handleChange} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              </section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <section style={{ margin: '0px!important', width: '80vw' }}>
        <Grid
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            padding: '10px!important',
          }}
        >
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', marginLeft: '8px', marginY: '22px' }}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '18px',
                lineHeight: '40px',
                color: '#000',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
              }}
            >
              One Click Campaign
            </Typography>
          </Grid>

          {loadingOneClick ? (
            <Box sx={{ backgroundColor: '#F6F5F8' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width="100%" height={118} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Grid
              container
              sx={{
                // gap: 2,
                // textAlign: 'start',
                // display: 'flex',
                justifyContent: 'space-between',
                // flexWrap: 'wrap',
                // marginY: '10px',
                overflow: 'auto',
              }}
            >
              <section style={{ margin: '0px!important', width: '75vw' }}>
                <Grid
                  sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    padding: '10px!important',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {oneclicksegmentData?.map((v, i) => {
                    return <OneClickCampaign details={v} index={i} />;
                  })}
                </Grid>
              </section>
            </Grid>
          )}
        </Grid>
      </section>

      <section style={{ margin: '0px!important', paddingLeft: '16px', width: '80vw' }}>
        <LeadingOffer />
      </section>
    </div>
  );
};

export default NewDashBoard;
