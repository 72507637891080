import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

export default function CustomButton(props) {
  const [_disabled, set_disabled] = useState();
  useEffect(() => {
    const _disabled = props.disabled;
    set_disabled(_disabled);
  }, [props]);
  return (
    <div>
      <Button
        type="submit"
        variant="outlined"
        onClick={props.handleClickBack}
        style={{
          color: 'var(--col-12, #852598)',
          textAlign: 'center',
          fontFamily: 'Noto Sans',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          width: '20vh',
          height: '48px',
          borderRadius: '6px',
          background: '#fff',
          border: '1px solid var(--col-12, #852598)',
        }}
      >
        Back
      </Button>
      <Button
        type="submit"
        // variant="contained"
        onClick={props.handleClickNext}
        disabled={!!_disabled}
        style={{
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'Noto Sans',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          width: '20vh',
          height: '48px',
          borderRadius: '6px',
          marginLeft: '1vh',
          // background: '#852598',
          // background: !props.disabledFlag ? '#ffffff' : '#852598',
          background: !!_disabled ? '#d3abdb' : '#852598',
          ':disabled': {
            color: '#ffffff',
          },
          ':hover': {
            backgroundColor: '#d3abdb',
          },
        }}
      >
        Next
      </Button>
    </div>
  );
}