import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

import { Profilestep2 } from '../../../../../Language/language';

const Password = (props) => {
  const { successMessage, errorMessage, formik } = props;
  return (
    <div>
      <Typography
        style={{
          color: '#414446',
          fontFamily: 'IBM Plex Serif',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingBottom: '12px',
          marginTop: '25px',
        }}
      >
        {Profilestep2.headlineText3}
      </Typography>
      <form>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '22px',
                marginBottom: '9px',
              }}
            >
              {Profilestep2.passlabel}
            </Typography>
            <TextField fullWidth type="password" label="Current Password" {...formik.getFieldProps('currentPassword')} />
            {formik.touched.currentPassword && formik.errors.currentPassword ? <div style={{ color: 'red' }}>{formik.errors.currentPassword}</div> : null}
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '22px',
                marginBottom: '9px',
              }}
            >
              {Profilestep2.passlabel2}
            </Typography>
            <TextField fullWidth type="password" label="New Password" {...formik.getFieldProps('newPassword')} />
            {formik.touched.newPassword && formik.errors.newPassword ? <div style={{ color: 'red' }}>{formik.errors.newPassword}</div> : null}
            <Grid container>
              <Grid item xs={6} lg={3}>
                <ul style={{ paddingLeft: '25px' }}>
                  <li>One lowercase character</li>
                  <li>One uppercase character</li>
                  <li>One number</li>
                </ul>
              </Grid>
              <Grid item xs={6}  lg={3}>
                <ul>
                  <li>One special character</li>
                  <li>8 characters minimum</li>
                  <li>50 characters maximum</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          style={{
            color: '#000',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginTop: '22px',
            marginBottom: '9px',
          }}
        >
          {Profilestep2.passlabel3}
        </Typography>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={10}>
            <TextField fullWidth type="password" label="Confirm New Password" {...formik.getFieldProps('confirmPassword')} />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div style={{ color: 'red' }}>{formik.errors.confirmPassword}</div> : null}
          </Grid>
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
        </Grid>
      </form>
    </div>
  );
};

export default Password;
