import { Box, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DropDown from './DropDown';
import Fuse from 'fuse.js';

const performanceFilterOptions = [
  {
    label: 'By Top Performing',
    value: 'by_top_performing',
  },
  {
    label: 'Channels',
    options: [
      {
        label: 'Direct Mail',
        value: 'direct_mail',
      },
      { label: 'Email', value: 'email' },
      { label: 'Telemarketing', value: 'telemarketing' },
      { label: 'SMS', value: 'sms' },
      { label: 'Social Media', value: 'social_media' },
      { label: 'Paid Search', value: 'paid_search' },
      { label: 'Paid Display', value: 'paid_display' },
    ],
  },
  {
    label: 'Unused',
    value: 'unused',
  },
];

const timeFrameOptions = [
  {
    label: 'By Time Frame',
    options: [
      {
        label: 'Recent',
        value: 'recent',
      },
      {
        label: 'Past 30 Days',
        value: 'before_30_days',
      },
      {
        label: 'Past 90 Days',
        value: 'before_90_days',
      },
      {
        label: 'Past 120 Days',
        value: 'before_120_days',
      },
      {
        label: 'Archived',
        value: 'archived',
      },
    ],
  },
];

const FilterAndSearch = ({ selectedFilter, filterValue, handleFilter, data, onSearchResult }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const handleSearch = (term) => {
    if (!term.trim()) {
      onSearchResult(data);
      return;
    }

    const options = {
      keys: ['name'],
      includeScore: true,
      threshold: 0.3,
    };
    const fuse = new Fuse(data, options);
    const result = fuse.search(term);
    onSearchResult(result.map((res) => res.item));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #ebe8f1',
      }}
    >
      <DropDown
        defaultValue={selectedFilter === 'topPerforming' ? filterValue : null}
        options={performanceFilterOptions}
        value={selectedFilter === 'topPerforming' ? filterValue : null}
        handleChange={(value) => {
          handleFilter('topPerforming', value);
        }}
        placeholder="By Top Performing"
      />

      <DropDown
        defaultValue={selectedFilter === 'historicalData' ? filterValue : null}
        options={timeFrameOptions}
        value={selectedFilter === 'historicalData' ? filterValue : null}
        handleChange={(value) => {
          handleFilter('historicalData', value);
        }}
        placeholder="Recent"
      />

      <TextField
        id="app-integration-search"
        value={searchTerm}
        size="small"
        margin="none"
        variant="outlined"
        sx={{}}
        placeholder="Search files"
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          sx: {
            height: '2.1rem',
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default FilterAndSearch;
