import { ListItem, ListItemButton } from '@mui/material';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

const AppMenuItemComponent = ({ onClick, link, style, name, children }) => {
  if (!link || typeof link !== 'string') {
    return (
      <ListItem onClick={() => onClick(name)}>
        <ListItemButton style={style}>{children}</ListItemButton>
      </ListItem>
    );
  }
  return (
    <ListItem
      style={style}
      component={forwardRef((props, ref) => (
        <NavLink
          // exact
          {...props}
          ref={ref}
          onClick={() => onClick(name)}
        />
      ))}
      to={link}
    >
      <ListItemButton style={style}>{children}</ListItemButton>
    </ListItem>
  );
};

export default AppMenuItemComponent;
