import React, { useState } from 'react';
import { Button, Divider, Grid, Typography, Snackbar, Dialog, DialogContentText, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useSelector } from 'react-redux';
import OTPInput from 'otp-input-react';
import RegisterSuccessIcon from '../../assets/RegisterSuccessIcon.svg';
import { CommonLanguage, RegistrationStep3Screen } from '../../Language/language';
import {
  campaignPageType,
  aboutCampaignData,
  dataSourceData,
  segmetsData,
  offersData,
  dateTimeGlobal,
  spendingLimitGlobal,
  snackBarMsg,
  batchDataGlobal,
} from '../Campaigns/GlobalStore';
import { getItem, setItem, poolData, setCookie } from '../Utils/CommonFunction/CommonFunction';
import { LoadingButton } from '@mui/lab';

const resetApolloVariables = () => {
  campaignPageType(1);
  aboutCampaignData({});
  dataSourceData({ manualData: [], AIData: [] });
  segmetsData({ manualData: [], AIData: [] });
  offersData([]);
  dateTimeGlobal({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  });
  spendingLimitGlobal({
    noLimit: true,
    period: 'daily',
    min: '',
    max: '',
  });
  snackBarMsg(false);
  batchDataGlobal({
    batch_size: '',
    batch_type: 'daily',
  });

  // uploadCSVpage(1);
  // open(false);
};

const userPool = new CognitoUserPool(poolData);

const RegistrationStep2 = ({ handleNext }) => {
  const [OTP, setOTP] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const registrationDetails = useSelector((state) => state.login);

  const userData = {
    Username: registrationDetails.registration.email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  const handleSubmit = () => {
    setIsLoading(true);
    cognitoUser.confirmRegistration(OTP, true, function (err, result) {
      if (err) {
        setErrorMessage('Please enter valid OTP');
        setIsLoading(false);
        return;
      }
      if (result) {
        const userEmail = getItem('userEmail');
        const userPassword = getItem('userPassword');
        const firstName = getItem('userFirstName');
        const lastName = getItem('userLastName');

        const authenticationDetails = new AuthenticationDetails({
          Username: userEmail,
          Password: userPassword,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            resetApolloVariables();

            cognitoUser.associateSoftwareToken({
              onFailure: (err) => {
                console.error(err);
              },
              associateSecretCode: (secretCode) => {
                const generateQRCodeURL = (secretCode) => {
                  const username = `${firstName} ${lastName}`; // Replace with the actual username or user ID
                  // const issuer = process.env.REACT_APP_CLIENT_ID;
                  // Replace with your app's name or issuer
                  const issuer = 'Synergi'; // Replace with your app's name or issuer
                  const otpAuthURL = `otpauth://totp/${issuer}:${username}?secret=${secretCode}&issuer=${issuer}`;
                  setItem('qrCodeURL', otpAuthURL);
                  setCookie('qrCodeURL', otpAuthURL, 24);
                  return otpAuthURL;
                };
                generateQRCodeURL(secretCode);
                handleNext();
                // setQRCodeURL(qrCodeURL);
                // renders the QR component on the page
                // the example uses a prompt here and continues to verifySoftwareToken immediately
                // after, which I'm trying to modify
              },
            });
            setIsLoading(false);
            return result;
          },
          onFailure: function (err) {
            console.error(err);
          },
        });
      }
      // If successful, clear the current dialog and show the success dialog
    });
  };

  const handleResendEmail = () => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        setErrorMessage(err.message || JSON.stringify(err));
        return;
      }
      setMessage('Confirmation code resent successfully. Please check your email');
      setOpen(true);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  // const closeSuccessDialog = () => {
  //   setSuccessDialogOpen(false);
  //   navigate('/login');
  // };

  return (
    <>
      <Dialog alignContent="center" fullWidth maxWidth="md" open={successDialogOpen} x>
        <DialogContentText>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3} sx={{ p: 5 }}>
            <Grid item>
              <IconButton>
                <img src={RegisterSuccessIcon} alt="left move" width="150px" height="150px" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: '#132529',
                  textAlign: 'center',
                  fontFamily: 'IBM Plex Serif',
                  fontSize: '25px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                Registration Successfully <br /> Completed
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  color: '#ffffff',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 'Bold',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  width: '17rem',
                  height: '48px',
                  borderRadius: '6px',
                  backgroundColor: '#852598',
                  ':hover': {
                    backgroundColor: '#852598',
                  },
                }}
                // onClick={closeSuccessDialog}
              >
                Log in
              </Button>
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2} sx={{ pl: '3%' }}>
        <Grid item xs={12} variant="h5" sx={{ mt: '4%', fontSize: '18px' }} style={{ paddingTop: '0px' }}>
          <Typography color="secondary">{CommonLanguage.welcome}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: '10px' }} style={{ paddingTop: '0px' }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: { lg: 28, md: 25, sm: 20, xs: 16 },
              color: '#132529',
              fontFamily: 'IBM Plex Serif',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '38px' /* 135.714% */,
              textTransform: 'capitalize',
            }}
          >
            {RegistrationStep3Screen.headlineText1}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: { lg: 28, md: 25, sm: 20, xs: 16 },
              color: '#132529',
              fontFamily: 'IBM Plex Serif',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '38px' /* 135.714% */,
              textTransform: 'capitalize',
            }}
          >
            {RegistrationStep3Screen.headlineText2}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: '30px' }} style={{ paddingTop: '0px' }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              color: '#112333',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            We've sent a verification code to your email ({registrationDetails.registration.email}) via email.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: '10px' }} style={{ paddingTop: '0px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              color: '#112333',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            {RegistrationStep3Screen.otpmsgText}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <Typography sx={{ fontSize: { lg: 30, md: 25, sm: 20, xs: 16 } }}>{RegistrationStep3Screen.accountHelping}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: '5%' }} style={{ paddingTop: '0px' }}>
          <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
        </Grid>
        {errorMessage ? (
          <Grid item>
            <Typography
              sx={{
                color: 'red',
                textAlign: 'center',
                fontFamily: 'IBM Plex Serif',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '20px',
              }}
            >
              {errorMessage}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} sx={{ mt: '5%' }} style={{ paddingTop: '0px' }}>
          {isLoading ? (
            <LoadingButton
              color="secondary"
              loading
              loadingPosition="end"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '0.9',
                fontStyle: 'normal',
                fontWeight: 'Bold',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                borderRadius: '6px',
                color: 'white',
              }}
              disabled
            >
              <span style={{ marginRight: '0.5rem', color: 'white' }}>{CommonLanguage.Loading}</span>
            </LoadingButton>
          ) : (
            <Button variant="contained" onClick={handleSubmit} size="large" color="secondary" disabled={isLoading}>
              {RegistrationStep3Screen.verifybtn}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: '4%' }} style={{ paddingTop: '0px' }}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: '8%' }} style={{ paddingTop: '0px' }}>
          <Typography>
            {RegistrationStep3Screen.emailmsgText}&nbsp;
            <Button color="secondary" onClick={handleResendEmail}>
              {RegistrationStep3Screen.resend}
            </Button>
          </Typography>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {message}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default RegistrationStep2;
