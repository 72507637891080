import React, { useEffect, useState } from 'react';
import AppMenuItemComponent from './AppMenuItemComponent';
import { Box, Collapse, Divider, List, ListItemIcon, ListItemText, open } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppSubMenuItemComponent from './AppSubMenuItemComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { getItem, setItem } from '../../view/Utils/CommonFunction/CommonFunction';

const AppMenuItem = ({ title, name, icon, whiteIcon, link, items = [], selectedMenu, setSelectedMenu, selectedIndex, setSelectedIndex }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  const isExpandable = items && items.length > 0;

  const handleClick = (name) => {
    if (selectedMenu === name) {
      setOpen(!open);
    } else {
      setOpen(true);
      setSelectedMenu(name);
      setItem('selectedMenu', name);
    }

    if (selectedIndex !== null) {
      setSelectedIndex(null);
    }
  };

  const handleSelectSubMenu = (name, itemLink) => {
    if (!itemLink) return;
    setOpen(true);
    setSelectedSubMenu(name);
    setItem('selectedMenu', name);
    setSelectedIndex(null);
    navigate(itemLink);
  };

  const setSelectedMenuAndIndexFromPath = (path) => {
    let found = false;

    if (link === path) {
      setSelectedMenu(name);
      setSelectedIndex(null);
      setSelectedSubMenu(''); // Ensure subMenu is reset for top-level links
      found = true;
    } else {
      items.forEach((subItem, index) => {
        if (subItem.link === path) {
          setSelectedMenu(name);
          setSelectedIndex(index);
          setSelectedSubMenu(subItem.name); // Set the submenu based on the matched link
          found = true;
        }
      });
    }

    if (!found) {
      setSelectedMenu('');
      setOpen(false);
      setSelectedIndex(null);
      setSelectedSubMenu('');
    }
  };

  useEffect(() => {
    const savedMenu = getItem('selectedMenu');
    if (savedMenu) {
      setSelectedMenu(savedMenu);
      if (savedMenu === name) setOpen(true); // Open submenu if it matches the saved menu
      else setOpen(false);
    }

    items.forEach((item) => {
      if (location.pathname.includes(item.link)) {
        setSelectedMenu(name);
        setSelectedSubMenu(item.name);
        setOpen(true);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedMenuAndIndexFromPath(location.pathname);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <AppMenuItemComponent
        link={link}
        onClick={() => handleClick(name)}
        name={name}
        style={
          selectedMenu === name
            ? {
                width: '100%',
                backgroundColor: '#3e004a',
                borderRadius: '8px',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#3e004a', color: '#ffffff' },
              }
            : {
                '&:hover': { bgcolor: 'background.paper', borderRadius: '8px' },
              }
        }
      >
        <ListItemIcon style={{ minWidth: '2rem' }}>{selectedMenu === name ? whiteIcon : icon}</ListItemIcon>
        <ListItemText primary={title} style={selectedMenu === name ? { color: 'white ' } : { color: '#852598 ' }} />
        {isExpandable && !open && <ExpandMore style={selectedMenu === name ? { color: 'white ' } : { color: '#852598 ' }} />}
        {isExpandable && open && <ExpandLess style={selectedMenu === name ? { color: 'white ' } : { color: '#852598 ' }} />}
      </AppMenuItemComponent>

      {isExpandable && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((item, index) => (
              <AppSubMenuItemComponent
                {...item}
                key={index}
                onClick={() => handleSelectSubMenu(item.name, item.link)}
                style={{
                  padding: '0',
                  paddingLeft: '4rem',
                  width: '100%',
                  textTransform: 'capitalize',
                  cursor: !item.link ? 'not-allowed' : 'pointer',
                }}
                disabled={item.disabled}
              >
                <Box
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {selectedSubMenu === item.name && (
                    <Box
                      sx={{
                        bgcolor: '#3e004a',
                        height: '5px',
                        width: '5px',
                        borderRadius: '100%',
                      }}
                    />
                  )}
                </Box>
                <ListItemText primary={item.title} />
              </AppSubMenuItemComponent>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default AppMenuItem;
