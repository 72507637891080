import React, { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import SegmentsAI from "./SegmentsAI/SegmentsAI";
import SegmentsManual from "./SegmentsManual/SegmentsManual";
import { segmetsData as setSegmentsData } from "../../GlobalStore";

const SegmentsChannel = ({ segmentsAIAPI, segmentsManualAPI }) => {
  const segmetsData = useReactiveVar(setSegmentsData);

  const [selectedChannel, setChannel] = useState(
    segmetsData?.channelType || "Ai"
  );

  const StyledBoxAI = styled(Box)(({ theme }) => ({
    marginLeft: "10px",
    width: "200px",
    cursor: "pointer",
    textAlign: "center",
    borderBottom: selectedChannel === "Ai" ? "3px solid #764F7D" : "",
  }));

  const StyledBoxManual = styled(Box)(({ theme }) => ({
    width: "200px",
    cursor: "pointer",
    textAlign: "center",
    borderBottom: selectedChannel === "manual" ? "3px solid #764F7D" : "",
  }));

  const [selectedChannelFile, setChannelFile] = useState(
    segmetsData?.channelFile || []
  );

  useEffect(() => {
    setChannel(segmetsData?.channelType || "Ai");
    setChannelFile(segmetsData?.channelFile || []);
  }, []);

  const handleSelection = (selection) => {
    setChannel(selection);
    const currentSegmentsData = setSegmentsData();
    setSegmentsData({
      ...currentSegmentsData,
      channelType: selection,
    });
  };

  useEffect(() => {
    if (!segmetsData?.channelType) {
      const currentSegmentsData = setSegmentsData();
      setSegmentsData({
        ...currentSegmentsData,
        channelType: "Ai",
      });
    }
  }, []);

  const handleFileChange = (selectedFile) => {
    setChannelFile(selectedFile.selectedFileHere);
    const currentSegmentData = setSegmentsData();
    setSegmentsData({
      ...currentSegmentData,
      channelFile: selectedFile.selectedFileHere,
    });
  };

  const renderComponent = () => {
    if (selectedChannel === "Ai") {
      return <SegmentsAI segmentsAIAPI={segmentsAIAPI} />;
    } else {
      return <SegmentsManual segmentsManualAPI={segmentsManualAPI} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EBE8F1",
          borderRadius: "5px",
          width: "679px",
          height: "auto",
        }}
      >
        <Box display="flex" alignItems="center">
          <StyledBoxAI p={2} onClick={() => handleSelection("Ai")}>
            <h2
              variant="body1"
              style={{
                fontWeight: selectedChannel === "Ai" ? "500" : "400",
                color:
                  selectedChannel === "Ai"
                    ? "#764F7D"
                    : "var(--text-txt-primary, #112333)",
                fontFamily: "Roboto",
              }}
            >
              AI-Predictive Allocation
            </h2>
          </StyledBoxAI>

          <StyledBoxManual p={2} onClick={() => handleSelection("manual")}>
            <h2
              variant="body1"
              style={{
                fontWeight: selectedChannel === "manual" ? "500" : "400",
                color:
                  selectedChannel === "manual"
                    ? "#764F7D"
                    : "var(--text-txt-primary, #112333)",
                fontFamily: "Roboto",
              }}
            >
              Manual Allocation
            </h2>
          </StyledBoxManual>
        </Box>
        <div className="customHR"></div>
        <Box p={2} sx={{ maxHeight: "822px", overflowY: "auto" }}>
          {renderComponent()}
        </Box>
      </Box>

      {/* <div className="headerText" style={{ marginTop: "20px" }}>
        Rate Sheet Integration with Segment
      </div>
      <div children="normalText" style={{ color: "var(--col-6, #5D596C)" }}>
        Select a rate sheet for precise predictions and optimized segmentation
        guidance.
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "625px" }}>
          <InputDragNDrop
            setSelectedFile={handleFileChange}
            selectedFile={selectedChannelFile}
          />
        </div>
      </div>
      <div className="customHR"></div> */}
    </>
  );
};

export default SegmentsChannel;
