/// comman text ///
export const CommonLanguage = {
  subtext: 'CommonLanguage',
  submitbtn: 'Submit',
  cancelbtn: 'Cancel',
  AllField: ' * All fields are required',
  letsgobtn:'Let’s Go',
  unsuretext: 'Unsure how?',
  introtext: 'Our intro video can help.',
};

/// DataSampleFileModal ///
export const DataFileModal = {
  headlineText1: 'Explore Sample Data Files',
  subtitle: 'Click on the use case name to download the file',
  ListText1: 'Loan portfolio data',
  ListText2: 'Daily Rate Sheets',
  ListText3: 'Leads from CRM/ LMS',
  ListText4: 'Application data from LOS',
  ListText5: 'Call records',
  ListText6: 'Web visit logs',
  ListText7: 'Borrower Demographic data',
  ListText8: 'Marketing Campaigns Engagement/ Dispositions',
  ListText9: 'Campaign Templates Repository with CTA',
  ListText10: 'Vendor information and their file layouts',
};

/// View & Create Offers massage modal ///

export const createofferScreen = {
  headlineText1: 'View & Create Offers',
  subtitle: "Draft and set your promotions. Here, you can create enticing offers that your audience can't resist.",
  checkvideo: 'Check our video',
  btnText: 'Let’s Go',
  link: " https://www.synergi.ai/howto/create-offers"
};

/// View & Create Segments massage modal ///

export const createSegmentScreen = {
  headlineText1: 'View & Create Segments',
  subtitle: "Identify and group your audience. Use this section to create specific audience groups for targeted campaigns.",
  checkvideo: 'Check our video',
  btnText: 'Let’s Go',
  link: " https://www.synergi.ai/howto/create-segment"
};

/// intigration modal ///
export const intigrationmodal2 = {
  headlineText1: 'Great job!',
  textbody:
    ' A member of the Synergi team will reach out to you within the next 24-48 hours to finalize your custom data solution. In the meantime, you can explore more features on your dashboard.',
  processbtn: 'Proceed to Dashboard',
};

export const intigrationmodal1 = {
  headlineText1: 'Assessing Your Customer Data Requirements',
  textbody: 'Matching optimal data strategies... Just a moment.',
};

/// Campaigns ///
export const Campaignsmodal = {
  headlineText1: 'View & Create Campaigns',
  subtitle1: 'Set up and track your drives. Design your campaigns and monitor their reach and impact.',
  campainQtext: 'Unsure how?',
  introtext: 'Our intro video can help.',
  campaignbtn: 'Let’s Go',
};

export const datamissing = {
  headlineText1: 'Required Data Missing!',
  subtitle1: 'Please upload the Segment Data File and Offer Rate Sheet to proceed with the campaign.',
  campainQtext: 'Unsure how?',
  introtext: 'Our intro video can help.',
  campaignbtn: 'Let’s Go',
};

export const DataInModal = {
  headlineText1: 'Great job!',
  subTitle1:
    'A member of the Synergi team will reach out to you within the next 24-48 hours to finalize your custom data solution. In the meantime, you can explore more features on your dashboard.',
  databtn2: 'Proceed to Dashboard',
};

export const DataInModal1 = {
  headlineText1: 'Assessing Your Customer Data Requirements',
  subTitle1: 'Matching optimal data strategies... Just a moment.',
};

/// profile ///

export const profileModal = {
  headlineText1: 'Contact Information',
  headlineText2: ' Billing Information',
  Firstname: 'First name',
  Lastname: 'Last name',
  EmailId: 'Email ID (at work)*',
  CompanyOrganization: 'Company / Organization',
  WebsiteURL: 'Website URL',
  OfficePhone: ' Office Phone*',
  ConatctAddress: 'Contact Address*',
  ContactAddress2: 'Contact Address 2',
  ContactCountry: 'Contact Country*',
  ContactCity: 'Contact City*',
  Contactzipcode: 'Contact zip code*',
  ContactState: ' Contact State*',
};

/// loading screen ///

export const LoadingScreen4 = {
  headlineText1: 'Finalizing Details',
  subTitle1: (
    <span>
      A moment, please. <br />
      We're consolidating your conditions for the final
      <br /> review.
    </span>
  ),
};

export const LoadingScreen5 = {
  headlineText1: 'Processing Your Input',
  subTitle1: (
    <span>
      Please wait, your selections are being processed <br />
      for the next step.
    </span>
  ),
};

/// loading screen for offer ///
export const LoadingScreen6ForOffer = {
  title: "Missing Rate Sheet Detected!",
  subtitle: "To create offers seamlessly, we require your Rate Spreadsheet.",
  buttonText: "Go to Rate sheet upload"
};

export const LoadingScreen7ForOffer = {
  title: 'Great job!',
  subtitle: <span>Your new Offer has been successfully created. You can now utilize this in your upcoming campaigns or manage it along with your existing offers.</span>,
  buttonText: "Manage Offers"
};

/// loading screen for segment ///
export const LoadingScreen6ForSegment = {
  title: 'Missing MST Loan Detected!',
  subtitle: 'To create segments seamlessly, we require your MST Loan.',
  buttonText: "Go to MST Loan upload"
};


export const LoadingScreen7ForSegment = {
  title: 'Great job!',
  subtitle: <span>Your new Segment has been successfully created. You can now utilize this in your upcoming campaigns or manage it along with your existing segments.</span>,
  buttonText: "Manage Segments"
};

/// campaignScreen Loading ///

export const campaignScreen1 = {
  headlineText1: 'Setting the Stage',
  subTitle1: (
    <span>
      Hang tight! <br />
      We're prepping the system for your data selection.
    </span>
  ),
};


export const campaignScreen2 = {
  headlineText1: 'Unlocking Your Segments',
  subTitle1: (
    <span>
      A moment, please. <br />
      We're ingesting your uploaded data to generate the <br /> most relevant segments.
    </span>
  ),
};


export const campaignScreen3 = {
  headlineText1: 'Matching Segments to Offers',
  subTitle1: (
    <span>
      Hold on! <br />
      We're assigning the best offers to your selected <br />
      segments for maximum impact.
    </span>
  ),
};  


export const campaignScreen4 = {
  headlineText1: 'Finalizing Details',
  subTitle1: (
    <span>
      Almost there! ! <br />
      maximum impact. We're consolidating your settings and preparing <br />
      your campaign for review.
    </span>
  ),
};


export const campaignScreen5 = {
  headlineText1: 'Processing Your Input',
  subTitle1: (
    <span>
      Please wait, your selections are being processed <br />
              for the next step.
    </span>
  ),
};


// INTIGRATION POPUP


export const intigrationpopupscreen={
  headlineText1:'Manage Import',
  subTitle1:" Fuel Synergi's AI engine. Upload your data, request vendor integrations, or export crucial insights. Begin your data journey now.",

}