import { Box, Typography } from "@mui/material";
import React from "react";

const UsesCampaign = ({ totalCampaign }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto",
        padding: "0 1.5rem",
      }}
    >
      <Typography
        sx={{
          color: "#5d596c",
        }}
      >
        Uses
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#a35bb1",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontFamily: "IBM Plex Serif",
          }}
        >
          {totalCampaign}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.7rem",
            fontWeight: "Bold",
          }}
        >
          Campaigns
        </Typography>
      </Box>
    </Box>
  );
};

export default UsesCampaign;
