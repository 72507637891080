import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const SynergiInsight = () => {
  return (
    <div>
      <Box>
        <Grid container sx={{ alignItems: 'center', display: 'flex', padding: '17px 2px 5px 30px' }}>
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <img src={require('../../../../assets/bulb icon.png')} alt="" />
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingLeft: '14px',
              }}
            >
              SYNERGI Insights
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img src={require('../../../../assets/Plus-icon.png')} />
          </Grid>
          <Grid sx={{ width: '80%', mr: 1, mt: '10px' }}>
            <LinearProgress
              variant="determinate"
              value={90}
              sx={{
                height: 4,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#E08029', // Color for the 80%
                },
                '& .MuiLinearProgress-dashed': {
                  backgroundImage: 'none',
                },
                backgroundColor: '#D0CBC3', // Color for the remaining 20%
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: ' #414446',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '18px',
                padding: '17px 0px',
              }}
            >
              Your current lending offers show potential for optimization based on the latest data insights. To tap into evolving market dynamics and align with your user
              preferences, let Synergi's AI craft scientifically tailored offers. Elevate your strategy and stay ahead of the curve.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SynergiInsight;
