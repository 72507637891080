import { Button, Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const UserPopup = ({ closeModal, state, selectedUser }) => {
  return (
    <>
      <div className="wrapper"></div>
      <Card
        sx={{ my: 5, borderRadius: 2, padding: '6px' }}
        style={{
          width: '834px',
          // height: '371px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          zIndex: '99',
          transform: 'Translate(-50%,-50%)',
          // padding: '14px 34px 69px 49px',
        }}
      >
        <Grid container>
          {/* <Grid
            item
            xs={12}
            onClick={closeModal}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CloseIcon />
          </Grid> */}

          <Grid container sx={{ padding: '55px 66px ' }}>
            <Grid item xs={1.5}>
              <img src={require('../../../../assets/Checkout-icon.png')} alt="" sx={{ width: '65px' }} />
            </Grid>
            <Grid item xs={10} sx={{ paddingRight: '80px', paddingTop: '10px' }}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',
                  color: '#158C6F',
                }}
              >
                {selectedUser ? 'User Details Updated Successfully' : 'User Creation Successful'}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '8px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
                {selectedUser ? `Your changes to the user details have been successfully saved. You can now view the updated information.` : `The new user ${state.email} has been successfully created and will be notified via email with their temporary password.`}
                
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                onClick={closeModal}
                sx={{
                  width: { lg: '137px' },
                  height: '48px',
                  marginTop: '37px',
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default UserPopup;
