import axios from 'axios';
import {
  DELETE_OFFER_SEGMENT_API,
  GET_ALL_AI_CREATED_SEGMENTS_OR_OFFERS,
  GET_SEGMENT_OFFER_REVIEW,
  POST_CREATE_SEGMENT_API,
  SEGMENT_OFFER_AI_CREATE_V2,
  SEGMENT_OFFER_CONDITION_COLUMNS,
  SEGMENT_OFFER_CREATE,
  SEGMENT_OFFER_CUSTOM_ADD_CONDITION,
  SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION,
  SEGMENT_OFFER_CUSTOM_GET_INFO,
  SEGMENT_OFFER_SAVE,
  SEGMENT_OFFER_SOURCES_GET,
  SEGMENT_OFFER_SOURCE_CONFIG,
  SEGMENT_OFFER_SOURCE_TOTAL_RECORDS,

} from '../common/config';
import { getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
};

export const createSegment = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_CREATE_SEGMENT_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const editSegment = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const copySegment = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const addSegmentCondition = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_ADD_CONDITION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentData = async (baseUrl, parameter) => {
  try {
    const response = await axios({
      method: 'get',
      // url: `${GET_TOP_PERFORMING_SEGMENT_API}`,
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        parameter,
      },
    });
    return response;
  } catch (error) {
    console.log('Error:', error.message);
    return error;
  }
};

export const getSegmentConditionColumn = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_CONDITION_COLUMNS}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: 'segment',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getAICraftedListForSegment = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_ALL_AI_CREATED_SEGMENTS_OR_OFFERS}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: 'segment',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const createAICraftedForSegment = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_AI_CREATE_V2}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const deleteSegment = async (payload) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${DELETE_OFFER_SEGMENT_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentSource = async () => {
  try {
    const response = await axios({
      method: "get",
      url: `${SEGMENT_OFFER_SOURCES_GET}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: "segment",
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentConfig = async (payload) => {
  try {
    const response = await axios({
      method: "get",
      url: `${SEGMENT_OFFER_SOURCE_CONFIG}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentSourceTotalRecords = async (payload) => {
  try {
    const response = await axios({
      method: "get",
      url: `${SEGMENT_OFFER_SOURCE_TOTAL_RECORDS}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const reviewSegment = async (payload) => {
  try {
    const response = await axios({
      method: "post",
      url: `${SEGMENT_OFFER_CREATE}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const saveSegment = async (payload) => {
  try {
    const response = await axios({
      method: "post",
      url: `${SEGMENT_OFFER_SAVE}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentEditInfo = async (payload) => {
  try {
    const response = await axios({
      method: "get",
      url: `${SEGMENT_OFFER_CUSTOM_GET_INFO}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getSegmentOfferReview = async (payload) => {
  try {
    const response = await axios({
      method: "get",
      url: `${GET_SEGMENT_OFFER_REVIEW}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
}