import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import CustomerdataIntelligence from '../CustomerdataIntelligence';
import Modal from './Modal';
import ContactInfo from './ContactInfo';

const ContactConfermation = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <section
            style={{
              padding: '0px 22px',
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                padding: '20px 0px',
                color: '#414446',
              }}
            >
              We've analyzed the information you provided and it seems we need a bit more detail to tailor the perfect data solution for you. A Synergi expert will soon reach out
              for a brief consultation.
            </Typography>
          </section>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              border: '1px solid #EBE8F1',
              borderRadius: '5px',
              height: '282px',
              width: '679px',
              marginLeft: '22px',
            }}
          >
            <ContactInfo />
          </section>

          <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: { lg: '253px' }, margin: '40px 22px 29px 20px' }} onClick={() => setShowModal(true)}>
            Schedule My Consultation
          </Button>
          {showModal && <Modal closeModal={closeModal} />}
        </Grid>
        <Grid item xs={5}>
          <CustomerdataIntelligence />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactConfermation;
