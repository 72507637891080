import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getManageAllCampaign } from '../../services/AllCampaign';
import channelIconMapping from '../Utils/icons/channelIconMapping';
import { CustomTempIcon } from '../Utils/icons/icons';
import CampaignPopUp from './CampaignPopUp/CampaignPopUp';
import CustomButton from '../Utils/CustomButton/CustomButton';
import LoadingScreen from '../Campaigns/Components/LoadingScreen/LoadingScreen';
import { downloadCampaignFile } from '../../services/Exports';
import { DOWNLOAD_CAMPAIGN_DATA_DOWNLOAD } from '../../common/config';
import ErrorPage from '../../ErrorPage';
import CreateCampaigns from './Popup/CreateCampaigns';
import { Box } from '@mui/system';
import { campaignScreen } from '../../Language/language';
import { CapableCampaign } from '../../services/capableCampaign';
import { capableCampaignActions } from '../../store/slices/capableCampaign';
import { LoadingButton } from '@mui/lab';
import { aboutCampaignData as setAboutCampaignData } from '../Campaigns/GlobalStore/index';
import { resetCampaignData } from '../../common/constant';
import moment from 'moment';
import CustomPagination from '../../components/CustomPagination';

const { setCampaignCapable } = capableCampaignActions;
const AllCampaigns = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [downloading, setDownloadLoading] = useState(false);
  const [selectedDownloading, setSelectedDowloading] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [massageBox, setMassageBox] = useState(true);
  const [btnLoading, setbtnLoading] = useState(false);
  const [pageLoader, setpageLoader] = useState(false);
  console.log('🚀 ~ file: AllCampaigns.js:37 ~ AllCampaigns ~ pageLoader:', pageLoader);

  // const [capableFlage, setcapableFlage] = useState({
  //   direct_to_offer: '',
  //   direct_to_segment: '',
  //   is_capable_to_create_campaign: '',
  // });

  useEffect(() => {
    setAboutCampaignData({});
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setMassageBox(false);
  };

  const downloadMedia = (url) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };

  const downloadFile = async (name, pk) => {
    setDownloadLoading(true);
    const response = await downloadCampaignFile(DOWNLOAD_CAMPAIGN_DATA_DOWNLOAD, name, pk);
    if (response.status === 200) {
      downloadMedia(response.data.url);
      setDownloadLoading(false);
    } else {
      setDownloadLoading(false);
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
    }
  };

  const itemsPerPage = 5;
  const navigate = useNavigate();

  const ordinalSuffix = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) return num + 'st';
    if (j === 2 && k !== 12) return num + 'nd';
    if (j === 3 && k !== 13) return num + 'rd';
    return num + 'th';
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return `${months[date.getMonth()]} ${ordinalSuffix(date.getDate())}`;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Running':
        return '#158C6F';
      case 'Paused':
        return '#E08029';
      case 'Error':
        return '#8E0000';
      case 'Archived':
        return '#414446';
      default:
        return '#414446'; // default color
    }
  };

  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handlePageChange = (event) => {
    // window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    setCurrentPage(event.selected + 1);
  };

  const handleDialogOpen = (item, name, pk, sk) => {
    setSelectedCampaign({ popup: item, name: name, pk: pk, sk: sk });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setSelectedCampaign(null);
    setOpenDialog(false);
  };

  useEffect(() => {
    (async () => {
      const response = await CapableCampaign();

      if (response.status === 200) {
        setCampaignCapable(response?.data);
        // setcapableFlage((preve) => ({
        //   ...preve,
        //   direct_to_offer: response?.data?.direct_to_offer,
        //   direct_to_segment: response?.data?.direct_to_segment,
        //   is_capable_to_create_campaign: response?.data?.is_capable_to_create_campaign,
        // }));
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
      }
    })();
  }, []);

  useEffect(() => {
    resetCampaignData();
  }, []);

  useEffect(() => {
    setpageLoader(true);
    (async () => {
      const response = await getManageAllCampaign('campaign');
      if (response.status === 200) {
        setpageLoader(false);
        // setAllCampaignsData(response.data);
        setFilteredData(response.data);
      } else {
        setpageLoader(false);
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
      }
    })();
  }, []);

  const createCampaign = async () => {
    setbtnLoading(true);
    const response = await CapableCampaign();
    if (response.status === 200) {
      setCampaignCapable(response.data);
      // setcapableFlage({
      //   direct_to_offer: response.data.direct_to_offer,
      //   direct_to_segment: response.data.direct_to_segment,
      //   is_capable_to_create_campaign: response.data.is_capable_to_create_campaign,
      // });
      setbtnLoading(false);
      if (!!response?.data?.is_capable_to_create_campaign) {
        navigate('/create-campaign');
      } else {
        navigate('/DatamissingPopup');
      }
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true); 
      setbtnLoading(false);
    }
  };

  const ConnectedAnalytics = ({ analytics }) => (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid #ebe8f1',
        backgroundColor: '#f6f5f8',
        gap: '1rem 1.5rem',
        padding: '0.5rem',
        borderRadius: '5px',
        justifyContent: 'space-between',
      }}
    >
      {Object.keys(analytics).map((keys) => (
        <div
          style={{
            minWidth: '2.5rem',
          }}
        >
          <Typography
            sx={{
              color: '#5d596c',
              fontFamily: 'Roboto',
              fontSize: '0.7rem',
              textTransform: 'capitalize',
            }}
          >
            {keys}
          </Typography>
          <Typography
            sx={{
              color: '#414446',
              fontFamily: 'IBM Plex Serif',
              fontSize: '0.9',
            }}
          >
            {analytics[keys]}
          </Typography>
        </div>
      ))}
    </Box>
  );
  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        // height: '100vh',
        borderRadius: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          borderBottom: '1px solid #ebe8f1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: '#414446',
            lineHeight: '1.5',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'IBM Plex Serif',
              fontSize: '1.5rem',
            }}
          >
            {campaignScreen.pagetitle}
          </Typography>
        </Box>
        <CustomButton
          buttonValue={btnLoading ? 'Loading' : 'create new campaign'}
          style={{
            width: '237px',
            height: '44px',
            marginLeft: '0px',
            marginRight: '20px',
          }}
          onClick={createCampaign}
          isDisabled={false}
        />
      </div>
      <Box padding="1rem">
        <Table>
          <TableBody>
            {pageLoader ? (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: '32px',
                  }}
                >
                  <LoadingScreen gifType={1} />
                </div>
              </>
            ) : currentData && currentData.length > 0 ? (
              currentData.map((item, index) => (
                <>
                  <TableRow sx={{ height: '100%' }}>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        padding: '1rem 0',
                        width: '50%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontFamily: 'Roboto',
                          lineHeight: 1.5,
                          width: '98%',
                        }}
                      >
                        <CustomTempIcon />
                        <div
                          style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            marginTop: '9px',
                            backgroundColor: getStatusColor(item.status),
                            marginRight: '10px',
                            marginLeft: '10px',
                          }}
                        ></div>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h2
                              style={{
                                color: getStatusColor(item.status),
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDialogOpen(item.popup, item.name, item.pk, item.sk)}
                            >
                              {item.name}
                            </h2>
                            <h4 style={{ marginLeft: '10px' }}>({item.status})</h4>
                          </div>
                          <h3>
                            <div>{item.description}</div>
                            <div style={{ marginTop: '-2px' }}>
                              Campaign Duration:
                              {item.campaign_start_datetime
                                ? ` From ${moment(item.campaign_start_datetime).format('MMMM Do, YYYY hh:mm a')} to ${
                                    item.campaign_end_datetime ? moment(item.campaign_start_datetime).format('MMMM Do, YYYY hh:mm a') : 'infinity'
                                  } `
                                : ''}
                            </div>
                            <h3>
                              Refreshed on: {formatDate(item.created_datetime)}, {new Date(item.created_datetime).getFullYear()}
                            </h3>{' '}
                            <div style={{ width: '60%', marginTop: '10px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'space-between',
                                }}
                              >
                                {item.channel_2_offers &&
                                  Object?.keys(item.channel_2_offers).map((key, value) => {
                                    const IconComponent = channelIconMapping[key];
                                    return (
                                      <div key={key}>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            border: '1px solid #EBE8F1',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: '5px',
                                            margin: '0px 4px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              transform: 'scale(0.75)',
                                              display: 'inline-flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {IconComponent ? <IconComponent /> : key}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        >
                                          {value}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </h3>
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        verticalAlign: 'top',
                        padding: '1rem 0',
                        width: '8rem',
                      }}
                    >
                      {item.download_status === 'Ready For Download' ? (
                        <div>
                          {downloading && item.pk === selectedDownloading ? (
                            <LoadingButton
                              color="secondary"
                              loading
                              loadingPosition="end"
                              sx={{
                                width: '150px',
                                border: '2px solid #158C6F',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '151.188%',
                                textTransform: 'capitalize',
                                color: 'white !important',
                                backgroundColor: '#158C6F !important',
                              }}
                            >
                              <span style={{ marginRight: '0.5rem', color: 'white' }}>Downloading...</span>
                            </LoadingButton>
                          ) : (
                            <Button
                              onClick={() => {
                                setSelectedDowloading(item.pk);
                                downloadFile(item.name, item.pk);
                              }}
                              sx={{
                                width: '150px',
                                border: '2px solid #158C6F',
                                color: '#158C6F',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '151.188%',
                                textTransform: 'capitalize',
                              }}
                            >
                              Download Ready
                            </Button>
                          )}
                        </div>
                      ) : (
                        <div style={{ paddingRight: '10px' }}>
                          <Button
                            sx={{
                              width: '150px',
                              border: '2px solid #E08029',
                              textAlign: 'center',
                              color: '#E08029',
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '151.188%',
                              textTransform: 'capitalize',
                            }}
                            disabled={true}
                          >
                            Processing
                          </Button>
                        </div>
                      )}
                    </TableCell> */}
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        padding: '1rem 0',
                        width: '8rem',
                      }}
                    >
                      <h3>Objective</h3>
                      <h1 style={{ fontWeight: '400' }}>{item.objective}</h1>
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        padding: '1rem 0',
                        width: '16rem',
                      }}
                    >
                      <ConnectedAnalytics analytics={item?.kpi} />
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : filteredData && filteredData.length === 0 ? (
              <>
                <h1 style={{ marginTop: '20px', color: '#8E0000' }}>Your Campaign Canvas Awaits! </h1>
                <h2 style={{ marginTop: '20px', fontWeight: '400' }}>
                  It looks like you haven't created any campaigns yet. Transform your marketing landscape with just a few clicks!
                </h2>

                <h2
                  style={{
                    marginTop: '20px',
                    fontWeight: '400',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/create-campaign')} // Use navigate to change the route
                >
                  Start Crafting
                </h2>

                <Box>{massageBox && <CreateCampaigns closeModal={closeModal} />}</Box>
              </>
            ) : (
              ''
            )}
          </TableBody>
        </Table>

        <div></div>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
        <CustomPagination totalPages={totalPages} handlePageChange={handlePageChange} />
      </div>
      <CampaignPopUp open={openDialog} handleClose={handleDialogClose} data={selectedCampaign} />
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} closingFlag={1} />}
      {/* {capableFlage && <DatamissingPopup capableFlage={capableFlage} />} */}
    </Paper>
  );
};

export default AllCampaigns;
