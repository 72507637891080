import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubHeader from '../ui-components/pageHeaders/SubHeader';
import MainFooter from '../view/mainFooter/MainFooter';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from '../view/integration/Components/Loader';
import { getItem } from '../view/Utils/CommonFunction/CommonFunction';
import MobileHeader from '../view/MobileHeader/MobileHeader';

const MobileLayout = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/segments' && location.state?.scrollToChannel) {
      const channelComponent = document.getElementById('channelComponent');
      if (channelComponent) {
        channelComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const getSubheaderTitle = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/campaigns':
        return 'AllCampaigns';
      case '/create-campaign':
        return 'Campaigns';
      case '/app-integrations':
        return 'Integrations';
      case '/segments':
        return 'Segments';
      case '/Create new segment':
        return 'Segments';
      case '/offers':
        return 'Offers';
      case '/create-offer':
        return 'Offers';
      case '/onboarding':
        return 'How Synergi works';
      case '/CustmerDataintelligence':
        return 'Custmer Data Intelligence';
      // case '/onboarding':
      //   return '360 Dashboard';
      case '/channels':
        return '360 Dashboard';
      case '/campaigns-dasboard':
        return '360 Dashboard';
      case '/industry-comparison':
        return '360 Dashboard';
      // case '/setting':
      //   return 'Account Management';
      case '/sales':
        return 'Portfolio Analytics Dashboard';
      case '/portfolio':
        return 'Portfolio Analytics Dashboard';
      case '/marketing':
        return 'Marketing Analytics Dashboard';
      case '/setting/overview':
        return 'Overview';
      case '/setting/profile':
        return 'Profile';
      case '/setting/user':
        return 'User';
      case '/setting/security':
        return 'Security';
      case '/setting/companyDetail':
        return 'Company Detail';
      case '/setting/sftpUser':
        return 'SFTP User';

      case '/setting':
        return 'Account Management';
      // Add more cases as needed...
      default:
        return 'Integration'; // Default title
    }
  };

  const subheaderTitle = getSubheaderTitle();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  useEffect(() => {
    const accessToken = getItem('accessToken');
    if (accessToken) setIsAuthenticated(true);
    // else navigate('/');
  }, []);

  return (
    <Grid container direction="column" sx={{ backgroundColor: '#FFF' }}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ p: 1, backgroundColor: { lg: '#FFF', md: '#FFF', xs: '#fff' } }}>
        <Grid item xs={12}>
          <MobileHeader />
        </Grid>
      </Grid>

      <Grid item sx={{ background: '#F6F5F8' }}>
        <Grid container spacing={2}>
          {/* </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid item sx={{ margin: '20px 0px 23px 0px' }}>
              <SubHeader title={subheaderTitle} />
            </Grid>
            {isAuthenticated ? <Outlet /> : <Loader />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ paddingLeft: { lg: '35vh', md: '35vh', xs: '0vh' } }}>
        <MainFooter />
      </Grid>
    </Grid>
  );
};

export default MobileLayout;
