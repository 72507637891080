import React, { useState, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Card, CardHeader, Checkbox, FormControlLabel, FormGroup, CardContent } from '@mui/material';

import { dataSourceData as setDataSourceData } from '../../../GlobalStore';

const DataSourceManual = ({ manualInput }) => {
  const dataSourceData = useReactiveVar(setDataSourceData);
  const [selected, setSelected] = useState(dataSourceData?.manualData || []);
  const scrollContainer = useRef(null);

  useEffect(() => {
    const currentCampaignData = setDataSourceData();
    setDataSourceData({
      ...currentCampaignData,
      manualData: selected,
    });
  }, [selected]);

  const handleCheckChange = (channel, vendor) => (event) => {
    setSelected({
      ...selected,
      [channel]: { ...selected[channel], [vendor]: event.target.checked },
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={scrollContainer}
        style={{
          display: 'flex',
          width: 'auto',
          overflowX: 'hidden', // hides the scrollbar
          gap: '15px',
          whiteSpace: 'nowrap',
          scrollBehavior: 'smooth',
        }}
      >
        {manualInput.body.map((item, index) => (
          <Card
            key={index}
            style={{
              width: '280px',
              height: '237px',
              borderRadius: '5px',
              border: '1px solid #EBE8F1',
              background: '#FFF',
              boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
              padding: '5px',
            }}
          >
            <CardHeader
              title={item.channel}
              titleTypographyProps={{
                style: {
                  color: 'var(--col-8, #000)',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '151.188%',
                  textTransform: 'capitalize',
                  width: '150px',
                },
              }}
            />
            <div className="customHR"></div>
            <CardContent>
              <FormGroup>
                {item.Vendors.map((vendor) => (
                  <FormControlLabel
                    key={vendor}
                    control={
                      <Checkbox
                        checked={selected[item.channel]?.[vendor] || false}
                        onChange={handleCheckChange(item.channel, vendor)}
                        color="secondary"
                        style={{ fontSize: '14px' }}
                      />
                    }
                    label={vendor}
                    sx={{
                      color: 'var(--col-8, #000)',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '222.688%',
                    }}
                  />
                ))}
              </FormGroup>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default DataSourceManual;
