/// LoginScreen ///

export const LoginScreen = {
  welcomeBack: 'Welcome Back!',
  headlineText1: 'Enter Your Account',
  headlineText2: 'Details To Start.',
  forgetPassword: 'Forgot Password?',
  dontHaveAccountText: "Don't have an account?",
};

/// common Language ///

export const CommonLanguage = {
  footerText: '©2024 Synergi Corporation | Privacy Policy',
  register: 'Register',
  login: 'Login',
  fieldrequiredText: '* All Fields Are Required',
  joinsynergi: 'Join SYNERGI',
  welcome: 'Welcome to Synergi!',
  Loading: 'Loading...',
  howSynergiText: 'How Synergi works',
  whatnext: 'What’s Next?',
  videoguide: 'View video guide',
  contacttext: 'Contact Support',
  dontshow: "Don't show this again",
  needGuide: 'Need a quick guide?',
  canclebtn: 'cancel',
  nextbtn: 'Next',
  savebtn:'Save',
  updatebtn:' update',
  logoutbtn:'logout',
  errordatamsg:' *All error data be not be imported',
  backbtn:'back',
  Overview:'Overview',
  Profile:'Profile',
  LogOut:'Log Out'
};

///Registration Page///

export const RegistrationStep1Screen = {
  headlineText1: 'Sign Up And Democratize',
  headlineText2: 'Your Mortgage Experience',
  agreementText:
    'By Clicking Agree & Join  You Agree To Synergi Tearms & Conditions And Privacy Policy, Acknowledging That Full Platform Access Is Contingent On Your Vendor Approval Process. Until Then, You&apos;re Welcome To Explore With Our Trial Data.',
  registrationbtnText: 'AGREE AND JOIN',
};

export const RegistrationStep2Screen = {
  headlineText1: 'One Tiny Step More:',
  headlineText2: 'Spill The Beans About Your Team',
  needhelpText: 'What do you need help with?',
  yourteamText: 'How many people are in your team?',
  registrationStep2btnText: "Let's Roll",
};

export const HELPING_TEXT_LIST = [
  'Managing projects',
  'Team collaboration',
  'Reporting to executives',
  "Managing my team's tasks",
  'Sales forecasting',
  'Automate follow-ups',
  'Boosting client retention',
];

export const PEOPLE_IN_TEAM_LIST = ['Only me', '2-5', '6-10', '11-20', '20-50', '50+'];

export const RegistrationStep3Screen = {
  headlineText1: 'Your SYNERGI Account',
  headlineText2: 'Is Almost Ready.',
  otpmsgText: 'Please enter the code below to verify your identity and complete your registration.',
  accountHelping: 'Thank you for helping us keep your account secure.',
  resend: 'Resend Email',
  emailmsgText: "Didn't receive an email yet?",
  verifybtn: 'Verify And Complete Registration',
};

/// Forgot Password Page///

export const ForgotPasswordStep1 = {
  headlineText1: 'Enter your email to',
  headlineText2: 'recover your password.',
  Passwordrecovery: 'Password recovery.',
  subtitle: 'Recovery instructions will be sent to your registered email.',
  send: 'Send',
};

export const ForgotPasswordStep2 = {
  otpTitle: 'Enter your code:',
};

/// Onboarding Page ///

export const onboarding = {
  headlineText1: 'Import Your Data',
  subTitle1: (
    <span>
      Begin by uploading your portfolio and rate sheet. This powers up our <br />
      AI to work its magic.
    </span>
  ),
  headlineText2: 'Getting Started with Synergi',
  subTitle2: 'It’s simpler than you think! Watch now.',
  importbtn: 'Import Files',
  SampleDatabtn: 'View Sample Data Files',
  headlineText3: 'Stuck or unsure?',
  subTitle3: 'Our support is here to guide every step of your onboarding journey.',
};

export const onboarding2={
  headlineText1:'Data Mapping',
  subTitle1:'Choose the data fields to map for use and mark any rows not required for import.',
  headlineText2:'CSV Field',
  headlineText3:'Target Field',
}

export const onboarding3={
  headlineText1:' Finalize Data Import & Review',
  headlineText2:'Summary Of Data',
  headlineText3:'Description',
  headlineText4:'Actionable Data Matrix',
}
/// dashboard ///

export const dashboard = {
  pagetitle: 'Ready To Illuminate Your Insights?',
  headlineText1: 'Bring your campaigns to life with just a few data uploads.',
  headlineText2: 'Unsure about which data to upload?',
  subTitle1: ' Synergi is equipped to provide you powerful campaign insights. All we need is the right data from you.',
  reportingbtn: 'Import Reporting data',
  sublist1: '  View sample data files',
};

export const EmptyDashboardScreen = {
  headlineText1: 'Your Future Dashboard View',
  headlineText2: 'See What Awaits with the Right Data',
};

/// offer page ///


export const offerScreen = {
  pagetitle: 'Manage Offers',
  createofferbtn: 'create offer',
};

export const createoffer = {
  headlineText1: 'Create New Offer',
  subtitle1: 'Use Synergi AI to generate a new offer tailored to current market conditions and your data performance, or manually create a new offer to meet your unique needs.',
  headlineText2: 'Custom Offer',
  subtitle2: (
    <span>
      Craft your ideal audience. Start fresh by building a custom offer with rules that
      <br /> match your targeting criteria.
    </span>
  ),
};

export const customoffer = {
  headlineText1: 'Offer Information',
  headlineText2: 'Offer Type',
  subTitle1: 'Select The Type Of Offer You Wish To Create',
  headlineText3: 'Total Data Set',
  headlineText4: 'Offer Duration',
  targetbtn: 'Get Target Data',
  reviewbtn: 'Review',
};

/// segment //
export const Segments = {
  pagetitle: 'Manage Segments',
  createsegmentbtn: 'create Segment',
};

export const createsegment = {
  headlineText1: 'Create New Segment',
  subtitle1:
    'Use Synergi AI to generate a new segment tailored to current market conditions and your data performance, or manually create a new segment to meet your unique needs.',
  headlineText2: 'Custom Segment',
  subtitle2: (
    <span>
      Craft your ideal audience. Start fresh by building a custom offer with rules that
      <br /> match your targeting criteria.
    </span>
  ),
  createsegmentbtn: 'Create Segment',
};

export const CustomSegment = {
  CustomSegment: 'Custom Segment',
  headlineText1: 'Segment Information',
  headlineText2: 'Segment Type',
  subTitle1: 'Select The Type Of Segment You Wish To Create',
  headlineText3: 'Total Data Set',
  targetbtn: 'Get Target Data',
  reviewbtn: 'Review',
};

/// intigration //
export const reviseintigration = {
  pagetitle: 'Automation',
  subTitle1: 'Select Your Data Sources for Tailored Connectivity',
  headlineText1: 'Current Data Management Solution',
  headlineText2: 'Size of Customer Database',
  headlineText3: 'Just Confirming Your Contact Information',
  schedulebtn: 'Schedule My Consultation',
};

export const ReviewDataIntiScreen = {
  headlineText1: 'What is Automation?',
  bodytext:
    "Automation in Synergi is all about creating a seamless bridge between your chosen data sources and our platform. By opting for this integration, you empower yourcampaigns with real-time data updates, ensuring your marketing efforts are always driven by the latest insights. This streamlined process reduces user dependency,ensuring data flows uninterrupted between systems. It's a smart way to keep Synergi in sync with your evolving datasets, ensuring you're always a step ahead in your marketing strategies.",
};

export const intigrationimport = {
  pagetitle: 'Manage Imports',
  importbtn: 'Import CSV',
};

export const csvuploadScreen = {
  headlineText1: 'New Data Upload',
  subTitle1: "Can't find the exact dataset you need? No problem! Upload your own sheet to tailor your campaigns to perfection.",
  headlineText2: 'Import Name',
  headlineText3: 'Description',
  subtitle2: (
    <span>
      By proceeding, you confirm that you legally own this data and adhere to all applicable industry standards and <br /> regulations for its use.
    </span>
  ),
  updbtn: 'Agree And Upload',
};

// campaign screen //
export const campaignScreen = {
  pagetitle: 'Manage All Campaigns',
};

export const newcampaign = {
  pagetitle: 'Create a new campaign',
  headlineText1: 'About Campaign',
  subTitle1: ' Kickstart your campaign by defining its name and brief overview.',
  headlineText2: 'Objective',
  subTitle2: 'Align your campaign strategy with AI optimization by selecting an objective from the below list.',
  headlineText3: 'Channel & Vendor',
  subTitle3: 'Select your campaign channel and let us guide you to the suitable, integrated vendors.',
};

export const newcampaign2 = {
  headlineText1: 'Choose Your Campaign Data Source',
  // subTitle1: "Select 'AI-Recommended Data' for streamlined, efficient data sourcing.",
};

export const newcampaign3 = {
  headlineText1: 'Choose Your Campaign Segments & Rate Sheet',
  subTitle1: "Modify segment allocation using controllers. Click 'Manual Segments' to add segments manually.",
};

export const newcampaign4 = {
  headlineText1: 'Offers',
  subTitle1: 'Click on a channel to select and fine-tune offers. AI has auto-selected optimal offers for each channel. For details, simply tap the channel name.',
  headlineText2: 'Settings',
  subTitle2: ' For a unified campaign, set date/budget below, or customize individually by selecting each channel card.',
  subTitle3: 'Click on each channel card to configure templates and modify dates or budget for the vendors within each channel.',
};

// customer Data Intelligence //

export const DataIntelligenceScreen = {
  pagetitle: 'Activate Customer Data Intelligence',
  subTitle1: "Share your customer data details, and our Synergi team will tailor a plan just for you. Let's get started!",
  headlineText1: 'Current Data Management Solution',
  headlineText2: 'Size of Customer Database',
  DataInbtn: 'Explore Options',
};

export const DataInCard = {
  headlineText1: 'What is Customer Data Intelligence?',
  subTitle1:
    'With Data Warhorse, you gain access to a comprehensive Customer Data Platform (CDP) that collects, unifies, and organizes customer data from all digital touch points in real-time. By centralizing this data, Data Warhorse uncovers hidden patterns and trends, enabling precise predictive modeling and more effective, personalized marketing.',
};
export const DataInCard2 = {
  headlineText1: 'Just Confirming Your Contact Information',
  databtn: 'Schedule My Consultation',
};

//// Profile //

export const Profilestep1 = {
  headlineText1: 'Enterprise Plan',
  subTitle1: ' The Enterprise Plan is designed to meet the complex needs of larger organizations, offering unparalleled control, customization, and insights.',
  headlineText2: 'About Your Company',
  subTitle2: (
    <span>
      UMortgage
      <br />
      100 N 18th St STE 1400, Philadelphia, PA 19103
      <br />
      Primary Contact: Joe Mathew
    </span>
  ),
  headlineText3:'Help Us Improve Your Status',
  ListText:'Your Industry :',
  ListText2:'How Many People Are In Your Organization ?',
  ListText3:'How Old Is Your Organization ?',
};

export const Profilestep2 = {
  headlineText1:'Profile Photo',
  photomsg:'Upload your photo...',
  photosize:'Photo should be at least 300px x 300px',
  uploadPhotobtn:'Upload Photo',
  headlineText2:'Basic Information',
  inputlabel1:'Username',
  inputlabel2:'First Name',
  inputlabel3:'Last Name',
  inputlabel4:'Email Address',
  inputlabel5:'Company Name',
  inputlabel6:'Designation',
  inputlabel7:'Mobile',
  headlineText3:'Change Password',
  passlabel:'Verify Current Password',
  passlabel2:'New Password',
  passlabel3:'Confirm New Password',
}

export const Profilestep4={
  pagetitle:'Account Security ',
  headlineText1:'Set Up 2-Factor Authentication',
  subTitle1:'Enable 2-factor authentication as a second layer of security for your account. If an account owner or admin enables 2-factor authentication, all users will be reqired to set it up using their preferred method. Once enabled, it cannot be disabled.',
  expText:'Examples: Google Authenticator, IOS authenticator, etc.',
  headlineText2:'Account Verification',
  subTitle2:'We’ll Use This To Confirm Your Identity If We Spot Any Unusal Activity On Your Account.',
  subTitle3:(<span> You’re verified with phone number
  <span style={{ color: "#000000", fontWeight: "700" }}>
    917-805-6232.
  </span></span>)
}

export const Profilestep5={
  headlineText1:'Primary Account Contact',
  subTitle1:'Information about this account, such as campaign send notifications, will be sent to the email address listed here.',
  headlineText2:'Billing Info',
  subTitle2:'This is the information we have associated with your payment method.',
  Contactname:'Contact name',
  CompanyOrganization:'Company / Organization',
  Address:'Address',
  Phone:'Phone',
  Email:'Email',
  TaxId:'Tax Id',
  ContactInfobtn:'Edit Contact Information',
  BillingInfobtn:'Edit Billing Information',
}

export const logout={
  headlineText1:'Confirm Logout',
  subTitle1:'Are you sure you want to log out?',

}