import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  dataSourceData as setDataSourceData,
  aboutCampaignData as setAboutCampaignData,
} from '../GlobalStore';
import { Grid } from '@mui/material';
import DataSourceChannel from './DataSourceChannel/DataSourceChannel';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import { newcampaign2 } from '../../../Language/language';

// import InputDragNDrop from "../../Utils/InputDragNDrop/InputDragNDrop";
import { getDataSourceAI } from '../../../services/CampaignService';
import ErrorPage from '../../../ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import CustomStepper from '../Components/Header/CustomStepper/CustomStepper';
import { getCampaignDataSourceDetails } from '../../../services/OffersService';

const DataSource = () => {
  const navigate = useNavigate();
  const { sk, pk } = useParams();
  const dataSourceData = useReactiveVar(setDataSourceData);
  const campaignData = useReactiveVar(setAboutCampaignData);
  const [isLoading, setLoading] = useState(true);
  // const [getCampaignSummaryData, setCampaignSummary] = useState(false);
  const [DataSourceAiAPI, setDataSourceAiAPI] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (Object.keys(campaignData).length === 0) {
      (async () => {
        setLoading(true);
        const _sk = `campaign#${sk}`;
        const _pk = `campaign#${pk}`;
        const response = await getCampaignDataSourceDetails(_sk, _pk);
        if (response?.status === 200) {
          setLoading(false);
          const sourceData = Object.keys(response?.data?.response?.data_sources).map((e) => {
            return {
              name: e,
              document_pk: response?.data?.response?.data_sources[e]?.pk,
              document_sk: response?.data?.response?.data_sources[e]?.sk,
            };
          });
          const dataSourceData = setDataSourceData();
          setDataSourceData({
            ...dataSourceData,
            AIData: [...sourceData],
          });
          // setCampaignSummary(response?.data?.response?.summary);
        } else {
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
          setTimeout(() => {
            navigate('/create-campaign');
          }, 5000);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  const handleNextButtonClick = () => {
    navigate(`/create-campaign/segment/${sk}/${pk}`);
  };
  const handlePrevButtonClick = () => {
    navigate('/create-campaign');
  };


  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getDataSourceAI('document');
      if (response?.status === 200) {
        setLoading(false);
        setDataSourceAiAPI(response.data);
        setTimeout(() => {}, 2000);
      } else {
        setLoading(false);
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
      }
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            padding: '70px 0px',
          }}
        >
          <LoadingScreen gifType={1} />
        </div>
      ) : (
        <React.Fragment>
          <CustomStepper type={1} />
          <div className="customHR"></div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="campaign-container">
              {/* <div className="customHR"></div> */}
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  {DataSourceAiAPI && (
                    <div style={{ display: 'grid', gap: '20px' }}>
                      <div className="headerText"> {newcampaign2.headlineText1}</div>
                      <div className="normalText">{newcampaign2.subTitle1}</div>

                      <div
                        style={{
                          borderRadius: '5px',
                          border: '1px solid #EBE8F1',
                          width: { lg: '693px', md: '500px' },
                        }}
                      >
                        {DataSourceAiAPI && <DataSourceChannel DataSourceAiAPI={DataSourceAiAPI} />}
                      </div>

                      <div className="customHR"></div>

                      <div style={{ marginTop: '20px' }}>
                        <CustomButton
                          isDisabled={false}
                          onClick={handlePrevButtonClick}
                          buttonValue="Prev step"
                          style={{
                            color: '#852598',
                            backgroundColor: '#ffffff',
                            border: '1px solid #852598',
                          }}
                        />

                        <CustomButton isDisabled={dataSourceData?.AIData?.length === 0} onClick={handleNextButtonClick} buttonValue="Next" style={{}} />
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>

            {/* <div>
              <Box>{getCampaignSummaryData && <SummaryBox campaignSummaryData={getCampaignSummaryData} />}</Box>
            </div> */}
          </div>
        </React.Fragment>
      )}
      {showModal && <ErrorPage closingFlag={1} closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

export default DataSource;
