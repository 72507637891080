import { useRef, useState, useEffect } from "react";
import { Slider, Grid, Button } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { PieChart, Pie, Cell } from "recharts";
import { Label } from "recharts";
import { aboutCampaignData as setAboutCampaignData } from "../../../GlobalStore";
import {
  CustomResetIcon,
  CustomRegenerateIcon,
  CustomAlertIcon,
} from "../../../../Utils/icons/icons";

const getColor = (index, isInvalid) => {
  const colors = [
    "#852598",
    "#E08029",
    "#158C6F",
    "#00B5C7",
    "#5C427E",
    "#D89B00",
    "#13717D",
    "#d0ed57",
    // add more colors if needed
  ];
  return colors[index % colors.length];
};

const AboutAI = ({ AIInput, setCorrect }) => {
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);

  const [sliderData, setSliderData] = useState(aboutCampaignData?.AIdata);

  const totalValue = sliderData?.reduce((acc, item) => acc + item.value, 0);
  const isInvalid = totalValue !== 100;
  const sliderDataRef = useRef(sliderData);

  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox;
    return (
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="32px"
        fontFamily="IBM Plex Serif"
        fill="#8E0000"
      >
        <tspan x={cx} dy="-0.5em">{`${totalValue}%`}</tspan>
        {isInvalid && (
          <tspan
            x={cx}
            dy="2em"
            style={{
              color: "red",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            <CustomAlertIcon />! Allocation Total Must Be 100%
          </tspan>
        )}
      </text>
    );
  };

  const [selectedObjective, setSelectedObjective] = useState();
  const prevObjectiveRef = useRef();

  useEffect(() => {
    const currentObjective = aboutCampaignData?.objective;

    if (
      prevObjectiveRef.current &&
      prevObjectiveRef.current !== currentObjective
    ) {
      const initialData = Object.entries(AIInput?.[currentObjective] || {}).map(
        ([key, value]) => ({
          channel: key,
          value: parseInt(value, 10),
        })
      );
      setSliderData(initialData);
    } else {
      if (sliderDataRef.current) {
        setSliderData(sliderDataRef.current);
      } else {
        const currentData = AIInput?.[currentObjective] || {};
        const initialData = Object.entries(currentData).map(([key, value]) => ({
          channel: key,
          value: parseInt(value, 10),
        }));
        setSliderData(initialData);
      }
    }

    prevObjectiveRef.current = currentObjective;
    setSelectedObjective(aboutCampaignData?.objective);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutCampaignData?.objective]);

  useEffect(() => {
    setCorrect(!isInvalid);
    const currentCampaignData = setAboutCampaignData();

    setAboutCampaignData({
      ...currentCampaignData,
      AIdata: sliderData,
    });
    sliderDataRef.current = sliderData;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderData]);

  const resetValues = () => {
    const initialData = Object.entries(AIInput?.[selectedObjective]).map(
      ([key, value]) => ({
        channel: key,
        value: parseInt(value, 10),
      })
    );
    setSliderData(initialData);
  };

  const handleSliderChange = (index) => (event, newValue) => {
    setSliderData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, value: newValue } : item
      )
    );
  };

  // const incrementSlider = (index) => {
  //   setSliderData((prevData) =>
  //     prevData.map((item, i) =>
  //       i === index ? { ...item, value: Math.min(100, item.value + 1) } : item
  //     )
  //   );
  // };

  // const decrementSlider = (index) => {
  //   setSliderData((prevData) =>
  //     prevData.map((item, i) =>
  //       i === index ? { ...item, value: Math.max(0, item.value - 1) } : item
  //     )
  //   );
  // };

  return (
    <>
      <div style={{ display: "grid", gap: "20px" }}>
        <div className="NormalText">
          Select AI-Predictive Allocation for a hassle-free, optimized
          distribution of your campaign across multiple channels. 
        </div>

        <div style={{ display: "flex" }}>
          <Button
            onClick={resetValues}
            style={{
              borderRadius: "5px",
              border: "1px solid #A35BB1",
              color: "#A35BB1",
              fontFamily: "Roboto",
              fontStyle: "normal",
              width: "140px",
              marginRight: "20px",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            <div style={{ marginRight: "10px", display: "flex" }}>
              <CustomResetIcon />
            </div>
            Reset All
          </Button>

          <Button
            onClick={resetValues}
            style={{
              borderRadius: "5px",
              border: "1px solid #A35BB1",
              background: "#A35BB1",
              color: "#FFF",
              fontFamily: "Roboto",
              fontStyle: "normal",
              width: "140px",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            <div style={{ marginRight: "10px", display: "flex" }}>
              <CustomRegenerateIcon />
            </div>
            Regenerate
          </Button>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {sliderData?.map((item, index) => (
              <div key={index} style={{ height: "60px" }}>
                <div
                  className="headerText"
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  <div>{item.channel}:</div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <div onClick={() => decrementSlider(index)}>
                    <RemoveIcon />
                  </div> */}

                  <Slider
                    value={item.value}
                    onChange={handleSliderChange(index)}
                    min={0}
                    max={100}
                    style={{
                      color: getColor(index, isInvalid),
                      width: "60%",
                      display: "inline-block",
                    }}
                  />
                  <div style={{ marginLeft: "20px" }}>{item.value}%</div>
                  {/* <div onClick={() => incrementSlider(index)}>
                    <AddIcon />
                  </div> */}
                </div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChart width={400} height={400}>
              <Pie
                data={sliderData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={170}
                innerRadius={150}
                fill="#8884d8"
              >
                {sliderData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColor(index, isInvalid)}
                  />
                ))}
                <Label content={<CustomLabel />} />
              </Pie>
            </PieChart>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AboutAI;
