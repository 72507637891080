// base Url
export const API_URL = process.env.REACT_APP_API_URL;

export const POST_CREATE_SEGMENT_API = API_URL + 'create';

export const SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION = API_URL + 'segment_offer_custom_edit_name_and_description';

export const SEGMENT_OFFER_CUSTOM_ADD_CONDITION = API_URL + 'segment_offer_custom_add_condition';

export const GET_HISTORICAL_EVENT_DATA = API_URL + 'get_historical_event_data';

export const GET_HISTORICAL_EVENT_DATA_CAMPAIGN = API_URL + 'campaign_related_sources';

export const SEGMENT_OFFER_CONDITION_COLUMNS = API_URL + 'segment_offer_condition_columns';

export const GET_ALL_AI_CREATED_SEGMENTS_OR_OFFERS = API_URL + 'get_all_ai_created_segments_or_offers';

export const GET_DATA_SOURCE_CAPAIGN_DATA = API_URL + 'get_campaign_information';

export const SEGMENT_OFFER_AI_CREATE_V2 = API_URL + 'segment_offer_ai_create_v2';

export const POST_CREATE_OFFER_API = API_URL + 'create';

export const DELETE_OFFER_SEGMENT_API = API_URL + 'delete_item';

export const POST_OFFER_CUSTOM_UPDATE_ADD_START_END_TIME_API = API_URL + 'offer_custom_update_add_start_end_time';

export const GET_TYPES_OF_UPLOAD = API_URL + 'mapping_tables';

export const GET_TOP_PERFORMING_KPI = API_URL + 'get_top_performing_KPI';

export const POST_FILE_TO_S3 = API_URL + 'document_create';

export const POST_MAPPED_DOCUMENT = API_URL + 'document_mapping_and_restore';

export const POST_DOCUMENT_UPDATE_WITH_SLECTED_CHANNELS = API_URL + 'document_update_with_selected_channels';

export const POST_CREATE_CAMPAIGN_ID_API = API_URL + 'create_campaign_id';

export const GET_SEGMENT_AI_API = API_URL + 'campaign_ai_segments';

export const GET_SEGMENT_OFFER_API = API_URL + 'segment_offers';

export const GET_SEGMENT_OFFER_MULTIPLE_API = API_URL + 'segment_multiple_offers';

export const GET_SUMMARY_SEGMENT_OFFER_API = API_URL + 'add_data_source_or_segment_2_campaign';

export const GET_ABOUT_CAMPAIGN_API = API_URL + 'get_campaign_objective_channel_vendors';

export const GET_FINALIZE_AND_CONFIRM_API = API_URL + 'campaign_add_segment_offers';

export const POST_SAVE_CAMPAIGN = API_URL + 'campaign_confirm';

export const POST_MANAGE_ALL_CAMPAIGN_API = API_URL + 'update_campaign_channel_offer_segment';

export const GET_ALL_EXPORTS = API_URL + 'export_get_all';

export const DOWNLOD_S3_FILE = API_URL + 'export_file_download';

export const CUSTOM_INTIGRATION_SETUP = API_URL + 'custom_integration_setup';

export const CUSTOM_INTIGRATION_CONTACT_URL = API_URL + 'custom_integration_to_post_contact_information';

export const DOWNLOAD_CAMPAIGN_DATA_DOWNLOAD = API_URL + 'campaign_data_download';

export const IS_USER_HAVE_DASHBOARD_DATA = API_URL + 'is_user_have_dashboard_data';

export const IS_USER_UPLOADED_FILES = API_URL + 'is_user_uploaded_files';

export const GET_CUSTOMER_INFORMATION = API_URL + 'get_customer_information';

export const DOWNLOAD_SAMPLE_FILE = API_URL + 'download_sample_data';

export const SEGMENT_OFFER_SOURCES_GET = API_URL + 'segment_offer_sources_get';

export const SEGMENT_OFFER_SOURCE_CONFIG = API_URL + 'segment_offer_source_config';

export const SEGMENT_OFFER_SOURCE_TOTAL_RECORDS = API_URL + 'segment_offer_source_total_records';

export const SEGMENT_OFFER_CREATE = API_URL + 'segment_offer_create';

export const SEGMENT_OFFER_SAVE = API_URL + 'segment_offer_save';

export const SEGMENT_OFFER_CUSTOM_GET_INFO = API_URL + 'segment_offer_custom_get_info';

export const CUSTOMER_DATA_INTELLIGENCE_POST = API_URL + 'customer_data_intelligence_post';

export const ALL_PAGE_API = API_URL + 'all_page_kpi';

export const GET_DOCUMENT_FILE_UPLOAD_S3 = API_URL + 'get_document_file_upload_s3_presigned_url';

export const SETTING_GET_COMPANY_DETAILS = API_URL + 'settings_get_company_details';

export const SETTINGS_GET_PROFILE_PICTURE = API_URL + 'settings_get_profile_picture';

export const SETTINGS_POST_PRIMARY_ACCOUNT_DETAILS = API_URL + 'settings_post_primary_account_details';

export const SETTINGS_POST_BILLING_INFORMATION = API_URL + 'settings_post_billing_information';

export const SETTINGS_OVERVIEW = API_URL + 'settings_overview';

export const SETTINGS_PROFILE_UPLOAD_PICTURE = API_URL + 'settings_profile_upload_picture';

export const SETTING_PROFILE_POST_SETTING_INFORMATION = API_URL + 'setting_profile_post_setting_information';

export const SETTINGS_BASIC_PROFILE = API_URL + 'settings_basic_profile';

export const DASHBOARD_EMBED_URL_V2 = API_URL + 'dashboard_embed_url_v2';

export const REACT_APP_IS_MFA_ENABLE = API_URL + 'is_mfa_configured';

export const LOGIN_AFTER_PAGES = API_URL + 'is_mfa_configure_user_uploaded_dashboard_data';

export const REGISTRATION_URL = API_URL + 'registration';

export const CAPABLE_CAMPAIGN_URL = API_URL + 'is_user_capable_to_create_campaign';

export const USERS_SETTING_URL = API_URL + 'company_add_user';

export const USERS_CONFIRM_URL = API_URL + 'company_confirm_user';

export const CMP_LIST_USER = API_URL + 'company_list_user';

export const CMP_EDIT_USER = API_URL + 'company_edit_user';

export const CMP_DELETE_USER = API_URL + 'company_delete_user';

export const GET_SFTP_USER = API_URL + 'get_sftp_details';

export const LOAN_BY_INTEREST_RATE_URL = API_URL + 'loan_by_interest_rate';

export const GET_SCORE_URL = API_URL + 'get_score';

export const GET_SEGMENT_URL = API_URL + 'get_segment';

export const LOAN_BY_SEGMENT = API_URL + 'loan_by_segment';

export const GET_LENDING_OFFER_ANALYSIS_URL = API_URL + 'get_lending_offer_analysis';

export const GET_CHANNELS_ANALYSIS_URL = API_URL + 'get_channels_data';

export const GET_FUNNEL_METRICS_ANALYSIS_URL = API_URL + 'get_funnel_metrics';

export const GET_CAMPAIGNS_ANALYSIS_URL = API_URL + 'get_campaigns_data';

export const SEGMENT_OFFER_ONE_CLICK_ALL_GET = API_URL + 'segment_offer_one_click_all_get';

export const SEGMENT_OFFER_ONE_CLICK_CREATE_POST = API_URL + 'segment_offer_one_click_create_post';

export const ONE_CLICK_ALL_CAMPAIGN = API_URL + 'one_click_all_campaign';

export const GET_ONE_CLICK_CAMPAIGN = API_URL + 'get_one_click_campaign';

export const GET_SEGMENT_OFFER_REVIEW = API_URL + 'segment_offer_review';

export const IS_USER_REGISTERED = API_URL + 'is_user_registered';
