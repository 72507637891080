import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { segmentsActions } from '../../../../store/slices/segments';
import { GET_HISTORICAL_EVENT_DATA, GET_TOP_PERFORMING_KPI } from '../../../../common/config';
import IntegratedApp from './IntegratedApp';
import { getSegmentData } from '../../../../services/SegmentService';
import { useDispatch } from 'react-redux';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import ErrorPage from '../../../../ErrorPage';
import { useNavigate } from 'react-router-dom';
import { Segments } from '../../../../Language/language';
import FilterAndSearch from '../../UI-Components/FilterAndSearch';
import CreatePopup from '../../UI-Components/CreatePopup';
import { createSegmentScreen } from '../../../../Language/DialogBoxLanguage';
import CustomPagination from '../../../../components/CustomPagination';

const { setSegmentList, resetSegment, setSegmentAction } = segmentsActions;


const SegmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allSegmentData, setAllSegmentData] = useState();
  const [selectedFilter, setSelectedFilter] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [displatDataList, setDisplayDataList] = useState([]);
  const { segmentList } = useSelector((state) => state.segments);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [massageBox, setMassageBox] = useState(true);
  const itemsPerPage = 5;
  const closeModal = () => {
    setShowModal(false);
    setMassageBox(false);
  };
  useEffect(() => {
    if (segmentList.length !== 0) {
      setDisplayDataList(segmentList);
    }
  }, [segmentList]);

  useEffect(() => {
    segmentData(GET_HISTORICAL_EVENT_DATA, 'segment');
    dispatch(resetSegment());
    dispatch(setSegmentAction('Add'));

    document.title = 'Segments';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const segmentData = async (baseUrl, parameter) => {
    setIsLoading(true);
    const response = await getSegmentData(baseUrl, parameter);
    if (response.status === 200) {
      setIsLoading(false);
      dispatch(setSegmentList({ segmentList: response.data }));
      setAllSegmentData(response.data);
      // setFilteredData(response.data);
      setDisplayDataList(response.data);
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
      setIsLoading(false);
    }
  };

  const handleFilter = (filter, value) => {
    setSelectedFilter(filter);
    setFilterValue(value);
    if (filter === 'topPerforming') {
      segmentData(GET_TOP_PERFORMING_KPI, 'CAC_segment');
    } else if (filter === 'historicalData') {
      segmentData(GET_HISTORICAL_EVENT_DATA, 'segment');
    }
  };
  const handlePageChange = (event) => {
    // window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    setCurrentPage(event.selected + 1);
  };
  // const handleViewMore = () => {
  //   setPage(page + 1);
  //   const data = allData.slice((page + 1) * TOTAL_DISPLAY_ROW, (page + 1) * TOTAL_DISPLAY_ROW + TOTAL_DISPLAY_ROW);
  //   setDisplayDataList([...displatDataList, ...data]);
  // };

  const handleCreateSegment = () => {
    navigate('/create-segment');
    // setIsPageType(2);
  };
  const totalPages = Math.ceil(displatDataList?.length / itemsPerPage);
  const currentData = displatDataList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        height: 'auto',
        borderRadius: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          borderBottom: '1px solid #ebe8f1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: '#414446',
            lineHeight: '1.5',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'IBM Plex Serif',
              fontSize: '1.5rem',
            }}
          >
            {Segments.pagetitle}
          </Typography>
        </Box>

        {/* TODO: To add Auto Scroll - onClick - connect apps */}
        <Button
          type="submit"
          variant="contained"
          sx={{
            color: '#ffffff',
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 'Bold',
            textAlign: 'center',
            textTransform: 'capitalize',
            width: '17rem',
            height: '48px',
            borderRadius: '6px',
            backgroundColor: '#852598',
            ':hover': {
              backgroundColor: '#852598',
            },
          }}
          onClick={handleCreateSegment}
        >
          {Segments.createsegmentbtn}
        </Button>
      </div>
      <section>
        <Box padding="1rem">
          <FilterAndSearch
            data={allSegmentData}
            selectedFilter={selectedFilter}
            filterValue={filterValue}
            handleFilter={handleFilter}
            onSearchResult={(results) => {
              setDisplayDataList(results);
              // setCurrentPage(1);
            }}
          />
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: '32px',
              }}
            >
              <LoadingScreen gifType={5} />
            </div>
          ) : !!currentData?.length ? (
            <IntegratedApp segmentDataList={currentData} setIsLoading={setIsLoading} />
          ) : (
            massageBox && <CreatePopup closeModal={closeModal} navigateType="/create-segment" popupConfig={createSegmentScreen} />
          )}
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
        <CustomPagination totalPages={totalPages} handlePageChange={handlePageChange} />
        </div>
      </section>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </Paper>
  );
};

export default SegmentList;
