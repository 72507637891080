import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../view/Utils/CommonFunction/CommonFunction';

const PublicRoute = () => {
  const token = getCookie('accessToken');
  return token ? <Outlet /> : <Navigate to={`/login`} />;
};

export default PublicRoute;
