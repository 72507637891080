import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import '../../IntegrateApp.css';
import ViewIcon from '../../../../assets/View.svg';
import CopyIcon from '../../../../assets/Copy.svg';
import DeleteIcon from '../../../../assets/Delete.svg';
import EditIcon from '../../../../assets/Edit.svg';
import moment from 'moment';
import Data from '../../Data';
import { deleteSegment } from '../../../../services/SegmentService';
import { segmentsActions } from '../../../../store/slices/segments';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ErrorPage from '../../../../ErrorPage';
import { useNavigate } from 'react-router-dom';
import ViewDialog from '../../UI-Components/ViewDialog';

const { setSegmentList, setAICraftedClickedItems } = segmentsActions;

const AppNameCategoryConnection = ({ created, segmentData, popoverRef, popover, handlePopoverOpen, handlePopoverClose, setIsLoading, setIsPageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { segmentList } = useSelector((state) => state.segments);
  const [dialogOpen, setdialogOpen] = useState(false);
  const closeDialog = () => setdialogOpen(false);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  // eslint-disable-next-line no-unused-vars
  const [errormsg, seterrormsg] = useState();

  const handleDeleteSegment = async () => {
    setIsLoadingDelete(true);
    const payload = { event_sk: segmentData.sk, event_pk: segmentData.pk };

    const responseData = await deleteSegment(payload);
    if (responseData.status === 200) {
      const newSegmentList = segmentList.length > 0 && segmentList.filter((segment) => segment.sk !== segmentData.sk);
      dispatch(setSegmentList({ segmentList: newSegmentList }));
      dispatch(setAICraftedClickedItems({ [segmentData.sk]: false }));
      setIsLoadingDelete(false);
      closeDialog();
    } else {
      setIsLoadingDelete(false);
      window.alert('error while delete the offer');
    }
    setIsLoadingDelete(false);
  };

  const handleCopySegment = async (event) => {
    navigate(`/copy-segment/${segmentData.sk.split('#')[1]}/${segmentData.pk.split('#')[1]}`);
  };

  const handleEditSegment = async (event) => {
    navigate(`/edit-segment/${segmentData.sk.split('#')[1]}/${segmentData.pk.split('#')[1]}`);
  };
  const toTitleCase = (str) => {
    return str
      ?.split('_') // Split the string at each underscore
      ?.map((word) => word.toUpperCase()) // Capitalize the first letter of each word
      ?.join(' '); // Join the words back together with spaces
  };

  const sourceResult = toTitleCase(segmentData?.source);
  return (
    <>
      <ViewDialog popoverRef={popoverRef} popover={popover} handlePopoverClose={handlePopoverClose} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Roboto',
          lineHeight: 1.5,
          width: '98%',
        }}
      >
        <span
          style={{
            color: '#852598',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            cursor: 'pointer',
          }}
          onClick={(event) => handlePopoverOpen(event, segmentData)}
        >
          {segmentData?.name}
        </span>
        <span
          style={{
            color: 'var(--col-8, #112333)',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '149.453%',
            marginTop: '10px',
          }}
        >
          {segmentData?.description}
        </span>
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {segmentData?.created_by_full_name
            ? `Created by ${segmentData?.created_by_full_name} ${segmentData?.created_datetime ? moment(segmentData?.created_datetime).format('MMMM D, YYYY') : ''}`
            : ''}
        </span>
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {/* {segmentData?.created_datetime ? `Created: ${moment(segmentData?.created_datetime).format("MMMM D, YYYY")}` : ''} */}
          {segmentData?.modified_datetime ? `Modified At : ${moment(segmentData?.modified_datetime).format('MMMM D, YYYY')}` : ''}
        </span>
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {segmentData?.source ? `Source Data: ${sourceResult}` : ''}
        </span>
      </div>
      {loading ? (
        <>
          <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Roboto',
            lineHeight: 1.5,
            width: '100%',
          }}
        >
          <img src={ViewIcon} alt="add" title="View Segment" onClick={(event) => handlePopoverOpen(event, segmentData)} className={'iconMEnu'} />

          <img src={CopyIcon} alt="add" title="Copy Segment" onClick={handleCopySegment} className={'iconMEnu'} />

          <>
            <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 0.5, width: '10px' }} />

            <img src={DeleteIcon} alt="add" title="Delete Segment" onClick={() => setdialogOpen(true)} className={'iconMEnu'} />

            <img src={EditIcon} alt="add" title="Edit Segment" onClick={handleEditSegment} className={'iconMEnu'} />
          </>
        </div>
      )}
      <Dialog
        open={dialogOpen}
        // onClose={closeDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSegment} color="secondary" disabled={isLoadingDelete}>
            {isLoadingDelete ? (
              <>
                <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} title="Delete Segment" className={'iconMEnu'} />
              </>
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

const UsesCampaign = ({ totalCompaigns }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex',
      fontFamily: 'Roboto',
      width: '100%',
    }}
  >
    <span style={{ color: '#5D596C' }}>Uses</span>
    <div
      style={{
        display: 'flex',
        color: '#A35BB1',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <span style={{ fontFamily: 'IBM Plex Serif', fontSize: '24px' }}>{totalCompaigns}</span>
      <span style={{ fontSize: '12px', fontWeight: 'medium' }}>Campaigns</span>
    </div>
  </div>
);

const ConnectedAnalytics = ({ analytics }) => (
  <Box
    sx={{
      display: 'flex',
      border: '1px solid #ebe8f1',
      backgroundColor: '#f6f5f8',
      gap: '1rem 1.5rem',
      padding: '0.5rem',
      borderRadius: '5px',
      justifyContent: 'space-between',
    }}
  >
    {Object.keys(analytics).map((keys) => (
      <div
        style={{
          minWidth: '2.5rem',
        }}
      >
        <Typography
          sx={{
            color: '#5d596c',
            fontFamily: 'Roboto',
            fontSize: '0.7rem',
            textTransform: 'capitalize',
          }}
        >
          {keys}
        </Typography>
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'IBM Plex Serif',
            fontSize: '0.9',
          }}
        >
          {analytics[keys]}
        </Typography>
      </div>
    ))}
  </Box>
);

const IntegratedApp = ({ segmentDataList, setIsLoading, setIsPageType }) => {
  const [popover, setPopover] = useState({
    open: false,
    anchorEl: null,
    content: '',
  });

  const popoverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopover({ open: false, anchorEl: null, content: '' });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popover]);

  const handlePopoverOpen = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    setPopover({ open: true, anchorEl: event.currentTarget, content });
  };

  const handlePopoverClose = () => {
    setPopover({ open: false, anchorEl: null, content: '' });
  };

  const getCreatedBy = (segmentData) => {
    const parsedDate = moment(segmentData?.created_datetime, 'YYYY-MM-DD HH:mm:ss');

    const formattedDate = parsedDate.format('MM/DD/YY hh:mm a');
    const created = `Created by Synergi At ${formattedDate}`;
    return created;
  };

  return (
    <Table>
      <TableBody>
        {Array.isArray(segmentDataList) &&
          segmentDataList.length > 0 &&
          segmentDataList.map((segmentData) => (
            <TableRow sx={{ height: '100%' }}>
              <TableCell
                sx={{
                  verticalAlign: 'top',
                  padding: '1rem 0',
                  width: '70%',
                }}
              >
                <AppNameCategoryConnection
                  created={() => getCreatedBy(segmentData)}
                  segmentData={segmentData}
                  popoverRef={popoverRef}
                  popover={popover}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}
                  setIsLoading={setIsLoading}
                  setIsPageType={setIsPageType}
                />
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: 'top',
                  padding: '1rem 0',
                  width: '8rem',
                }}
              >
                <UsesCampaign totalCompaigns={segmentData?.total_run_campaigns} />
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: 'top',
                  padding: '1rem 0',
                  width: '8rem',
                  pl: 2,
                }}
              >
                <Data value={segmentData?.total_record} />
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: 'top',
                  padding: '1rem',
                  width: '16rem',
                }}
              >
                <ConnectedAnalytics analytics={segmentData?.kpi} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default IntegratedApp;
