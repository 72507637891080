import { IS_USER_HAVE_DASHBOARD_ERROR, IS_USER_HAVE_DASHBOARD_LOADING, IS_USER_HAVE_DASHBOARD_SUCCESS, RESET_IS_USER_HAVE_DASHBOARD } from "../Constant";

let initialState = {
    userdashboard: {
        loading: false,
        data: null,
        error: false,
        errors: null,
        success: false,
        pagination: {},
    },
};

export default function UserdashboardReducer(state = initialState, action) {
    switch (action.type) {
        case IS_USER_HAVE_DASHBOARD_LOADING:
            return {
                ...state,
                userdashboard: {
                    ...state.userdashboard,
                    loading: true,
                    error: false,
                    errors: [],
                    success: false,
                },
            };

        case IS_USER_HAVE_DASHBOARD_SUCCESS:
            return {
                ...state,
                userdashboard: {
                    ...state.userdashboard,
                    loading: false,
                    error: false,
                    errors: [],
                    success: true,
                    data: action.response.data,
                },
            };

        case IS_USER_HAVE_DASHBOARD_ERROR:
            return {
                ...state,
                userdashboard: {
                    ...state.userdashboard,
                    loading: false,
                    error: true,
                    errors: action.errors,
                    success: false,
                    data: null,
                },
            };

        case RESET_IS_USER_HAVE_DASHBOARD:
            return {
                ...state,
                userdashboard: {
                    ...state.userdashboard,
                    loading: false,
                    error: false,
                    errors: null,
                    success: false,
                },
            };

        default:
            return state;
    }
}