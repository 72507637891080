import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout/Layout';
import './App.css';
import './Master.css';

import AllCampaigns from './view/AllCampaigns/AllCampaigns';
import Synergi from './view/Synergi';
import Exports from './view/integration/Exports';
import Imports from './view/integration/Imports';
import PasswordRecoverySteps from './view/login/PasswordRecoverySteps';
import Panel from './view/Panel/Panel';
import PrivateRoute from './Routes/PrivateRoute';
import PublicRoute from './Routes/PublicRoute';
import MFAScreenRegister from './view/register/MFAScreenRegister';
import LoginMFA from './view/login/LoginMFA';
import RepostingImportData from './view/NewOnboarding/Component/RepostingImportData';
import Reposting from './view/NewOnboarding/Reposting';
import ReviseInti from './view/ReviseIntigrations/ReviseInti';
import Revisecontactinfo from './view/ReviseIntigrations/Revisecontactinfo';
import ContactInfo from './view/DataIntelligence/CustomerData/ContactConfermation/ContactInfo';
import ErrorPage from './ErrorPage';
import DataSampleFileModal from './view/NewOnboarding/DataSampleFileModal';
import UrlErrorPage from './UrlErrorPage';
import DatamissingPopup from './view/Campaigns/Popup/DatamissingPopup';
import Channels from './view/DashBoard/Components/Channels/Channels';
import CampaignsDashboard from './view/DashBoard/Components/Campaigns Dashboard/CampaignsDashboard';
import IndustryComparison from './view/DashBoard/Components/Industry comparison/IndustryComparison';
import VerifyUser from './view/Settings/Component/User/VerifyUser';
import SegmentSourceError from './view/offers-and-segments/segments/SegmentSourceError';
import OverView from './view/Settings/Component/OverView/OverView';
import Profile from './view/Settings/Component/Profile/Profile';
import User from './view/Settings/Component/User/User';
import Security from './view/Settings/Component/Security/Security';
import CompanyDetail from './view/Settings/Component/CompanyDetail/CompanyDetail';
import SftpUser from './view/Settings/Component/SFTP User/SftpUser';
import NewDashBoard from './view/Panel/NewDashBoard';
import Search from './view/Search/Search';
import MainSearch from './view/Search/MainSearch';
import AllPersona from './view/Search/All Persona/AllPersona';
import NewPersona from './view/Search/NewPersona/NewPersona';
import CrossSell from './view/Panel/Component/CrossSell';

import CustomizeDataSolution from './view/DataIntelligence/CustomerData/ContactConfermation/CustomizeDataSolution';
import MarketingAutomation from './view/Onboarding/MarketingAutomation/MarketingAutomation';
import DataIntelligence from './view/DataIntelligence/DataIntelligence';
import Funnel from './view/DashBoard/Components/funnel/Funnel';
import SegmentList from './view/offers-and-segments/segments/Components/SegmentList';
import AICraftedSegment from './view/offers-and-segments/segments/Components/AICraftedSegment';
import CustomCreateSegment from './view/offers-and-segments/segments/Components/CustomCreateSegment';
import ReviewSegment from './view/offers-and-segments/segments/Components/ReviewSegment';
import OfferList from './view/offers-and-segments/offers/Components/OfferList';
import AICraftedOffer from './view/offers-and-segments/offers/Components/AICraftedOffer';
import CustomeCreateOffer from './view/offers-and-segments/offers/Components/CustomeCreateOffer';
import ReviewOffer from './view/offers-and-segments/offers/Components/ReviewOffer';
import AboutCampaign from './view/Campaigns/AboutCampaign/AboutCampaign';
import DataSource from './view/Campaigns/DataSource/DataSource';
import Segments from './view/Campaigns/Segments/Segments';
import Offers from './view/Campaigns/Offers/Offers';
import FinalizeConfirm from './view/Campaigns/FinalizeConfirm/FinalizeConfirm';
import { titleCase } from './common/constant';
import MachineryPartner from './view/DashBoard/Components/MachinaryPartner/MachineryPartner';

function App() {
  const location = useLocation();

  useEffect(() => {
    const str = location.pathname.substring(1);
    const _str = titleCase(str.replaceAll('-', ' '));
    const finalStr = titleCase(_str, "/")
    document.title = finalStr;
  }, [location?.pathname]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" title="Home" element={<Synergi />} />
        <Route path="/login" title="Login" element={<Synergi />} />
        <Route path="/registration" title="Registration" element={<Synergi />} />
        <Route path="/password-recovery" title="PasswordRecovery" element={<PasswordRecoverySteps />} />
        <Route path="/usersignup" title="usersignup" element={<VerifyUser />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        <Route element={<Layout />}>
          {/* segments */}

          {/* 1 */}
          <Route path="/segments" title="Segments" element={<SegmentList />} />
          <Route path="/create-segment" title="Segments" element={<AICraftedSegment />} />
          <Route path="/create-segment/custom" title="Segments" element={<CustomCreateSegment />} />
          <Route path="/review-segment/:sk/:pk" title="Segments" element={<ReviewSegment />} />
          <Route path="/copy-segment/:sk/:pk" title="Segments" element={<CustomCreateSegment />} />
          <Route path="/edit-segment/:sk/:pk" title="Segments" element={<CustomCreateSegment />} />

          {/* 1 */}
          <Route path="/offers" title="Offers" element={<OfferList />} />
          <Route path="/create-offer" title="Offers" element={<AICraftedOffer />} />
          <Route path="/create-offer/custom" title="Offers" element={<CustomeCreateOffer />} />
          <Route path="/review-offer/:sk/:pk" title="Offers" element={<ReviewOffer />} />
          <Route path="/copy-offer/:sk/:pk" title="Offers" element={<CustomeCreateOffer />} />
          <Route path="/edit-offer/:sk/:pk" title="Offers" element={<CustomeCreateOffer />} />

          {/* 1 */}
          <Route path="/campaigns" title="campaigns" element={<AllCampaigns />} />
          <Route path="/create-campaign" title="New Campaign" element={<AboutCampaign />} />
          <Route path="/create-campaign/data-source/:sk/:pk" title="New Campaign" element={<DataSource />} />
          <Route path="/create-campaign/segment/:sk/:pk" title="New Campaign" element={<Segments />} />
          <Route path="/create-campaign/offer/:sk/:pk" title="New Campaign" element={<Offers />} />
          <Route path="/create-campaign/review/:sk/:pk" title="New Campaign" element={<FinalizeConfirm />} />


          <Route path="/app-integrations" title="App Integrations" element={<ReviseInti />} />
          <Route path="/imports" title="Imports" element={<Imports />} />
          <Route path="/exports" title="Exports" element={<Exports />} />
          <Route path="/mfascreen" element={<MFAScreenRegister />} />
          <Route path="/login-mfa" element={<LoginMFA />} />
          <Route path="/reposting-import-data" element={<RepostingImportData />} />
          <Route path="/onboarding" element={<Reposting />} />
          <Route path="/revisecontact-info" element={<Revisecontactinfo />} />
          <Route path="/marketing" element={<Panel />} />
          <Route path="/portfolio" element={<NewDashBoard />} />
          <Route path="/contactinfo" element={<ContactInfo />} />
          <Route path="/errorpage" element={<ErrorPage />} />
          <Route path="/segment-source-error" element={<SegmentSourceError />} />
          <Route path="/datasamplefilemodal" element={<DataSampleFileModal />} />
          <Route path="/datamissingpopup" title="Funnel" element={<DatamissingPopup />} />
          <Route path="/Channels" title="Channels" element={<Channels />} />
          <Route path="/CampaignsDashboard" title="CampaignsDashboard" element={<CampaignsDashboard />} />
          <Route path="/machinerypartner" title="machinerypartner" element={<MachineryPartner />} />
          <Route path="/Industry-comparison" title="Industry-comparison" element={<IndustryComparison />} />
          <Route path="/setting/overview" title="setting/overview" element={<OverView />} />
          <Route path="/setting/profile" title="setting/profile" element={<Profile />} />
          <Route path="/setting/user" title="setting/user" element={<User />} />
          <Route path="/setting/security" title="setting/security" element={<Security />} />
          <Route path="/setting/companyDetail" title="setting/companyDetail" element={<CompanyDetail />} />
          <Route path="/setting/sftpUser" title="setting/sftpUser" element={<SftpUser />} />
          <Route path="/CrossSell" title="CrossSell" element={<CrossSell />} />
          <Route path="/Search" element={<Search />}>
            {/* Default (index) route for /Search */}
            <Route index element={<MainSearch />} />
            <Route path="AllPersona" element={<AllPersona />} />
            <Route path="NewPersona" element={<NewPersona />} />
          </Route>

          <Route path="/customize-data-solution" title="CustomizeDataSolution" element={<CustomizeDataSolution />} />
          <Route path="/MarketingAutomation" title="MarketingAutomation" element={<MarketingAutomation />} />
          <Route path="/customer-data-intelligence" title="Custmer Data Intelligence" element={<DataIntelligence />} />
          <Route path="/sales" title="sales" element={<Funnel />} />
        </Route>
      </Route>

      <Route path="*" element={<UrlErrorPage />} />
    </Routes>
  );
}

export default App;
