import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

import footer_icon from '../../assets/footer_icon.svg';
import footer_icon2 from '../../assets/footer_icon2.svg';
import footer_icon3 from '../../assets/footer_icon3.svg';
import { useLocation } from 'react-router-dom';

const MainFooter = () => {
  const { pathname } = useLocation();
  const isFooterSubInfoDisplay = pathname === '/' || pathname === '/login' || pathname === '/registration' || pathname === '/password-recovery';
  return (
    <>
      {/* {isFooterSubInfoDisplay ? ( */}
      <Paper sx={{ p: 2, mt: 1 }}>
        <Grid>
          <Typography textAlign="center">©2024 Synergi Corporation | Privacy Policy</Typography>
        </Grid>
      </Paper>
      {/* ) : (
        <>
          <Grid container sx={{ pt: "0.25%" }}>
            <Grid
              container
              className="footer-detail-background"
              justifyContent="center"
              textAlign="center"
              sx={{ p: "2%" }}
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  Sign Up And Subsribe For 1:1 Consultations With A
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">
                  Member Of The Synergi Team.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <a
                  href="https://www.synergi.ai/request-synergi-demo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: "30vh", mt: "2%" }}
                  >
                    Sign Up
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ pt: 5 }}
          >
            <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
            <Grid item xs={3} sm={2} md={2} lg={2}>
              <img
                alt="header"
                style={{
                  margin: "1% auto", // Use margin auto for center alignment
                  width: "80%", // Adjusted the width for responsiveness
                  maxWidth: "300px", // Limiting the maximum width to ensure the image doesn't stretch too much on larger screens
                }}
                src="https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/image-255@2x.png"
              />
            </Grid>
            <Grid
              item
              xs={9}
              sm={4}
              md={4}
              lg={4}
              sx={{ paddingTop: { xs: "15px", sm: "0px" } }}
            >
              <Grid container justifyContent="center" alignItems="flex-start">
                <Grid item xs={2} sm={3} md={3} lg={2}>
                  <img
                    alt="header"
                    style={{
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      border: "1px solid #A35BB1",
                      padding: "10px",
                    }}
                    src={footer_icon}
                  />
                </Grid>
                <Grid item xs={10} sm={9} md={7} lg={6}>
                  <Typography
                    sx={{
                      color: "#414446",
                      fontFamily: "IBM Plex Serif",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Need Help? We're Here For You!
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--col-12, #414446)",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "151.188%",
                      pt: 1,
                    }}
                  >
                    Contact us at Support@synergi.ai with any questions,
                    concerns, or feedback. We're here to help!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={9}
              sm={4}
              md={4}
              lg={4}
              sx={{
                paddingTop: { xs: "15px", sm: "0px" },
                paddingBottom: { xs: "15px", md: "0px" },
              }}
            >
              <Grid container justifyContent="center" alignItems="flex-start">
                <Grid item xs={2} sm={3} md={3} lg={2}>
                  <img
                    alt="header"
                    style={{
                      // marginLeft: "1.7vh",
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      border: "1px solid #A35BB1",
                      padding: "10px",
                    }}
                    src={footer_icon2}
                  />
                </Grid>
                <Grid item xs={10} sm={9} md={7} lg={6}>
                  <Typography
                    sx={{
                      color: "#414446",
                      fontFamily: "IBM Plex Serif",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Recommended for You
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--col-12, #414446)",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "151.188%",
                      pt: 1,
                    }}
                  >
                    Explore the power of Customer Data Center Advantage for a
                    360-degree view of your clients.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#852598",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                      pt: 1,
                    }}
                  >
                    Get Started
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ mb: 20, pt: "1%" }}
          >
            <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    sx={{
                      color: "var(--col-12, #414446)",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "151.188%",
                    }}
                  >
                    Terms & Privacy: 2023 Synergi AI Corp.
                  </Typography>
                </Grid>
                <Grid item alignItems="flex-end">
                  <img
                    alt="header"
                    style={{
                      margin: "2%",
                      height: "3vh",
                      width: "15vh",
                    }}
                    src={footer_icon3}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Grid>
        </>
      )} */}
    </>
  );
};

export default MainFooter;
