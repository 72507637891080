import { useEffect, useState } from 'react';
import { Typography, Button, Grid, Divider, Paper, TextField, FormControl, Select, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { segmentsActions } from '../../../../store/slices/segments';
import { getSegmentOfferReview, saveSegment } from '../../../../services/SegmentService';
import { useSelector } from 'react-redux';
import ErrorPage from '../../../../ErrorPage';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpressionReview } from '../../query-builder/ExpressionReview';
import { LoadingScreen7ForSegment } from '../../../../Language/DialogBoxLanguage';

const ReviewSegment = ({ setIsPageType, ...props }) => {
  const { sk, pk } = useParams();
  const { setCustomeSegmentInfo, setCreateSegmentInputFields, resetSegment, setSegmentCreate } = segmentsActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [greatJob, setGreatJob] = useState(false);
  const { create, segmentAction } = useSelector((state) => state.segments);
  const getSegmentOffer = async () => {
    const responseData = await getSegmentOfferReview({
      sk: `segment#${sk}`,
      pk: `segment#${pk}`,
    });
    if (responseData.status === 200) {
      dispatch(setSegmentCreate({ response: responseData.data }));
    } else {
      seterrormsg(responseData?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (create.name === '') {
      getSegmentOffer();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleDescriptionChange = (event) => {
    const name = 'description';
    const value = event.target.value;
    dispatch(setCreateSegmentInputFields({ name, value }));
  };
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  const handleSegmentNameChange = (event) => {
    const name = 'name';
    const value = event.target.value;
    dispatch(setCreateSegmentInputFields({ name, value }));
  };

  const toTitleCase = (str) => {
    return str
      ?.split('_') // Split the string at each underscore
      ?.map((word) => word.toUpperCase()) // Capitalize the first letter of each word
      ?.join(' '); // Join the words back together with spaces
  };

  const handleSegmentSourceChange = (event) => {
    const name = 'selectedSegmentSource';
    const value = event.target.value;
    dispatch(setCreateSegmentInputFields({ name, value }));
  };

  const handleSegmentEdit = () => {
    navigate(`/edit-segment/${sk}/${pk}`);
    // setIsPageType(3);
  };

  const getProcessType = () => {
    let action = 'create';
    if (segmentAction === 'Add') {
      action = 'create';
    }
    if (segmentAction === 'Copy') {
      action = 'copy';
    }
    if (segmentAction === 'Edit') {
      action = 'edit';
    }
    return action;
  };

  const handleSaveSegmentInfo = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const processType = getProcessType();
    const payload = {
      pk: create?.pk,
      sk: create?.sk,
      entity_type: 'segment',
      process_type: processType,
    };
    const responseData = await saveSegment(payload);
    if (responseData.status === 200) {
      setIsLoading(false);
      setGreatJob(true);
      dispatch(setCustomeSegmentInfo({ response: responseData.data }));
      dispatch(resetSegment());
    } else {
      seterrormsg(responseData?.response?.data?.error);
      setShowModal(true);
      // window.alert(responseData.error);
    }
    setIsLoading(false);
  };

  const handleBackToCreateCustomSegment = () => {
    navigate('/segments');
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          // height: '100%',
          borderRadius: '6px',
        }}
      >
        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  Review Your Segment
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography
                sx={{
                  color: 'var(--Col-9, #8E0000)',
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '151.188%',
                }}
              >
                *All fields are required
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography
                sx={{
                  color: 'var(--Col-8, #000)',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                Segment Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                    <TextField
                      id="segmentName"
                      type="text"
                      fullWidth
                      size="medium"
                      value={create?.name}
                      placeholder="Enter Segment name"
                      onChange={handleSegmentNameChange}
                      disabled
                      sx={{ opacity: segmentAction === 'Add' ? 1 : 0.5 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                    <TextField
                      id="description"
                      type="text"
                      fullWidth
                      size="medium"
                      multiline
                      rows={4}
                      value={create?.description}
                      placeholder="Breif description"
                      disabled
                      sx={{ opacity: segmentAction === 'Add' ? 1 : 0.5 }}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={create?.selectedSegmentSource}
                        onChange={handleSegmentSourceChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        disabled
                        sx={{ opacity: segmentAction === 'Add' ? 1 : 0.5 }}
                      >
                        <MenuItem value={create?.selectedSegmentSource}>{`${toTitleCase(create?.selectedSegmentSource)}`}</MenuItem>
                        {/* {locationstate
                          ? Array.isArray(locationstate.segmentSourceList) &&
                            locationstate.segmentSourceList.length > 0 &&
                            locationstate.segmentSourceList.map((source) => <MenuItem value={source}>{source}</MenuItem>)
                          : Array.isArray(create.segmentSourceList) &&
                            create.segmentSourceList.length > 0 &&
                            create.segmentSourceList.map((source) => <MenuItem value={source}>{source}</MenuItem>)} */}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6}></Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: 'var(--Col-8, #000)',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Total Data Set
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: '#414446',
                        fontFamily: 'IBM Plex Serif',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {create?.total_record}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: 'var(--Col-12, #414446)',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '173.7%',
                        textTransform: 'capitalize',
                      }}
                    >
                      Contacts who match any of the following criteria;
                    </Typography>
                  </Grid>

                  {!!create && create.name !== '' && (
                    <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mt: 2 }}>
                      <div id="expression-logic-review" />
                      <ExpressionReview expression={create.json_where_condition} queryConfig={create.segmentConfig} />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} sm={6} lg={6}></Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      sx={{
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        marginTop: '2vh',
                      }}
                      onClick={handleSegmentEdit}
                    >
                      Edit
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        background: '#852598',
                        marginTop: '2vh',
                        marginLeft: '1vw',
                      }}
                      onClick={handleSaveSegmentInfo}
                    >
                      Save Segment
                    </Button>

                    {isLoading && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '32px',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100vh',
                        }}
                      >
                        <LoadingScreen gifType={5} />
                      </div>
                    )}
                    {greatJob && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          fontSize: '32px',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          height: '100vh',
                        }}
                      >
                        <LoadingScreen gifType={7} type="segments" loadingScreen7={LoadingScreen7ForSegment} />
                      </div>
                    )}

                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      sx={{
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        marginLeft: '1vw',
                        marginTop: '2vh',
                      }}
                      onClick={handleBackToCreateCustomSegment}
                    >
                      Cancle
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      </Paper>
    </>
  );
};

export default ReviewSegment;
