import { createSlice } from '@reduxjs/toolkit';
// import { SET_DASHBOARD_URL } from '../actions/dashboard-Action';

const initialState = {
  embedUrl: null,
};

// const dashboard = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_DASHBOARD_URL:
//       return {
//         ...state,
//         embedUrl: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setEmbedURL(state, action) {
      state.embedUrl = action.payload;
    },
    //   setOfferList(state, action) {
    //     state.offerList = action.payload.offerList;
    //   },
  },
});

export const dashboardActions = dashboard.actions;
export const dashboardReducer = dashboard.reducer;
