import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { aboutCampaignData as setAboutCampaignData } from '../../GlobalStore';
import { Grid } from '@mui/material';

const StyledCard = styled(Card)({
  border: '1px solid #EBE8F1',
  borderRadius: '5px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  background: '#FAF7FB',
  width: '547px',
  height: '295px',
  marginTop: '8px',
});

const StyledListItemButton = styled(ListItemButton)({
  height: '42px',
  width: '176px',
  color: 'var(--col-6, #5D596C)',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '266.688%',
  textTransform: 'capitalize',

  '&.objective-selected.Mui-selected': {
    backgroundColor: '#FAF7FB',
    borderRadius: '5px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    border: '1px solid #EBE8F1',
    borderRight: 'none',
    color: 'var(--col-12, #852598)',
  },
});

const Objective = ({ ObjectiveAPI, saveObjectiveChange }) => {
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);

  const [selectedObjective, setSelectedObjective] = useState(aboutCampaignData?.objective || ObjectiveAPI[0].objective);

  useEffect(() => {
    setSelectedObjective(aboutCampaignData?.objective || ObjectiveAPI[0].objective);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutCampaignData]);

  useEffect(() => {
    if (!aboutCampaignData?.objective) {
      const currentCampaignData = setAboutCampaignData();
      setAboutCampaignData({
        ...currentCampaignData,
        objective: ObjectiveAPI[0].objective,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObjectiveChange = (value) => {
    setSelectedObjective(value);
    const currentCampaignData = setAboutCampaignData();
    setAboutCampaignData({
      ...currentCampaignData,
      objective: value,
    });
  };

  const selectedData = ObjectiveAPI.find((item) => item.objective === selectedObjective);

  return (
    <div
   
    >
      <Grid container>
        <Grid item xs={12}    style={{
        display: 'flex',
      }}>
          <FormControl component="fieldset">
            <List component="nav" aria-label="api inputs">
              {ObjectiveAPI.map((item) => (
                <StyledListItemButton
                  className="objective-selected"
                  selected={selectedObjective === item.objective}
                  onClick={() => handleObjectiveChange(item.objective)}
                  key={item.objective}
                >
                  {selectedObjective === item.objective ? <RadioButtonCheckedIcon sx={{ marginRight: '10px' }} /> : <RadioButtonUncheckedIcon sx={{ marginRight: '10px' }} />}

                  <ListItemText primary={item.objective} />
                </StyledListItemButton>
              ))}
            </List>
          </FormControl>
          <StyledCard>
            <CardContent>
              <div className="NormalText">Show your ads to people who are most likely to remember them.</div>
              <br />
              <div className="headerText" style={{ fontSize: '14px' }}>
                Good for:
              </div>
              {selectedData?.good_for?.map((item, index) => (
                <div key={index}>
                  <div className="goodFor">{item}</div>
                </div>
              ))}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Objective;
