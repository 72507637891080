
import { Button, Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import errorimg from '../../../../assets/RegisterSuccessIcon.svg';
import '../../../../../src/index.css';
import { useEffect } from 'react';

const VerifySuccessDailog = ({ closeModal, closingFlag }) => {
    useEffect(() => {
        document.body.classList.add('disable-scroll');
        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, []);
    return (
        <>
            <div className="modal-overlay">
                <Card sx={{ borderRadius: 2, overflow: "auto" }} className="modal">
                    <Grid container>
                        <Grid container sx={{ padding: '60px' }}>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{
                                        color: '#414446',
                                        fontFamily: 'IBM Plex Serif',
                                        fontSize: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '40px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    User Verified Successfully
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ justifyContent: 'end', display: 'flex' }}>
                                <img
                                    src={errorimg}
                                    style={{
                                        width: '121.6px',
                                        height: '132px',
                                        flexShrink: '0',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>
    );
};

export default VerifySuccessDailog;
