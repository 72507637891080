import { Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../../assets/100-X-100_Loader.gif';
import { DataInModal1 } from '../../../Language/DialogBoxLanguage';


const MyModal = ({ closeModal }) => {
  return (
    <>
      <div className="wrapper"></div>
      <Card sx={{ my: 5, borderRadius: 6, padding: '6px' }} className="modal">
        <Grid container>
          <Grid item xs={12} onClick={closeModal} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon />
          </Grid>
          <Grid container sx={{ padding: '102px 160px 0px 160px ' }}>
            <Grid item xs={7}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',

                  color: '#414446',
                }}
              >
                {DataInModal1.headlineText1}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '18px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
               {DataInModal1.subTitle1}
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ paddingLeft: '20px' }}>
              <img src={Loader} alt="Group Icon" />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default MyModal;
