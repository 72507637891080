import React, { useState } from 'react';
import MapFields from './MapFields';
import Finalize from './Finalize';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Header from './Header';
import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen';
import Imports from '../../Imports';
import { useNavigate } from 'react-router-dom';
import AnalyzingSpreadsheet from './AnalyzingSpreadsheet';

const PopUp = ({ value, togglePopUp, isPopUpOpen, uploadResponse, imports, setToggle, toggle }) => {
  const [selectedFirstPageData, setSelectedFirstPageData] = useState([]);
  const [selectedFirstPageResponse, setSelectedFirstPageResponse] = useState(null);
  const [selectedSecondPageData, setSelectedSecondPageData] = useState([]);
  const [pageType, setPageType] = useState(1);
  const [isDataUploading, setIsDataUploading] = useState(false);
  const navigate = useNavigate();

  const handleFirstPageDataSelected = (data) => {
    setSelectedFirstPageData(data);
  };
  const handleSecondPageDataSelected = (data) => {
    setSelectedSecondPageData(data);
  };

  let pageComponent;
  if (pageType === 0) {
    togglePopUp(false);
  } else if (pageType === 1) {
    pageComponent = (
      <MapFields
        onDataSelected={handleFirstPageDataSelected}
        handleResponse={setSelectedFirstPageResponse}
        csvFieldList={uploadResponse.csv_field_list}
        targetFieldList={uploadResponse.target_field_list}
        csvFieldToTargetField={uploadResponse.csv_field2target_field}
        documentId={uploadResponse.document_id}
        setPageType={setPageType}
        togglePopUp={togglePopUp}
        setIsDataUploading={setIsDataUploading}
        isDataUploading={isDataUploading}
      />
    );
  } else if (pageType === 2) {
    pageComponent = (
      <Finalize
        onDataSelected={handleSecondPageDataSelected}
        importName={uploadResponse.file_name}
        importDescription={uploadResponse.file_description}
        fileKpi={selectedFirstPageResponse.file_kpi}
        documentId={selectedFirstPageResponse.document_id}
        applicationDataMatrix={selectedFirstPageResponse.actionable_data_matrix}
        togglePopUp={(value) => togglePopUp(value)}
        setPageType={setPageType}
        imports={imports}
        isDataUploading={isDataUploading}
        setIsDataUploading={setIsDataUploading}
        setToggle={setToggle}
        toggle={toggle}
      />
    );
  } else if (pageType === 3) {
    pageComponent = <AnalyzingSpreadsheet />;
  }

  return (
    <React.Fragment>
      <Dialog
        open={isPopUpOpen}
        maxWidth={'lg'}
        sx={{
          borderRadius: '30px',
        }}
      >
        {isDataUploading ? (
          <LoadingScreen gifType={1} />
        ) : (
          <>
            {/* <DialogTitle>
              <Header type={pageType - 1} />
            </DialogTitle> */}
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90vh',
              }}
            >
              {pageComponent}
            </DialogContent>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default PopUp;
