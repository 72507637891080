import { Typography } from '@mui/material';
import React from 'react';
import BillingInfo from './BillingInfo';
import AccountContact from './AccountContact';
import { Profilestep5 } from '../../../../Language/language';
import SettingFront from '../../SettingFront';
const CompanyDetail = () => {
  return (
    <div>
      {/* <SettingFront /> */}
      <section>
        <Typography
          style={{
            color: '#414446',
            fontFamily: 'IBM Plex Serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            paddingBottom: '12px',
            marginTop: '25px',
          }}
        >
          {Profilestep5.headlineText1}
        </Typography>
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            marginBottom: '25px',
          }}
        >
          {Profilestep5.subTitle1}
        </Typography>
        <AccountContact />
      </section>
      <section>
        <Typography
          style={{
            color: '#414446',
            fontFamily: 'IBM Plex Serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            paddingBottom: '12px',
            marginTop: '25px',
          }}
        >
          {Profilestep5.headlineText2}
        </Typography>
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            marginBottom: '25px',
          }}
        >
          {Profilestep5.subTitle2}
        </Typography>
        <BillingInfo />
      </section>
    </div>
  );
};

export default CompanyDetail;
