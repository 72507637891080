import React, { useRef, useEffect, useState } from 'react';

import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { getItem } from '../../../Utils/CommonFunction/CommonFunction';
import { DASHBOARD_EMBED_URL_V2 } from '../../../../common/config';
import ErrorPage from '../../../../ErrorPage';

const MachineryPartner = () => {
  const dashboardRef = useRef(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [dimensions, setDimensions] = useState({ height: '90%', width: '90%' });
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  // Mock function for size. Replace this as per your requirement.
  const fetchMachineryPartnerDataSize = async () => {
    return Promise.resolve({ height: '100%', width: '100%' });
  };

  useEffect(() => {
    const loadEmbeddingContext = async () => {
      try {
        const context = await createEmbeddingContext();
        setEmbeddingContext(context);
      } catch (error) {
        console.error('Error creating embedding context:', error);
        setError('Failed to create embedding context');
      }
    };

    loadEmbeddingContext().catch((error) => {
      console.error('Unhandled error in loadEmbeddingContext:', error);
    });
  }, []);

  useEffect(() => {
    const fetchDataSize = async () => {
      try {
        const size = await fetchMachineryPartnerDataSize();
        setDimensions({
          height: size.height || '80%',
          width: size.width || '100%',
        });
      } catch (error) {}
    };

    fetchDataSize();
  }, []);

  useEffect(() => {
    if (!embeddingContext) return;
    const embedDashboard = async () => {
      try {
        const accessToken = getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found in localStorage');
        }
        const response = await fetch(`${DASHBOARD_EMBED_URL_V2}?dashboard_name=MachineryPartner`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        const parsData = JSON.parse(data)

        console.log("pars", parsData  )
        if (!parsData.EmbedUrl) {
          throw new Error('Missing URL');
        }

        const options = {
          url: parsData.EmbedUrl,
          container: dashboardRef.current,
          height: dimensions.height,
          width: dimensions.width,
          borderRadius: '6px',
        };

        await embeddingContext.embedDashboard(options);
      } catch (error) {
        setShowModal(true);
        seterrormsg(error?.message);
        console.error('Error embedding dashboard:', error);
        setError(error.message);
      }
    };

    embedDashboard().catch((error) => {
      console.error('Unhandled error in embedDashboard:', error);
    });
  }, [embeddingContext, dimensions]); // Added dimensions to the dependency list

  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          height: '100vh',
          borderRadius: '6px',
        }}
      >
        {/* <div className="dashboard-subtitle">Funnel</div> */}
        {error ? <Typography color="error">{error}</Typography> : <div ref={dashboardRef} className="dashboard-embed" />}
      </Paper>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} closingFlag={1} />}
    </>
  );
};

export default MachineryPartner;
