import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import StepLabel from '@mui/material/StepLabel';

import { newcampaign } from '../../../../../Language/language';

import { SelectedIcon, UnselectedIcon } from '../../../../Utils/icons/icons';

const steps = ['About Campaign', 'Data Source', 'Segments', 'Offers', 'Finalize & Confirm'];

export default function CustomStepper({ type }) {
  return (
    <Grid container spacing={2}>
      <Grid item md={8} sm={12} style={{ alignItems: 'center', margin: '0px' }}>
        <div
          className="titleText"
          style={{
            color: "#414446",
            fontFamily: "IBM Plex Serif",
            marginLeft: "20px",
            // marginTop: "20px",
            fontSize: "30px",
          }}
        >
          {newcampaign.pagetitle}
        </div>
      </Grid>
      <Grid item md={4} sm={12} style={{ marginTop: '05px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Stepper activeStep={type} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel className="stepLabel" StepIconComponent={() => <StepIconComponent index={index} type={type} />}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
}

const StepIconComponent = ({ index, type }) => {
  return type === index ? <SelectedIcon /> : <UnselectedIcon />;
};
