import { Paper } from '@mui/material';
import React from 'react';
import RepostingImportData from './Component/RepostingImportData';
import EmptyDashboard from './Component/EmptyDashboard';

const Reposting = () => {
  return (
    <>
      <Paper
        style={{
          backgroundColor: '#ffffff',
          width: '100%',
          borderRadius: '6px',
        }}
      >
        <RepostingImportData />
      </Paper>

      <Paper>
        <EmptyDashboard />
      </Paper>
    </>
  );
};

export default Reposting;
