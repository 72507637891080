import { Box, Button, Chip, Grid, Slider, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from '../Component/BarChart';
import CachedIcon from '@mui/icons-material/Cached';
import { getSegmentData } from '../../../services/LoanInterestRateService';
import { getCookie, getItem, setCookie, setItem } from '../../Utils/CommonFunction/CommonFunction';

const Segment = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const IsLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const segmentFilter = getItem('segmentFilterData');
  const segmentFilterCall = getCookie('segmentFilterCall');
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [segmentFilterData, setSegmentFilterData] = useState(segmentFilter ? segmentFilter : []);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      // If the tag is already selected, remove it
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      // If the tag is not selected, add it
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleResetClick = () => {
    // Reset the selected tags
    setSelectedTags([]);
  };
  const isTagSelected = (tag) => selectedTags.includes(tag);
  useEffect(() => {
    setSkeletonLoading(true);
    if (!segmentFilterCall) {
      (async () => {
        const response = await getSegmentData();
        setItem('segmentFilterData', response?.data?.result);
        setCookie('segmentFilterCall', true, 1440);
        setSegmentFilterData(response?.data?.result);
      })();
    } else {
      setSkeletonLoading(false);
    }
  }, [segmentFilterData]);
  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: '4px' }} direction={isMobile || isTablet || IsLarge ? 'column' : 'row'}>
        <Grid
          item
          xs={3}
          sm={3}
          md={5}
          lg={3}
          xl={3}
          sx={{
            marginRight: '2%',
          }}
        >
          {skeletonLoading ? (
            <Box sx={{ border: '1px solid #A35BB1', borderRadius: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #D3D3D3', padding: '17px 27px' }}>
                <Skeleton variant="text" width={180} height={28} />
                <Skeleton variant="rectangular" width={100} height={36} />
              </Box>
              <Box sx={{ padding: '23px' }}>
                <Grid container>
                  {Array.from(new Array(4)).map((_, index) => (
                    <Grid item xs={6} style={{ padding: '6px 15px' }} key={index}>
                      <Skeleton variant="text" width="70%" height={24} />
                      <Skeleton variant="rectangular" width="100%" height={58} style={{ marginBottom: '8px' }} />
                      <Skeleton variant="text" width="50%" height={24} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box sx={{ border: '1px solid #A35BB1', borderRadius: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #D3D3D3', padding: '17px 27px' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  Segments
                </Typography>

                <Button
                  sx={{ color: '#A35BB1', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', textTransform: 'capitalize' }}
                  onClick={handleResetClick}
                >
                  <CachedIcon /> Reset all
                </Button>
              </Box>
              <Box sx={{ padding: '23px', height: '410px', overflow: 'auto' }}>
                {segmentFilterData?.map((TagData, index) => {
                  return (
                    <Button
                      onClick={() => handleTagClick(TagData.borrower_persona)}
                      style={{
                        backgroundColor: isTagSelected(TagData.borrower_persona) ? '#F0EAF1' : 'transparent',
                        color: isTagSelected(TagData.borrower_persona) ? '#A35BB1' : '#5D596C',
                        borderColor: isTagSelected(TagData.borrower_persona) ? '#A35BB1' : '#5D596C',
                        border: '1px solid',
                        margin: '8px 6px',
                        textTransform: 'capitalize',
                      }}
                      key={index}
                    >
                      {TagData.borrower_persona}
                      <span style={{ paddingLeft: '10px' }}>{TagData.count}</span>
                    </Button>
                  );
                })}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={8.5} sm={8.5} md={6} lg={8.5} xl={8.5}>
          <BarChart dataColors='["#A35BB1", "#7F4D90", "#852598", "#7A4B84", "#DFBBE5", "--vz-info"]' isSegment={true} selectedTags={selectedTags} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Segment;
