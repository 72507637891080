import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import youtube from '../../../assets/Youtube.svg';
import styled from '@emotion/styled';
import { CommonLanguage } from '../../../Language/language';
import { useNavigate } from 'react-router-dom';

const CreatePopup = ({ closeModal, popupConfig, navigateType }) => {
  const navigate = useNavigate();
  const StyledCheckbox = styled(Checkbox)(`
  color: #A35BB1;

  &.Mui-checked {
    color: #A35BB1;
  }
`);
  return (
    <>
      <Card
        sx={{ mb: 5, mt: 5, borderRadius: 2, padding: '6px', width: { xl: '674px', lg: '674px', md: '674px' }, position: { xl: 'relative', lg: 'relative', md: 'relative' }, left: { xl: '50%', lg: '50%', md: '50%' }, right: { xl: '50%', lg: '50%', md: '50%' }, transform: { xl: 'translateX(-50%)', lg: 'translateX(-50%)', md: 'translateX(-50%)' } }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}></Grid>
          <Grid container sx={{ padding: '20px 66px ' }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',
                  color: '#414446',
                }}
              >
                {popupConfig.headlineText1}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '18px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
                {popupConfig.subtitle}
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  padding: '24px 0px',
                }}
              >
                <Typography style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      color: '#414446',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '173.7%',
                    }}
                  >
                    {CommonLanguage.needGuide}
                  </Typography>
                </Typography>
                <Link href={popupConfig.link} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '31px',
                    }}
                  >
                    <img src={youtube} alt="call icon" style={{ width: '20px' }} />
                    <Typography
                      style={{
                        paddingLeft: '15px',
                        color: '#A35BB1',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '151.188%',
                      }}
                    >
                      {popupConfig.checkvideo}
                    </Typography>
                  </Typography>
                </Link>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                onClick={() => {
                  navigate(navigateType);
                }}
                sx={{
                  // width: { xl: "137px", lg: '137px', md: "150px", sm: "150px", xs: "150px" },
                  width: ["137px", "137px", "150px", "150px"],
                  height: '48px',
                  marginTop: '20px',
                }}
              >
                {popupConfig.btnText}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '25px' }}>
              <FormControlLabel control={<StyledCheckbox />} label="Don't show this again" style={{ color: '#000' }} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default CreatePopup;
