import { Grid, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import NewDashboardHead from './NewDashboardHead'

const CrossSell = () => {

    const [selectedTab, setSelectedTab] = useState(-1);
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <></>
        // <Grid container>
        //     {/* .......... Main paper start ............ */}
        //     <Grid container>
        //         <Paper
        //             xs={12}
        //             sm={12}
        //             lg={12}
        //             xl={12}
        //             sx={{
        //                 width: "100%",
        //                 padding: "15px"
        //             }}
        //         >
        //             <Typography
        //                 sx={{
        //                     fontFamily: 'Roboto',
        //                     fontSize: '18px',
        //                     lineHeight: '40px',
        //                     color: '#000',
        //                     fontStyle: 'normal',
        //                     fontWeight: '600',
        //                     lineHeight: 'normal',
        //                     textTransform: 'capitalize',
        //                 }}
        //             >
        //                 Portfolio by Interest Rate & Unpaid Balance
        //             </Typography>
        //             <Typography
        //                 sx={{
        //                     fontFamily: 'Roboto',
        //                     fontSize: '12px',
        //                     lineHeight: '18px',
        //                     color: '#414446',
        //                     fontStyle: 'normal',
        //                     fontWeight: '400',
        //                     lineHeight: 'normal',
        //                     // paddingLeft: '13px',
        //                 }}
        //             >
        //                 Hover over each interest rate segment to view the number of loans. Click for detailed loan balance breakdowns.
        //             </Typography>
        //             <Grid container>
        //                 <section style={{ marginTop: '10px' }}>
        //                     <NewDashboardHead handleChange={handleChange} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        //                 </section>
        //             </Grid>
        //         </Paper>
        //     </Grid>
        //     {/* .......... Main paper end ............ */}

        // </Grid>
    )
}

export default CrossSell