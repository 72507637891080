import { Button, Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProcessDashboard from '../../../../assets/process_dashboard.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import { DataInModal } from '../../../../Language/DialogBoxLanguage';

const Modal = ({ closeModal, errormsg }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="wrapper"></div>
      <Card sx={{ my: 5, borderRadius: 6, padding: '6px' }} className="modal">
        <Grid container>
          <Grid item xs={12} onClick={closeModal} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon />
          </Grid>
          <Grid container sx={{ padding: '76px 66px 0px 80px ' }}>
            <Grid item xs={7} sx={{ paddingRight: '80px' }}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',

                  color: '#414446',
                }}
              >
                {DataInModal.headlineText1}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '18px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
                {DataInModal.subTitle1}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                sx={{ width: { lg: '253px' }, margin: '53px 0px 29px 0px' }}
                onClick={() => {
                  navigate('/onboarding');
                }}
              >
                {DataInModal.databtn2}
              </Button>
            </Grid>
            <Grid item xs={5} sx={{ paddingLeft: '20px' }}>
              <img src={ProcessDashboard} alt="Group Icon" />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default Modal;
