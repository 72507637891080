import React, { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { Slider, Box, Grid } from "@mui/material";
import { segmetsData as setSegmentsData } from "../../../GlobalStore";
import { CustomCreditIcon } from "../../../../Utils/icons/icons";
import { getSegmentsAI } from "../../../../../services/CampaignService";

const getColor = (index, isInvalid) => {
  const colors = [
    "#852598",
    "#E08029",
    "#158C6F",
    "#00B5C7",
    "#5C427E",
    "#D89B00",
    "#13717D",
    "#d0ed57",
  ];
  return colors[index % colors.length];
};

const SegmentsAI = ({ segmentsAIAPI }) => {
  const segmetsData = useReactiveVar(setSegmentsData);
  const [totalValue, setTotalValue] = useState(0);

  const [values, setValues] = useState([]);

  useEffect(() => {
    const currentSegmentData = setSegmentsData();
    setSegmentsData({
      ...currentSegmentData,
      AIData: values,
    });
    setTotalValue(values.reduce((a, b) => a + b.value, 0));
  }, [values]);

  const handleSliderChange = (index, newValue) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index].value = newValue;
      return newValues;
    });
  };

  useEffect(() => {
    if (values.length === 0) {
      setValues(
        segmentsAIAPI.map((segment) => ({
          name: segment?.segment_name,
          segment_pk: segment?.segment_pk,
          segment_sk: segment?.segment_sk,
          value: segment?.segment_min_value,
        }))
      );
    }
  }, [segmentsAIAPI]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {segmentsAIAPI.map((segment, index) => (
          <Box key={segment?.segment_id}>
            <h2 id="input-slider" style={{ fontWeight: "400", width: "75%" }}>
              {segment?.segment_name}
            </h2>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <Slider
                aria-labelledby="input-slider"
                defaultValue={segment?.segment_min_value}
                value={values[index]?.value}
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue)
                }
                step={1}
                min={0}
                max={segment.segment_max_value}
                style={{ color: getColor(index) }}
              />
              <h1 style={{ fontWeight: "400", marginLeft: "10px" }}>
                {values[index]?.value}
              </h1>
            </div>
          </Box>
        ))}
      </Grid>
      {/* <Grid item xs={6}>
        <div
          className="normalText"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <CustomCreditIcon />
          </div>
          Credit bureau fees may be applicable
        </div>
      </Grid> */}
      <Grid item xs={12}>
        <hr style={{ margin: "0px" }} />
      </Grid>
      <Grid item xs={6}>
        <h2
          style={{
            color: "var(--col-14, #A35BB1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ marginTop: "7px" }}>Total Data</div>
          <div>{totalValue}</div>
        </h2>
      </Grid>
    </Grid>
  );
};
export default SegmentsAI;
