// LoadingScreen.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import Upload from '../../../../assets/Uploadgif.gif';

const LoadingScreen = ({ gifType }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 6,
        border: '1px solid #EBE8F1',
        background: '#FFF',
        boxShadow: '0px 16px 21px 0px rgba(0, 0, 0, 0.33)',
        width: '834px',
        height: '371px',
        flexShrink: 0,
        overflow: 'hidden !important',
        p: 3,
      }}
    >
      {gifType === 1 && (
        <>
          <Box sx={{ padding: '75px' }}>
            <div className="manageAllCampaignText">Please Wait...</div>
            <h3
              style={{
                color: '#414446',
                marginTop: '20px',
                lineHeight: '25px',
              }}
            >
              Your Data are Uploading
            </h3>
          </Box>
          <Box>
            <img src={Upload} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
          </Box>
        </>
      )}
      {gifType === 2 && (
        <>
          <Box sx={{ padding: '75px' }}>
            <div className="manageAllCampaignText">Please Wait...</div>
            <h3
              style={{
                color: '#414446',
                marginTop: '20px',
                lineHeight: '25px',
              }}
            >
              Your Data are Uploading
            </h3>
          </Box>
          <Box>
            <img src={Upload} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default LoadingScreen;
