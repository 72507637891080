import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../view/Utils/CommonFunction/CommonFunction';

const PrivateRoute = () => {
  const token = getCookie('accessToken');
  return token ? <Navigate to={'/onboarding'} /> : <Outlet />;
};

export default PrivateRoute;
