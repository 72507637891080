import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getcustomerinfoAction } from '../../ReduxCmp/UserAction/Useraction';
import { ReviewDataIntiScreen } from '../../Language/language';

const ReviewDataIntigration = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          width: { xl: '335px', md: '95%', sm: "95%", xs: "95%" },
          height: { lg: '292px', md: '100%' },
          backgroundColor: '#F6F5F8',
          border: '1px solid #EBE8F1',
          borderRadius: '5px',
          marginLeft: { xl: '35px', md: '10px', sm:"0px", xs: "0px" },
          marginTop: { lg: '19px', md: '5px' },
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            style={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              borderBottom: '1px solid #D9D6E3',
              padding: '17px 22px 19px 17px',
            }}
          >
            {ReviewDataIntiScreen.headlineText1}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              padding: '15px 22px',
              color: '#414446',
            }}
          >
            {ReviewDataIntiScreen.bodytext}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ReviewDataIntigration;
