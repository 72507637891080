import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { dataSourceData as setDataSourceData } from '../../../GlobalStore';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RemoveIcon from '@mui/icons-material/Remove';
import { CustomMailIcon } from '../../../../Utils/icons/icons';

const DataSourceAI = ({ DataSourceAiAPI }) => {
  const dataSourceData = useReactiveVar(setDataSourceData);
  const isArrayOfStrings = (array) => array.every((item) => typeof item === 'string');

  const transformAIData = (AIData, DataSourceAiAPI) => {
    const aiDataMapping = DataSourceAiAPI.reduce((acc, item) => {
      acc[item.name.toLowerCase()] = { name: item.name, document_pk: item.pk, document_sk: item.sk };
      return acc;
    }, {});

    return AIData.map((item) => {
      if (typeof item === 'string') {
        const lowerCaseItem = item.toLowerCase();
        return aiDataMapping[lowerCaseItem] || { name: item };
      }
      return item;
    });
  };

  const [checked, setChecked] = useState(() => {
    const initialCheckedState = {};
    const transformedAIData = isArrayOfStrings(dataSourceData?.AIData || []) ? transformAIData(dataSourceData.AIData, DataSourceAiAPI) : dataSourceData.AIData;

    transformedAIData.forEach((item) => {
      initialCheckedState[item.document_pk] = true;
    });
    return initialCheckedState;
  });

  const handleChange = (document_pk, name, document_sk) => {
    const isChecked = checked[document_pk];
    const updatedChecked = {
      ...checked,
      [document_pk]: !isChecked,
    };
    setChecked(updatedChecked);

    const currentDataSourceData = setDataSourceData();

    if (!isChecked) {
      setDataSourceData({
        ...currentDataSourceData,
        AIData: [
          ...currentDataSourceData.AIData.filter((item) => item.document_sk !== document_pk),
          {
            name: name,
            document_pk: document_pk,
            document_sk: document_sk,
          },
        ],
      });
    } else {
      setDataSourceData({
        ...currentDataSourceData,
        AIData: currentDataSourceData.AIData.filter((item) => item.document_sk !== document_pk),
      });
    }
  };

  useEffect(() => {
    if (dataSourceData?.AIData?.length > 0 && isArrayOfStrings(dataSourceData?.AIData || [])) {
      const transformedAIData = transformAIData(dataSourceData.AIData, DataSourceAiAPI);
      const newCheckedState = {};
      transformedAIData.forEach((item) => {
        newCheckedState[item.document_pk] = true;
      });
      setChecked(newCheckedState);

      setDataSourceData({
        ...dataSourceData,
        AIData: transformedAIData,
      });
    } else {
      const newCheckedState = {};
      (dataSourceData?.AIData || []).forEach((item) => {
        newCheckedState[item.document_sk] = true;
      });
      setChecked(newCheckedState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceData?.AIData]);

  return (
    <div style={{ padding: '10px' }}>
      <Grid container spacing={2} display="flex" gap={'5px'}>
        {DataSourceAiAPI &&
          DataSourceAiAPI.map((item, index) => (
            <React.Fragment key={item.pk}>
              <Grid container spacing={2} display="flex" key={item.pk}>
                <Grid item xs={8}>
                  <div>
                    <FormControlLabel
                      control={<Checkbox checked={checked[item.pk] || false} onChange={() => handleChange(item.pk, item.name, item.sk)} name={item.pk} color="secondary" />}
                      label={
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: '10px' }}>
                            <CustomMailIcon />
                          </div>
                          <div flexGrow={1}>
                            <div style={{ alignContent: 'center' }}>
                              <h2
                                style={{
                                  color: 'var(--col-14, #A35BB1)',
                                  display: 'flex',
                                  textTransform: 'capitalize',
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                {item.name}
                              </h2>
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <Box
                      display="flex"
                      sx={{
                        marginLeft: '58px',
                        width: '75%',
                        marginTop: '-5px',
                      }}
                    >
                      <Box flexGrow={1} display="flex" flexDirection="column">
                        <div className="normalText">{item.description}</div>
                        <div className="normalText">File Type : {item.server_file_name}</div>
                        <div className="normalText">Total Record : {item.total_records}</div>
                        <div
                          className="normalText"
                          style={{
                            color: 'var(--col-14, #5D596C)',
                            fontSize: '10px',
                            marginTop: '3px',
                          }}
                        >
                          {item.created_datetime} By
                        </div>
                      </Box>
                    </Box>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Box display="flex" justifyContent="flex-end">
                    <div>
                      {item.overall_status === '+' ? (
                        <div className="predictivePKPI" style={{ display: 'flex' }}>
                          Predictive KPIs{' '}
                          <NorthEastIcon
                            style={{
                              fontSize: '12px',
                              marginLeft: '5px',
                              color: '#158C6F',
                            }}
                          />
                        </div>
                      ) : item.overall_status === '-' ? (
                        <div className="predictiveNKPI" style={{ display: 'flex' }}>
                          Predictive KPIs{' '}
                          <SouthEastIcon
                            style={{
                              fontSize: '12px',
                              marginLeft: '5px',
                              color: '#8E0000',
                            }}
                          />
                        </div>
                      ) : (
                        <div className="predictiveNKPI" style={{ display: 'flex', color: '#414446' }}>
                          Predictive KPIs{' '}
                          <RemoveIcon
                            style={{
                              fontSize: '12px',
                              marginLeft: '5px',
                              color: '#414446',
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          width: '233px',
                          textAlign: 'left',
                          border: '1px solid #EBE8F1',
                          background: '#F6F5F8',
                          borderRadius: '5px',
                          padding: '5px',
                        }}
                      >
                        <Grid container>
                          {item.kpi &&
                            Object.entries(item.kpi).map(([key, value]) => (
                              <Grid item xs={12 / Object.keys(item.kpi).length} key={key}>
                                <Box>
                                  <div
                                    className="grid-content-key"
                                    style={{
                                      color: 'var(--col-6, #5D596C)',
                                    }}
                                  >
                                    {key}
                                  </div>
                                  <br />
                                  <div
                                    className="grid-content-value"
                                    style={{
                                      color: 'var(--col-6, #414446)',
                                    }}
                                  >
                                    {value}
                                  </div>
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              {index < DataSourceAiAPI.length - 1 && <hr />}
            </React.Fragment>
          ))}
      </Grid>
    </div>
  );
};

export default DataSourceAI;
