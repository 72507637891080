import { Box, Typography } from "@mui/material";
import React from "react";
import LoaderGif from "../../../assets/60-X-60_Loader.gif";

const Loader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        padding: "1rem",
        justifyContent: "center",
      }}
    >
   
      <img src={LoaderGif} alt='loading' />

      <Typography
        sx={{
          color: "#852598",
          fontFamily: "Roboto",
          fontSize: "0.9rem",
          textTransform: "capitalize",
        }}
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default Loader;
