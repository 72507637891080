import { Button, ListItem } from '@mui/material';
import React, { forwardRef } from 'react';

const AppSubMenuItemComponent = ({ link, style, name, title, onClick, children, disabled }) => {
  if (!link || typeof link !== 'string') {
    return (
      <ListItem style={style} disablePadding onClick={() => onClick(name)}>
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      style={style}
      disablePadding
      component={forwardRef((props, ref) => (
        <Button {...props} ref={ref} onClick={() => onClick(name)} sx={{ color: '#852598' }} disabled={disabled} />
      ))}
      to={link}
    >
      {children}
    </ListItem>
  );
};

export default AppSubMenuItemComponent;
