import { Box, List } from "@mui/material";
import React from "react";
import AppMenuItem from "./AppMenuItem";

const AppMenu = ({
  appMenuItems,
  selectedMenu,
  setSelectedMenu,
  selectedIndex,
  setSelectedIndex,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <List
        component="nav"
        aria-labelledby="nested-list"
        sx={{
          width: "100%",
          // maxWidth: 280,
          // minWidth: 280,
          bgcolor: "background.paper",
          borderRadius: "0px 8px 8px 0px",
        }}
      >
        {appMenuItems.map((item, index) => (
          <AppMenuItem
            key={index}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            selectedIndex={selectedIndex} // Pass the selectedIndex
            setSelectedIndex={setSelectedIndex}
            open={selectedMenu === item.name}
            {...item}
          />
        ))}
      </List>
    </Box>
  );
};

export default AppMenu;
