import { Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { intigrationmodal1 } from '../../Language/DialogBoxLanguage';

const ReviewModal = ({ closeModal }) => {
  return (
    <>
      <div className="wrapper">
        <Card sx={{ my: 5, borderRadius: 6, padding: '6px', transform: 'translate(-50%,-50%)', position: 'absolute', top: '50%', left: '50%',width:{sm:'834px',xs:'100%'},height:{lg:'371px',xs:'300px'} }} >
          <Grid container>
            {/* <Grid item xs={12} onClick={closeModal} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon />
            </Grid> */}
            <Grid container sx={{ padding: {lg:'85px 160px 0px 160px',xs:'40px 75px 0px 75px'} }}>
              <Grid item xs={7}>
                <Typography
                  style={{
                    fontFamily: 'IBM Plex',
                    fontSize: '24px',
                    color: '#414446',
                  }}
                >
                  {intigrationmodal1.headlineText1}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    paddingTop: '18px',
                    fontSize: '14px',
                    color: '#414446',
                  }}
                >
                  {intigrationmodal1.textbody}
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ paddingLeft: '50px' }}>
                <img src={require('../../assets/60-X-60_Loader.gif')} alt="Loader" width={'133.999px'} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};
export default ReviewModal;
