import axios from 'axios';
import {
  POST_CREATE_CAMPAIGN_ID_API,
  GET_AI_API_WITH_KPI,
  GET_SEGMENT_AI_API,
  GET_SEGMENT_OFFER_API,
  GET_SUMMARY_SEGMENT_OFFER_API,
  GET_ABOUT_CAMPAIGN_API,
  GET_FINALIZE_AND_CONFIRM_API,
  GET_SEGMENT_OFFER_MULTIPLE_API,
  POST_SAVE_CAMPAIGN,
  GET_HISTORICAL_EVENT_DATA_CAMPAIGN,
} from '../common/config';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  var accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getCampaignSummary = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_CREATE_CAMPAIGN_ID_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const postSaveCampaign = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_SAVE_CAMPAIGN}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getCampaignSummary2 = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${GET_SUMMARY_SEGMENT_OFFER_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getDataSourceAI = async (parameter) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_HISTORICAL_EVENT_DATA_CAMPAIGN}?parameter=${parameter}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getSegmentsManual = async (parameter) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_HISTORICAL_EVENT_DATA_CAMPAIGN}?parameter=${parameter}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getSegmentsAI = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_SEGMENT_AI_API}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getSegmentOffersMultiple = async (campaign_pk, campaign_sk) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_SEGMENT_OFFER_API}?campaign_pk=${encodeURIComponent(campaign_pk)}&campaign_sk=${encodeURIComponent(campaign_sk)}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};
// getSegmentOffers;
export const getSegmentOffers = async (campaign_pk, campaign_sk) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_SEGMENT_OFFER_MULTIPLE_API}?campaign_pk=${encodeURIComponent(campaign_pk)}&campaign_sk=${encodeURIComponent(campaign_sk)}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getAboutCampaigns = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_ABOUT_CAMPAIGN_API}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};

export const getFinalizeAndConfirm = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${GET_FINALIZE_AND_CONFIRM_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};
