import axios from 'axios';
import { GET_HISTORICAL_EVENT_DATA, GET_IMPORTS, GET_TYPES_OF_UPLOAD, POST_DOCUMENT_UPDATE_WITH_SLECTED_CHANNELS, POST_FILE_TO_S3, POST_MAPPED_DOCUMENT } from '../common/config';
import { data } from 'jquery';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getTypesOfUpload = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: GET_TYPES_OF_UPLOAD,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const getImports = async (baseUrl, parameter) => {
  try {
    const response = await axios({
      method: 'get',
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        parameter,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

/* TODO: Need Help while uploading the data */
export const uploadFile = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: POST_FILE_TO_S3,
      headers: prepareHeaders().headers,
      data: payload,
    });

    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const mappedFields = async (documentId, mappedDocument) => {
  try {
    const response = await axios({
      method: 'post',
      url: POST_MAPPED_DOCUMENT,
      headers: prepareHeaders().headers,
      data: {
        document_id: documentId,
        mapped_document: mappedDocument,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

//aizbg0sdta.execute-api.us-east-1.amazonaws.com/v1/document_update_with_selected_channels

export const documentUpdateWithSelectedChannels = async (documentId, selectedChannles) => {
  try {
    const resposne = await axios({
      method: 'post',
      url: POST_DOCUMENT_UPDATE_WITH_SLECTED_CHANNELS,
      headers: prepareHeaders().headers,
      data: {
        document_id: documentId,
        selected_channels: selectedChannles,
      },
    });

    return resposne;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
  }
};
