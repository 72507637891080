import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import youtube from '../../../assets/Youtube.svg';
import { Campaignsmodal } from '../../../Language/DialogBoxLanguage';

import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
const CreateCampaigns = ({ closeModal }) => {
  const navigate = useNavigate();

  const StyledCheckbox = styled(Checkbox)(`
  color: #A35BB1;

  &.Mui-checked {
    color: #A35BB1;
  }
`);
  return (
    <>
      <Card sx={{ borderRadius: 2, padding: '6px' }} className="modal" style={{ width: '674px', position: 'relative', left: '50%', right: '50%', transform: 'translateX(-50%)' }}>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        
          </Grid>
          <Grid container sx={{ padding: '20px 66px ' }}>
            <Grid item xs={12} sx={{ paddingRight: '80px' }}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',
                  color: '#414446',
                }}
              >
                {Campaignsmodal.headlineText1}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '18px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
                {Campaignsmodal.subtitle1}
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  padding: '24px 0px',
                }}
              >
                <Typography style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      color: '#414446',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '173.7%',
                    }}
                  >
                    {Campaignsmodal.campainQtext}
                  </Typography>
                </Typography>
                <Link href=" https://www.synergi.ai/howto/create-campaigns" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '31px',
                    }}
                  >
                    <img src={youtube} alt="call icon" style={{ width: '20px' }} />
                    <Typography
                      style={{
                        paddingLeft: '15px',
                        color: '#A35BB1',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '151.188%',
                      }}
                    >
                      {Campaignsmodal.introtext}
                    </Typography>
                  </Typography>
                </Link>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                onClick={() => {
                  navigate('/create-campaign');
                }}
                sx={{
                  width: { lg: '137px' },
                  height: '48px',
                  marginTop: '20px',
                }}
              >
                {Campaignsmodal.campaignbtn}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '25px' }}>
              <FormControlLabel control={<StyledCheckbox />} label="Don't show this again" style={{ color: '#000' }} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default CreateCampaigns;
