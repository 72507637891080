import { Button, Card, Checkbox, FormControlLabel, Grid, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { clearAllCookies, clearItem, getItem } from '../../../../Utils/CommonFunction/CommonFunction';
import { SETTINGS_POST_BILLING_INFORMATION, SETTING_GET_COMPANY_DETAILS } from '../../../../../common/config';
import { profileModal, CommonLanguage } from '../../../../../Language/DialogBoxLanguage';

const BillingModal = ({ closeModal }) => {
  const [respStatus, setrespStatus] = useState();
  const [_isdisabled, set_isdisabled] = useState(false);

  const StyledCheckbox = styled(Checkbox)(`
  color: #A35BB1;

  &.Mui-checked {
    color: #A35BB1;
  }
`);
  const data = {
    first_name: '',
    last_name: '',
    company: '',
    website_url: '',
    office_phone: '',
    contact_address: '',
    contact_address_2: '',
    email_id_at_work: '',
    contact_country: '',
    contact_city: '',
    contact_zip_code: '',
    contact_state: '',
  };
  const navigate = useNavigate();
  const [inputData, setInputData] = useState(data);
  let accessToken = getItem('accessToken');

  const handleDataSubmit = (e) => {
    set_isdisabled(true);
    const headers = { Authorization: `Bearer ${accessToken}` };
    e.preventDefault();
    axios
      .post(SETTINGS_POST_BILLING_INFORMATION, inputData, { headers })
      .then((response) => {
        if (response?.status === 200) {
          setrespStatus(response);
          closeModal();
          set_isdisabled(false);
        }
      })
      .catch((e) => {
        if (e?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
          set_isdisabled(false);
        }
      });
  };

  useEffect(() => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    axios
      .get(SETTING_GET_COMPANY_DETAILS, { headers })
      .then((res) => {
        setInputData({
          ...inputData,
          email: res.data.billing_info.email,
          website_url: res.data.billing_info.website_url,
          first_name: res.data.billing_info.first_name,
          last_name: res.data.billing_info.last_name,
          office_phone: res.data.billing_info.office_phone,
          company: res.data.billing_info.company,
          contact_address: res.data.billing_info.contact_address,
          contact_address_2: res.data.billing_info.contact_address_2,
          email_id_at_work: res.data.billing_info.email_id_at_work,
          contact_country: res.data.billing_info.contact_country,
          contact_city: res.data.billing_info.contact_city,
          contact_zip_code: res.data.billing_info.contact_zip_code,
          contact_state: res.data.billing_info.contact_state,
        });
      })
      .catch((e) => {
        if (e?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  }, []);

  return (
    <>
      <div className="wrapper"></div>
      <Card
        sx={{ my: 5, borderRadius: 2, padding: '6px' }}
        style={{
          width: '834px',
          position: 'absolute',
          left: '299px',
          top: '10px',
          zIndex: '99',
          padding: '14px 34px 69px 49px',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            onClick={closeModal}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '18px',
              borderBottom: '1px solid #D9D6E3',
            }}
          >
            <Typography
              style={{
                color: '#414446',
                fontFamily: 'IBM Plex Serif',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '12px',
              }}
            >
              {profileModal.headlineText2}
            </Typography>
            <CloseIcon />
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.Firstname}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="first_name"
                value={inputData.first_name}
                onChange={(e) => setInputData({ ...inputData, first_name: e.target.value })}
              />
            </Grid>
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.Lastname}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Last Name"
                name="last_name"
                value={inputData.last_name}
                onChange={(e) => setInputData({ ...inputData, last_name: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.EmailId}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Email"
                name="email_id_at_work"
                value={inputData.email_id_at_work}
                onChange={(e) => setInputData({ ...inputData, email_id_at_work: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.CompanyOrganization}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="company"
                value={inputData.company}
                onChange={(e) => setInputData({ ...inputData, company: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.WebsiteURL}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="website_url"
                value={inputData.website_url}
                onChange={(e) => setInputData({ ...inputData, website_url: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.OfficePhone}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="office_phone"
                value={inputData.office_phone}
                onChange={(e) => setInputData({ ...inputData, office_phone: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.ConatctAddress}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Contact Address*"
                name="contact_address"
                value={inputData.contact_address}
                onChange={(e) => setInputData({ ...inputData, contact_address: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.ContactAddress2}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Contact Address 2"
                name="contact_address_2"
                value={inputData.contact_address_2}
                onChange={(e) => setInputData({ ...inputData, contact_address_2: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.ContactCountry}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="contact_country"
                value={inputData.contact_country}
                onChange={(e) => setInputData({ ...inputData, contact_country: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.ContactCity}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="contact_city"
                value={inputData.contact_city}
                onChange={(e) => setInputData({ ...inputData, contact_city: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.Contactzipcode}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="contact_zip_code"
                value={inputData.contact_zip_code}
                onChange={(e) => setInputData({ ...inputData, contact_zip_code: e.target.value })}
              />
            </Grid>
            <Grid xs={6} item style={{ paddingTop: '25px' }}>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {profileModal.ContactState}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                name="contact_state"
                value={inputData.contact_state}
                onChange={(e) => setInputData({ ...inputData, contact_state: e.target.value })}
              />
            </Grid>
            <Grid xs={12} item style={{ paddingTop: '25px' }}>
              <Typography
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '6px',
                }}
              >
                {CommonLanguage.AllField}
              </Typography>
            </Grid>
            <Grid xs={12} item style={{ paddingTop: '25px' }}>
              {' '}
              <FormControlLabel control={<StyledCheckbox />} label="Same billing information" style={{ color: '#000' }} />
            </Grid>
            <Grid xs={12} item style={{ paddingTop: '25px' }}>
              <Button
                onClick={handleDataSubmit}
                disabled={!!_isdisabled}
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                sx={{
                  width: {
                    lg: '118px',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                  },
                }}
              >
                {CommonLanguage.submitbtn}
              </Button>
              <Button
                type="submit"
                onClick={closeModal}
                fullWidth
                sx={{
                  width: '118px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  marginRight: '22px',
                  backgroundColor: 'transparent',
                  border: '1px solid #9C27B5',
                  color: '#9C27B5',
                  marginLeft: '29px',
                }}
              >
                {CommonLanguage.cancelbtn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default BillingModal;
