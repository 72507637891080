import { useState, useEffect } from 'react';
import { Typography, Button, Grid, IconButton, Paper, TextField, FormControl, Select, MenuItem } from '@mui/material';
import Loader from '../../../../assets/60-X-60_Loader.gif';
import { useDispatch } from 'react-redux';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { getSegmentConfig, getSegmentEditInfo, getSegmentSource, getSegmentSourceTotalRecords, reviewSegment } from '../../../../services/SegmentService';
import { segmentsActions } from '../../../../store/slices/segments';
import { useSelector } from 'react-redux';
import ErrorPage from '../../../../ErrorPage';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CommonLanguage, CustomSegment } from '../../../../Language/language';
import QueryBuilder from '../../query-builder/QueryBuider';
import { LoadingScreen6ForSegment } from '../../../../Language/DialogBoxLanguage';
// import { QueryBuilder } from 'react-querybuilder';
import { useParams } from 'react-router-dom';

const CustomCreateSegment = ({ setIsPageType }) => {
  const location = useLocation();
  const locationstateData = location?.state?.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisplayTotalDataSet, setIsDisplayTotalDataSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFileMissingLoading, setIsFileMissingLoading] = useState(false);
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const [isLoadingTargateData, setIsLoadingTargateData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const { create, segmentAction } = useSelector((state) => state.segments);
  const { setSegmentCreate, setSegmentAction, setCreateSegmentInputFields } = segmentsActions;
  const { sk, pk } = useParams();

  const [createLoading, setCreateLoading] = useState(false);

  const getEditInfo = async (type) => {
    setCreateLoading(true);
    const payload = {
      pk: `segment#${pk}`,
      sk: `segment#${sk}`,
      entity_type: 'segment',
      process_type: type === 'Edit' ? 'edit' : 'copy',
    };
    const responseData = await getSegmentEditInfo(payload);
    if (responseData.status === 200) {
      setCreateLoading(false);
      const newResponse = { ...responseData?.data };
      dispatch(setSegmentCreate({ response: newResponse }));
      // dispatch(setEditSegmentInfo({ response: segmentData }));
      dispatch(setSegmentAction(type));
      // navigate(`/edit-segment/${(segmentData.sk).split('#')[1]}/${(segmentData.pk).split('#')[1]}`);
    } else {
      setCreateLoading(false);
      seterrormsg(responseData?.response?.data?.error);
      setShowModal(true);
    }
  };
  useEffect(() => {
    const pathName = location.pathname;
    if (pathName?.includes('/edit-segment/')) {
      getEditInfo('Edit');
    } else if (pathName?.includes('/copy-segment/')) {
      getEditInfo('Copy');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSegmentConfigData = async () => {
    const payload = {
      source: create.selectedSegmentSource,
    };
    const responseData = await getSegmentConfig(payload);
    if (responseData.status === 200) {
      const name = 'segmentConfig';
      const value = create.segmentConfig ? { ...responseData.data, ...create.segmentConfig } : responseData.data;
      dispatch(setCreateSegmentInputFields({ name, value }));
    } else {
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (create.segmentSourceList.length === 0) {
      (async () => {
        setCreateLoading(true);
        const responseData = await getSegmentSource();
        if (responseData.status === 200) {
          const name = 'segmentSourceList';
          const value = responseData.data;
          dispatch(setCreateSegmentInputFields({ name, value }));
          setCreateLoading(false);
        } else {
          setCreateLoading(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  useEffect(() => {
    if (create.selectedSegmentSource !== 'segmentSource') getSegmentConfigData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create.selectedSegmentSource]);

  const handleSegmentNameChange = (event) => {
    const { name, value } = event.target;
    dispatch(setCreateSegmentInputFields({ name, value }));
  };

  const handleSegmentSourceChange = (event) => {
    const name = 'selectedSegmentSource';
    const value = event.target.value;
    setIsLoading(true);
    dispatch(setCreateSegmentInputFields({ name, value }));
  };

  const getProcessType = () => {
    let action = 'create';
    if (segmentAction === 'Add') {
      action = 'create';
    }
    if (segmentAction === 'Copy') {
      action = 'copy';
    }
    if (segmentAction === 'Edit') {
      action = 'edit';
    }
    return action;
  };

  const handleReviewSegment = async (event) => {
    event.preventDefault();
    setIsLoadingReview(true);
    const processType = getProcessType();
    const payload = {
      json_where_condition: create.json_where_condition,
      where_condition: create.where_condition,
      sql_where_condition: create.sql_where_condition,
      source: create.selectedSegmentSource,
      entity_type: 'segment',
      name: create.name,
      description: create.description,
      total_records: create.totalRecords,
      process_type: processType,
      pk: `segment#${pk}`,
      sk: `segment#${sk}`,
    };
    const responseData = await reviewSegment(payload);
    if (responseData.status === 200) {
      const newResponse = {
        ...responseData.data,
        segmentSourceList: create.segmentSourceList,
        selectedSegmentSource: create.selectedSegmentSource,
        segmentConfig: create.segmentConfig,
        totalRecords: responseData.data?.total_record,
      };
      dispatch(setSegmentCreate({ response: newResponse }));
      navigate(`/review-segment/${(responseData?.data?.sk).split('#')[1]}/${(responseData?.data?.pk).split('#')[1]}`, {
        state: {
          data: {
            ...locationstateData,
          },
        },
      });
    } else {
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
      // window.alert('error while review segment data');
    }
    setIsLoadingReview(false);
  };

  const handleGetTargetData = async (event) => {
    setIsDisplayTotalDataSet(true);
    event.preventDefault();
    setIsLoadingTargateData(true);
    const payload = {
      where_condition: create.where_condition,
      sql_where_condition: create.sql_where_condition,
      source: create.selectedSegmentSource,
      entity_type: 'segment',
    };
    const responseData = await getSegmentSourceTotalRecords(payload);
    if (responseData.status === 200) {
      const name = 'totalRecords';
      const value = responseData.data;
      dispatch(setCreateSegmentInputFields({ name, value }));
      setIsLoadingTargateData(false);
      setIsDisplayTotalDataSet(true);
    } else {
      setShowModal(true);
      setIsLoadingTargateData(false);
      seterrormsg(responseData?.response?.data?.error);
      // window.alert('error while geting Segment source total records');
    }
    setIsLoadingTargateData(false);
  };

  const handleBackToCreateAICrafted = () => {
    navigate('/segments');
  };
  const toTitleCase = (str) => {
    return str
      ?.split('_') // Split the string at each underscore
      ?.map((word) => word.toUpperCase()) // Capitalize the first letter of each word
      ?.join(' '); // Join the words back together with spaces
  };
  // const readableExp = QbUtils.queryString(tree, config, true);
  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          // height: '100%',
          borderRadius: '6px',
          marginTop: '15px',
        }}
      >
        {isFileMissingLoading ? (
          <Grid container justifyContent="center" alignItems="center" alignContent="center" style={{ height: '100%' }}>
            <LoadingScreen gifType={6} loadingScreen6={LoadingScreen6ForSegment} />
          </Grid>
        ) : (
          <Grid container sx={{ p: 2 }} spacing={2}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    sx={{
                      color: '#414446',
                      fontFamily: 'IBM Plex Serif',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '40px',
                    }}
                  >
                    {CustomSegment.CustomSegment}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton>
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography
                  sx={{
                    color: 'var(--Col-9, #8E0000)',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '151.188%',
                  }}
                >
                  {CommonLanguage.fieldrequiredText}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography
                  sx={{
                    color: 'var(--Col-8, #000)',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {CustomSegment.headlineText1}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                      <TextField
                        id="segmentName"
                        disabled={segmentAction === 'Add' || segmentAction === 'Copy' ? false : true}
                        sx={{ opacity: segmentAction === 'Add' || segmentAction === 'Copy' ? 1 : 0.5 }}
                        type="text"
                        fullWidth
                        name="name"
                        size="medium"
                        value={create.name}
                        placeholder="Enter Segment name"
                        onChange={handleSegmentNameChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                      <TextField
                        id="description"
                        name="description"
                        type="text"
                        fullWidth
                        size="medium"
                        multiline
                        rows={4}
                        value={create.description}
                        placeholder="Breif description"
                        onChange={handleSegmentNameChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Typography
                        sx={{
                          color: 'var(--Col-8, #000)',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {CustomSegment.headlineText2}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Typography
                        sx={{
                          color: 'var(--col-6, #5D596C)',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '173.7%',
                          textTransform: 'capitalize',
                        }}
                      >
                        {CustomSegment.subTitle1}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <FormControl fullWidth size="small">
                        <Select value={create.selectedSegmentSource} onChange={handleSegmentSourceChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem value="segmentSource" disabled={true}>
                            Select Segment source
                          </MenuItem>
                          {Array.isArray(create.segmentSourceList) &&
                            create.segmentSourceList.length > 0 &&
                            create.segmentSourceList.map((source) => <MenuItem value={source}>{`${toTitleCase(source)}`}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={6} sm={6} lg={6}>
                      <FormControl fullWidth size="small">
                        <Select value={create.selectedOfferSource} onChange={handleSegmentSourceChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem value="segmentSource" disabled={true}>
                            Select segment source
                          </MenuItem>
                          {Array.isArray(create.segmentSourceList) &&
                            create.segmentSourceList.length > 0 &&
                            create.segmentSourceList.map((source) => <MenuItem value={source}>{source}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={12} md={6} sm={6} lg={6}></Grid>

                    {isLoading ? (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <img src={Loader} alt="Loading..." />
                      </Grid>
                    ) : (
                      create.selectedSegmentSource !== 'segmentSource' && (
                        <>
                          <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Typography
                              sx={{
                                color: 'var(--Col-8, #000)',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              Define Rules
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Typography
                              sx={{
                                color: 'var(--col-6, #5D596C)',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '173.7%',
                                textTransform: 'capitalize',
                              }}
                            >
                              Specify conditions based on your selected criteria
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={6} sm={8} lg={6}>
                            <QueryBuilder
                              queryConfig={create?.segmentConfig}
                              json_where_condition={create?.json_where_condition}
                              setCreateInputFields={setCreateSegmentInputFields}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} sm={4} lg={6}></Grid>
                        </>
                      )
                    )}

                    {isLoadingTargateData ? (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <img src={Loader} alt="Loading..." />
                      </Grid>
                    ) : (
                      <>
                        {' '}
                        {isDisplayTotalDataSet && (
                          <>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                              <Typography
                                sx={{
                                  color: 'var(--Col-8, #000)',
                                  fontFamily: 'Roboto',
                                  fontSize: '18px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {CustomSegment.headlineText3}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={12} sm={12} lg={12}>
                              <Typography
                                sx={{
                                  color: '#414446',
                                  fontFamily: 'IBM Plex Serif',
                                  fontSize: '24px',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {create.totalRecords}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {/* <Grid item xs={12} md={12} sm={12} lg={12} sx={{ ml: 2 }}>
                      {subtractionError === true ? <span style={{ color: 'red' }}>{`${'Value'} ${splitArray[2] ? splitArray[2] : ''} < ${'minimum 0'}`}</span> : ''}
                    </Grid> */}
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!create?.sql_where_condition}
                        sx={{
                          color: '#ffffff',
                          fontFamily: 'Roboto',
                          fontSize: '1rem',
                          fontWeight: 'Bold',
                          textAlign: 'center',
                          textTransform: 'capitalize',
                          width: '10rem',
                          height: '48px',
                          marginTop: { sm: '0px', xs: '15px' },
                          borderRadius: '6px',
                          backgroundColor: '#852598',
                          ':hover': {
                            backgroundColor: '#852598',
                          },
                          ml: 2,
                        }}
                        onClick={handleGetTargetData}
                      >
                        {CustomSegment.targetbtn}
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!create?.sql_where_condition}
                        sx={{
                          color: '#ffffff',
                          fontFamily: 'Roboto',
                          fontSize: '1rem',
                          fontWeight: 'Bold',
                          textAlign: 'center',
                          textTransform: 'capitalize',
                          width: { lg: '10rem', sm: '7rem' },
                          marginTop: { sm: '0px', xs: '15px' },
                          height: '48px',
                          borderRadius: '6px',
                          backgroundColor: '#852598',
                          ':hover': {
                            backgroundColor: '#852598',
                          },
                          ml: 2,
                        }}
                        // disabled={isLoadingReview}
                        onClick={handleReviewSegment}
                      >
                        {CustomSegment.reviewbtn}
                      </Button>

                      {isLoadingReview && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '32px',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100vh',
                            zIndex: '99',
                          }}
                        >
                          <LoadingScreen gifType={4} />
                        </div>
                      )}

                      <Button
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        sx={{
                          textAlign: 'center',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                          width: { lg: '10rem', sm: '7rem' },
                          height: '48px',
                          marginTop: { sm: '0px', xs: '15px' },
                          borderRadius: '6px',
                          ml: 2,
                        }}
                        onClick={handleBackToCreateAICrafted}
                      >
                        {CommonLanguage.canclebtn}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
              {/* {sourceModal && <SegmentSourceError closeSource={closeSource} />} */}
            </Grid>
          </Grid>
        )}
      </Paper>

      {!!createLoading && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '32px',
          }}
        >
          <LoadingScreen gifType={4} />
        </div>
      )}
    </>
  );
};

export default CustomCreateSegment;
