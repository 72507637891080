import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';

const LeadQuilityAlert = () => {
  return (
    <div>
      <Box>
        <Grid container sx={{ alignItems: 'center', display: 'flex', padding: '30px 27px 0px 27px' }}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../../../../assets/bulb icon.png')} alt="" />
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingLeft: '14px',
              }}
            >
              SYNERGI Insights
            </Typography>
          </Grid>
          <Box sx={{ width: '100%', margin: '13px 0px' }}>{/* <LinearProgress  />  */}</Box>

          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#414446',
                textOverflow: 'ellipsis',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
              }}
            >
              Lead Quality Alert
            </Typography>

            <Typography
              sx={{
                color: ' #414446',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '18px',

                marginTop: '18px',
              }}
            >
              The current analysis of your lead funnel reveals a potential shortfall in the upcoming sales cycle. Your sales are predicted to be $20 million short of the target due
              to poor quality of leads in the pipeline.
            </Typography>
            <Typography
              sx={{
                color: '#414446',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '6px',
              }}
            >
              How can I improve the lead quality?
            </Typography>
            <Typography
              sx={{
                color: '#A35BB1',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginTop: '9px',
              }}
            >
              Adjust Your Lead Strategy Now
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LeadQuilityAlert;
