import { Box } from '@mui/material';
import React, { useState } from 'react';
import TableRow from './TableRow';

const DataTable = ({ exportFiles }) => {
  const [sortedField, setSortedField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState(exportFiles);

  const handleSort = (field) => {
    let order = 'asc';
    if (sortedField === field && sortOrder === 'asc') {
      order = 'desc';
    }

    const sorted = [...exportFiles].sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      // Handle null or undefined values
      if (valueA == null) return order === 'asc' ? -1 : 1;
      if (valueB == null) return order === 'asc' ? 1 : -1;

      // Handle different data types
      if (typeof valueA === 'string' || valueA instanceof Date) {
        return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        // Default to string comparison if types are not handled
        return order === 'asc' ? valueA.toString().localeCompare(valueB.toString()) : valueB.toString().localeCompare(valueA.toString());
      }
    });

    setSortedData(sorted);
    setSortedField(field);
    setSortOrder(order);
  };

  return (
    <Box sx={{ padding: '1rem' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          paddingBottom: '1rem',
          borderBottom: '1px solid #ebe8f1',
          marginBottom: '1rem',
          fontWeight: 'Bold',
        }}
      >
        <Box sx={{ width: '30%', cursor: 'pointer' }} onClick={() => handleSort('campaign_name')}>
          Type of Export {sortedField === 'campaign_name' && (sortOrder === 'asc' ? '↑' : '↓')}
        </Box>
        <Box sx={{ width: '20%', cursor: 'pointer' }} onClick={() => handleSort('created_datetime')}>
          Creation Date {sortedField === 'created_datetime' && (sortOrder === 'asc' ? '↑' : '↓')}
        </Box>
        <Box sx={{ width: '20%', cursor: 'pointer' }} onClick={() => handleSort('channel_name')}>
          Channel {sortedField === 'channel_name' && (sortOrder === 'asc' ? '↑' : '↓')}
        </Box>
        <Box sx={{ width: '20%', cursor: 'pointer' }} onClick={() => handleSort('total_records')}>
          Number of Records {sortedField === 'total_records' && (sortOrder === 'asc' ? '↑' : '↓')}
        </Box>
        <Box sx={{ width: '10%', textAlign: 'center' }}></Box>
      </Box>
      <div>
        {sortedData.map((fileData) => (
          <TableRow key={fileData.id} fileData={fileData} />
        ))}
      </div>
    </Box>
  );
};

export default DataTable;
