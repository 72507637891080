import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAllCookies, clearItem, getCookie, getItem } from '../../view/Utils/CommonFunction/CommonFunction';
import { ALL_PAGE_API } from '../../common/config';

const SubHeader = ({ title }) => {
  let accessToken = getItem('accessToken');
  const location = useLocation();
  const pathname = location.pathname;
  const [headerData, setHeaderData] = useState({});
  const navigate = useNavigate();
  const allpagekpi = localStorage?.getItem('all+_page_kpi');

  useEffect(() => {
    if (!allpagekpi) {
      const fetchData = async () => {
        try {
          const response = await axios.get(ALL_PAGE_API, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const dataObj = {};
          response.data.KPI.forEach((item) => {
            dataObj[item.name] = item.value;
          });
          localStorage?.setItem('all_page_kpi', true);
          setHeaderData(dataObj);
        } catch (error) {
          if (error?.response?.status == 401) {
            window.location.reload();
            navigate('/login');
            clearAllCookies();
            clearItem();
            if ('caches' in window) {
              caches.keys().then((names) => {
                names.forEach((name) => {
                  caches.delete(name);
                });
              });

              // Makes sure the page reloads. Changes are only visible after you refresh.
              window.location.reload(true);
            }
          }
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    const checkAndHandleLogout = () => {
      if (!accessToken) {
        // If cookies are not valid, log the user out
        navigate('/login');
        clearAllCookies();
        clearItem();
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true);
        }
      }
    };

    // Check for cookie validity every 5 seconds (adjust as needed)
    const intervalId = setInterval(checkAndHandleLogout, 5000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid container>
      {/* <Grid item xs={10} sm={2.5} md={2.5} lg={2.1}>
        <IconDropdown onMenuClick={onMenuItemClick} options={MenuOperations} keyField="name" />
      </Grid> */}
      <Grid
        item
        xs={12}
        //  sm={8.6} md={8.6} lg={9.6}
      >
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid
            item
            xs={12}
            lg={7}
            md={5}
            sm={4}
            xxl={5}
            className="headerText"
            style={{
              paddingLeft: '24px',
              color: 'var(--c-ol-1, #3E004A)',
              fontSize: '26px',
            }}
          >
            {title}
          </Grid>
          {/* <Grid item xs={12} md={7} lg={5} xxl={7} sm={8}>
            {pathname === '/setting' ? (
              ''
            ) : (
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  {Object.entries(headerData).map(([key, value]) => (
                    <Grid item xs={12 / Object.keys(headerData).length} key={key}>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <div className="grid-content-key" style={{ fontSize: '14px' }}>
                          {key}
                        </div>
                        <br />
                        <div className="grid-content-value">{value}</div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubHeader;
