import { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardContent, Typography, CardActions, Button, Grid, Box, styled, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import TruncatedDescription from '../../../../ui-components/truncateTooltip/TruncateTooltip';
import { initialCustomeCreateSegment, segmentsActions } from '../../../../store/slices/segments';
import { createAICraftedForSegment, getAICraftedListForSegment, getSegmentSource } from '../../../../services/SegmentService';
import { CustomLeftArrow, CustomRightArrow } from '../../../Utils/icons/icons';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import ErrorPage from '../../../../ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonLanguage, createsegment } from '../../../../Language/language';
import SegmentSourceError from '../SegmentSourceError';

const { setAICraftedList, setSegmentCreate, setCreateSegmentInputFields, setSegmentAction } = segmentsActions;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '200px',
  cursor: 'pointer',
  textAlign: 'center',
}));

const AICraftedSegment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sk } = useParams();
  const [sourceModal, setsourceModal] = useState(false);
  const closeSource = () => setsourceModal(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAICraftedCreateLoading, setIsAICraftedCreateLoading] = useState(false);
  const [isFileMissingLoading, setIsFileMissingLoading] = useState(false);
  const { aICraftedClickedItems, segmentAICrafetdList } = useSelector((state) => state.segments);
  const scrollContainer = useRef(null);
  const scrollAmount = 380;
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [btnLoading, setbtnLoading] = useState(false);

  const getAICraftedList = async () => {
    setIsLoading(true);
    const responseData = await getAICraftedListForSegment();
    if (responseData.status === 200) {
      setIsLoading(false);
      dispatch(setSegmentCreate({ response: initialCustomeCreateSegment }));
      dispatch(setAICraftedList({ segmentAICrafetdList: responseData.data?.data }));
    } else {
      setIsLoading(false);
      window.alert('error while geting AI Crafted data');
    }
  };

  useEffect(() => {
    getAICraftedList();
  }, []);

  const scroll = (direction) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += scrollAmount * direction;
    }
  };

  const handleCreateAICrafted = async (sk, pk) => {
    setIsAICraftedCreateLoading(true);
    const payload = {
      event_id: sk,
    };
    const response = await createAICraftedForSegment(payload);
    if (response?.status === 200) {
      setIsAICraftedCreateLoading(false);
      dispatch(setSegmentCreate({ response: response?.data }));
      navigate(`/review-segment/${(response?.data?.sk).split('#')[1]}/${(response?.data?.pk).split('#')[1]}`);
      // setIsPageType(4);
    } else {
      setIsAICraftedCreateLoading(false);
      setShowModal(true);
      seterrormsg(response?.response?.data?.error);
    }
  };

  // const handleCreateCustomeSegment = () => {
  //   setIsPageType(3);
  // };

  const handleBackToSegmentList = () => {
    navigate('/segments');
  };

  const getSegmentSourceList = async () => {
    setIsAICraftedCreateLoading(true);
    setbtnLoading(true);
    const responseData = await getSegmentSource();
    if (responseData.status === 200) {
      dispatch(setSegmentAction('Add'));
      const name = 'segmentSourceList';
      const value = responseData.data;
      dispatch(setCreateSegmentInputFields({ name, value }));
      setbtnLoading(false);
      navigate(`/create-segment/custom`);
      // setIsPageType(3);
      setIsAICraftedCreateLoading(false);
    } else {
      setIsAICraftedCreateLoading(false);
      setbtnLoading(false);
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
    }
    if (responseData?.data?.length == 0) {
      setsourceModal(true);
      // window.alert('error while geting Segment source data');
    }
  };

  const isAICraftedLoading = isAICraftedCreateLoading || isFileMissingLoading;
  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          borderRadius: '6px',
          marginTop: '15px',
        }}
      >
        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  {createsegment.headlineText1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={7} lg={6}>
            <Typography
              sx={{
                color: 'var(--col-12, #414446)',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '173.7%',
              }}
            >
              {createsegment.subtitle1}
            </Typography>
          </Grid>
        </Grid>
        {isAICraftedLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontSize: '32px',
            }}
          >
            <LoadingScreen gifType={4} />
          </div>
        ) : (
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ mb: 3, mx: 2, maxWidth: '79vw' }}>
                <Box display="flex" sx={{ pt: 3, pl: 2 }}>
                  <Grid container>
                    <Grid item lg={12} style={{ marginBottom: '37PX' }}>
                      <Box sx={{ position: 'relative', width: '73vw' }}>
                        <div
                          style={{
                            display: 'flrx',
                            justifyContent:'space-between'
                          }}
                        >
                          <div>
                            <StyledBox>
                              <h2
                                variant="body1"
                                style={{
                                  fontWeight: '500',
                                  color: '#764F7D',
                                  borderBottom: '3px solid #764F7D',
                                  fontFamily: 'Roboto',
                                }}
                              >
                                AI-Crafted Segments
                              </h2>
                            </StyledBox>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: '20px',
                              marginBottom: '20px',
                            }}
                          >
                            <button
                              onClick={() => scroll(-1)}
                              style={{
                                background: 'white',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <CustomLeftArrow />
                            </button>

                            <button
                              onClick={() => scroll(1)}
                              style={{
                                background: 'white',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <CustomRightArrow />
                            </button>
                          </div>
                        </div>
                        <div
                          ref={scrollContainer}
                          style={{
                            display: 'flex',
                            width: 'auto',
                            overflowX: 'hidden',
                            gap: '15px',
                            whiteSpace: 'nowrap',
                            scrollBehavior: 'smooth',
                          }}
                        >
                          {isLoading ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                fontSize: '32px',
                              }}
                            >
                              <LoadingScreen gifType={5} />
                            </div>
                          ) : (
                            Array.isArray(segmentAICrafetdList) &&
                            segmentAICrafetdList.length > 0 &&
                            segmentAICrafetdList.map((item, index) => (
                              <Card
                                key={index}
                                style={{
                                  width: '280px',
                                  height: '257px',
                                  flexShrink: '0',
                                  borderRadius: '5px',
                                  border: '1px solid #EBE8F1',
                                  background: '#FFF',
                                  boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
                                  padding: '10px',
                                  position: 'relative',
                                }}
                              >
                                <CardHeader
                                  title={item.name}
                                  sx={{
                                    color: 'var(--Col-8, #000)',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '151.188%',
                                    textTransform: 'capitalize',
                                  }}
                                />
                                <div className="customHR" style={{ margin: 0, padding: 0 }}></div>
                                <CardContent style={{ paddingTop: '0px' }}>
                                  <TruncatedDescription description={item.description} />
                                </CardContent>
                                <CardActions>
                                  <Button
                                    sx={{
                                      color: 'var(--col-14, #A35BB1)',
                                      fontFamily: 'Roboto',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      textTransform: 'capitalize',
                                      position: 'absolute',
                                      bottom: '10px',
                                      left: '15px',
                                    }}
                                    disabled={aICraftedClickedItems[item.sk]}
                                    onClick={() => handleCreateAICrafted(item?.sk, item?.pk)}
                                  >
                                    Create
                                  </Button>
                                </CardActions>
                              </Card>
                            ))
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </Paper>

      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          // height: '269px',
          borderRadius: '6px',
        }}
      >
        <Grid container sx={{ padding: { lg: '34px 22px 54px 22px', xs: '15px 22px' } }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  {createsegment.headlineText2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={7} lg={6}>
            <Typography
              sx={{
                color: 'var(--col-12, #414446)',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '173.7%',
              }}
            >
              {createsegment.subtitle2}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: '#ffffff',
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 'Bold',
                textAlign: 'center',
                textTransform: 'capitalize',
                width: { lg: '15rem', sm: '10rem' },
                height: '48px',
                marginTop: { lg: '31px', xs: '0px' },
                borderRadius: '6px',
                backgroundColor: '#852598',
                ':hover': {
                  backgroundColor: '#852598',
                },
              }}
              onClick={getSegmentSourceList}
            >
              {btnLoading ? 'Loading...' : createsegment.createsegmentbtn}
            </Button>

            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                width: { lg: '10rem', sm: '7rem' },
                height: '48px',
                borderRadius: '6px',
                marginTop: { lg: '31px', xs: '0px' },
                marginLeft: '1vw',
              }}
              onClick={handleBackToSegmentList}
            >
              {CommonLanguage.canclebtn}
            </Button>
          </Grid>
          {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
          {sourceModal && <SegmentSourceError closeSource={closeSource} />}
        </Grid>
      </Paper>
    </>
  );
};

export default AICraftedSegment;
