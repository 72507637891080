import {
  dataSourceData as setDataSourceData,
  segmetsData as setSegmentsData,
  offersData as setOffersData,
  dateTimeGlobal as setDateTimeGlobal,
  spendingLimitGlobal as setSpendingLimitGlobal,
  batchDataGlobal as setBatchDataGlobal,
} from '../view/Campaigns/GlobalStore/index';

export const titleCase = (str, symbol) => {
  return (
    str
      // .toLowerCase()
      .split(symbol ?? ' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(symbol ?? ' ')
  );
};

export const resetCampaignData = () => {
  setDataSourceData({ AIData: [] });
  setSegmentsData({ manualData: [], AIData: [] });
  setOffersData([]);
  setDateTimeGlobal({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  });
  setSpendingLimitGlobal({
    noLimit: true,
    period: 'daily',
    min: '',
    max: '',
  });
  setBatchDataGlobal({
    batch_size: '',
    batch_type: 'daily',
  });
};

export const formattedDate = (date) => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formattedTime = (date) => {
  let hours = '' + date.getHours();
  let minutes = '' + date.getMinutes();

  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;

  return [hours, minutes].join(':');
};

export const initialDataTimeGlobal = {
  startDate: formattedDate(new Date()),
  startTime: formattedTime(new Date()),
  endDate: formattedDate(new Date()),
  endTime: formattedTime(new Date()),
  isEndDate: false,
};

export const initalSpendingLimit = {
  noLimit: true,
  period: 'daily',
  min: '',
  max: '',
}

export const initialBatchGlobal = {
  batch_size: '',
  batch_type: 'daily',
}
