import { makeVar } from '@apollo/client';
import { formattedDate, formattedTime, initalSpendingLimit, initialBatchGlobal, initialDataTimeGlobal } from '../../../common/constant';

export const campaignPageType = makeVar(1);

export const aboutCampaignData = makeVar({});
export const dataSourceData = makeVar({ AIData: [] });
export const segmetsData = makeVar({ manualData: [], AIData: [] });
export const offersData = makeVar([]);
export const snackBarMsg = makeVar([false]);
export const dateTimeGlobal = makeVar(initialDataTimeGlobal);
export const spendingLimitGlobal = makeVar(initalSpendingLimit);

export const batchDataGlobal = makeVar(initialBatchGlobal);
