import React, { useEffect, useState } from 'react';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { registerEmail } from '../../store/actions/login-actions';
import 'react-phone-number-input/style.css';

import AccountExistsDialog from './AccountExistsDialog';
import { poolData, setItem } from '../Utils/CommonFunction/CommonFunction';
import { CommonLanguage, RegistrationStep1Screen } from '../../Language/language';
import VerifySuccessDailog from '../Settings/Component/User/VerifySuccessDailog';

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-])|(\\([0-9]{2,3}\\)[ \\-])|([0-9]{2,4})[ \\-])?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const phoneRegExp = /^(\+\d{1,4})?([1-9]\d{6,14})$/;

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required!'),
  email: Yup.string()
    .required('Email is required!')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address'),
  phoneNumber: Yup.string()
    .required('Phone number is required!')
    .test('isValid', 'Phone number is not valid', (value) => {
      const cleanedPhoneNumber = value.replace(/\s/g, '');
      if (cleanedPhoneNumber.match(/^\+1\d{10}$/)) {
        return true;
      } else {
        return false;
      }
    }),
  companyName: Yup.string().required('Company Name is required!'),
  password: Yup.string()
    .required('Password is required!')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const userPool = new CognitoUserPool(poolData);

const Registration = ({ handleNext }) => {
  const [successuser, setsuccessuser] = useState(false);
  const closeModal = () => {
    setsuccessuser(false);
  };
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  // const [isCorrectUser, setIsCorrectUser] = useState(false);
  // const registrationDetails = useSelector((state) => state.login);
  // const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isAccountExistsDialog, setIsAccountExistsDialog] = useState(false);

  useEffect(
    // setting initial state
    () => () => {
      // eslint-disable-next-line no-use-before-define
      formik.resetForm({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
      });
    },
    []
  );

  // const handleIconClick = () => {
  //   setTooltipOpen(!isTooltipOpen);
  // };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
    },
    enableReinitialize: true,
    validationSchema,

    onSubmit: async (values, { setErrors }) => {
      const attributeList = [];
      const dataEmail = {
        Name: 'email',
        Value: values.email,
      };
      if (values.password !== values.confirmPassword) {
        setErrors({
          confirmPassword: 'Passwords do not match',
        });
        return;
      }

      const cleanedPhoneNumber = values.phoneNumber.replace(/\s/g, '');
      const dataPhoneNumber = {
        Name: 'phone_number',
        Value: cleanedPhoneNumber,
      };

      const dataFirstName = {
        Name: 'given_name',
        Value: values.firstName,
      };

      const dataLastName = {
        Name: 'family_name',
        Value: values.lastName,
      };

      const dataCompanyName = {
        Name: 'custom:company_name',
        Value: values.companyName,
      };

      dispatch(registerEmail(values));
      const attributeEmail = new CognitoUserAttribute(dataEmail);
      const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
      const attributeFirstName = new CognitoUserAttribute(dataFirstName);
      const attributeLastName = new CognitoUserAttribute(dataLastName);
      const attributeCompanyName = new CognitoUserAttribute(dataCompanyName);

      attributeList.push(attributeEmail);
      attributeList.push(attributePhoneNumber);
      attributeList.push(attributeFirstName);
      attributeList.push(attributeLastName);
      attributeList.push(attributeCompanyName);

      userPool.signUp(
        values.email,
        values.password,
        attributeList,
        null,

        (err, result) => {
          if (err) {
            setIsAccountExistsDialog(true);
            return;
          } else {
            setsuccessuser(true)
          }
          setItem('userEmail', values.email);
          setItem('userPassword', values.password);
          setItem('userSub', result.userSub);
          setItem('userFirstName', values.firstName);
          setItem('userLastName', values.lastName);
          handleNext();
        }
      );
    },
  });
  window.xyz1 = formik;

  return (
    <>
      {isAccountExistsDialog && <AccountExistsDialog open={isAccountExistsDialog} handleClose={() => setIsAccountExistsDialog(false)} />}
      {successuser && <VerifySuccessDailog closeModal={closeModal} />}
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary" sx={{ fontSize: { lg: 18, sm: 15, xs: 14, fontFamily: 'Roboto' } }}>
            {CommonLanguage.joinsynergi}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: '6px' }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
              color: '#132529',
              fontFamily: 'IBM Plex Serif',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            {RegistrationStep1Screen.headlineText1}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
              color: '#132529',
              fontFamily: 'IBM Plex Serif',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            {RegistrationStep1Screen.headlineText2}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: '15px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ paddingTop: '15px' }}>
                <TextField
                  id="firstName"
                  type="text"
                  fullWidth
                  size="medium"
                  placeholder="First Name"
                  {...formik.getFieldProps('firstName')}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingTop: '15px' }}>
                <TextField
                  id="lastName"
                  type="text"
                  fullWidth
                  size="medium"
                  placeholder="Last Name"
                  {...formik.getFieldProps('lastName')}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <TextField
                  id="email"
                  type="text"
                  fullWidth
                  size="medium"
                  placeholder="Email"
                  {...formik.getFieldProps('email')}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <TextField
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  size="medium"
                  placeholder="Password"
                  {...formik.getFieldProps('password')}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <TextField
                  id="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  size="medium"
                  placeholder="Confirm Password"
                  {...formik.getFieldProps('confirmPassword')}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="companyName"
                  type="text"
                  fullWidth
                  size="medium"
                  placeholder="Company Name"
                  {...formik.getFieldProps('companyName')}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <TextField
                  fullWidth
                  id="phoneNumber"
                  placeholder="+1 Enter phone number"
                  value={formik.values.phoneNumber}
                  onChange={(event) => {
                    let value = event.target.value;
                    if (value.length === 0 || !value.startsWith('+1')) {
                      value = '+1' + value;
                    }
                    formik.setFieldValue('phoneNumber', value);
                  }}
                  className="custom-phone-input"
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <Typography variant="body2" color="error">
                    {formik.errors.phoneNumber}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <Typography variant="body2" textAlign="start" sx={{ fontSize: '12px', fontFamily: 'Roboto' }}>
                  {CommonLanguage.fieldrequiredText}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <Typography variant="body2" textAlign="start" sx={{ fontSize: '12px', fontFamily: 'Roboto' }}>
                  {RegistrationStep1Screen.agreementText}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '15px' }}>
                <Grid container direction="row">
                  <Grid item>
                    <Button variant="contained" onClick={formik.handleSubmit} size="large" color="secondary">
                      {RegistrationStep1Screen.registrationbtnText}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default Registration;
