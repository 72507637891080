import { Box, Typography } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
const BarChart = ({ data, color, name, darkColor }) => {
  const colors = [color, darkColor];
  const series = [
    {
      data: data.map((val, index) => ({
        x: index === 0 ? 'Present' : 'Forecast',
        y: val,
        fillColor: index === 0 ? color : darkColor,
      })),
    },
  ];
  const options = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: colors, // Use the same color for all bars, or specify each color in the array
    plotOptions: {
      bar: {
        columnWidth: '60%',
        borderRadius: 8,
        borderRadiusApplication: 'end', // This applies the border radius to the top left and top right corners
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#000'],
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 50,
      dynamicAnimation: {
        speed: 50, // Adjust the speed as needed
      },
    },
    xaxis: {
      categories: ['Present', 'Forecast'],
      labels: {
        style: {
          colors: '#000',
          fontSize: '10px',
          fontWeight: 'bold',
        },
      },
      axisBorder: {
        show: true,
        color: '#000',
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <Box>
      <ReactApexChart options={options} dir="ltr" series={series} type="bar" height={350} />
      <Typography align="center">{name}</Typography>
    </Box>
  );
};

export default BarChart;
