import axios from "axios";
import { clearAllCookies, clearItem, getItem } from "../view/Utils/CommonFunction/CommonFunction";
import { GET_LENDING_OFFER_ANALYSIS_URL } from "../common/config";

const prepareHeaders = () => {
    const accessToken = getItem('accessToken');
    return {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

export const getlendingofferanylysisService = async (payload) => {
    try {
        const response = await axios({
            method: 'get',
            url: GET_LENDING_OFFER_ANALYSIS_URL,
            headers: prepareHeaders().headers,
            data: payload,
            params: payload,
        });
        return response;
    } catch (error) {
        if (error?.response?.status == 401) {
            window.location.reload()
            clearAllCookies();
            clearItem();
        }
        return error;
    }
};
