import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RemoveIcon from '@mui/icons-material/Remove';
import { Checkbox, FormControlLabel, Grid, Box, Popover } from '@mui/material';
import CustomButton from '../../../../Utils/CustomButton/CustomButton';
import { segmetsData as setSegmentsData } from '../../../GlobalStore';

const SegmentsManual = ({ segmentsManualAPI }) => {
  const segmetsData = useReactiveVar(setSegmentsData);
  const [checked, setChecked] = useState([]);

  const [popover, setPopover] = useState({
    open: false,
    anchorEl: null,
    content: '',
  });
  useEffect(() => {
    const newCheckedState = {};
    (segmetsData?.manualData || [])?.forEach((item) => {
      newCheckedState[item?.segment_sk] = true;
    });
    setChecked(newCheckedState);
  }, [segmetsData?.manualData]);

  const handleChange = (pk, name, sk) => {
    const isChecked = checked[sk];
    const updatedChecked = {
      ...checked,
      [sk]: !isChecked,
    };
    setChecked(updatedChecked);

    const currentSegmentsData = setSegmentsData();

    if (!isChecked) {
      setSegmentsData({
        ...currentSegmentsData,
        manualData: [
          ...currentSegmentsData?.manualData?.filter((item) => item.segment_sk !== sk),
          {
            name: name,
            segment_pk: pk,
            segment_sk: sk,
          },
        ],
      });
    } else {
      setSegmentsData({
        ...currentSegmentsData,
        manualData: currentSegmentsData?.manualData?.filter((item) => item.segment_sk !== sk),
      });
    }
  };

  const handlePopoverOpen = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    setPopover({ open: true, anchorEl: event.currentTarget, content });
  };

  const handlePopoverClose = () => {
    setPopover({ open: false, anchorEl: null, content: '' });
  };

  return (
    <>
      <Grid container spacing={2}>
        {segmentsManualAPI &&
          segmentsManualAPI.map((items, key) => (
            <React.Fragment key={items.pk}>
              <Grid item xs={8}>
                <FormControlLabel
                  control={<Checkbox checked={checked[items.sk] || false} onChange={() => handleChange(items.pk, items.name, items.sk)} name={items.sk} color="secondary" />}
                  label={
                    <Box display="flex" sx={{}}>
                      <div className="headerText" style={{ color: 'var(--col-14, #A35BB1)' }} onClick={(event) => handlePopoverOpen(event, items)}>
                        {items.name}
                      </div>
                      <Popover
                        disableRestoreFocus
                        open={popover.open}
                        anchorEl={popover.anchorEl}
                        onClose={(event, reason) => {
                          if (reason !== 'clickAway') {
                            handlePopoverClose();
                          }
                        }}
                        
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          style: {
                            border: '1px solid #D3BED7',
                            background: '#F5F2F5',
                            padding: '20px',
                            boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.08)',
                            width: '600px',
                          },
                        }}
                      >
                        <div
                          // ref={popoverRef}
                          style={{ display: 'grid', gap: '10px' }}
                        >
                          <div className="headerText" style={{ color: 'var(--col-14, #A35BB1)' }}>
                            {popover.content['name']}
                          </div>
                          <div className="normalText">{popover.content['description']}</div>
                          <div
                            className="normalText"
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {popover.content['created_datetime']} By {popover.content?.['created_by'] || 'Mr. Tester'}
                          </div>
                          <div className="customHR"></div>
                          <div className="headerText" style={{ color: '#5D596C' }}>
                            Conditions
                          </div>
                          <div className="normalText">{popover.content['where_condition']}</div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <CustomButton
                              onClick={handlePopoverClose}
                              buttonValue={'close'}
                              isDisabled={false}
                              style={{
                                background: '#fff',
                                color: '#852598',
                                border: '1px solid #852598',
                                width: '105px',
                                height: '42px',
                              }}
                            />
                          </div>
                        </div>
                      </Popover>
                    </Box>
                  }
                />
                <Box display="flex" sx={{ marginLeft: '30px', width: '75%' }}>
                  <div style={{ alignContent: 'center' }}>
                    <h2
                      style={{
                        color: '#a35bb1',
                        fontSize: '14px',
                        marginBottom: '8px',
                      }}
                    >
                      Total Records: {items.total_record}
                    </h2>
                    <div className="normalText">{items.description}</div>
                    <div
                      className="normalText"
                      style={{
                        color: 'var(--col-14, #5D596C)',
                        fontSize: '10px',
                        marginTop: '4px',
                      }}
                    >
                      {items.created_datetime} By {items?.['created_by'] || 'Mr. Tester'}
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <div>
                    {items?.overall_status === '+' ? (
                      <div className="predictivePKPI" style={{ display: 'flex' }}>
                        Predictive KPIs{' '}
                        <NorthEastIcon
                          style={{
                            fontSize: '12px',
                            marginLeft: '5px',
                            color: '#158C6F',
                          }}
                        />
                      </div>
                    ) : items?.overall_status === '-' ? (
                      <div className="predictiveNKPI" style={{ display: ' flex' }}>
                        Predictive KPIs{' '}
                        <SouthEastIcon
                          style={{
                            fontSize: '12px',
                            marginLeft: '5px',
                            color: '#8E0000',
                          }}
                        />
                      </div>
                    ) : (
                      <div className="predictiveNKPI" style={{ display: ' flex', color: '#414446' }}>
                        Predictive KPIs{' '}
                        <RemoveIcon
                          style={{
                            fontSize: '12px',
                            marginLeft: '5px',
                            color: '#414446',
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        width: '233px',
                        textAlign: 'left',
                        border: '1px solid #EBE8F1',
                        background: '#F6F5F8',
                        borderRadius: '5px',
                        padding: '5px',
                      }}
                    >
                      <Grid container>
                        {items?.kpi &&
                          Object.entries(items?.kpi).map(([key, value]) => (
                            <Grid item xs={12 / Object.keys(items.kpi).length}>
                              <Box>
                                <div
                                  className="grid-content-key"
                                  style={{
                                    color: 'var(--col-6, #5D596C)',
                                  }}
                                  key={key}
                                >
                                  {key}
                                </div>
                                <br />
                                <div
                                  className="grid-content-value"
                                  key={value}
                                  style={{
                                    color: 'var(--col-6, #414446)',
                                  }}
                                >
                                  {value}
                                </div>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </div>
                </Box>
              </Grid>
              {key !== segmentsManualAPI.length - 1 && (
                <Grid item xs={12}>
                  <div className="customHR" style={{ margin: '0px' }}></div>
                </Grid>
              )}
            </React.Fragment>
          ))}
      </Grid>
    </>
  );
};

export default SegmentsManual;
