import { Box, Button, Grid, LinearProgress, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import SynergiInsight from '../SynergiInsight/SynergiInsight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import numeral from 'numeral';
import { getCookie, getItem, setCookie, setItem } from '../../../Utils/CommonFunction/CommonFunction';
import { getCampaignsAnalysisService } from '../../../../services/GetCampaignsAnalysis';
import { useNavigate } from 'react-router-dom';

const Campaigns = () => {
  const navigate = useNavigate();
  const campaignAnalysisData = getItem('campaignAnalysisData');
  const campaignAnalysisCall = getCookie('campaignAnalysisData');
  const [selectedData, setSelectedData] = useState('1D');
  const [campaignAnalysis, setCampaignAnalysis] = useState(campaignAnalysisData ? campaignAnalysisData : []);
  const [loading, setloading] = useState(false);

  const ChangeDataButtonClick = (dataKey) => {
    setSelectedData(dataKey);
  };

  useEffect(() => {
    setloading(true);
    if (!campaignAnalysisCall) {
      (async () => {
        const response = await getCampaignsAnalysisService();
        if (response?.status == 200) {
          setloading(false);
          setCampaignAnalysis(response?.data?.result);
          setItem('campaignAnalysisData', response?.data?.result);
          setCookie('campaignAnalysisData', true, 1440);
        } else {
          setloading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!!campaignAnalysisCall) {
      setloading(false);
    }
  }, [campaignAnalysisCall]);
  const currentData = campaignAnalysis?.find((data) => data?.date_frequency === selectedData);

  return (
    <>
      {loading ? (
        <>
          <Box sx={{ backgroundColor: '#F6F5F8' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width="100%" height={118} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <div>
          <Grid container direction="column" sx={{ backgroundColor: '#F6F5F8' }}>
            <Grid item>
              <Grid container spacing={2} sx={{ padding: '20px 0px' }}>
                <Grid
                  item
                  xs={12}
                  sm={8.6}
                  md={8.6}
                  lg={12}
                  sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    padding: '0px!important',
                  }}
                >
                  <section>
                    <Grid
                      container
                      sx={{
                        borderBottom: '1px solid #EBE8F1',
                        padding: '11px 22px',
                      }}
                    >
                      <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '18px',
                            lineHeight: '40px',
                            color: '#000',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          Campaigns
                        </Typography>
                        <img src={require('../../../../assets/title icon dashboard.png')} alt="" width={16} style={{ marginLeft: '10px' }} />
                      </Grid>
                      {/* <Grid xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                        <MoreVertIcon />
                      </Grid> */}
                    </Grid>
                  </section>

                  <section
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '1rem',
                    }}
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={8} sx={{ padding: '0px 10px' }}>
                          <Table style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow className="tablerow">
                                <TableCell></TableCell>
                                {currentData?.items.map((channel, subIndex) => (
                                  <TableCell key={subIndex} align="center" className="title">
                                    {channel.campaign_name}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className="tablerow">
                                <TableCell className="leadingTableRow">engagement</TableCell>
                                {currentData?.items.map((channel, subIndex) => (
                                  <TableCell key={subIndex} align="center" className="tableData">
                                    {numeral(channel.engagement).format('0.0a')}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow className="tablerow">
                                <TableCell className="leadingTableRow">cost per leads</TableCell>
                                {currentData?.items.map((channel, subIndex) => (
                                  <TableCell key={subIndex} align="center" className="tableData">
                                    {numeral(channel.cost_per_leads).format('$0.0a')}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow className="tablerow">
                                <TableCell className="leadingTableRow">funded ($M)</TableCell>
                                {currentData?.items.map((channel, subIndex) => (
                                  <TableCell key={subIndex} align="center" className="tableData">
                                    {numeral(channel.funded).format('$0.0a')}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '14px',
                            }}
                          >
                            <button className={`box ${selectedData === '1D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1D')}>
                              1D
                            </button>
                            <button className={`box ${selectedData === '3D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3D')}>
                              3D
                            </button>
                            <button className={`box ${selectedData === '7D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('7D')}>
                              7D
                            </button>
                            <button className={`box ${selectedData === '1M' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1M')}>
                              1M
                            </button>
                            <button className={`box ${selectedData === '3M' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3M')}>
                              3M
                            </button>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <Grid container sx={{ alignItems: 'center', display: 'flex', padding: '30px 27px 0px 27px' }}>
                              <Grid item xs={6} sx={{ display: 'flex' }}>
                                <img src={require('../../../../assets/bulb icon.png')} alt="" />
                                <Typography
                                  sx={{
                                    color: '#000',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                    paddingLeft: '14px',
                                  }}
                                >
                                  SYNERGI Insights
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <img src={require('../../../../assets/Plus-icon.png')} />
                              </Grid>
                              <Grid sx={{ width: '80%', mr: 1, mt: '10px' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={90}
                                  sx={{
                                    height: 4,
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: '#E08029', // Color for the 80%
                                    },
                                    '& .MuiLinearProgress-dashed': {
                                      backgroundImage: 'none',
                                    },
                                    backgroundColor: '#D0CBC3', // Color for the remaining 20%
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    color: ' #414446',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '18px',
                                    padding: '17px 0px',
                                  }}
                                >
                                  Your campaigns are underperforming, potentially due to an ineffective channel mix and targeting misaligned segments. Consider leveraging Synergi's
                                  advanced analytics for optimal channel selection and precise segment targeting. CTA - "Activate AI Insights"
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          <Button
                            sx={{
                              color: '#A35BB1',
                              padding: '0px 27px 0px 27px',
                              fontFamily: 'Roboto',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => navigate('/campaigns')}
                          >
                            View Campaigns
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </section>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Campaigns;
