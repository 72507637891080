import React, { useEffect, useState } from 'react';
import { Utils as QbUtils, MuiConfig } from '@react-awesome-query-builder/mui';
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Logic from './Logic';

export const ExpressionReview = ({ expression, queryConfig }) => {
    const [currConfig, setCurrConfig] = useState(null);
    const [logic, setLogic] = useState(null);

    useEffect(() => {
        if (Object.keys(queryConfig).length !== 0) {
            const newConfig = {
                ...MuiConfig,
                fields: queryConfig,
            };
            const [immutableTree] = QbUtils._loadFromJsonLogic(expression, newConfig);
            const JSON = QbUtils.getTree(immutableTree, newConfig);
            setLogic(JSON);
            setCurrConfig(newConfig);
        }

    }, [queryConfig]);
    if (logic === null) {
        return null;
    }

    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={5}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ m: '5px' }}>
                            <Grid item xs={12}>
                                <Logic position="review" expression={logic} currConfig={currConfig} />
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
