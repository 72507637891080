import { Box, Button, Grid, Slider, Typography, useTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import BarChart from '../Component/BarChart';
import CachedIcon from '@mui/icons-material/Cached';

const PriceAnalysis = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const IsLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const ScoreData = [
    { title: 'Prepayment' },
    { title: 'Refinance' },
    { title: 'Purchase' },
    { title: 'HELOC' },
    { title: 'Prepayment' },
    { title: 'Refinance' },
    { title: 'Purchase' },
    { title: 'HELOC' },
  ];

  const initialSliderValues = Array(ScoreData.length).fill(50);
  const [sliderValues, setSliderValues] = useState(initialSliderValues);

  const handleSliderChange = (index, newValue) => {
    setSliderValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });
  };

  const handleResetClick = () => {
    setSliderValues(initialSliderValues);
  };
  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: '4px' }} direction={isMobile || isTablet || IsLarge ? 'column' : 'row'}>
        <Grid item xs={3} sm={3} md={5} lg={3} xl={3} sx={{
          marginRight: "2%"
        }}>
          <Box sx={{ border: '1px solid #A35BB1', borderRadius: '5px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #D3D3D3', padding: '17px 27px' }}>
              <Typography
                sx={{
                  color: '#000',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                Price Analysis Filters
              </Typography>

              <Button
                onClick={handleResetClick}
                sx={{ color: '#A35BB1', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', textTransform: 'capitalize' }}
              >
                <CachedIcon /> Reset all
              </Button>
            </Box>
            <Box sx={{ padding: '23px' }}>
              <Grid container>
                {ScoreData.map((ScoreData, index) => {
                  return (
                    <>
                      <Grid item xs={6} style={{ padding: '6px 15px' }}>
                        <Typography>{ScoreData.title}</Typography>
                        <Slider
                          value={sliderValues[index]}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          valueLabelPlacement="bottom"
                          sx={{ color: '#852598', height: '7px' }}
                          onChange={(event, newValue) => handleSliderChange(index, newValue)}
                        />
                        <Typography variant="body2" align="center">
                          {sliderValues[index]}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8.5} sm={8.5} md={6} lg={8.5} xl={8.5}>
          <BarChart dataColors='["#A35BB1", "#7F4D90", "#852598", "#7A4B84", "#DFBBE5", "--vz-info"]' />
        </Grid>
      </Grid>
    </div>
  );
};

export default PriceAnalysis;
