import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Popover, Grid, CircularProgress } from '@mui/material';

import { snackBarMsg as setSnackBarMsg } from '../../Campaigns/GlobalStore';

export const SummaryBox = ({ campaignSummaryData }) => {
  const snackBarMsg = useReactiveVar(setSnackBarMsg);

  const [selectedArray, setSelectedArray] = useState([]);
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  // const [campaignSummaryData, setCampaignSummaryData] = useState(null);
  const isObject = (value) => typeof value === 'object' && value !== null && !Array.isArray(value);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return value.length;
    }
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  const getKeyDisplayValue = (key) => {
    switch (key.toLowerCase()) {
      case 'ai':
        return 'AI Generated';
      case 'manual':
        return 'Manually Selected';
      default:
        return key;
    }
  };

  // useEffect(() => {
  //   if (CampaignSummary?.length > 0) {
  //     if (which === 1) {
  //       (async () => {
  //         const response = await getCampaignSummary(CampaignSummary);
  //         const currentCampaignData = setAboutCampaignData();
  //         setAboutCampaignData({
  //           ...currentCampaignData,
  //           campaign_pk: response?.data?.response?.campaign_pk,
  //           campaign_sk: response?.data?.response?.campaign_sk,
  //         });
  //         setLoading(false);
  //         if (
  //           "Campaign Name already exists" === response?.response?.data?.error
  //         ) {
  //           setPageType(1);
  //           setSnackBarMsg(true);
  //         }
  //         setCampaignSummaryData(response?.data?.response?.summary);
  //       })();
  //     } else {
  //       (async () => {
  //         const response = await getCampaignSummary2(CampaignSummary);
  //         setCampaignSummaryData(response?.data?.response?.summary);
  //         setLoading(false);
  //       })();
  //     }
  //   }
  // }, [CampaignSummary]);

  const handleArrayClick = (dict, event) => {
    setSelectedArray(dict[Object.keys(dict)[0]]);
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  return (
    <>
      {campaignSummaryData && (
        <div className="summaryBox">
          <h2 style={{ paddingTop: '20px', paddingLeft: '10px' }}>Campaign Summary</h2>
          <div className="customHR"></div>
          {campaignSummaryData ? (
            Object.entries(campaignSummaryData).map(([key, value]) => (
              <div style={{ margin: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h3>{key}</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3
                      onClick={(event) => isObject(value) && handleArrayClick(value, event)}
                      style={{
                        cursor: isObject(value) ? 'pointer' : 'default',
                        color: isObject(value) ? '#852598' : '#112333',
                        textDecoration: isObject(value) ? 'underline' : 'none',
                        fontWeight: '700',
                      }}
                    >
                      {isObject(value) ? getKeyDisplayValue(Object.keys(value)[0]) : renderValue(value)}
                    </h3>
                  </Grid>
                </Grid>
              </div>
            ))
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '129px',
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
          <Popover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                border: '1px solid #D3BED7',
                background: '#F5F2F5',
                padding: '20px',
                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            <div>
              {selectedArray.map((item, index) => (
                <div key={index} style={{ marginTop: index === 0 ? '0px' : '10px' }}>
                  {item}
                </div>
              ))}
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default SummaryBox;
