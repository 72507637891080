import React from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { Grid, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import MFADownloadApp from './assets/MFADownloadApp.svg';
import { useNavigate } from 'react-router-dom';
import { poolData, setCookie, setItem } from '../Utils/CommonFunction/CommonFunction';

const userPool = new CognitoUserPool(poolData);

const MFAScreen = ({ userEmail, userPassword }) => {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    code: Yup.string()
      .required('Code is required!')
      .matches(/^[0-9]{6}$/, 'Please enter a valid 6-digit code'),
  });
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: userEmail,
        Password: userPassword,
      });

      const cognitoUser = new CognitoUser({
        Username: userEmail,
        Pool: userPool,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result1) {
          cognitoUser.verifySoftwareToken(values.code, `${cognitoUser.getUsername()}Device`, {
            onSuccess: (result) => {
              const accessToken = result1.getAccessToken().getJwtToken();
              setCookie('accessToken', accessToken, 1440);
              setItem('accessToken', accessToken);
              navigate('/onboarding');
            },
            onFailure: (err) => {
              formik.setFieldError('code', err.message);
            },
            mfaCodeDeliveryDetails: {
              Destination: userEmail, 
            },
          });
        },
        onFailure: function (err) {
          console.error(err);
          alert('Login failed. Please check your username and password.');
        },
      });

      setSubmitting(false);
    },
  });
  return (
    <div>
      {' '}
      <Grid
        container
        spacing={2}
        sx={{
          pt: { xs: '10%', sm: '4%', md: '4%' },
          pl: '10%',
          pb: '3%',
        }}
      >
        <Card>
          <CardContent>
            <Grid
              container
              direction="column"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                pr: { lg: 10, md: 8, sm: 6, xs: 4 },
                pl: { lg: 10, md: 8, sm: 6, xs: 4 },
              }}
            >
              <Grid item xs={12} sx={{ mb: '5%', mt: '5%' }} style={{ paddingTop: '5px' }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { lg: 30, md: 25, sm: 20, xs: 18 },
                    fontFamily: 'IBM Plex Serif',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    color: '#132529',
                  }}
                >
                  Set up Authenticator App MFA
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  pr: { lg: 10, md: 8, sm: 6, xs: 4 },
                  pl: { lg: 10, md: 8, sm: 6, xs: 4 },
                  width: '40%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { lg: 18, md: 15, sm: 15, xs: 15 },
                    fontFamily: 'IBM Plex Serif',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    color: '#132529',
                    width: '50%',
                  }}
                >
                  Install an authenticator app on your mobile device.
                </Typography>
                <img src={MFADownloadApp} alt="MFADownloadApp" />
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  pr: { lg: 10, md: 8, sm: 6, xs: 4 },
                  pl: { lg: 10, md: 8, sm: 6, xs: 4 },
                  mt: '5%',
                  width: '40%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { lg: 18, md: 15, sm: 15, xs: 15 },
                    fontFamily: 'IBM Plex Serif',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    color: '#132529',
                    width: '50%',
                  }}
                >
                  Scan this QR code with your authenticator app. Alternatively, you can manually enter a secret key in your authenticator app..
                </Typography>
              </Grid>
              <Grid>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} sx={{ m: '3%' }}>
                      <TextField
                        id="code"
                        type="text"
                        fullWidth
                        size="medium"
                        sx={{
                          paddingRight: { xs: '30px', md: '0px' },
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '45vh',
                            lg: '45vh',
                          }, 
                        }}
                        placeholder="MFA code"
                        {...formik.getFieldProps('code')}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                      />
                    </Grid>
                    <Grid item sx={{ mt: { xs: '2%', sm: '3%', lg: '4%' } }}>
                      <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: { lg: '45vh', md: '25vh' } }}>
                        Sign In
                      </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: '3%', mb: '117px' }}>
                      <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 14, lg: 14 } }}>
                        <a href="\login">Back</a>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default MFAScreen;
