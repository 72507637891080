import React, { useEffect, useState } from 'react';
import { Builder, Query, Utils as QbUtils, MuiConfig } from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';

import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const InitialConfig = MuiConfig;

const settings = { ...InitialConfig.settings };
// hide not operator
if (settings.notLabel === 'Not') {
  settings.showNot = false;
}

InitialConfig.settings = settings;

const getConfig = (queryConfig) => {
  const config = {
    ...InitialConfig,
    fields: queryConfig
  };
  return config;
};

const queryValue = { id: QbUtils.uuid(), type: 'group' };

const QueryBuilder = ({ queryConfig, json_where_condition, setCreateInputFields }) => {
  const dispatch = useDispatch();
  const [tree, setTree] = useState(null);
  const config = useMemo(() => getConfig(queryConfig), [queryConfig]);

  useEffect(() => {
    if (json_where_condition) {
      const [immutableTree] = QbUtils._loadFromJsonLogic(json_where_condition, config);
      setTree(immutableTree);
    } else {
      setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    const elements = document.querySelectorAll('.group.group-or-rule');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.style.backgroundColor = 'white';
      });
    } 

    const visibleButton = document.querySelectorAll('.group--actions.group--actions--tr');
    if (visibleButton.length > 0) {
      visibleButton.forEach((element) => {
        element.style.display = 'contents';
      });
    }
  }, [tree]);

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  const onChange = (immutableTree, config) => {
    setTree(immutableTree);
    const expression = QbUtils.spelFormat(immutableTree, config);
    const jsonLogicExpression = QbUtils.jsonLogicFormat(immutableTree, config);
    const sqlExpression = QbUtils.sqlFormat(immutableTree, config);
    dispatch(setCreateInputFields({ name: 'where_condition', value: expression }));
    dispatch(setCreateInputFields({ name: 'json_where_condition', value: jsonLogicExpression.logic }));
    dispatch(setCreateInputFields({ name: 'sql_where_condition', value: sqlExpression }));
  };

  if (tree === null) {
    return null;
  }

  return <Query {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />;
};

export default QueryBuilder;
