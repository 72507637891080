import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  samplefile: {},
  // forget: {},
};

const samplefileSlice = createSlice({
  name: 'samplefile',
  initialState,
  reducers: {
    setsamplefile(state, action) {
      state.samplefile = action.payload;
    },
  },
});

export const samplefileAction = samplefileSlice.actions;
export const samplefileReducer = samplefileSlice.reducer;
