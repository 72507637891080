import { GET_CUSTOMER_INFORMATION_LOADING, GET_CUSTOMER_INFORMATION__ERROR, GET_CUSTOMER_INFORMATION__SUCCESS, RESET_GET_CUSTOMER_INFORMATION_ } from "../Constant";

let initialState = {
    getcustomerinformation: {
        loading: false,
        data: null,
        error: false,
        errors: null,
        success: false,
        pagination: {},
    },
};

export default function getcustomerinformationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER_INFORMATION_LOADING:
            return {
                ...state,
                getcustomerinformation: {
                    ...state.getcustomerinformation,
                    loading: true,
                    error: false,
                    errors: [],
                    success: false,
                },
            };

        case GET_CUSTOMER_INFORMATION__SUCCESS:
            return {
                ...state,
                getcustomerinformation: {
                    ...state.getcustomerinformation,
                    loading: false,
                    error: false,
                    errors: [],
                    success: true,
                    data: action.response.data,
                },
            };

        case GET_CUSTOMER_INFORMATION__ERROR:
            return {
                ...state,
                getcustomerinformation: {
                    ...state.getcustomerinformation,
                    loading: false,
                    error: true,
                    errors: action.errors,
                    success: false,
                    data: null,
                },
            };

        case RESET_GET_CUSTOMER_INFORMATION_:
            return {
                ...state,
                getcustomerinformation: {
                    ...state.getcustomerinformation,
                    loading: false,
                    error: false,
                    errors: null,
                    success: false,
                },
            };

        default:
            return state;
    }
}