import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DeskTopLayout from './DeskTopLayout';
import MobileLayout from './MobileLayout';

const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/segments' && location.state?.scrollToChannel) {
      const channelComponent = document.getElementById('channelComponent');
      if (channelComponent) {
        channelComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>{isMobile ? <MobileLayout /> : <DeskTopLayout />}</>;
};

export default Layout;
