import { Card, Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Success from '../../assets/Success.png';
import { intigrationmodal2 } from '../../Language/DialogBoxLanguage';
import { useDispatch } from 'react-redux';

const ReviewContentModal = ({ closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>

      <div className="wrapper">
        
        <Card sx={{ my: 5, borderRadius: 6, padding: '6px', transform: 'translate(-50%,-50%)', position: 'absolute', top: '50%', left: '50%',width:{sm:'834px',xs:'100%'},height:{lg:'371px',xs:'300px'}}}>
          <Grid container>
            {/* <Grid item xs={12} onClick={closeModal} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon />
            </Grid> */}
            <Grid container sx={{ padding: {lg:'85px 160px 0px 160px',xs:'40px 75px 0px 75px'} }}>
              <Grid item xs={7}>
                <Typography
                  style={{
                    fontFamily: 'IBM Plex',
                    fontSize: '24px',
                    color: '#414446',
                  }}
                >
                  {intigrationmodal2.headlineText1}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    paddingTop: '18px',
                    fontSize: '14px',
                    color: '#414446',
                  }}
                >
                  {intigrationmodal2.textbody}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  textAlign="center"
                  sx={{ width: { lg: '250px' }, margin: '40px 0px 29px 0px' }}
                  onClick={() => {
                    navigate('/onboarding');
                  }}
                >
                  {intigrationmodal2.processbtn}
                </Button>
              </Grid>
              <Grid item xs={5} sx={{ paddingLeft: '50px', paddingTop: '30px' }}>
                <img src={Success} alt="Loader" />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};
export default ReviewContentModal;
