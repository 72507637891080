import React from 'react';
import EmptyDashboardimg from '../../../assets/dashboard.jpg';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EmptyDashboardScreen, dashboard } from '../../../Language/language';

const EmptyDashboard = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/imports');
  };
  
  return (
    <div>
      <Grid container sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: '#414446',
              fontFamily: 'IBM Plex Serif',
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              padding: '40px 0px',
            }}
          >
            {EmptyDashboardScreen.headlineText1}
          </Typography>
          <Box>
            <img src={EmptyDashboardimg} alt="EmptyDashboard" style={{ maxWidth: '100%', height: 'auto', width: '100%!important' }} />
          </Box>

          <Grid item xs={12} sx={{ paddingTop: '37px' }}>
            <Typography
              sx={{
                color: '#414446',
                fontFamily: 'IBM Plex Serif',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                padding: '10px 0px',
              }}
            >
              {EmptyDashboardScreen.headlineText2}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{
                width: {
                  xs: '56px',
                  fontSize: '16px',
                  textTransform: 'capitalize',
                },
                height: '48px',
                marginBottom: '40px',
              }}
              onClick={handleButtonClick}
            >
              {dashboard.reportingbtn}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyDashboard;
