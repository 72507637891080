import React from 'react';
import { Box, Button, Card, Dialog, Grid, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import errorimg from './../../../assets/SegmentError.svg';
import { useNavigate } from 'react-router-dom';

const SegmentSourceError = ({ closeModal, errormsg }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card sx={{ my: 5, borderRadius: 6, padding: '6px' }} className="modal">
        <Grid container>
          <Grid xs={12} onClick={closeModal} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon />
          </Grid>
          <Grid container sx={{ padding: '80px 155px 0px 144px ' }}>
            <Grid xs={8} item>
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'IBM Plex Serif',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '40px',
                }}
              >
                File Not Found
              </Typography>
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '173.7%',
                }}
              >
                To create offers seamlessly, we require Data.
              </Typography>
              <Button
              type="submit"
              variant="outlined"
              style={{
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Noto Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                width: '241px',
                height: '48px',
                borderRadius: '6px',
                background: '#852598',
                border: '1px solid var(--col-12, #852598)',
                marginTop:"54px",
                marginBottom:"90px"
              }}
              onClick={() => navigate("/Imports")}
            >
              Go to upload File
            </Button>
            </Grid>
            <Grid xs={4} item sx={{ justifyContent: 'end', display: 'flex' }}>
              <img
                src={errorimg}
                style={{
                  width: '121.6px',
                  height: '132px',
                  flexShrink: '0',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default SegmentSourceError;
