import { Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState, useEffect } from 'react';
import { CustomcontactAction, getcustomerinfoAction } from '../../../../ReduxCmp/UserAction/Useraction';
import { connect, useDispatch, useSelector } from 'react-redux';
import Modal from './Modal';
import { RESET_IS_CUSTOM_INTIGRATION_CONTACT } from '../../../../ReduxCmp/Constant';
import { DataInCard2 } from '../../../../Language/language';

const ContactInfo = (props) => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const getcustomerinformationReducer = useSelector((state) => state.getcustomerinformationReducer?.getcustomerinformation);
  const [state, setstate] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    pk: props.customerdataintigrationpostReducer?.data?.pk,
    sk: props.customerdataintigrationpostReducer?.data?.sk,
  });
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setstate((preve) => ({
      ...preve,
      [name]: value,
    }));
  };

  const handlesubmit = () => {
    props.CustomcontactAction(state);
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getcustomerinfoAction());
  }, []);

  useEffect(() => {
    setLoading(true);
    if (getcustomerinformationReducer?.success) {
      setstate((preve) => ({
        ...preve,
        first_name: getcustomerinformationReducer?.data?.first_name,
        last_name: getcustomerinformationReducer?.data?.last_name,
        phone_number: getcustomerinformationReducer?.data?.phone_number,
        email: getcustomerinformationReducer?.data?.email,
      }));
      setLoading(false);
    }
  }, [getcustomerinformationReducer]);

  return (
    <div>
      <Grid container spacing={1} sx={{ padding: '25px' }}>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              paddingBottom: '8px',
            }}
          >
            {DataInCard2.headlineText1}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            value={state?.first_name}
            name="first_name"
            fullWidth
            size="medium"
            onChange={handleChange}
            placeholder="Auto-filled from registration firstname"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField type="text" value={state?.last_name} name="last_name" fullWidth size="medium" onChange={handleChange} placeholder="Auto-filled from registration lastname" />
        </Grid>
        <Grid item xs={6}>
          <TextField type="text" name="last_name" fullWidth size="medium" onChange={handleChange} placeholder="Auto-filled from registration lastname" />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <TextField type="tel" name="phone" value={state?.phone_number} fullWidth size="medium" onChange={handleChange} placeholder="Auto-filled from registration phone" />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <TextField type="email" name="email" value={state?.email} fullWidth size="medium" onChange={handleChange} placeholder="Auto-filled from registration email" />
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
          textAlign="center"
          sx={{ width: { lg: '250px' }, margin: '40px 0px 29px 0px' }}
          onClick={handlesubmit}
        >
          {DataInCard2.databtn}
        </Button>
        {showModal && <Modal closeModal={closeModal} />}
      </Grid>
    </div>
  );
};

const mapStateToProp = (state) => ({
  CustomcontactReducer: state.CustomcontactReducer.CustomcontactReducer,
  customerdataintigrationpostReducer: state.customerdataintigrationpostReducer.customerdataintigrationpost,
});

const mapDispatchToProps = (dispatch) => ({
  CustomcontactAction: (details) => dispatch(CustomcontactAction(details)),
  Resetiscustomeinticontact: (details) => dispatch({ type: RESET_IS_CUSTOM_INTIGRATION_CONTACT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(ContactInfo);
