import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import UsesCampaign from "./UsesCampaign";
import Data from "./Data";
import AppAnalytics from "../Components/AppAnalytics";
import File from "./File";

const   ImportedFiles = ({ importedFiles }) => {
  return (
    <Table id="import_lists">
      <TableBody>
        {importedFiles.map((importedFile) => (
          <TableRow sx={{ height: "100%" }}>
            <TableCell
              sx={{
                verticalAlign: "top",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <File
                name={importedFile.name}
                description={importedFile.description}
                serverFileName={importedFile.server_file_name}
                // createdBy={importedFile.createdBy ?? ""}
                createdAt={importedFile.updated_datetime}
                status={importedFile.active_flag}
              />
            </TableCell>
            <TableCell
              sx={{
                verticalAlign: "top",
                padding: "1rem 0",
                width: "8rem",
              }}
            >
              <UsesCampaign totalCampaign={importedFile.total_run_campaigns} />
            </TableCell>
            <TableCell
              sx={{
                verticalAlign: "top",
                padding: "1rem 0",
                width: "8rem",
              }}
            >
              <Data value={importedFile.total_records} />
            </TableCell>
            <TableCell
              sx={{
                verticalAlign: "top",
                padding: "1rem 0",
                width: "16rem",
              }}
            >
              <AppAnalytics analytics={importedFile.kpi} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ImportedFiles;
