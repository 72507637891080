import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { logout, CommonLanguage } from '../../Language/language';
import SettingsIcon from '../../assets/Setting.svg';
import Overview from '../../assets/Overview.svg';
import Profile from '../../assets/Profile.svg';
import Logout from '../../assets/Logout.svg';
import { useNavigate } from 'react-router-dom';
import { clearAllCookies, clearItem } from '../../view/Utils/CommonFunction/CommonFunction';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerComponent from '../mainHeader/DrawerComponent';
const MobileHeader = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogout = () => {
    setDialogOpen(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleProfileClick = () => {
    navigate('/setting/profile', {
      state: {
        profile: 'profile',
      },
    });
    handleMenuClose();
  };

  const handleNotificationsClick = () => {
    navigate('/setting/overview');
    handleMenuClose();
  };
  const handleLogoutConfirmed = async () => {
    setDialogOpen(false);

    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_CLIENT_ID,
    });

    const currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.signOut();
      navigate('/login');
      clearAllCookies();
      clearItem();
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
    }
  };

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ p: 1, backgroundColor: { lg: '#FFF', md: '#FFF', xs: '#fff' } }}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item className="LeftSide" xs={2}>
              <Button sx={{ color: '#3E004A' }} onClick={toggleDrawer(true)}>
                <DrawerComponent />
              </Button>
            </Grid>

            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  navigate('/onboarding');
                }}
              >
                <img
                  alt="header"
                  style={{
                    marginLeft: '1.7vh',
                    width: '135px',
                  }}
                  src="https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/image-255@2x.png"
                />
              </Button>
            </Grid>

            <Grid item xs={2}>
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Grid item sx={{ marginRight: '30px' }}>
                    <IconButton onClick={handleMenuOpen} color="secondary">
                      <img src={SettingsIcon} alt="Setting" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Dialog open={dialogOpen} onClose={closeDialog}>
              <DialogTitle>{logout.headlineText1}</DialogTitle>
              <DialogContent>
                <DialogContentText>{logout.subTitle1}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} color="secondary">
                  {CommonLanguage.canclebtn}
                </Button>
                <Button onClick={handleLogoutConfirmed} color="secondary">
                  {CommonLanguage.logoutbtn}
                </Button>
              </DialogActions>
            </Dialog>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleNotificationsClick} style={{ marginBottom: '8px' }}>
                <ListItemIcon>
                  <img src={Overview} alt="overview" />
                </ListItemIcon>
                <Typography variant="inherit">{CommonLanguage.Overview}</Typography>
              </MenuItem>
              <MenuItem onClick={handleProfileClick} style={{ marginBottom: '8px' }}>
                <ListItemIcon>
                  <img src={Profile} alt="Profile" />
                </ListItemIcon>
                <Typography variant="inherit">{CommonLanguage.Profile}</Typography>
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <img src={Logout} alt="logout" />
                </ListItemIcon>
                <Typography variant="inherit">{CommonLanguage.LogOut}</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileHeader;
