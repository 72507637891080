import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { CustomcontactAction, getcustomerinfoAction, CustomSetupActionClear } from '../../ReduxCmp/UserAction/Useraction';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReviewDataIntigration from './ReviewDataIntigration';
import { Box } from '@mui/system';
import ReviewContentModal from './ReviewContentModal';
import { reviseintigration, CommonLanguage } from '../../Language/language';
import { RESET_IS_CUSTOM_INTIGRATION_CONTACT } from '../../ReduxCmp/Constant';
import Loader from '../integration/Components/Loader';
import ErrorPage from '../../ErrorPage';

const Revisecontactinfo = (props) => {
  const dispatch = useDispatch();
  const [showModalError, setshowModalError] = useState(false);
  const closeModalError = () => {
    setshowModalError(false);
  };
  const [errormsg, seterrormsg] = useState();
  const [Loading, setLoading] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const getcustomerinformationReducer = useSelector((state) => state.getcustomerinformationReducer?.getcustomerinformation);
  const [state, setstate] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    pk: props.CustomsetupReducer?.data?.pk,
    sk: props.CustomsetupReducer?.data?.sk,
  });
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setstate((preve) => ({
      ...preve,
      [name]: value,
    }));
  };

  const handlesubmit = () => {
    setbtnLoading(true);
    props.CustomcontactAction(state);
    props.CustomSetupActionClear();
  };

  useEffect(() => {
    if (props.CustomcontactReducer?.error) {
      seterrormsg(props.CustomcontactReducer?.errors);
      setshowModalError(true);
      setbtnLoading(false);
    }
    if (props.CustomcontactReducer?.success) {
      setbtnLoading(false);

      setShowModal(true);
      setTimeout(() => {
        props.resetrevisecontactinfo();
      }, 2500);
    }
  }, [props.CustomcontactReducer]);

  useEffect(() => {
    dispatch(getcustomerinfoAction());
  }, []);

  useEffect(() => {
    setLoading(true);
    if (getcustomerinformationReducer?.success) {
      setstate((preve) => ({
        ...preve,
        first_name: getcustomerinformationReducer?.data?.first_name,
        last_name: getcustomerinformationReducer?.data?.last_name,
        phone_number: getcustomerinformationReducer?.data?.phone_number,
        email: getcustomerinformationReducer?.data?.email,
        pk: props.CustomsetupReducer?.data?.pk,
        sk: props.CustomsetupReducer?.data?.sk,
      }));
      setLoading(false);
      setbtnLoading(false);
    }
  }, [getcustomerinformationReducer]);

  return (
    <div>
      {Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Grid container spacing={1} sx={{ bgcolor: 'background.paper', borderRadius: 2 }}>
            <Grid
              container
              sx={{
                borderBottom: '1px solid #EBE8F1',
                padding: { xl: '11px 22px', lg: '11px 22px', md: '11px 22px', sm: '0px 0px', xs: '11px 22px' },
              }}
              // style={{
              //   borderBottom: '1px solid #EBE8F1',
              //   padding: '11px 22px',
              // }}
            >
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontFamily: 'IBM Plex',
                    fontSize: '24px',
                    lineHeight: '40px',
                    color: '#414446',
                    padding: { md: '0px', xs: '10px' },
                  }}
                >
                  {reviseintigration.pagetitle}
                </Typography>
              </Grid>
              {/* <Grid xs={2}>icon</Grid> */}
            </Grid>

            <Box sx={{ padding: { xl: '20px 22px', lg: '20px 22px', md: '20px 22px', sm: '20px 22px', xs: '20px 22px' } }}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: '#414446',
                  paddingBottom: '15px',
                }}
              >
                {reviseintigration.headlineText1}
              </Typography>
              <Grid
                container
                sx={{
                  flexDirection: { md: 'row', xs: 'column-reverse' },
                }}
              >
                <Grid item sm={12} md={6} lg={8.2} xl={8.4}>
                  <section>
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        color: '#5D596C',
                        paddingBottom: '15px',
                      }}
                    >
                      {CommonLanguage.fieldrequiredText}
                    </Typography>
                  </section>
                  <Box
                    sx={{
                      paddingTop: '0px',
                      width: { xl: '679px', md: '100%' },

                      height: { xl: '282px', md: '300px' },
                      borderRadius: '5px',
                      border: '1px solid #EBE8F1',
                      padding: '25px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        paddingBottom: '24px',
                      }}
                    >
                      {reviseintigration.headlineText3}
                    </Typography>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            type="text"
                            name="first_name"
                            value={state?.first_name}
                            fullWidth
                            size="medium"
                            onChange={handleChange}
                            placeholder="Auto-filled from registration name"
                          />
                        </Grid>
                        <Grid item xs={6} sx={{ paddingLeft: '15px' }}>
                          <TextField
                            type="text"
                            name="last_name"
                            value={state?.last_name}
                            fullWidth
                            size="medium"
                            onChange={handleChange}
                            placeholder="Auto-filled from registration name"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: '16px' }}>
                      <TextField
                        type="tel"
                        value={state?.phone_number}
                        name="phone_number"
                        fullWidth
                        size="medium"
                        onChange={handleChange}
                        placeholder="Auto-filled from registration phone"
                      />
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: '16px' }}>
                      <TextField type="email" name="email" value={state?.email} fullWidth size="medium" onChange={handleChange} placeholder="Auto-filled from registration email" />
                    </Grid>
                  </Box>

                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    textAlign="center"
                    sx={{ width: '250px', margin: { lg: '40px 0px 29px 0px', xs: '25px 0px' } }}
                    onClick={handlesubmit}
                  >
                    {btnLoading ? 'Loading...' : reviseintigration.schedulebtn}
                  </Button>
                  {showModal && <ReviewContentModal closeModal={closeModal} />}
                </Grid>
                <Grid item sm={12} md={6} lg={3.8} xl={3.6} sx={{ marginBottom: '10px' }}>
                  <ReviewDataIntigration />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />} */}
          {showModalError && <ErrorPage closeModal={closeModalError} errormsg={errormsg} />}
        </>
      )}
    </div>
  );
};

const mapStateToProp = (state) => ({
  CustomcontactReducer: state.CustomcontactReducer.CustomcontactReducer,
  CustomsetupReducer: state.CustomsetupReducer.CustomsetupReducer,
});

const mapDispatchToProps = (dispatch) => ({
  CustomcontactAction: (details) => dispatch(CustomcontactAction(details)),
  resetrevisecontactinfo: () => dispatch({ type: RESET_IS_CUSTOM_INTIGRATION_CONTACT }),
  CustomSetupActionClear: () => dispatch(CustomSetupActionClear()),
});

export default connect(mapStateToProp, mapDispatchToProps)(Revisecontactinfo);
