import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import axios from 'axios';
import { getItem } from '../Utils/CommonFunction/CommonFunction';
import { CommonLanguage, RegistrationStep2Screen, HELPING_TEXT_LIST, PEOPLE_IN_TEAM_LIST } from '../../Language/language';
import { REGISTRATION_URL } from '../../common/config';

const RegistrationStep1 = ({ handleNext }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedOption, setSelectedOption] = useState('');

  // Define the areOptionsSelected variable here
  const areOptionsSelected = Object.keys(checkedItems).some((key) => checkedItems[key]) && selectedOption !== '';

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSubmit = async () => {
    try {
      // Read token from local storage
      const userSub = getItem('userSub');
      handleNext();

      // Prepare the payload
      const payload = {
        userSub: userSub,
        need_help: Object.keys(checkedItems).filter((key) => checkedItems[key]),
        team_size: selectedOption,
      };

      if (areOptionsSelected) {
        // Make the POST request
        const response = await axios.post(REGISTRATION_URL, payload);

        if (response.data.success) {
          //   navigate("/success-page");
        }
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary" sx={{ fontSize: { lg: 18, sm: 15, xs: 14 } }}>
          {CommonLanguage.joinsynergi}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: '2%' }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
            color: '#132529',
            fontFamily: 'IBM Plex Serif',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
          }}
        >
          {RegistrationStep2Screen.headlineText1}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
            color: '#132529',
            fontFamily: 'IBM Plex Serif',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
          }}
        >
          {RegistrationStep2Screen.headlineText2}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: '5%' }}>
        <Typography variant="h5" sx={{ fontSize: '18px' }}>
          {RegistrationStep2Screen.needhelpText}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: '2%' }}>
        <Grid container spacing={1}>
          {HELPING_TEXT_LIST.map((helpingText, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={checkedItems[helpingText] || false}
                    onChange={handleCheckboxChange}
                    name={helpingText}
                    style={{
                      color: checkedItems[helpingText] ? '#4CAF50' : 'inherit',
                    }}
                  />
                }
                label={helpingText}
                style={{
                  color: checkedItems[helpingText] ? '#4CAF50' : 'inherit',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: '5%' }}>
        <Typography variant="h5" sx={{ fontSize: { lg: 25, md: 25, sm: 20, xs: 18 } }}>
          {RegistrationStep2Screen.yourteamText}
        </Typography>
      </Grid>
      <Grid container item xs={12} sx={{ mt: '2%', mb: '5%' }}>
        {PEOPLE_IN_TEAM_LIST.map((people) => (
          <Grid item key={people}>
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  checked={selectedOption === people}
                  onChange={handleRadioChange}
                  value={people}
                  sx={{
                    color: selectedOption === people ? 'success.main' : 'default',
                    '&.Mui-checked': {
                      color: 'success.main',
                    },
                  }}
                />
              }
              label={people}
              sx={{
                color: selectedOption === people ? 'success.main' : 'inherit',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mt: '25px', mb: '10px', width: { sx: '50%', sm: '30%', md: '30%' } }}>
        <Button
          variant="contained"
          onClick={handleSubmit} //{() => handleNext()}
          color="secondary"
          fullWidth
          disabled={!areOptionsSelected}
        >
          {RegistrationStep2Screen.registrationStep2btnText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RegistrationStep1;
