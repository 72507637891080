import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AllCampaignsCard from './AllCampaignsCard/AllCampaignsCard';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import { postManageAllCampaign } from '../../../services/AllCampaign';
import { downloadCampaignFile } from '../../../services/Exports';
import { DOWNLOAD_CAMPAIGN_DATA_DOWNLOAD } from '../../../common/config';
import { LoadingButton } from '@mui/lab';

const CampaignPopUp = ({ open, handleClose, data }) => {
  const ordinalSuffix = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) return num + 'st';
    if (j === 2 && k !== 12) return num + 'nd';
    if (j === 3 && k !== 13) return num + 'rd';
    return num + 'th';
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return null;

    const date = new Date(dateStr);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${months[date.getMonth()]} ${ordinalSuffix(date.getDate())}, ${date.getFullYear()} ${hours}:${minutes} AM EST`;
  };

  const startDate = formatDate(data?.popup?.campaign_start_datetime) || 'no start date';
  const endDate = formatDate(data?.popup?.campaign_end_datetime) || 'no end date';
  const [channelRows, setChannelRows] = useState([]);
  const [campaignData, setCampaignData] = useState([]);

  const downloadMedia = (url) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };

  const [loading, setLoading] = useState(false);
  const downloadFile = async () => {
    setLoading(true);
    const response = await downloadCampaignFile(DOWNLOAD_CAMPAIGN_DATA_DOWNLOAD, data.name, data.pk);
    if (response.status === 200) {
      setLoading(false);
      downloadMedia(response.data.url);
    } else {
      setLoading(false);
    }
  };

  const handleSave = () => {
    (async () => {
      const raw = {
        pk: data.pk,
        sk: data.sk,
        change_records: campaignData,
      };

      const response = await postManageAllCampaign(raw);
    })();
    handleClose();
  };

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < data?.popup?.channel_segment_offers.length; i += 3) {
      temp.push(data?.popup?.channel_segment_offers.slice(i, i + 3));
    }
    setChannelRows(temp);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '1310px',
          padding: '20px',
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: '24px',
          fontFamily: 'IBM Plex Serif',
          lineHeight: '40px',
          color: '#414446',
        }}
      >
        {data?.name || 'Campaign Details'}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: '70%', display: 'grid', gap: '10px' }}>
          <h3>Marketing Channel: {data?.popup?.channels?.join(', ')}</h3>
          <h3>Objective: {data?.popup?.objective}</h3>
          <h3>Vendors: {data?.popup?.vendors?.join(', ')}</h3>
          <h3>Data Source: {data?.popup?.data_sources?.join(', ')}</h3>
        </div>

        <hr style={{ marginTop: '15px', marginBottom: '15px' }} />

        <div style={{ width: '70%', display: 'grid', gap: '10px' }}>
          <h1 style={{ fontFamily: 'Roboto', color: '#000' }}>Segments</h1>
          <h3>{data?.popup?.segments?.join(', ')}</h3>
        </div>

        <hr style={{ marginTop: '15px', marginBottom: '15px' }} />

        <div style={{ width: '70%', display: 'grid', gap: '10px' }}>
          <h1 style={{ fontFamily: 'Roboto', color: '#000' }}>Offers</h1>
          <div>
            <h3>Click on a channel to edit offers</h3>
            <h3>
              Start Date: {startDate} to {endDate}
            </h3>
          </div>
        </div>

        <div style={{ width: '70%', marginTop: '30px' }}>
          {channelRows.map((rowChannels, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
                marginBottom: '10px',
              }}
            >
              {rowChannels &&
                rowChannels.map((channelData, index) => (
                  <div style={{ marginLeft: index === 0 ? '0px' : '20px' }}>
                    <AllCampaignsCard key={channelData.channel} channelData={channelData} channel={channelData.channel} setCampaignData={setCampaignData} />
                  </div>
                ))}
            </div>
          ))}
        </div>

        {/* <CustomButton
          isDisabled={false}
          onClick={handleClose}
          buttonValue="Close"
          style={{
            marginLeft: "0",
            marginTop: "20px",
            background: "white",
            color: "#852598",
            border: "1px solid #852598",
          }}
        /> */}

        <CustomButton
          isDisabled={false}
          onClick={handleSave}
          buttonValue="Save"
          style={{
            marginLeft: '20px',
            marginTop: '20px',
          }}
        />
        {data?.popup.download_status === 'Ready For Download' ? (
          <>
            {' '}
            {loading ? (
              <LoadingButton
                color="secondary"
                loading
                loadingPosition="end"
                sx={{
                  marginTop: '20px',
                  width: '225px',
                  border: '2px solid #852598',
                  color: '#158C6F',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  height: '48px',
                  textTransform: 'capitalize',
                  color: 'white !important',
                  backgroundColor: '#852598 !important',
                }}
              >
                <span style={{ marginRight: '0.5rem', color: 'white' }}>Downloading...</span>
              </LoadingButton>
            ) : (
              <CustomButton
                isDisabled={false}
                onClick={downloadFile}
                buttonValue="Download Campaign Data"
                style={{
                  marginTop: '20px',
                  width: '225px',
                }}
              />
            )}
          </>
        ) : (
          <CustomButton
            isDisabled={true}
            buttonValue="Download Campaign Data"
            style={{
              marginTop: '20px',
              width: '225px',
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CampaignPopUp;
