import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import DataSourceAI from './DataSourceAI/DataSourceAI';
import DataSourceManual from './DataSourceManual/DataSourceManual';
import { dataSourceData as setDataSourceData } from '../../GlobalStore';

let manualInput = {
  statusCode: 200,
  body: [
    { channel: 'direct mail', Vendors: ['Jetson', 'UPSC'] },
    { channel: 'email', Vendors: ['Adobe', 'Hubspot', 'Mailchimp'] },
    {
      channel: 'paid search/ paid display',
      Vendors: ['Google Ads', 'Microsoft Bing'],
    },
    { channel: 'social media', Vendors: ['Facebook', 'Linkedin', 'Twitter'] },
    { channel: 'application management', Vendors: ['Encompass'] },
    {
      channel: 'behavioral triggers',
      Vendors: ['Jornaya', 'DialogTech', 'Invoca'],
    },
    { channel: 'call tracking', Vendors: ['Invoca', 'DialogTech'] },
    { channel: 'chat', Vendors: ['Livechat'] },
  ],
};

const DataSourceChannel = ({ DataSourceAiAPI }) => {
  const dataSourceData = useReactiveVar(setDataSourceData);

  const [selectedChannel, setChannel] = useState(dataSourceData?.channelType || 'Ai');

  const StyledBoxAI = styled(Box)(({ theme }) => ({
    marginLeft: '10px',
    width: '205px',
    cursor: 'pointer',
    textAlign: 'center',
    borderBottom: selectedChannel === 'Ai' ? '3px solid #764F7D' : '',
  }));


  useEffect(() => {
    setChannel(dataSourceData?.channelType || 'Ai');
    if (!dataSourceData?.channelType) {
      const currentDataSourceData = setDataSourceData();
      setDataSourceData({
        ...currentDataSourceData,
        channelType: 'Ai',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChannelSelection = (selection) => {
    setChannel(selection);
    const currentDataSourceData = setDataSourceData();
    setDataSourceData({
      ...currentDataSourceData,
      channelType: selection,
    });
  };


  const renderComponent = () => {
    if (selectedChannel === 'Ai') {
      return <DataSourceAI DataSourceAiAPI={DataSourceAiAPI} />;
    } else {
      return <DataSourceManual manualInput={manualInput} />;
    }
  };

  return (
    <Box
      sx={{
        border: '1px solid #EBE8F1',
        borderRadius: '5px',
        height: 'auto',
        display: 'contents',
      }}
    >
      <Box display="flex" alignItems="center">
        <StyledBoxAI
          p={2}
          onClick={() => {
            handleChannelSelection('Ai');
          }}
          sx={{
            cursor: 'text',
            borderBottom: 'none',
          }}
        >
          <h2
            variant="body1"
            style={{
              fontWeight: selectedChannel === 'Ai' ? '500' : '400',
              color: selectedChannel === 'Ai' ? '#764F7D' : 'var(--text-txt-primary, #112333)',

              fontFamily: 'Roboto',
            }}
          >
            AI-Predictive Allocation
          </h2>
        </StyledBoxAI>

        {/* <StyledBoxManual
          p={2}
          onClick={() => {
            handleChannelSelection("manual");
          }}
        >
          <h2
            variant="body1"
            style={{
              fontWeight: selectedChannel === "manual" ? "500" : "400",
              color:
                selectedChannel === "manual"
                  ? "#764F7D"
                  : "var(--text-txt-primary, #112333)",
              fontFamily: "Roboto",
            }}
          >
            Manual Allocation
          </h2>
        </StyledBoxManual> */}
      </Box>
      <div className="customHR"></div>
      <Box p={2} sx={{ overflow: 'auto', display: '-webkit-box' }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default DataSourceChannel;
