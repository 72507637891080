import React from 'react';
import PanelHead from './Component/PanelHead';
import Channel from './Component/Channel/Channel';
import Campaigns from './Component/Campaign/Campaign';
import { Box, Grid, Typography } from '@mui/material';
import clockIcon from '../../assets/clockIcon.png';

const Panel = () => {
  return (
    <div>
      <Grid container direction="column" sx={{ backgroundColor: '#F6F5F8', margin: 0 }}>
        <Grid item>
          <Grid container spacing={2} sx={{ padding: 0, margin: 0, width: '100%!important', paddingBottom: '20px' }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 2,
                padding: '0px!important',
              }}
            >
              <section>
                <Grid
                  container
                  sx={{
                    borderBottom: '1px solid #EBE8F1',
                    padding: '11px 22px',
                  }}
                >
                  <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        lineHeight: '40px',
                        color: '#000',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Funnel Metrics
                    </Typography>

                    <img src={require('../../assets/title icon dashboard.png')} alt="" width={16} style={{ marginLeft: '10px' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#414446',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        paddingLeft: '13px',
                      }}
                    >
                      Select funnel category to view more details
                    </Typography>
                  </Grid>
                  {/* <Grid xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}><MoreVertIcon sx={{
                    cursor: "pointer"
                  }} /></Grid> */}
                </Grid>
              </section>
              <section style={{ marginTop: '10px' }}>
                <PanelHead />
              </section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <section style={{ margin: '0px!important', paddingLeft: '16px' }}>
        <Channel />
      </section>

      <section style={{ margin: '0px!important', paddingLeft: '16px' }}>
        <Campaigns />
      </section>
      <section style={{ margin: '0px!important' }}>
        <div>
          <Grid container direction="column" sx={{ backgroundColor: '#F6F5F8' }}>
            <Grid item>
              <Grid container spacing={2} sx={{ padding: '20px 0px' }}>
                <Grid
                  item
                  xs={12}
                  sm={8.6}
                  md={8.6}
                  lg={12}
                  sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    padding: '0px!important',
                  }}
                >
                  <section>
                    <Grid
                      container
                      sx={{
                        borderBottom: '1px solid #EBE8F1',
                        padding: '11px 22px',
                      }}
                    >
                      <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '18px',
                            lineHeight: '40px',
                            color: '#000',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          Industry Comparisons
                        </Typography>
                        <img src={require('../../assets/title icon dashboard.png')} alt="" width={16} style={{ marginLeft: '10px' }} />
                      </Grid>
                    </Grid>
                  </section>

                  <section
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '1rem',
                    }}
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} sx={{ padding: '0px 10px' }}>
                          <div
                            style={{
                              // background: '#D9D9D9',
                              padding: '75px 0px',
                              margin: '25px',
                            }}
                          >
                            <div
                              style={{
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 10,
                                objectFit: 'contain',
                              }}
                            >
                              {/* <div
                                style={{
                                  background: '#3E004A',
                                  padding: '25px',
                                  borderRadius: '50%',
                                  width: '150px',
                                  height: '150px',
                                }}
                              ></div> */}
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '350px' }}>
                                <img style={{ objectFit: 'contain', height: '50px' }} src={clockIcon} alt="" />
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    padding: '15px',
                                    fontSize: '24px',
                                    width: '850px',
                                  }}
                                >
                                  Stay tuned
                                </div>
                              </div>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  padding: '15px',
                                  fontSize: '20px',
                                  width: '850px',
                                }}
                              >
                                Synergi will populate this section with insights as soon as more data becomes available.
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </section>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
};

export default Panel;
