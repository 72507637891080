import { Card, CardContent, useTheme } from "@mui/material";
import React from "react";

const LoginCard = ({ children }) => {
  const theme=useTheme();
  return (
    <Card
      sx={{
        my: 2,
        ml: 22,
        [theme.breakpoints.down("md")]: {
          ml: 0,
        },
        [theme.breakpoints.down("sm")]: {
          ml: 0,
        },
        borderRadius: 3,
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default LoginCard;
