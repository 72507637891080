export const IS_USER_UPLOADED_FILES_LOADING = 'IS_USER_UPLOADED_FILES_LOADING';
export const IS_USER_UPLOADED_FILES_SUCCESS = 'IS_USER_UPLOADED_FILES_SUCCESS';
export const IS_USER_UPLOADED_FILES_ERROR = 'IS_USER_UPLOADED_FILES_ERROR';
export const RESET_IS_USER_UPLOADED_FILES = 'RESET_IS_USER_UPLOADED_FILES';

export const IS_USER_HAVE_DASHBOARD_LOADING = 'IS_USER_HAVE_DASHBOARD_LOADING';
export const IS_USER_HAVE_DASHBOARD_SUCCESS = 'IS_USER_HAVE_DASHBOARD_SUCCESS';
export const IS_USER_HAVE_DASHBOARD_ERROR = 'IS_USER_HAVE_DASHBOARD_ERROR';
export const RESET_IS_USER_HAVE_DASHBOARD = 'RESET_IS_USER_HAVE_DASHBOARD';

export const CUSTOM_INTIGRATION_SETUP_LOADING = 'IS_CUSTOM_INTIGRATION_SETUP_LOADING';
export const IS_CUSTOM_INTIGRATION_SETUP_SUCCESS = 'IS_CUSTOM_INTIGRATION_SETUP_SUCCESS';
export const IS_CUSTOM_INTIGRATION_SETUP_ERROR = 'IS_CUSTOM_INTIGRATION_SETUP_ERROR';
export const RESET_IS_CUSTOM_INTIGRATION_SETUP = 'RESET_IS_CUSTOM_INTIGRATION_SETUP';

export const CUSTOM_INTIGRATION_CONTACT_LOADING = 'IS_CUSTOM_INTIGRATION_CONTACT_LOADING';
export const IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS = 'IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS';
export const IS_CUSTOM_INTIGRATION_CONTACT_ERROR = 'IS_CUSTOM_INTIGRATION_CONTACT_ERROR';
export const RESET_IS_CUSTOM_INTIGRATION_CONTACT = 'RESET_IS_CUSTOM_INTIGRATION_CONTACT';

export const GET_CUSTOMER_INFORMATION_LOADING = 'GET_CUSTOMER_INFORMATION_LOADING';
export const GET_CUSTOMER_INFORMATION__SUCCESS = 'GET_CUSTOMER_INFORMATION__SUCCESS';
export const GET_CUSTOMER_INFORMATION__ERROR = 'GET_CUSTOMER_INFORMATION__ERROR';
export const RESET_GET_CUSTOMER_INFORMATION_ = 'RESET_GET_CUSTOMER_INFORMATION_';

export const CUSTOMER_DATA_INTIGRATION_LOADING = 'CUSTOMER_DATA_INTIGRATION_LOADING';
export const CUSTOMER_DATA_INTIGRATION_SUCCESS = 'CUSTOMER_DATA_INTIGRATION_SUCCESS';
export const CUSTOMER_DATA_INTIGRATION_ERROR = 'CUSTOMER_DATA_INTIGRATION_ERROR';
export const RESET_CUSTOMER_DATA_INTIGRATION = 'RESET_CUSTOMER_DATA_INTIGRATION';
