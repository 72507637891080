import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import jsonData from './dashboard_data.json';
import BulletChart from './BulletChart';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModelChart from './ModelChart';
import PanelHead from './PanelHead';

const PanelBodytitle = ({ ChangeDataButtonClick, selectedData, activeButton, funnelMetricsAnalysis }) => {
  const [boxID, setboxID] = useState();
  const navigate = useNavigate();
  const PanelHeadData = funnelMetricsAnalysis[selectedData]?.map((item) => ({
    stage_name: item.stage_name,
    present: item.present,
    forecast: item.forecast,
    channel: item?.channel,
    size: item?.size,
    id: item?.id,
  }));
  return (
    <div>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '5px 0px' }}>
        {PanelHeadData?.map((data) => {
          return (
            <Grid
              key={data.id}
              item
              xs={2.2}
              style={{
                backgroundColor: '#ccc9c957',
                margin: '5px',
                padding: '10px',
                width: '100%',
              }}
            >
              {data?.channel?.map((panelItem, i) => {
                // calculation for value
                const pervalue = Math.min(panelItem?.forecast, panelItem?.present);
                const leftPercentage = ((pervalue - 0) / (panelItem?.size - 0)) * 150;
                // const darkvalue=(panelItem?.present)/(panelItem?.size-0)*(150-GreenPercentage);
                return (
                  <>
                    <Box
                      onClick={() => {
                        setboxID(data?.id);
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            marginX: '10px',
                          }}
                        >
                          {panelItem.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: ' IBM Plex Serif',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {panelItem?.present}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <BulletChart data={panelItem} size={panelItem.size} color={panelItem.color} dark_color={panelItem.dark_color} boxID={boxID} />
                        <Typography
                          sx={{
                            color: '#878787',
                            fontFamily: 'Roboto',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            left: leftPercentage,
                            position: 'absolute',
                            bottom: '0',
                          }}
                        >
                          {data?.id == boxID && panelItem.present}
                        </Typography>
                      </Box>
                    </Box>

                    {/* <Box className="hover-box">
                        <Typography>{panelItem?.hover_data?.title}</Typography>
                        <Typography>{panelItem?.hover_data?.values[0].title}</Typography>
                        <Typography>{panelItem?.hover_data?.values[0].value}</Typography> <Typography>{panelItem?.hover_data?.values[0].percent_growth}</Typography>{' '}
                        <Typography>{panelItem?.hover_data?.values[0].growth_type}</Typography> <Typography>{panelItem?.hover_data?.values[0].target}</Typography>
                      </Box> */}
                  </>
                );
              })}
            </Grid>
          );
        })}
      </Grid>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '20px',
        }}
      >
        <button className={`box ${activeButton === '1D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1D')}>
          1D
        </button>
        <button className={`box ${activeButton === '3D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3D')}>
          3D
        </button>
        <button className={`box ${activeButton === '7D' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('7D')}>
          7D
        </button>
        <button className={`box ${activeButton === '1M' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('1M')}>
          1M
        </button>
        <button className={`box ${activeButton === '3M' ? 'btnactive' : 'btnstyle'}`} onClick={() => ChangeDataButtonClick('3M')}>
          3M
        </button>
      </div>
    </div>
  );
};

export default PanelBodytitle;
