import { Button, Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommonLanguage } from '../../../src/Language/language';
import '../../index.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserNotCapableModal = ({ closeModal, errormsg, closingFlag }) => {
  useEffect(() => {
    document.body.classList.add('disable-scroll');
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="modal-overlay">
        <Card sx={{ borderRadius: 2, overflow: 'auto' }}>
          <Grid container>
            <Grid item xs={12} onClick={() => navigate('/registration')} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon />
            </Grid>
            <Grid container sx={{ padding: '50px' }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '40px',
                    marginBottom: '8px',
                  }}
                >
                  User Not Registered
                </Typography>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '173.7%',
                  }}
                >
                  Please Sign Up And Democratize Your Mortgage Experience
                </Typography>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'Noto Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    width: '20vh',
                    height: '48px',
                    borderRadius: '6px',
                    background: '#852598',
                    border: '1px solid var(--col-12, #852598)',
                    marginTop: '34px',
                  }}
                  onClick={() => navigate('/registration')}
                >
                  Registered
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default UserNotCapableModal;
