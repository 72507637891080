import { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardContent, Typography, CardActions, Button, Grid, Divider, Box, styled, Paper } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import TruncatedDescription from '../../../../ui-components/truncateTooltip/TruncateTooltip';
import { createAICraftedForOffer, getAICraftedListForOffer, getOfferSource } from '../../../../services/OffersService';
import { CustomLeftArrow, CustomRightArrow } from '../../../Utils/icons/icons';
import { offersActions, initialCustomeCreateOffer } from '../../../../store/slices/offers';
import ErrorPage from '../../../../ErrorPage';
import { createoffer, offerScreen, CommonLanguage } from '../../../../Language/language';
import { useNavigate } from 'react-router-dom';
import SegmentSourceError from '../../segments/SegmentSourceError';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const { setOfferCreate, setAICraftedList, setCreateOfferInputFields } = offersActions;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '200px',
  cursor: 'pointer',
  textAlign: 'center',
}));

const AICraftedOffer = ({ setIsPageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAICraftedCreateLoading, setIsAICraftedCreateLoading] = useState(false);
  const [isFileMissingLoading, setIsFileMissingLoading] = useState(false);
  const { aICraftedClickedItems, offerAICrafetdList } = useSelector((state) => state.offers);
  const scrollContainer = useRef(null);
  const scrollAmount = 380;
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [sourceModal, setsourceModal] = useState(false);
  const closeSource = () => setsourceModal(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const getAICraftedList = async () => {
    setIsLoading(true);
    const responseData = await getAICraftedListForOffer();
    if (responseData.status === 200) {
      setIsLoading(false);
      dispatch(setOfferCreate({ response: initialCustomeCreateOffer }));
      dispatch(setAICraftedList({ offerAICrafetdList: responseData.data?.data }));
    } else {
      setIsLoading(false);
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
    }
  };

  useEffect(() => {
    getAICraftedList();
  }, []);

  const scroll = (direction) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += scrollAmount * direction;
    }
  };

  const handleCreateAICrafted = async (sk, pk) => {
    setIsAICraftedCreateLoading(true);
    const payload = {
      event_id: sk,
    };
    const response = await createAICraftedForOffer(payload);
    if (response.status === 200) {
      setIsAICraftedCreateLoading(false);
      dispatch(setOfferCreate({ response: response.data }));
      navigate(`/review-offer/${response.data?.sk.split('#')[1]}/${response.data?.pk.split('#')[1]}`);
    } else {
      setIsAICraftedCreateLoading(false);
      setShowModal(true);
      seterrormsg(response?.response?.data?.error);
    }
  };

  const handleBackToOfferList = () => {
    navigate('/offers');
  };

  const getOfferSourceList = async () => {
    setIsAICraftedCreateLoading(true);
    setbtnLoading(true);
    const responseData = await getOfferSource();
    if (responseData.status === 200) {
      const name = 'offerSourceList';
      const value = responseData.data;
      dispatch(setCreateOfferInputFields({ name, value }));
      setbtnLoading(false);
      navigate(`/create-offer/custom`);
      setIsAICraftedCreateLoading(false);
    } else {
      setbtnLoading(false);
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
      setIsAICraftedCreateLoading(false);
    }
    if (responseData?.data?.length === 0) {
      setsourceModal(true);
    }
  };

  const isAICraftedLoading = isAICraftedCreateLoading || isFileMissingLoading;
  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          borderRadius: '6px',
          marginTop: '15px',
        }}
      >
        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  {createoffer.headlineText1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={12} lg={6}>
            <Typography
              sx={{
                color: 'var(--col-12, #414446)',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '173.7%',
              }}
            >
              {createoffer.subtitle1}
            </Typography>
          </Grid>
        </Grid>
        {isAICraftedLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '32px',
            }}
          >
            <LoadingScreen gifType={isAICraftedCreateLoading && 4} />
          </div>
        ) : (
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ mb: 3, mx: 2, maxWidth: '79vw' }}>
                <Box display="flex" alignItems="center" sx={{ pt: 3, pl: 2 }}>
                  <Grid container>
                    <Grid item lg={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item xs={9}>
                          <StyledBox>
                            <h2
                              variant="body1"
                              style={{
                                fontWeight: '500',
                                color: '#764F7D',
                                borderBottom: '3px solid #764F7D',
                                fontFamily: 'Roboto',
                              }}
                            >
                              AI-Crafted Offer
                            </h2>
                          </StyledBox>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: '20px',
                              marginBottom: '20px',
                              paddingRight: '37PX',
                            }}
                          >
                            <button
                              onClick={() => scroll(-1)}
                              style={{
                                background: 'white',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <CustomLeftArrow />
                            </button>

                            <button
                              onClick={() => scroll(1)}
                              style={{
                                background: 'white',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <CustomRightArrow />
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <CardContent>
                  <Box p={2} sx={{ overflow: 'auto' }}>
                    <div style={{ position: 'relative' }}>
                      <div
                        ref={scrollContainer}
                        style={{
                          display: 'flex',
                          width: 'auto',
                          overflowX: 'hidden',
                          gap: '15px',
                          whiteSpace: 'nowrap',
                          scrollBehavior: 'smooth',
                        }}
                      >
                        {isLoading ? (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: '32px',
                            }}
                          >
                            <LoadingScreen gifType={5} />
                          </div>
                        ) : (
                          Array.isArray(offerAICrafetdList) &&
                          offerAICrafetdList.length > 0 &&
                          offerAICrafetdList.map((item, index) => (
                            <Card
                              key={index}
                              style={{
                                width: '280px',
                                height: '257px',
                                position: 'relative',
                                flexShrink: '0',
                                borderRadius: '5px',
                                border: '1px solid #EBE8F1',
                                background: '#FFF',
                                boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
                                padding: '10px',
                              }}
                            >
                              <CardHeader
                                title={item.name}
                                sx={{
                                  color: 'var(--Col-8, #000)',
                                  fontFamily: 'Roboto',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: '151.188%',
                                  textTransform: 'capitalize',
                                }}
                              />
                              <div className="customHR" style={{ margin: 0, padding: 0 }}></div>
                              <CardContent style={{ paddingTop: '0px' }}>
                                <TruncatedDescription description={item.description} />
                              </CardContent>
                              <CardActions>
                                <Button
                                  sx={{
                                    color: 'var(--col-14, #A35BB1)',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    textTransform: 'capitalize',
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '16px',
                                  }}
                                  disabled={aICraftedClickedItems[item.sk]}
                                  onClick={() => handleCreateAICrafted(item.sk, item?.pk)}
                                >
                                  Create
                                </Button>
                              </CardActions>
                            </Card>
                          ))
                        )}
                      </div>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Paper>

      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          // height: '269px',
          borderRadius: '6px',
        }}
      >
        <Grid container sx={{ padding: { lg: '34px 22px 54px 22px', xs: '22px' } }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  {createoffer.headlineText2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={12} lg={6}>
            <Typography
              sx={{
                color: 'var(--col-12, #414446)',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '173.7%',
              }}
            >
              {createoffer.subtitle2}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: '#ffffff',
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 'Bold',
                textAlign: 'center',
                textTransform: 'capitalize',
                width: { lg: '17rem', md: '15rem' },
                height: '48px',
                marginTop: { lg: '30px', xs: '10px' },
                borderRadius: '6px',
                backgroundColor: '#852598',
                ':hover': {
                  backgroundColor: '#852598',
                },
              }}
              onClick={getOfferSourceList}
            >
              {btnLoading ? 'Loading...' : offerScreen.createofferbtn}
            </Button>

            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                width: { xs: '7rem', lg: '10rem' },
                height: '48px',
                borderRadius: '6px',
                marginTop: { lg: '30px', xs: '10px' },
                marginLeft: '1vw',
              }}
              onClick={handleBackToOfferList}
            >
              {CommonLanguage.canclebtn}
            </Button>
          </Grid>
          {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
          {sourceModal && <SegmentSourceError closeSource={closeSource} />}
        </Grid>
      </Paper>
    </>
  );
};

export default AICraftedOffer;
