import React from "react";
import Button from "@mui/material/Button";


const CustomButton = ({ isDisabled, onClick, buttonValue, style }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      disabled={isDisabled}
      onClick={onClick}
      style={{
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Noto Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "capitalize",
        width: "200px",
        height: "48px",
        borderRadius: "6px",
        marginLeft: "10px",
        background: isDisabled ? "#d3abdb" : "#852598",
        ...style,
      }}
    >
      {buttonValue}
    </Button>
  );
};

export default CustomButton;
