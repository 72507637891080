import React, { useState, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { aboutCampaignData as setAboutCampaignData } from '../../../GlobalStore';
import channelIconMapping from '../../../../Utils/icons/channelIconMapping';
import { CustomRightArrow, CustomLeftArrow } from '../../../../Utils/icons/icons';
import { Card, CardHeader, Checkbox, FormControlLabel, FormGroup, CardContent, Box } from '@mui/material';

const AboutManual = ({ manualInput, setChannelData }) => {
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);

  const transformManualData = (manualData, manualInput) => {
    const transformed = {};
    const lowerCaseManualData = manualData.map((item) => item.toLowerCase());

    manualInput.forEach(({ channel, vendors }) => {
      const lowerCaseChannel = channel.toLowerCase();
      transformed[lowerCaseChannel] = vendors.reduce((acc, vendor) => {
        const lowerCaseVendor = vendor.toLowerCase();
        acc[lowerCaseVendor] = lowerCaseManualData.includes(lowerCaseVendor);
        return acc;
      }, {});
    });
    return transformed;
  };
  const [selected, setSelected] = useState(() => {
    if (Array.isArray(aboutCampaignData?.manualData)) {
      return transformManualData(aboutCampaignData.manualData, manualInput);
    }
    return aboutCampaignData?.manualData || {};
  });

  const scrollContainer = useRef(null);
  const scrollAmount = 380;

  const scroll = (direction) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += scrollAmount * direction;
    }
  };

  useEffect(() => {
    if (Array.isArray(aboutCampaignData?.manualData)) {
      setSelected(transformManualData(aboutCampaignData.manualData, manualInput));
    } else {
      setSelected(aboutCampaignData?.manualData || {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutCampaignData?.objective]);

  useEffect(() => {
    const currentCampaignData = setAboutCampaignData();
    setAboutCampaignData({
      ...currentCampaignData,
      manualData: selected,
    });
  }, [selected]);

  const handleCheckChange = (channel, vendor) => (event) => {
    const lowerCaseChannel = channel.toLowerCase();
    const lowerCaseVendor = vendor.toLowerCase();

    setSelected({
      ...selected,
      [lowerCaseChannel]: { ...selected[lowerCaseChannel], [lowerCaseVendor]: event.target.checked },
    });
  };


  return (
    <Box sx={{ position: 'relative', width: '73vw' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          marginBottom: '20px',
        }}
      >
        <button
          onClick={() => scroll(-1)}
          style={{
            background: 'white',
            borderRadius: '50%',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CustomLeftArrow />
        </button>

        <button
          onClick={() => scroll(1)}
          style={{
            background: 'white',
            borderRadius: '50%',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CustomRightArrow />
        </button>
      </div>

      <div
        ref={scrollContainer}
        style={{
          display: 'flex',
          width: 'auto',
          overflowX: 'hidden',
          gap: '15px',
          whiteSpace: 'nowrap',
          scrollBehavior: 'smooth',
        }}
      >
        {manualInput.map((item, index) => {
          const lowerCaseChannel = item.channel.toLowerCase();
          return (
            <Card
              key={index}
              style={{
                width: '280px',
                height: '257px',
                flexShrink: '0',
                borderRadius: '5px',
                border: '1px solid #EBE8F1',
                background: '#FFF',
                boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
              }}
            >
              {/* ... existing CardHeader and CardContent code */}

              <CardHeader
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '10px' }}>{React.createElement(channelIconMapping[item.channel.toLowerCase()])}</div>
                    <div style={{ marginBottom: '5px' }}>{item.channel}</div>
                  </div>
                }
                // action={
                //   <FormControlLabel
                //     control={
                //       <Checkbox
                //         onChange={(e) =>
                //           handleCheckAllChange(item.channel, item.vendors)(e)
                //         }
                //         checked={
                //           Object.values(selected[item.channel] || {}).length ===
                //             item.vendors.length &&
                //           Object.values(selected[item.channel] || {}).every(
                //             (val) => val
                //           )
                //         }
                //         color="secondary"
                //         style={{ fontSize: "14px" }}
                //       />
                //     }
                //     label={<div className="selectAll">Select All</div>}
                //   />
                // }
                titleTypographyProps={{
                  style: {
                    color: 'var(--col-8, #000)',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '151.188%',
                    textTransform: 'capitalize',
                    width: '150px',
                  },
                }}
              />
              <hr style={{ margin: '0px' }} />
              <CardContent>
                <FormGroup>
                  {item.vendors.map((vendor) => {
                    const lowerCaseVendor = vendor.toLowerCase();
                    return (
                      <FormControlLabel
                        key={vendor}
                        control={
                          <Checkbox
                            checked={selected[lowerCaseChannel]?.[lowerCaseVendor] || false}
                            onChange={handleCheckChange(lowerCaseChannel, lowerCaseVendor)}
                            color="secondary"
                            style={{ fontSize: '14px' }}
                          />
                        }
                        label={vendor}
                        sx={{
                          color: 'var(--col-8, #000)',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '222.688%',
                        }}
                      />
                    );
                  })}
                </FormGroup>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </Box>
  );
};

export default AboutManual;
