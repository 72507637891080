import React, { useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography, Card, CardContent, Checkbox } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import Registration from '../register/Registration';

import {
  campaignPageType,
  aboutCampaignData,
  dataSourceData,
  segmetsData,
  offersData,
  dateTimeGlobal,
  spendingLimitGlobal,
  snackBarMsg,
  batchDataGlobal,
} from '../Campaigns/GlobalStore';
import MFAScreen from './MFAScreen';
import { poolData, setCookie, setItem } from '../Utils/CommonFunction/CommonFunction';
import axios from 'axios';
import { REACT_APP_IS_MFA_ENABLE } from '../../common/config';

// import { uploadCSVpage, open } from "../FileInputs/UploadCSV/GlobalStore";

const resetApolloVariables = () => {
  campaignPageType(1);
  aboutCampaignData({});
  dataSourceData({ manualData: [], AIData: [] });
  segmetsData({ manualData: [], AIData: [] });
  offersData([]);
  dateTimeGlobal({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  });
  spendingLimitGlobal({
    noLimit: true,
    period: 'daily',
    min: '',
    max: '',
  });
  snackBarMsg(false);
  batchDataGlobal({
    batch_size: '',
    batch_type: 'daily',
  });

  // uploadCSVpage(1);
  // open(false);
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required!')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address'),
  password: Yup.string().required('Password is required!'), // add this line
});

const userPool = new CognitoUserPool(poolData);

const LoginMFA = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [userLogged, setUerLogged] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setUserEmail(values.email);
      setUserPassword(values.password);
      const authenticationDetails = new AuthenticationDetails({
        Username: values.email,
        Password: values.password,
      });

      const cognitoUser = new CognitoUser({
        Username: values.email,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          // navigate to another page, if needed
          // window.alert("Login successfully...");
          resetApolloVariables();

          cognitoUser.associateSoftwareToken({
            onFailure: (err) => {
              console.error(err);
            },
          });
          const accessToken = result.getAccessToken().getJwtToken();
          const MFAValidation = async () => {
            try {
              const response = await axios.get(REACT_APP_IS_MFA_ENABLE, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              if (response.data === true) {
                setUerLogged(true);
              } else if (response.data === false) {
                setCookie('accessToken', accessToken, 1440);
                setItem('accessToken', accessToken);
                navigate('/onboarding');
              }
            } catch (error) {
              console.error('Error :', error);
              return null;
            }
          };
          MFAValidation();
          // setUerLogged(true);
          return result;
          // navigate('/OnBoarding');
        },
        onFailure: function (err) {
          console.error(err);
          alert('Login failed. Please check your username and password.');
        },
      });

      setSubmitting(false);
    },
  });

  const handleForgetPassword = () => {
    navigate('/password-recovery');
  };

  const handleChange = (e) => {
    e.preventDefault();
    navigate('/registration');
  };

  return (
    <>
      {showRegister && <Registration open={showRegister} close={setShowRegister(false)} />}
      {userLogged ? (
        <MFAScreen userEmail={userEmail} userPassword={userPassword} />
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            pt: { xs: '10%', sm: '4%', md: '4%' },

            pb: '3%',
            width: '507px',
          }}
        >
          <Card>
            <CardContent>
              <Grid
                container
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="start"
                sx={{
                  pr: { lg: 8, md: 8, sm: 6, xs: 4 },
                  pl: { lg: 8, md: 8, sm: 6, xs: 4 },
                }}
              >
                <Grid item xs={10} sx={{ mt: '10%' }}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    sx={{
                      color: '#132529',
                      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      fontFamily: 'IBM Plex Serif',
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                    }}
                  >
                    Enter Two-Factor Authentication code
                  </Typography>
                </Grid>
                <Grid item xs={10} sx={{ display: 'flex' }}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    sx={{
                      color: '#112333',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '138.687%',
                      marginRight: '8px',
                    }}
                  >
                    Enter the two-factor authentication code that is displayed in the authenticator app on your device.
                  </Typography>
                  <Typography
                    variant="h5"
                    color="secondary"
                    sx={{
                      color: '#A35BB1',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '138.687%',
                    }}
                  >
                    Learn More
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: '#000',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '151.188%',
                      marginBottom: '6px',
                    }}
                  >
                    Authentication Code
                  </Typography>
                  <TextField
                    id="number"
                    type="number"
                    fullWidth
                    size="medium"
                    sx={{
                      paddingRight: { xs: '30px', md: '0px' },
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '45vh',
                        lg: '45vh',
                      },
                    }}
                    placeholder="Enter Code"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    marginTop: '20px',
                  }}
                >
                  <Grid>
                    <Checkbox sx={{ padding: '0' }} />
                  </Grid>
                  <Grid
                    sx={{
                      color: '#112333',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      marginLeft: '10px',
                    }}
                  >
                    Don’t ask 2FA code on this computer for the next 30 days
                  </Grid>
                </Grid>
                <Grid item sx={{ mt: { xs: '2%', sm: '3%', lg: '4%' } }}>
                  <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: { lg: '45vh', md: '25vh' } }}>
                    Login To My Account
                  </Button>
                  <Grid>
                    <Typography
                      style={{
                        color: '#852598',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      Unable to access your authentication code?
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default LoginMFA;
