// LoadingScreen.js
import React from 'react';
import { Box, Modal, useMediaQuery, useTheme } from '@mui/material';
import Loading4 from './loading4.gif';
import Loading5 from './loading5.gif';
import Loading6 from '../../../assets/Group 1000006108.svg';
import SuccessImage from '../../../assets/RegistrationLoadingImg1.png';

import { useNavigate } from 'react-router';
import { Button } from '@mui/base';
import { LoadingScreen4, LoadingScreen5 } from '../../../Language/DialogBoxLanguage';

const LoadingScreen = ({ gifType, type, loadingScreen6, loadingScreen7 }) => {
  const navigate = useNavigate();

  const handleGotoFileUpload = () => {
    navigate('/imports');
  };

  const handleGoToList = () => {
    navigate(`/${type}`);
  };
  const theme = useTheme();

  const isMinWidth = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Modal open={true}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 6,
            border: '1px solid #EBE8F1',
            background: '#FFF',
            boxShadow: '0px 16px 21px 0px rgba(0, 0, 0, 0.33)',
            width: isMinWidth ? '834px' : '100%',
            height: '371px',
            flexShrink: 0,
            marginBottom: '80px',
            p: 3,
          }}
        >
          {gifType === 4 && (
            <>
              <Box sx={{ padding: isMinWidth ? '75px' : '40px' }}>
                <div className="manageAllCampaignText">{LoadingScreen4.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {LoadingScreen4.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading4} alt="Loading..." style={{ height: '176px', width: '176  px', marginRight: '60px' }} />
              </Box>
            </>
          )}
          {gifType === 5 && (
            <>
              <Box sx={{ padding: isMinWidth ? '75px' : '40px' }}>
                <div className="manageAllCampaignText">{LoadingScreen5.headlineText1}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {LoadingScreen5.subTitle1}
                </h3>
              </Box>
              <Box>
                <img src={Loading5} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}

          {gifType === 6 && (
            <>
              <Box sx={{ padding: isMinWidth ? '75px' : '40px' }}>
                <div className="manageAllCampaignText">{loadingScreen6.title}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {loadingScreen6.subtitle}
                </h3>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    width: '15rem',
                    height: '48px',
                    borderRadius: '6px',
                    background: '#852598',
                    marginTop: '2vh',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleGotoFileUpload();
                  }}
                >
                  {loadingScreen6.buttonText}
                </Button>
              </Box>
              <Box>
                <img src={Loading6} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}

          {gifType === 7 && (
            <>
              <Box sx={{ padding: isMinWidth ? '75px' : '40px' }}>
                <div className="manageAllCampaignText">{loadingScreen7.title}</div>
                <h3
                  style={{
                    color: '#414446',
                    marginTop: '20px',
                    lineHeight: '25px',
                  }}
                >
                  {loadingScreen7.subtitle}
                </h3>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    width: '250px',
                    height: '48px',
                    borderRadius: '6px',
                    background: '#852598',
                    marginTop: '42px',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={handleGoToList}
                >
                  {loadingScreen7.buttonText}
                </Button>
              </Box>
              <Box>
                <img src={SuccessImage} alt="Loading..." style={{ height: '176px', width: '176px', marginRight: '60px' }} />
              </Box>
            </>
          )}
        </Box>
      </div>
    </Modal>
  );
};

export default LoadingScreen;
