import { Box, Button, CircularProgress, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTypesOfUpload, uploadFile } from '../../../../services/Imports';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { clearAllCookies, clearItem, getItem } from '../../../Utils/CommonFunction/CommonFunction';
import ErrorPage from '../../../../ErrorPage';
import { csvuploadScreen } from '../../../../Language/language';
import { GET_DOCUMENT_FILE_UPLOAD_S3 } from '../../../../common/config';

const CsvFileUpload = ({ togglePopUp, setUploadResponse, setPageType }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [importName, setImportName] = useState('');
  const [description, setDescription] = useState('');
  const [uploadType, setUploadType] = useState('');
  const [typesOfUpload, setTypesOfUpload] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [checkFiletype, setcheckFiletype] = useState(false);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);
  };
  const validateFile = (file) => {
    const validTypes = ['text/csv', 'application/vnd.ms-excel', 'application/csv', 'text/x-csv', 'application/x-csv'];
    const extension = file.name.split('.').pop().toLowerCase();
    const isValidType = validTypes.includes(file.type) || extension === 'csv';
    const maxSize = 15 * 1024 * 1024; // Adjusted size for 15 MB
    return isValidType && file.size <= maxSize;
  };

  // Updated handleFileInputChange to address inconsistent MIME types
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (validateFile(file)) {
      setUploading(true);
      setTimeout(() => setUploading(false), 1000); // Simulated upload timing
      setFile(file);
      setcheckFiletype(false);
    } else {
      setcheckFiletype(false);
      setFile(null);
    }
  };
  const handleFileBrowse = () => {
    const fileInput = document.getElementById('csvFileInput');
    fileInput.click();
  };
  const getPresignedUrl = async () => {
    try {
      const accessToken = getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token not found in localStorage');
      }
      const response = await axios.get(GET_DOCUMENT_FILE_UPLOAD_S3, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          file_name: file.name,
          server_table: uploadType,
        },
      });
      return response.data;
    } catch (error) {
      setShowModal(true);
      seterrormsg(error?.response?.data?.error);
      if (error?.response?.status == 401) {
        window.location.reload();
        clearAllCookies();
        clearItem();
      }
      return null;
    }
  };

  const handleImportNameChange = (event) => setImportName(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleUploadTypeChange = (event) => setUploadType(event.target.value);

  const clearStates = () => {
    setFile(null);
    setImportName('');
    setDescription('');
    setUploadType('');
    setcheckFiletype('');
  };

  const handleFormSubmit = async (event) => {
    setIsFileUploading(true);
    if (!checkFiletype) {
      try {
        // Get the pre-signed URL
        const presignedData = await getPresignedUrl();
        if (!presignedData) {
          throw new Error('Failed to get the pre-signed URL.');
        }

        await axios.put(presignedData.response?.url, file, {
          headers: {
            'Content-Type': 'text/csv',
          },
        });
        setMessage('Document uploaded successfully!');
        const uploadFileName = presignedData.file_name;
        const payload = { uploadFileName, importName, uploadType, description };
        const response = await uploadFile(payload);
        if (response.status !== 200) {
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
        }
        if (response?.status == 200) {
          setUploadResponse(response.data.response);
          clearStates();
          togglePopUp(true);
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
        setShowWarning(true);
        clearStates();
      } finally {
        setIsFileUploading(false);
      }
    }
  };

  const shouldEnableButton = () => file !== null && importName.trim() !== '' && uploadType.trim() !== '';

  const getUploadTypes = async () => {
    const response = await getTypesOfUpload();
    if (response.status === 200) {
      const uploads = [];
      for (let upload of response.data) uploads.push({ label: upload.replaceAll('_', ' '), value: upload });
      setTypesOfUpload(uploads);
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!typesOfUpload.length) getUploadTypes();
  }, []);

  return (
    <Paper
      id="csv_file_upload"
      sx={{
        borderRadius: '6px',
        border: '1px solid #ebe8f1',
        backgroundColor: '#ffffff',
        padding: '1rem',
      }}
    >
      <Typography
        sx={{
          color: '#414446',
          fontFamily: 'IBM Plex Serif',
          fontSize: '1.5rem',
          lineHeight: '2.5rem',
          marginBottom: '1rem',
        }}
      >
        {csvuploadScreen.headlineText1}
      </Typography>
      <form
        // onSubmit={handleFormSubmit}
        style={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '3rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Typography
            sx={{
              color: '#414446',
              fontFamily: 'Roboto',
              fontSize: '0.9rem',
              lineHeight: '24px',
            }}
          >
            {csvuploadScreen.subTitle1}
          </Typography>
        </Box>
        <Box
          sx={{
            width: { lg: '40rem', sm: '100%' },
          }}
        >
          <div
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={handleFileBrowse}
            style={{
              border: '2px dashed #B9B4C3',
              background: '#F6F5F8',
              height: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              borderRadius: '5px',
              cursor: 'pointer',
              padding: '20px',
            }}
          >
            <input type="file" id="csvFileInput" sx={{ display: 'none' }} onChange={handleFileInputChange} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                textAlign: 'center',
                color: '#b9b4c3',
                fontFamily: 'Roboto',
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              {file || uploading ? '' : 'Drag and drop your files here or browse'}
            </Typography>

            {uploading ? (
              <Box sx={{ textAlign: 'center', marginTop: '0.2rem' }}>
                <CircularProgress />
                <Typography variant="body2">Uploading...</Typography>
              </Box>
            ) : (
              file && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    marginTop: '0.2remx',
                    fontFamily: '12px',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  {file.name}
                </Typography>
              )
            )}
          </div>
          {checkFiletype && (
            <span
              style={{
                color: 'red',
              }}
            >
              Please provide the file in CSV format only
            </span>
          )}
        </Box>

        <div
          style={{
            width: { lg: '43rem', sm: '100%' },
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75rem',
          }}
        >
          <Typography
            sx={{
              color: '#000000',
              fontFamily: 'Roboto',
              fontSize: '0.9rem',
              fontWeight: 'Bold',
              textTransform: 'capitalize',
            }}
          >
            {csvuploadScreen.headlineText2}
          </Typography>
          <TextField
            value={importName}
            onChange={handleImportNameChange}
            placeholder="File name will appear here"
            sx={{
              width: { lg: '40rem', sm: '100%' },
              borderRadius: '6px',
              border: '1px solid #ebe8f1',
              background: '#ffffff',
            }}
          />

          <Typography
            sx={{
              color: '#000000',
              fontFamily: 'Roboto',
              fontSize: '0.9rem',
              fontWeight: 'Bold',
              textTransform: 'capitalize',
            }}
          >
            {csvuploadScreen.headlineText3}
          </Typography>
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Add an optional description for your import"
            multiline
            rows={4}
            sx={{
              width: { lg: '40rem', sm: '100%' },

              borderRadius: '6px',
              border: '1px solid #EBE8F1',
              background: '#FFF',
            }}
          />

          <Select
            labelId="types-of-upload"
            id="types-of-upload"
            value={uploadType}
            onChange={handleUploadTypeChange}
            sx={{
              color: '#000000',
              width: { lg: '40rem', sm: '100%' },
            }}
            displayEmpty={true}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <span
                    style={{
                      color: '#838383',
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                    }}
                  >
                    Select Data Source Type
                  </span>
                );
              }
              return selected.replace('_', ' ');
            }}
          >
            {!!typesOfUpload.length && typesOfUpload.map((upload) => <MenuItem value={upload.value}>{upload.label}</MenuItem>)}
          </Select>

          <div className="text-subheadings" style={{ marginBottom: '4.5vh', marginTop: '2vh' }}>
            {csvuploadScreen?.subtitle2}
          </div>

          {isFileUploading ? (
            <LoadingButton
              loading
              loadingPosition="end"
              variant="contained"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '0.9',
                fontStyle: 'normal',
                fontWeight: 'Bold',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                width: 'fit-content',
                borderRadius: '6px',
                padding: '1rem 2rem 1rem 2rem',
              }}
            >
              <span style={{ marginRight: '0.5rem' }}>Uploading</span>
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              onClick={handleFormSubmit}
              // disabled={!shouldEnableButton() || uploading}
              sx={{
                color: '#ffffff',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '0.9',
                fontStyle: 'normal',
                fontWeight: 'Bold',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                width: 'fit-content',
                borderRadius: '6px',
                padding: '1rem 2rem',
                background: !shouldEnableButton() ? '#d3abdb' : '#852598',
                ':disabled': {
                  color: '#ffffff',
                },
                ':hover': {
                  backgroundColor: '#d3abdb',
                },
              }}
            >
              {csvuploadScreen.updbtn}
            </Button>
          )}
        </div>
      </form>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </Paper>
  );
};

export default CsvFileUpload;
