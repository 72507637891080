import React from 'react';
import FunnelMatrics from './FunnelMatrics';

const FunnelPannel = ({ closeButton, selectedTitle, groupData }) => {
  return (
    <div>
      <FunnelMatrics closeButton={closeButton} selectedTitle={selectedTitle} groupData={groupData} />
    </div>
  );
};

export default FunnelPannel;
