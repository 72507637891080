import axios from 'axios';
// import { custom_integration_contact_Url, custom_integration_setup_Url, is_user_have_dashboard_data_Url, is_user_uploaded_files_Url } from "../ApiSheet";
import {
  CUSTOMER_DATA_INTIGRATION_ERROR,
  CUSTOMER_DATA_INTIGRATION_LOADING,
  CUSTOMER_DATA_INTIGRATION_SUCCESS,
  CUSTOM_INTIGRATION_CONTACT_LOADING,
  CUSTOM_INTIGRATION_SETUP_LOADING,
  GET_CUSTOMER_INFORMATION_LOADING,
  GET_CUSTOMER_INFORMATION__ERROR,
  GET_CUSTOMER_INFORMATION__SUCCESS,
  IS_CUSTOM_INTIGRATION_CONTACT_ERROR,
  IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS,
  IS_CUSTOM_INTIGRATION_SETUP_ERROR,
  IS_CUSTOM_INTIGRATION_SETUP_SUCCESS,
  IS_USER_HAVE_DASHBOARD_ERROR,
  IS_USER_HAVE_DASHBOARD_LOADING,
  IS_USER_HAVE_DASHBOARD_SUCCESS,
  IS_USER_UPLOADED_FILES_ERROR,
  IS_USER_UPLOADED_FILES_LOADING,
  IS_USER_UPLOADED_FILES_SUCCESS,
} from '../Constant';
import {
  CUSTOMER_DATA_INTELLIGENCE_POST,
  CUSTOM_INTIGRATION_CONTACT_URL,
  CUSTOM_INTIGRATION_SETUP,
  GET_CUSTOMER_INFORMATION,
  IS_USER_HAVE_DASHBOARD_DATA,
  IS_USER_UPLOADED_FILES,
} from '../../common/config';
import secureLocalStorage from 'react-secure-storage';
import { clearAllCookies, clearItem } from '../../view/Utils/CommonFunction/CommonFunction';

export const UserUploadedFileAction = () => {
  return (dispatch) => {
    dispatch({
      type: IS_USER_UPLOADED_FILES_LOADING,
    });

    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: IS_USER_UPLOADED_FILES,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: IS_USER_UPLOADED_FILES_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
        return resp;
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IS_USER_UPLOADED_FILES_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: IS_USER_UPLOADED_FILES_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
        return error;
      });
  };
};

export const UserdashboardAction = () => {
  return (dispatch) => {
    dispatch({
      type: IS_USER_HAVE_DASHBOARD_LOADING,
    });

    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: IS_USER_HAVE_DASHBOARD_DATA,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: IS_USER_HAVE_DASHBOARD_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IS_USER_HAVE_DASHBOARD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: IS_USER_HAVE_DASHBOARD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
};

export const CustomsetupAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_INTIGRATION_SETUP_LOADING,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: CUSTOM_INTIGRATION_SETUP,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: IS_CUSTOM_INTIGRATION_SETUP_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IS_CUSTOM_INTIGRATION_SETUP_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data?.data.error;
          dispatch({
            type: IS_CUSTOM_INTIGRATION_SETUP_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 500) {
          let validationError = data?.data.error;
          dispatch({
            type: IS_CUSTOM_INTIGRATION_SETUP_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
};
export const CustomSetupActionClear = () => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_INTIGRATION_SETUP_LOADING,
    });
    dispatch({
      type: IS_CUSTOM_INTIGRATION_SETUP_SUCCESS,
      response: {
        data: [],
        msg: '',
      },
      payload: [],
      // data: resp.data.data,
    });
  };
};

export const CustomcontactAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_INTIGRATION_CONTACT_LOADING,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: CUSTOM_INTIGRATION_CONTACT_URL,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IS_CUSTOM_INTIGRATION_CONTACT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422 || data?.status === 400) {
          let validationError = data.data.error;
          dispatch({
            type: IS_CUSTOM_INTIGRATION_CONTACT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
};

export const getcustomerinfoAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_INFORMATION_LOADING,
    });

    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: GET_CUSTOMER_INFORMATION,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CUSTOMER_INFORMATION__SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CUSTOMER_INFORMATION__ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_CUSTOMER_INFORMATION__ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
};

export const customerdataintigrationpostAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_DATA_INTIGRATION_LOADING,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${secureLocalStorage.getItem('accessToken')}`,
      },
      url: CUSTOMER_DATA_INTELLIGENCE_POST,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: CUSTOMER_DATA_INTIGRATION_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
          payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CUSTOMER_DATA_INTIGRATION_ERROR,
            netError: error,
          });
        } else if (data?.status === 422 || data?.status === 500) {
          let validationError = data.data.message;
          dispatch({
            type: CUSTOMER_DATA_INTIGRATION_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          localStorage.clear();
        }
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  };
};
