import axios from 'axios';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';
import { ONE_CLICK_ALL_CAMPAIGN, GET_ONE_CLICK_CAMPAIGN } from '../common/config';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const CampaignOneClickService = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: ONE_CLICK_ALL_CAMPAIGN,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const OneClickCreateService = async (campaign_pk, campaign_sk) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${GET_ONE_CLICK_CAMPAIGN}?campaign_pk=${encodeURIComponent(campaign_pk)}&campaign_sk=${encodeURIComponent(campaign_sk)}`,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};
