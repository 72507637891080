import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  CamapignCapabletype: [],
};

const CapableCampaign = createSlice({
  name: 'CampaignCapable',
  initialState,
  reducers: {
    setCampaignCapable(state, action) {
      state.CamapignCapabletype = action.payload.CamapignCapabletype;
    },
  },
});

export const capableCampaignActions = CapableCampaign.actions;
export const capableCampaignReducers = CapableCampaign.reducer;
