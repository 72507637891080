import axios from 'axios';
import { GET_SCORE_URL, GET_SEGMENT_URL, LOAN_BY_INTEREST_RATE_URL, LOAN_BY_SEGMENT } from '../common/config';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const LoanInterestRateService = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: LOAN_BY_INTEREST_RATE_URL,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const getScoreData = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: GET_SCORE_URL,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const getSegmentData = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: GET_SEGMENT_URL,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const getLoanInterestRateSegmentData = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: LOAN_BY_SEGMENT,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};
