import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
  } from "@mui/material";
  import React from "react";
  import RegistrationLoadedImg3 from "../../assets/RegistrationLoadingImg3.png";
  import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
  
  const RegistrationLoadedDialog3 = ({ open }) => {
  
    return (
      <Dialog fullWidth maxWidth="md" open={open} sx={{ overflow: "hidden" }}>
        <DialogTitle>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ mb: 10 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ p: 3, paddingBottom: 2, overflow: "hidden" }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    sx={{
                      color: "#414446",
                      fontFamily: "IBM Plex Serif",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "40px",
                    }}
                  >
                    Finalizing the Magic
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{ width: "241px", height: "153px", flexShrink: 0 }}
                >
                  <img src={RegistrationLoadedImg3} alt="success" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default RegistrationLoadedDialog3;
  