/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET_ALL_EXPORTS } from '../../common/config';
import { getListOfFiles } from '../../services/Exports';
import DataTable from './Exports/DataTable';
import Loader from './Components/Loader';
import ErrorPage from '../../ErrorPage';

const Exports = () => {
  const [exportFiles, setExportFiles] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  const listOfFiles = async () => {
    setIsLoading(true);
    const response = await getListOfFiles(GET_ALL_EXPORTS, 'export');
    if (response.status === 200) {
      setExportFiles(() => response.data);
      setIsLoading(false);
    } else {
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
      setIsLoading(false);
      setExportFiles([]);
    }
  };

  useEffect(() => {
    document.title = 'Exports';
    if (!exportFiles.length) listOfFiles();
  }, []);

  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        borderRadius: '6px',
        padding: '1rem',
      }}
    >
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        {isLoading ? (
          <Loader />
        ) 
        : exportFiles.length ? (
          <DataTable exportFiles={exportFiles} />
        ) 
        : 
        (
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 'Normal',
              textTransform: 'capitalize',
              color: '#b9b4c3',
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            No Exports Available
          </Typography>
        )}
      </section>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </Paper>
  );
};

export default Exports;
