import { Grid, Typography } from '@mui/material';
import GroupChart from './GroupChart';
import React from 'react';

const LeadChart = ({ groupData }) => {
  return (
    <div>
      <Grid container>
        {groupData.map((group) => (
          <>
            <Grid item xs={1.5}>
              <GroupChart data={group.values} key={group.key} color={group.color} name={group.name} darkColor={group.darkColor} />
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
};

export default LeadChart;
