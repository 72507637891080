import { Button, Card, Grid, Typography } from '@mui/material';
import errorimg from './assets/errorimg.svg';
import { CommonLanguage } from '../src/Language/language';
import './index.css';
import { useEffect } from 'react';
import { campaignPageType as PageType } from './view/Campaigns/GlobalStore';
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({ closeModal, errormsg, closingFlag }) => {
  useEffect(() => {
    document.body.classList.add('disable-scroll');
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="modal-overlay">
        <Card sx={{ borderRadius: 2, overflow: 'auto' }} className="modal">
          <Grid container>
            <Grid
              item
              xs={12}
              onClick={() => {
                closeModal();
                PageType(closingFlag && closingFlag);
              }}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {/* <CloseIcon /> */}
            </Grid>
            <Grid container sx={{ padding: '60px' }}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '40px',
                    marginBottom: '8px',
                  }}
                >
                  {errormsg?.title}
                </Typography>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '173.7%',
                  }}
                >
                  {errormsg?.description
                    ? errormsg?.description
                    : errormsg
                    ? errormsg
                    : 'Synergi is equipped to provide you powerful campaign insights. All we need is the right data from you.'}
                </Typography>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'Noto Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    width: '20vh',
                    height: '48px',
                    borderRadius: '6px',
                    background: '#852598',
                    border: '1px solid var(--col-12, #852598)',
                    marginTop: '34px',
                  }}
                  onClick={() => {
                    // eslint-disable-next-line eqeqeq
                    if (closingFlag == -1) {
                      navigate('/campaigns');
                    }
                    closeModal();
                    PageType(closingFlag);
                  }}
                >
                  {CommonLanguage.backbtn}
                </Button>
              </Grid>
              <Grid item xs={4} sx={{ justifyContent: 'end', display: 'flex' }}>
                <img
                  src={errorimg}
                  alt='errorImage'
                  style={{
                    width: '121.6px',
                    height: '132px',
                    flexShrink: '0',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default ErrorPage;
