import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { CommonLanguage } from '../../../../Language/DialogBoxLanguage';
import { useState } from 'react';
import UserPopup from './UserPopup';
import { UsersSettingService } from '../../../../services/UsersService';
import ErrorPage from '../../../../ErrorPage';
import Loader from '../../../integration/Components/Loader';
import { UserEditService } from '../../../../services/UserEditService';
import { UserListService } from '../../../../services/UserListService';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight: '15px',
  },
}));

const NewUser = ({ selectedUser, closeModal, setUserList }) => {
  const [SuccessModal, setSuccessModal] = useState(false);
  const closeSuccessModal = () => setSuccessModal(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [Loading, setLoading] = useState(false);

  const [state, setstate] = useState({
    email: '',
    role: 'admin',
  });

  const [error, seterror] = useState({
    email: false,
    role: false,
  });

  useEffect(() => {
    if (!!selectedUser) {
      setstate((preve) => ({
        role: selectedUser?.user_role,
        email: selectedUser?.user_email,
        user_id: selectedUser?.user_id,
      }));
      setisDisabled(true);
    } else {
      setstate({
        email: '',
        role: 'admin',
      });
    }
  }, [selectedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setstate((preve) => ({
      ...preve,
      [name]: value,
    }));

    switch (name) {
      case 'email':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            email: "Email address can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            email: false,
          }));
        }
        break;
        // case 'company_name':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            company_name: "Company name can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            company_name: false,
          }));
        }
        break;
      case 'role':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            role: "Role can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            role: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const responseData = await UsersSettingService(state);
    if (responseData?.status === 200) {
      const response = await UserListService('');
      if (response?.status === 200) {
        setSuccessModal(true);
        setUserList(response?.data);
        setLoading(false);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setLoading(false);
      }
    } else {
      seterrormsg(responseData?.response?.data?.error);
      setShowModal(true);
      setLoading(false);
    }
  };

  const handleEditUser = async () => {
    setLoading(true);
    const responseData = await UserEditService(state);
    if (responseData?.status === 200) {
      const response = await UserListService('');
      if (response?.status === 200) {
        setSuccessModal(true);
        setUserList(response?.data);
        setLoading(false);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setLoading(false);
      }
    } else {
      seterrormsg(responseData?.response?.data?.error);
      setShowModal(true);
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <div className="wrapper"></div>
      <Card
        sx={{ my: 5, borderRadius: 2, padding: '6px' }}
        style={{
          width: '834px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          zIndex: '99',
          transform: 'Translate(-50%,-50%)',
          padding: '14px 34px 69px 49px',
        }}
      > */}
      {Loading ? (
        <Loader />
      ) : (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              onClick={closeModal}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '18px',
                borderBottom: '1px solid #D9D6E3',
              }}
            >
              <Typography
                style={{
                  color: '#414446',
                  fontFamily: 'IBM Plex Serif',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '12px',
                }}
              >
                {!selectedUser ? 'Create New User' : 'Edit User'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: '#414446',
                  fontFamily: 'IBM Plex Serif',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '12px',
                }}
              >
                Basic Information
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {/* <Grid xs={7} item style={{ paddingTop: '25px' }}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '6px',
              }}
            >
              Username
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Username" />
          </Grid>
          <Grid xs={7} item style={{ paddingTop: '25px' }}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '6px',
              }}
            >
              First name
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Enter First name" />
          </Grid>
          <Grid xs={7} item style={{ paddingTop: '25px' }}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '6px',
              }}
            >
              Last name
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Enter Last name" />
          </Grid> */}
              {/* <Grid xs={7} item style={{ paddingTop: '25px' }}>
                <Typography
                  variant="h3"
                  style={{
                    color: '#000',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    paddingBottom: '6px',
                  }}
                >
                  Company name
                </Typography>
                <TextField
                  disabled={isDisabled}
                  type="text"
                  fullWidth
                  size="medium"
                  placeholder="Company name"
                  name="company_name"
                  value={state?.company_name}
                  onChange={handleChange}
                />
                {error?.company_name && (
                  <Typography
                    sx={{
                      color: 'red',
                    }}
                  >
                    {error?.company_name}
                  </Typography>
                )}
              </Grid> */}
              <Grid xs={7} item style={{ paddingTop: '25px' }}>
                <Typography
                  variant="h3"
                  style={{
                    color: '#000',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    paddingBottom: '6px',
                  }}
                >
                  Email address
                </Typography>
                <TextField disabled={isDisabled} type="text" fullWidth size="medium" placeholder="Email Id" name="email" value={state?.email} onChange={handleChange} />
                {error?.email && (
                  <Typography
                    sx={{
                      color: 'red',
                    }}
                  >
                    {error?.email}
                  </Typography>
                )}
              </Grid>
              {/* <Grid xs={7} item style={{ paddingTop: '25px' }}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '6px',
              }}
            >
              Designation
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Designation" />
          </Grid>
          <Grid xs={7} item style={{ paddingTop: '25px' }}>
            <Typography
              variant="h3"
              style={{
                color: '#000',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '6px',
              }}
            >
              Mobile
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Mobile" />
          </Grid> */}
              <Grid xs={7} item style={{ paddingTop: '25px' }}>
                <Typography
                  variant="h3"
                  style={{
                    color: '#000',
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    paddingBottom: '6px',
                  }}
                >
                  User Role
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  {/* <Select name="role" onChange={handleChange} value={state?.role}> */}
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" defaultValue="female" name="role" onChange={handleChange} value={state?.role}>
                    <LightTooltip title={'Has full rights to manage billing, users, segments, offers, campaigns, and documents.'} arrow>
                      <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                    </LightTooltip>
                    <LightTooltip title={'Has rights to manage users, segments, offers, campaigns, and documents, but not billing.'} arrow>
                      <FormControlLabel value="agency" control={<Radio />} label="Agency" />
                    </LightTooltip>
                    <LightTooltip title={'Has rights to manage users, segments, offers, campaigns, and documents, but not billing.'} arrow>
                      <FormControlLabel value="employee-type-1" control={<Radio />} label="Employee_type_1" />
                    </LightTooltip>
                    <LightTooltip title={'Has read-only access to segments, offers, and documents.'} arrow>
                      <FormControlLabel value="employee-type-2" control={<Radio />} label="Employee_type_2" />
                    </LightTooltip>
                    <LightTooltip title={'Has read-only access to segments, offers, and documents.'} arrow>
                      <FormControlLabel value="super-admin" control={<Radio />} label="Super Admin" />
                    </LightTooltip>
                  </RadioGroup>
                  {/* </Select> */}
                </FormControl>
              </Grid>
            </Grid>
            {error?.role && (
              <Typography
                sx={{
                  color: 'red',
                }}
              >
                {error?.role}
              </Typography>
            )}
            <Grid xs={12} item style={{ paddingTop: '25px' }}>
              <Button
                type="submit"
                onClick={closeModal}
                fullWidth
                sx={{
                  width: '118px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  marginRight: '22px',
                  backgroundColor: 'transparent',
                  border: '1px solid #9C27B5',
                  color: '#9C27B5',
                }}
              >
                {CommonLanguage.cancelbtn}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => {
                  !isDisabled ? handleSubmit() : handleEditUser();
                }}
                fullWidth
                sx={{
                  width: '146px',

                  fontSize: '16px',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                  fontWeight: '600',
                }}
              >
                {!selectedUser ? 'Create User' : 'Edit User'}
              </Button>
              {SuccessModal && <UserPopup closeModal={closeModal} state={state} selectedUser={selectedUser}/>}
            </Grid>
          </Grid>
        </>
      )}
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      {/* </Card> */}
    </div>
  );
};

export default NewUser;
