import axios from 'axios';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  let accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getListOfFiles = async (baseUrl, parameter) => {
  try {
    const response = await axios({
      method: 'get',
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        parameter,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};

export const downloadS3File = async (baseUrl, pk) => {
  try {
    const response = await axios({
      method: 'get',
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        pk,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
  }
};

export const downloadCampaignFile = async (baseUrl, name, pk) => {
  try {
    const response = await axios({
      method: 'get',
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        name,
        pk,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
  }
};
