import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Switch } from '@mui/material';
import { CustomArrowDown, CustomArrowUp } from '../../../Utils/icons/icons';
import DateTimeInput from '../../../Utils/DateTimeInput/DateTimeInput';
import SpendingLimit from '../../../Utils/SpendingLimit/SpendingLimit';
import CustomButton from '../../../Utils/CustomButton/CustomButton';
import { CustomSaveIcon } from '../../../Utils/icons/icons';

const CardPopUp = ({ open, handleClose, channelData, channel, setCampaignData, isOn }) => {
  const [expandedRows, setExpandedRows] = useState([0]);
  const [enableStates, setEnableStates] = useState(
    channelData?.map((data) => ({
      segment_sk: data.segment_sk,
      offer_pk: data.offer_pk,
      segment_start_datetime: data.segment_start_datetime,
      segment_end_datetime: data.segment_end_datetime,
      segment_max_budget: data.segment_max_budget,
      segment_min_budget: data.segment_min_budget,
      offers: data.offers,
      active: true,
    }))
  );

  const [editStates, setEditStates] = useState(
    channelData?.map((data) => ({
      segment_sk: data.segment_sk,
      offer_pk: data.offer_pk,
      segment_start_datetime: data.segment_start_datetime,
      segment_end_datetime: data.segment_end_datetime,
      segment_max_budget: data.segment_max_budget,
      segment_min_budget: data.segment_min_budget,
      offers: data.offers,
      edit: false,
    }))
  );

  const [selectedOffers, setSelectedOffers] = useState([]);

  const handleDateTimeChange = (newDateTime, segment_sk) => {
    setSelectedOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer?.segment_sk === segment_sk
          ? {
              ...offer,
              segment_start_datetime: `${newDateTime.startDate} ${newDateTime.startTime}`,
              segment_end_datetime: `${newDateTime.endDate} ${newDateTime.endTime}`,
            }
          : offer
      )
    );
  };
  const handleSpendingLimitChange = (newSpendingLimit, segment_sk) => {
    setSelectedOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.segment_sk === segment_sk
          ? {
              ...offer,
              segment_min_budget: newSpendingLimit.min,
              segment_max_budget: newSpendingLimit.max,
            }
          : offer
      )
    );
  };

  useEffect(() => {
    setSelectedOffers(
      channelData.map((data) => ({
        segment_sk: data.segment_sk,
        offer_pk: data.offer_pk,
        segment_start_datetime: data.segment_start_datetime,
        segment_end_datetime: data.segment_end_datetime,
        segment_max_budget: data.segment_max_budget,
        segment_min_budget: data.segment_min_budget,
        offers: data.offers,
        active: 'True',
      }))
    );
  }, [channelData]);

  useEffect(() => {
    const updateCampaignData = (currentData) => {
      const channelIndex = currentData.findIndex((data) => data.channel === channel);

      const newSegmentOffer = {
        channel,
        segment_offers: selectedOffers,
        active: isOn,
      };

      if (channelIndex !== -1) {
        currentData[channelIndex] = newSegmentOffer;
      } else {
        currentData.push(newSegmentOffer);
      }

      return currentData;
    };

    setCampaignData((prevData) => updateCampaignData([...prevData]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffers, channelData]);

  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  const toggleExpand = (index) => {
    setExpandedRows((prevRows) => {
      if (prevRows.includes(index)) {
        return prevRows.filter((i) => i !== index);
      } else {
        return [...prevRows, index];
      }
    });
  };

  const handleEnableSwitchChange = (index, segment_sk) => (event) => {
    event.stopPropagation();

    setSelectedOffers((prevOffers) => {
      const offer = channelData[index];
      const existingOffer = prevOffers.find((o) => o.segment_sk === segment_sk);

      if (existingOffer.active === 'True') {
        return prevOffers.map((o) =>
          o.segment_sk === segment_sk
            ? {
                segment_sk: offer.segment_sk,
                offer_pk: offer.offer_pk,
                segment_start_datetime: offer.segment_start_datetime,
                segment_end_datetime: offer.segment_end_datetime,
                segment_max_budget: offer.segment_max_budget,
                segment_min_budget: offer.segment_min_budget,
                offers: offer.offers,
                active: 'False',
              }
            : o
        );
      } else {
        return prevOffers.map((o) =>
          o.segment_sk === offer.segment_sk
            ? {
                segment_sk: offer.segment_sk,
                offer_pk: offer.offer_pk,
                segment_start_datetime: offer.segment_start_datetime,
                segment_end_datetime: offer.segment_end_datetime,
                segment_max_budget: offer.segment_max_budget,
                segment_min_budget: offer.segment_min_budget,
                offers: offer.offers,
                active: 'True',
              }
            : o
        );
      }
    });

    setEnableStates((prevStates) =>
      prevStates.map((state) =>
        state.segment_sk === segment_sk
          ? {
              ...state,
              active: !state.active,
            }
          : state
      )
    );
  };

  const handleEditButtonClick = (segment_sk, index) => (event) => {
    event.stopPropagation();
    setExpandedRows((prevRows) => {
      if (!prevRows.includes(index)) {
        return [...prevRows, index];
      }
      return prevRows;
    });

    setEditStates((prevStates) => prevStates.map((state) => (state.segment_sk === segment_sk ? { ...state, edit: !state.edit } : state)));
  };

  const ordinalSuffix = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) return num + 'st';
    if (j === 2 && k !== 12) return num + 'nd';
    if (j === 3 && k !== 13) return num + 'rd';
    return num + 'th';
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return `${months[date.getMonth()]} ${ordinalSuffix(date.getDate())}`;
  };

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        onClick={stopEventPropagation}
        fullWidth
        maxWidth={false}
        sx={{
          '& .MuiDialog-paper': {
            width: '1310px',
            padding: '20px',
          },
        }}
      >
        <DialogTitle>
          <div className="CardHeading">Channel: {channel}</div>
        </DialogTitle>
        <DialogContent>
          <div>
            <h3 style={{ color: '#414446' }}>Expand to see detailed campaign info and 'Edit Details' to make changes.</h3>

            <div
              style={{
                borderRadius: '4px',
                border: '1px solid #D9D6E3',
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
            >
              {channelData.map((data, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'center',
                      }}
                    >
                      <div onClick={() => toggleExpand(index)}>{expandedRows.includes(index) ? <CustomArrowUp /> : <CustomArrowDown />}</div>
                    </Grid>
                    <Grid item xs={8}>
                      <h3
                        style={{
                          color: 'var(--col-12, #852598)',
                          fontWeight: '700',
                        }}
                      >
                        {data.segment_name}
                      </h3>
                      {data.offers.map((offer, oIndex) => (
                        <div key={oIndex}>
                          <h3>
                            {offer.offer_name}: {offer.template_name}
                          </h3>
                        </div>
                      ))}

                      {editStates.find((state) => state.segment_sk === data.segment_sk)?.edit ? (
                        <>
                          <hr style={{ width: '90%', marginLeft: '0px' }}></hr>
                          <h2 style={{ marginBottom: '20px', marginTop: '20px' }}>Edit Date Time</h2>

                          <DateTimeInput
                            onDateTimeChange={(newDateTime) => handleDateTimeChange(newDateTime, data.segment_sk)}
                            dateTimeData={{
                              startDate: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_start_datetime.split(' ')[0],
                              startTime: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_start_datetime.split(' ')[1],
                              endDate: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_end_datetime.split(' ')[0],
                              endTime: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_end_datetime.split(' ')[1],
                            }}
                          />
                        </>
                      ) : (
                        <h4 style={{ marginTop: '10px' }}>
                          Start date {formatDate(selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_start_datetime) || 'no start date'}
                          {' with '}
                          {'end date ' + formatDate(selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_end_datetime) || 'no end date'}
                        </h4>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginRight: '100px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        {/* {!enableStates.find(
                          (state) => state.segment_sk === data.segment_sk
                        )?.active && (
                          <div style={{ width: "20px" }}>
                            <h2 style={{ marginRight: "10px" }}>Off</h2>
                          </div>
                        )} */}

                        <Switch
                          checked={enableStates.find((state) => state.segment_sk === data.segment_sk)?.active}
                          onChange={handleEnableSwitchChange(index, data.segment_sk)}
                          color="secondary"
                          style={{ position: 'absolute' }}
                        />

                        {enableStates.find((state) => state.segment_sk === data.segment_sk)?.active ? (
                          <div style={{ width: '20px' }}>
                            <h2 style={{ marginRight: '10px' }}>On</h2>
                          </div>
                        ) : (
                          <div style={{ width: '20px' }}>
                            <h2 style={{ marginRight: '10px' }}>Off</h2>
                          </div>
                        )}
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {editStates.find((state) => state.segment_sk === data.segment_sk)?.edit ? (
                          <h3
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              fontWeight: '700',
                              color: '#A35BB1',
                            }}
                          >
                            <div
                              onClick={handleEditButtonClick(data.segment_sk, index)}
                              style={{
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                              // disabled={
                              //   !enableStates.find(
                              //     (state) => state.segment_sk === data.segment_sk
                              //   )?.active
                              // }
                            >
                              <CustomSaveIcon />
                            </div>
                            {' save'}
                          </h3>
                        ) : (
                          ''
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  {expandedRows.includes(index) && (
                    <Grid container sx={{ marginTop: '10px' }}>
                      <Grid item xs={1}>
                        {''}
                      </Grid>
                      <Grid item xs={11}>
                        <div style={{ display: 'grid', gap: '10px' }}>
                          {editStates.find((state) => state.segment_sk === data.segment_sk)?.edit ? (
                            <>
                              <hr style={{ width: '90%', marginLeft: '0px' }}></hr>
                              <h2>Edit Spending Limit</h2>
                              <SpendingLimit
                                onLimitChange={(newDateTime) => handleSpendingLimitChange(newDateTime, data.segment_sk)}
                                spendingLimit={{
                                  max: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_max_budget,
                                  min: selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_min_budget,
                                  noLimit: false,
                                  period: 'daily',
                                }}
                                flag={false}
                              />
                            </>
                          ) : (
                            <h4>
                              <b>Budget: </b>
                              Min: {selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_min_budget} Max:{' '}
                              {selectedOffers.find((o) => o.segment_sk === data.segment_sk)?.segment_max_budget}{' '}
                            </h4>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {index !== channelData.length - 1 && <hr style={{ marginTop: '15px', marginBottom: '15px' }} />}
                </div>
              ))}
            </div>
            <CustomButton
              isDisabled={false}
              onClick={handleClose}
              buttonValue="Close"
              style={{
                marginLeft: '0',
                marginTop: '20px',
                background: 'white',
                color: '#852598',
                border: '1px solid #852598',
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CardPopUp;
