import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import LeadChart from '../GroupChart/LeadChart';
import CloseIcon from '@mui/icons-material/Close';
import LeadQuilityAlert from './LeadQuilityAlert';
const FunnelMatrics = ({ closeButton, selectedTitle, groupData }) => {
  return (
    <div>
      <Grid container spacing={2} direction="column" sx={{ padding: 0, width: '100%!important' }}>
        <Grid item xs={12} sm={8.6} md={8.6} lg={12}>
          <Grid
            container
            sx={{
              padding: '5px 6px 25px 23px',
            }}
          >
            {/* <Grid item xs={3}>
                    <Grid container spacing={2} style={{ background: '#F6F5F8', width: '250px', padding: '10px', marginTop: '0px', marginLeft: '0px' }}>
                      {ChannelData.map((ChannelData) => {
                        return ChannelData.items.map((channel) => {
                          return (
                            <>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: '#414446',

                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {channel.channel}
                                </Typography>
                                <Typography>{channel.CPL}</Typography>
                              </Box>
                              <BulletChart data={channel.CPL} size={2} />
                            </>
                          );
                        });
                      })}
                    </Grid>
                  </Grid> */}
            <Grid item xs={12} sx={{ padding: '10px' }}>
              <Grid container sx={{ backgroundColor: '#F6F5F8', padding: '22px' }}>
                <Grid lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography onClick={closeButton}>
                    <CloseIcon
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={9.8}>
                  <Box>
                    <Grid container sx={{ padding: '18px 0px' }}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'IBM Plex Serif',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                          }}
                        >
                          {selectedTitle}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <LeadChart groupData={groupData} />
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={2.2}>
                  <LeadQuilityAlert />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FunnelMatrics;
