import axios from "axios";
import { clearAllCookies, clearItem, getItem } from "../view/Utils/CommonFunction/CommonFunction";
import { SEGMENT_OFFER_ONE_CLICK_ALL_GET } from "../common/config";

const prepareHeaders = () => {
    const accessToken = getItem('accessToken');
    return {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

export const segment_offer_one_click_all_get_service = async (payload) => {
    try {
        const response = await axios({
            method: 'get',
            url: SEGMENT_OFFER_ONE_CLICK_ALL_GET,
            headers: prepareHeaders().headers,
            data: payload,
            params: {
                event_type: 'segment',
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status == 401) {
            window.location.reload()
            clearAllCookies();
            clearItem();
        }
        return error;
    }
};
