import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { campaignPageType as PageType, dataSourceData as setDataSourceData, aboutCampaignData as setAboutCampaignData, segmetsData as setSegmentsData } from '../GlobalStore';
import SummaryBox from '../../Utils/SummaryBox/SummaryBox';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import { Grid, Box, CircularProgress } from '@mui/material';
import CustomButton from '../../Utils/CustomButton/CustomButton';
import SegmentsChannel from './SegmentsChannel/SegmentsChannel';
import { getSegmentsAI, getSegmentsManual, getCampaignSummary2 } from '../../../services/CampaignService';
import { newcampaign3 } from '../../../Language/language';
import ErrorPage from '../../../ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import CustomStepper from '../Components/Header/CustomStepper/CustomStepper';
import { getCampaignDataSourceDetails } from '../../../services/OffersService';

const Segments = () => {
  const navigate = useNavigate();
  const { sk, pk } = useParams();
  const dataSourceData = useReactiveVar(setDataSourceData);
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);
  const [getCampaignSummaryData, setCampaignSummary] = useState(null);
  const [segmentsAIAPI, setSegmentsAIAPI] = useState([]);
  const [segmentsManualAPI, setSegmentsManualAPI] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const closeModal = () => {
    setShowModal(false);
  };

  const segmetsData = useReactiveVar(setSegmentsData);

  const handleNextButtonClick = () => {
    setLoading(true);
    var raw = JSON.stringify({
      segments: segmetsData?.channelType == 'Ai' ? segmetsData?.AIData : segmetsData?.manualData,
      segments_type: segmetsData?.channelType,
      campaign_pk: `campaign#${pk}`,
      campaign_sk: `campaign#${sk}`,
    });

    (async () => {
      const response = await getCampaignSummary2(raw);
      if (response?.status == 200) {
        setCampaignSummary(response?.data?.response?.summary);
        setLoading(false);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setLoading(false);
      }
    })();

    navigate(`/create-campaign/offer/${sk}/${pk}`);
  };
  const handlePrevButtonClick = () => {
    navigate(`/create-campaign/data-source/${sk}/${pk}`);
  };
  useEffect(() => {
    if (dataSourceData.AIData.length == 0) {
      (async () => {
        setLoading(true);
        const _sk = `campaign#${sk}`;
        const _pk = `campaign#${pk}`;
        const response = await getCampaignDataSourceDetails(_sk, _pk);
        if (response?.status === 200) {
          setLoading(false);
          const sourceData = Object.keys(response?.data?.response?.data_sources).map((e) => {
            return {
              name: e,
              document_pk: response?.data?.response?.data_sources[e]?.pk,
              document_sk: response?.data?.response?.data_sources[e]?.sk,
            };
          });
          const dataSourceData = setDataSourceData();
          setDataSourceData({
            ...dataSourceData,
            AIData: [...sourceData],
          });

          const selectedSegment = Object.keys(response?.data?.response?.selected_segment).map((e) => {
            return {
              name: e,
              segment_pk: response?.data?.response?.selected_segment[e].pk,
              segment_sk: response?.data?.response?.selected_segment[e].sk,
            };
          });
          setSegmentsData({
            ...segmetsData,
            channelType: response?.data?.response?.segments_type,
            AIData: response?.data?.response?.segments_type === 'manual' ? [...segmentsAIAPI] : [...selectedSegment],
            manualData: response?.data?.response?.segments_type === 'manual' ? [...selectedSegment] : [],
          });
        } else {
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
          setTimeout(() => {
            navigate('/create-campaign');
          }, 5000);
        }
      })();
    } else {
      let channelData = dataSourceData.AIData;

      var raw = JSON.stringify({
        data_sources: channelData,
        data_sources_type: 'Ai',
        campaign_pk: `campaign#${pk}`,
        campaign_sk: `campaign#${sk}`,
      });

      (async () => {
        const response = await getCampaignSummary2(raw);
        if (response?.status == 200) {
          setCampaignSummary(response?.data?.response?.summary);
          setLoading(false);
        } else {
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
          setLoading(false);
        }
      })();
    }
  }, [dataSourceData.AIData]);

  // useEffect(() => {
  //   let channelData = dataSourceData.AIData;

  //   var raw = JSON.stringify({
  //     data_sources: channelData,
  //     data_sources_type: 'Ai',
  //     campaign_pk: `campaign#${pk}`,
  //     campaign_sk: `campaign#${sk}`,
  //   });

  //   (async () => {
  //     const response = await getCampaignSummary2(raw);
  //     if (response?.status == 200) {
  //       setCampaignSummary(response?.data?.response?.summary);
  //       setLoading(false);
  //     } else {
  //       seterrormsg(response?.response?.data?.error);
  //       setShowModal(true);
  //       setLoading(false);
  //     }
  //   })();
  // }, [dataSourceData]);

  useEffect(() => {
    (async () => {
      const response = await getSegmentsAI('');
      if (response?.status == 200) {
        setSegmentsAIAPI(response.data);
        setTimeout(() => {}, 2000);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      (async () => {
        const response = await getSegmentsManual('segment');
        if (response?.status == 200) {
          setSegmentsManualAPI(response.data);
        } else {
          seterrormsg(response?.response?.data?.error);
          setShowModal(true);
        }
      })();
    }
  }, [isLoading]);

  return (
    <>
      <CustomStepper type={2} />
      {segmentsAIAPI && segmentsManualAPI ? (
        <div className="campaign-container">
          <div className="customHR"></div>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              {segmentsAIAPI && segmentsManualAPI && (
                <div style={{ display: 'grid', gap: '20px' }}>
                  <div className="headerText"> {newcampaign3.headlineText1} </div>
                  <div className="normalText">{newcampaign3.subTitle1}</div>
                  {segmentsAIAPI && segmentsManualAPI && <SegmentsChannel segmentsAIAPI={segmentsAIAPI} segmentsManualAPI={segmentsManualAPI} />}

                  <div style={{ marginTop: '20px' }}>
                    <CustomButton
                      isDisabled={false}
                      onClick={handlePrevButtonClick}
                      buttonValue="Prev step"
                      style={{
                        color: '#852598',
                        backgroundColor: '#ffffff',
                        border: '1px solid #852598',
                      }}
                    />
                    <CustomButton isDisabled={!segmentsAIAPI || !segmentsManualAPI} onClick={handleNextButtonClick} buttonValue="Next" style={{}} />
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box display="flex">{getCampaignSummaryData && <SummaryBox campaignSummaryData={getCampaignSummaryData} />}</Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            padding: '70px 0px',
          }}
        >
          <LoadingScreen gifType={2} />
        </div>
      )}
      {showModal && <ErrorPage closingFlag={2} closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

export default Segments;
