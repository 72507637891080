import { Box, Typography } from "@mui/material";
import React from "react";

const AppAnalytics = ({ analytics }) => {
  return (
    <Box
      sx={{
        display: "flex",
        border: "1px solid #ebe8f1",
        backgroundColor: "#f6f5f8",
        gap: "1rem 1.5rem",
        padding: "0.5rem",
        borderRadius: "5px",
        justifyContent: "space-between",
      }}
    >
      {Object.keys(analytics).map((keys) => (
        <div
          style={{
            minWidth: "2.5rem",
          }}
        >
          <Typography
            sx={{
              color: "#5d596c",
              fontFamily: "Roboto",
              fontSize: "0.7rem",
              textTransform: "capitalize",
            }}
          >
            {keys}
          </Typography>
          <Typography
            sx={{
              color: "#414446",
              fontFamily: "IBM Plex Serif",
              fontSize: "0.9",
            }}
          >
            {analytics[keys]}
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default AppAnalytics;
