import { CUSTOM_INTIGRATION_CONTACT_LOADING, IS_CUSTOM_INTIGRATION_CONTACT_ERROR, IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS, RESET_IS_CUSTOM_INTIGRATION_CONTACT } from '../Constant';

let initialState = {
  CustomcontactReducer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
};

export default function CustomcontactReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOM_INTIGRATION_CONTACT_LOADING:
      return {
        ...state,
        CustomcontactReducer: {
          ...state.CustomcontactReducer,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case IS_CUSTOM_INTIGRATION_CONTACT_SUCCESS:
      return {
        ...state,
        CustomcontactReducer: {
          ...state.CustomcontactReducer,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case IS_CUSTOM_INTIGRATION_CONTACT_ERROR:
      return {
        ...state,
        CustomcontactReducer: {
          ...state.CustomcontactReducer,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_IS_CUSTOM_INTIGRATION_CONTACT:
      return {
        ...state,
        CustomcontactReducer: {
          ...state.CustomcontactReducer,
          loading: false,
          error: false,
          errors: null,
          success: false,
          data: null,
        },
      };

    default:
      return state;
  }
}
