import { IS_USER_UPLOADED_FILES_ERROR, IS_USER_UPLOADED_FILES_LOADING, IS_USER_UPLOADED_FILES_SUCCESS, RESET_IS_USER_UPLOADED_FILES } from "../Constant";

let initialState = {
    useruploadedfile: {
        loading: false,
        data: null,
        error: false,
        errors: null,
        success: false,
        pagination: {},
    },
};

export default function UserUploadedFileReducer(state = initialState, action) {
    switch (action.type) {
        case IS_USER_UPLOADED_FILES_LOADING:
            return {
                ...state,
                useruploadedfile: {
                    ...state.useruploadedfile,
                    loading: true,
                    error: false,
                    errors: [],
                    success: false,
                },
            };

        case IS_USER_UPLOADED_FILES_SUCCESS:
            return {
                ...state,
                useruploadedfile: {
                    ...state.useruploadedfile,
                    loading: false,
                    error: false,
                    errors: [],
                    success: true,
                    data: action.response.data,
                },
            };

        case IS_USER_UPLOADED_FILES_ERROR:
            return {
                ...state,
                useruploadedfile: {
                    ...state.useruploadedfile,
                    loading: false,
                    error: true,
                    errors: action.errors,
                    success: false,
                    data: null,
                },
            };

        case RESET_IS_USER_UPLOADED_FILES:
            return {
                ...state,
                useruploadedfile: {
                    ...state.useruploadedfile,
                    loading: false,
                    error: false,
                    errors: null,
                    success: false,
                },
            };

        default:
            return state;
    }
}