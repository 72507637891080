import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import DrawerComponent from './DrawerComponent';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../Utils/CommonFunction/CommonFunction';

const MainHeader = () => {
  const navigate = useNavigate();
  const token = getCookie('accessToken');
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMatch ? (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={2}>
              <img
                alt="header"
                style={{
                  marginLeft: '1.7vh',
                  height: '4.43vh',
                  width: '17.60vh',
                }}
                onClick={() => {
                  if (token) {
                    navigate('/onboarding');
                  } else {
                    navigate('/login');
                  }
                }}
                src="https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/image-255@2x.png"
              />
            </Grid>
            <Grid item xs={2} marginLeft="auto" textAlign="right">
              <DrawerComponent />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container direction="row" className="mainHeader" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2}>
            <img
              alt="header"
              style={{
                marginLeft: '1.7vh',
                height: '4.43vh',
                width: '17.60vh',
              }}
              src="https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/image-255@2x.png"
              onClick={() => {
                if (token) {
                  navigate('/onboarding');
                } else {
                  navigate('/login');
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MainHeader;
