import secureLocalStorage from 'react-secure-storage';

export const setItem = (key, value) => {
  return secureLocalStorage.setItem(key, value);
};

export const getItem = (key) => {
  return secureLocalStorage.getItem(key);
};

export const removeItem = (key) => {
  return secureLocalStorage.removeItem(key);
};

export const clearItem = () => {
  // window.location.reload(true);
  return secureLocalStorage.clear();
};

export const setCookie = (name, value, minutes) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + minutes * 60 * 1000);
  const expires = 'expires=' + expirationDate.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};
export const removeCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  // window.location.reload(true);
};
export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie has the name we're looking for
    if (cookie.startsWith(name + '=')) {
      // Extract and return the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }
  // If the cookie with the given name is not found, return null or handle as needed
  return null;
};

export const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
  ClientId: process.env.REACT_APP_CLIENT_ID, // Your client id here
};
