import axios from 'axios';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';
import { IS_USER_REGISTERED } from '../common/config';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const UserRegisteredService = async (payload) => {
  try {
    const response = await axios({
      method: 'GET',
      url: IS_USER_REGISTERED,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.reload();
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};
