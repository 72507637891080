import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { InputBox } from '../../Utils/input-box/InputBox';
import Objective from '../Components/Objective/Objective';
import Channel from './AboutChannel/AboutChannel';

import CustomButton from '../../Utils/CustomButton/CustomButton';
import { getAboutCampaigns, getCampaignSummary } from '../../../services/CampaignService';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import { newcampaign, CommonLanguage } from '../../../Language/language';
import ErrorPage from '../../../ErrorPage';

import { aboutCampaignData as setAboutCampaignData, snackBarMsg as setSnackBarMsg} from '../GlobalStore';
import { Snackbar, Alert, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SummaryBox from '../../Utils/SummaryBox/SummaryBox';
import CustomStepper from '../Components/Header/CustomStepper/CustomStepper';

export default function AboutCampaign() {
  const campaignData = useReactiveVar(setAboutCampaignData);
  const snackBarMsg = useReactiveVar(setSnackBarMsg);
  const navigate = useNavigate();
  const [getCampaignSummaryData, setCampaignSummary] = useState(false);

  const [CampaignName, setCampaignName] = useState(campaignData?.CampaignName || '');
  const [BriefDescription, setBriefDescription] = useState(campaignData?.BriefDescription || '');
  const [isSelectedDataCorrect, setIsSelectedDataCorrect] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [ObjectiveAPI, setObjectiveAPI] = useState(false);
  const [AIInput, setAIInput] = useState(false);
  const [manualInput, setManualInput] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const closeModal = () => {
    setShowModal(false);
  };
  const [isLoading, setisLoading] = useState(false);

  const updateCampaignData = (key, value) => {
    const currentCampaignData = setAboutCampaignData();
    setAboutCampaignData({
      ...currentCampaignData,
      [key]: value,
    });
  };

  useEffect(() => {
    setIsNextEnabled(!CampaignName || !BriefDescription || !isSelectedDataCorrect);
  }, [CampaignName, BriefDescription, isSelectedDataCorrect]);

  useEffect(() => {
    setCampaignName(campaignData?.name || '');
    setBriefDescription(campaignData?.description || '');
  }, [campaignData]);

  useEffect(() => {
    (async () => {
      setisLoading(true);
      const response = await getAboutCampaigns();
      if (response?.status === 200) {
        setisLoading(false);
        setObjectiveAPI(response?.data?.objective);
        setAIInput(response?.data?.ai_generated_channel_allocation);
        setManualInput(response?.data?.channel_vendors);
      } else {
        seterrormsg(response?.response?.data?.error);
        setisLoading(false);
        setShowModal(true);
      }
    })();
  }, []);

  const transformManualData = (data) => {
    const transformedData = [];

    Object?.entries(data)?.forEach(([channel, vendorsObj]) => {
      const vendors = Object?.entries(vendorsObj)
        ?.filter(([vendor, selected]) => selected)
        ?.map(([vendor, selected]) => vendor);
      if (vendors.length > 0) {
        transformedData.push({ channel, vendors });
      }
    });

    return transformedData;
  };

  const handleNextButtonClick = () => {
    setisLoading(true)
    let channelData = [];

    if (campaignData.channelType === 'Ai') {
      channelData = campaignData.AIdata;
    } else {
      const transformedManualData = transformManualData(campaignData?.manualData);
      channelData = transformedManualData;
    }

    var raw = JSON.stringify({
      name: campaignData.name,
      objective: campaignData.objective,
      selected_channel_vendors: channelData,
      description: campaignData.description,
      channel_type: campaignData.channelType,
    });
    (async () => {
      const response = await getCampaignSummary(raw);
      if (response?.status === 200) {
        const currentCampaignData = setAboutCampaignData();

        const sk = response?.data?.response?.campaign_sk
        const pk = response?.data?.response?.campaign_pk

        setAboutCampaignData({
          ...currentCampaignData,
          campaign_pk: pk,
          campaign_sk: sk,
        });
        setCampaignSummary(response?.data?.response?.summary);
        navigate(`/create-campaign/data-source/${(sk).split('#')[1]}/${(pk).split('#')[1]}`);
      } else {
        seterrormsg(response?.response?.data?.error);
        setShowModal(true);
        setTimeout(() => {
          navigate('/create-campaign')
        }, 5000);
      }
    })();
  };

  useEffect(() => {
    if (snackBarMsg === true) {
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        setSnackBarMsg(false);
      }, 3000);
    }
  }, [snackBarMsg]);

  return (
    <>
      <CustomStepper type={0} />
      {ObjectiveAPI && AIInput && manualInput ? (
        <>
          <Grid item sm={12} md={6} lg={5}>
            <Box display="flex" justifyContent="flex-end">
              {getCampaignSummaryData && <SummaryBox campaignSummaryData={getCampaignSummaryData} />}
            </Box>
          </Grid>
          <div className="campaign-container">
            <div className="customHR"></div>
            <div className="headerText">{newcampaign.headlineText1} </div>
            <div className="normalText">{newcampaign.subTitle1}</div>
            <div className="staredText">{CommonLanguage.fieldrequiredText}</div>
            <InputBox
              placeholder={'Enter Campaign Name*'}
              isMultiLine={false}
              rows={1}
              onChange={(value) => {
                setCampaignName(value);
                updateCampaignData('name', value);
              }}
              value={CampaignName}
            />
            <InputBox
              placeholder={'Brief Description*'}
              isMultiLine={true}
              rows={4}
              onChange={(value) => {
                setBriefDescription(value);
                updateCampaignData('description', value);
              }}
              value={BriefDescription}
            />
            <div className="customHR"></div>
            <div className="headerText"> {newcampaign.headlineText2} </div>
            <div className="normalText">{newcampaign.subTitle2}</div>

            {ObjectiveAPI && <Objective ObjectiveAPI={ObjectiveAPI} />}

            <div className="customHR"></div>
            <div className="headerText"> {newcampaign.headlineText3} </div>
            <div className="normalText">{newcampaign.subTitle3}</div>
            <div
              style={{
                borderRadius: '5px',
                border: '1px solid #EBE8F1',
              }}
            >
              {ObjectiveAPI && AIInput && manualInput && <Channel setCorrect={setIsSelectedDataCorrect} ObjectiveAPI={ObjectiveAPI} AIInput={AIInput} manualInput={manualInput} />}
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <CustomButton
              isDisabled={isNextEnabled || isLoading}
              onClick={handleNextButtonClick}
              buttonValue={isLoading ? "Loading ...." : 'Next'}

              style={{
                marginLeft: '20px',
                marginTop: '20px',
              }}
            />
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '32px',
                padding: '70px 0px',
              }}
            >
              <LoadingScreen gifType={1} />
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{
          style: { backgroundColor: 'red' },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          Campaign name is already in use. Please choose a unique name for your campaign.
        </Alert>
      </Snackbar>
      {showModal && <ErrorPage closingFlag={-1} closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
}
