import React from 'react';
import Select, { components } from 'react-select';
import FilterHorizontal from '../../../assets/filterHorizontal.svg';
import { Box } from '@mui/material';

const FilterHorizontalSvg = () => <img src={FilterHorizontal} alt="By Top Performing" />;

const DropDown = ({ defaultValue, value, options, handleChange, placeholder }) => {
  const styles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #a35bb1',
      boxShadow: 'none',
      ':hover': {
        border: '1px solid #a35bb1',
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      with: '100%',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#a35bb1',
    }),
    input: (provided) => ({
      ...provided,
      color: '#a35bb1',
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: 'inherit',
      textTransform: 'capitalize',
      padding: '8px 12px',
      fontSize: 'inherit',
      fontWeight: 'Normal',
      width: '100%',
      
    }),
    group: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      width: 'unset',
    }),
    menuList: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      width: '100%',
      wordBreak: 'keep-all',
      padding: 0,
    }),
    option: (provided) => ({
      ...provided,
    }),
  };

  // const DropdownIndicator = (props) => (
  //   <components.DropdownIndicator {...props}>
  //     <FilterHorizontalSvg />
  //   </components.DropdownIndicator>
  // );

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 1rem',
          height: '100%',
          gap: '1rem',
          width: 'fit-content',
          cursor:'pointer'
        }}
      >
        <FilterHorizontalSvg />
        <div>{children}</div>
      </div>
    </components.Control>
  );

  const Group = ({ children, options, data, ...props }) => (
    <components.Group {...props}>
      <Box sx={{ marginLeft: '1rem' }}>{children.map((child) => child)}</Box>
    </components.Group>
  );

  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      components={{ Control, Group }}
      styles={styles}
      placeholder={placeholder}
      // menuIsOpen
    />
  );
};

export default DropDown;
