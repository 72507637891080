import { Grid } from '@mui/material';
import React from 'react';
import SearchSideBar from './SearchSideBar';
import MainSearch from './MainSearch';
import { Outlet } from 'react-router-dom';

const Search = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={10} sm={2.5} md={2.5} lg={2.1}>
          <SearchSideBar />
        </Grid>
        <Grid item xs={12} sm={8.6} md={8.6} lg={9.9}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
