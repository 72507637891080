import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';

const NewPersona = () => {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <Box sx={{ backgroundColor: '#ffffff', width: '100%', height: '100%', borderRadius: '6px', marginLeft: '18px' }}>
        <Grid
          container
          style={{
            borderBottom: '1px solid #EBE8F1',
            padding: '11px 22px',
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                lineHeight: '40px',
                color: '#414446',
              }}
            >
              All Personas
            </Typography>
          </Grid>
          {/* <Grid xs={2}>icon</Grid> */}
          <Grid item xs={7}>
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                textTransform: 'capitalize',
                marginBottom: '11px',
              }}
            >
              Persona Name
            </Typography>
            <TextField type="text" fullWidth size="medium" placeholder="Name here" />

            <Grid container>
              <Box
                sx={{
                  width: '326px',
                  height: '480px',
                  borderRadius: '5px',
                  border: '1px solid #EBE8F1',
                  background: '#F6F5F8',
                  marginTop: '11px',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    padding: '19px 15px',
                  }}
                >
                  Filters
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 303, background: '#fff!important' }}>
                  <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled style={{ display: 'none' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require('./asset/new_person1.png')} alt="" width={20} />{' '}
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            paddingLeft: '15px',
                          }}
                        >
                          {' '}
                          Job Titles{' '}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 303, background: '#fff!important' }}>
                  <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled style={{ display: 'none' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require('./asset/new_person2.png')} alt="" width={20} />{' '}
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            paddingLeft: '15px',
                          }}
                        >
                          {' '}
                          Industry & Krywords{' '}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 303, background: '#fff!important' }}>
                  <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled style={{ display: 'none' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require('./asset/new_person3.png')} alt="" width={20} />{' '}
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            paddingLeft: '15px',
                          }}
                        >
                          {' '}
                          Location{' '}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>{' '}
                <FormControl sx={{ m: 1, minWidth: 303, background: '#fff!important' }}>
                  <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled style={{ display: 'none' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require('./asset/new_person4.png')} alt="" width={20} />{' '}
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            paddingLeft: '15px',
                          }}
                        >
                          {' '}
                          #employees{' '}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '17px' }}>
            <Button
              type="submit"
              fullWidth
              sx={{
                width: '181px',
                height: '48px',
                fontSize: '14px',
                textTransform: 'capitalize',

                backgroundColor: 'transparent',
                border: '1px solid #9C27B5',
                color: '#9C27B5',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
              sx={{
                width: {
                  lg: '181px',
                  height: '48px',
                  marginLeft: '7px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                },
              }}
            >
              Save Persona
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default NewPersona;
