import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

const MarketingAutomationData = () => {
  // ForStyle
  const subHeading = {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontStyle: 'normal',
    lineHeight: '18px',
    color: '#9853A6',
  };

  const button = {
    color: '#A35BB1',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '700',
    padding: '0px',
  };
  const Heading = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '600',
    color: '#000000',
  };
  const text = {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    color: '#5D596C',
  };
  const MARKETING_DATA = [
    {
      MainTitle: 'Apps & Data Integration',
      Engagement: '85%',
      CPL: '$0.03',
      Quality: '30%',
      Connected: '3',
      ConnectedData: 'Connected',
      Available: '2',
      AvailableData: 'More available',
    },
    {
      MainTitle: 'Segments',
      Engagement: '35%',
      CPL: '$0.20',
      Quality: '30%',
      Connected: '3',
      ConnectedData: 'Connected',
      Available: '12',
      AvailableData: 'More available',
    },
    {
      MainTitle: 'Apps & Data Integration',
      Engagement: '85%',
      CPL: '$6.5',
      Quality: '30%',
      Connected: '3',
      ConnectedData: 'Connected',
      Available: '2',
      AvailableData: 'More available',
    },
  ];
  return (
    <div>
      {MARKETING_DATA.map((data) => (
        <Grid container>
          <Grid item xs={3}>
            <Typography sx={Heading}>{data.MainTitle}</Typography>
            <Typography
              sx={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                color: '#414446',
              }}
            >
              <span>{data.Connected}</span> <span sx={text}>{data.ConnectedData}</span>
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: '10px 30px' }}>
            <Typography
              sx={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                color: '#414446',
                paddingTop: '12px',
              }}
            >
              <span>{data.Available}</span> <span sx={text}> {data.AvailableData}</span>
            </Typography>
            <Button sx={button}>Add More</Button>
          </Grid>
          <Grid item xs={2} sx={{ padding: '10px 30px' }}>
            <Typography sx={subHeading}>Engagement</Typography>{' '}
            <Typography
              sx={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                color: '#414446',
              }}
            >
              {data.Engagement}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: '10px 30px' }}>
            <Typography sx={subHeading}>Cost / Lead</Typography>{' '}
            <Typography
              sx={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                color: '#414446',
              }}
            >
              {data.CPL}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: '10px 30px' }}>
            <Typography sx={subHeading}>Quality</Typography>{' '}
            <Typography
              sx={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                color: '#414446',
              }}
            >
              {data.Quality}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default MarketingAutomationData;
