import { Box, Typography } from "@mui/material";
import React from "react";

const Data = ({ value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto",
        borderLeft: "1px solid #ebe8f1",
        padding: "0 1.5rem",
      }}
    >
      <Typography
        sx={{
          color: "#5d596c",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        }}
      >
        Total Records
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#a35bb1",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "IBM Plex Serif",
            fontSize: "24px",
          }}
        >
          {value ?? 0}
        </Typography>
      </Box>
    </Box>
  );
};

export default Data;
