import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Tab,
    Typography,
    alpha,
    styled,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';

  import { Outlet, useLocation, useNavigate } from 'react-router-dom';
 
  import { getItem } from '../../view/Utils/CommonFunction/CommonFunction';
 
  import { TabContext, TabList, TabPanel } from '@mui/lab';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

  import SearchIcon from '@mui/icons-material/Search';
  
  const Icon = ({ icon, alt }) => <img src={icon} alt={alt} />;
  
  const SearchSideBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      if (location.pathname === '/segments' && location.state?.scrollToChannel) {
        const channelComponent = document.getElementById('channelComponent');
        if (channelComponent) {
          channelComponent.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  
   
  
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    useEffect(() => {
      const accessToken = getItem('accessToken');
      if (accessToken) setIsAuthenticated(true);
      else navigate('/');
    }, []);
    const [value, setValue] = React.useState('1');
    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      
    };
    
  
    const commonStyle = {
      margin: '0 !important',
    };
  
    const mainTitle = {
      background: expanded ? '#3E004A' : '#fff',
      color: expanded ? '#fff' : '#414446',
      minHeight: '48px!important',
      margin: '0!important',
    };
  
    const Search = styled('div')(({ theme }) => ({
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    }));
  
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
      color: 'inherit',
      '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
    }));
  
    const StyledCheckbox = styled(Checkbox)(`
    color: #A35BB1;
  
    &.Mui-checked {
      color: #A35BB1;
    }
  `);
  
    const SearchIconWrapper = styled('div')(({ theme }) => ({
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }));
  
    return (
      <Grid container direction="column" sx={{ backgroundColor: { lg: '#F6F5F8', md: '#FAF8F7', xs: '#fff' } }}>
        <Grid item>
         
              <Box
                sx={{
                  width: '100%',
                  marginBottom: '14px',
                  bgcolor: 'background.paper',
                  borderRadius: '0px 8px 8px 0px',
                }}
              >
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                      <Tab label="Search" value="1" />
                      <Tab label="Saved Search" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ padding: '10px!important' }}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                    </Search>
                  </TabPanel>
                  <TabPanel value="2">Item Two</TabPanel>
                </TabContext>
              </Box>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" sx={expanded === 'panel1' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Persona</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography
                      sx={{
                        color: '#3E004A',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Manage Personas
                    </Typography>
                    <List sx={{borderBottom:'1px solid #EBE8F1'}}>
                      <ListItem>
                        <FormControlLabel control={<StyledCheckbox />} style={{ color: '#000', padding: '0px!important' }} />
                        <Typography sx={{ color: '#5D596C', fontFamily: 'Roboto', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight: '151.188%' }}>
                          Lorem ipsum dolor sit
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <FormControlLabel control={<StyledCheckbox />} style={{ color: '#000', padding: '0px!important' }} /> 
                        <Typography sx={{ color: '#5D596C', fontFamily: 'Roboto', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight: '151.188%' }}>
                          Lorem ipsum dolor sit
                        </Typography>
                      </ListItem>{' '}
                      <ListItem>
                        <FormControlLabel control={<StyledCheckbox />} style={{ color: '#000', padding: '0px!important' }} />
                        <Typography sx={{ color: '#5D596C', fontFamily: 'Roboto', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight: '151.188%' }}>
                          Lorem ipsum dolor sit
                        </Typography>
                      </ListItem>
                    </List>
                    <Box  style={{ paddingTop: '17px' }}>
               
                      <Button
                        type="submit"
                      onClick={()=>{navigate('/Search/AllPersona')}}
                        fullWidth
                        sx={{
                          width: '104.883px',
                          fontSize: '14px',
                          textTransform: 'capitalize',
                     
                          backgroundColor: 'transparent',
                          border: '1px solid #9C27B5',
                          color: '#9C27B5',
                        }}
                      >
                    All Personas
                      </Button>
                      <Button
                      onClick={()=>{navigate('/Search/NewPersona')}}
                        
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth
                        sx={{
                          width: {
                            lg: '104.883px',
                          marginLeft: '7px',
                          fontSize: '14px',
                            textTransform: 'capitalize',
                          },
                        }}
                      >
                        + Persona 
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" sx={expanded === 'panel2' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Name</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header" sx={expanded === 'panel3' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Location</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header" sx={expanded === 'panel4' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Buying Intent</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header" sx={expanded === 'panel5' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Scores</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={commonStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header" sx={expanded === 'panel6' ? mainTitle : commonStyle}>
                  <Typography sx={{ flexShrink: 0 }}>Signals</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
           
    

      </Grid>
    );
  };
  
  export default SearchSideBar;
  