import { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import reporting from '../../../assets/reporting.svg';
import youtube from '../../../assets/Youtube.svg';
import call from '../../../assets/call.svg';
import file from '../../../assets/file.svg';
import DataSampleFileModal from '../DataSampleFileModal';
import { useNavigate } from 'react-router-dom';
import { dashboard, CommonLanguage } from '../../../Language/language';

const RepostingImportData = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/imports');
  };
  const [sampleData, setsampleData] = useState(false);
  const closeSampleData = () => setsampleData(false);

  const handleShowModal = () => {
    setsampleData(true);
  };
  const handleLinkClickYoutube = () => {
    window.open('https://www.synergi.ai/howto/create-offers', '_blank');
  };
  const handleLinkClickContactUS = () => {
    window.open('https://www.synergi.ai/request-synergi-demo', '_blank');
  };
  return (
    <div>
      <Grid
        container
        sx={{
          borderBottom: '1px solid #EBE8F1',
          padding: '11px 22px',
        }}
      >
        <Grid item xs={10}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              lineHeight: '40px',
              color: '#000',
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
          >
            {dashboard.pagetitle}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          padding: '33px 22px',
          paddingBottom: '38px',
        }}
      >
        <Grid item sm={12} xs={12} md={12} lg={6} xl={7}>
          <Box
            sx={{
              width: { xl: '603px', sm: 'auto' },
              height: { lg: '272px', sm: '280px', xs: '340px' },
              flexShrink: '0',
              borderRadius: '6px',
              border: ' 1px solid #D0B6D6',
              background: ' #EFE7EF',
            }}
          >
            <Grid container sx={{ padding: '27px 28px' }}>
              <Grid item xs={2} lg={1}>
                <img src={reporting} alt="reportingIcon" />
              </Grid>
              <Grid item xs={10} lg={11} sx={{ paddingLeft: '10px' }}>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '26px',
                  }}
                >
                  {dashboard.headlineText1}
                </Typography>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '151.188%' /* 21.166px */,
                    marginTop: '20px',
                  }}
                >
                  {dashboard.subTitle1}
                </Typography>

                <Grid item xs={12} sx={{ paddingTop: '37px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    onClick={handleButtonClick}
                    sx={{
                      width: {
                        xs: '207px',
                        fontSize: '16px',
                        textTransform: 'capitalize',
                      },
                      height: '48px',
                    }}
                  >
                    {dashboard.reportingbtn}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={5}
          sx={{
            borderLeft: '1px solid #EBE8F1',
            paddingLeft: '35px',
            paddingTop: '25px',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: '#414446',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '26px',
              }}
            >
              {dashboard.headlineText2}
            </Typography>

            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '24px',
              }}
              onClick={handleShowModal}
            >
              <img src={file} alt="File icon" />
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '151.188%',
                  paddingLeft: '15px',
                  cursor: 'pointer',
                }}
              >
                {dashboard.sublist1}
              </Typography>
            </Typography>

            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '24px',
              }}
              onClick={handleLinkClickYoutube}
            >
              <img src={youtube} alt="youtube icon" />
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '151.188%',
                  paddingLeft: '15px',
                  cursor: 'pointer',
                }}
              >
                {CommonLanguage.videoguide}
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '24px',
              }}
              onClick={handleLinkClickContactUS}
            >
              <img src={call} alt="call icon" />
              <Typography
                sx={{
                  color: '#414446',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '151.188%',
                  paddingLeft: '15px',
                  cursor: 'pointer',
                }}
              >
                {CommonLanguage.contacttext}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {sampleData && <DataSampleFileModal closeSampleData={closeSampleData} />}
    </div>
  );
};

export default RepostingImportData;
