import { CustomDirectMailIcon, CustomEMailIcon, CustomTelemarketingIcon, CustomSmsIcon, CustomSocialMediaIcon, CustomPaidDisplayIcon, CustomPaidSearchIcon } from './icons';

const channelIconMapping = {
  'direct mail': CustomDirectMailIcon,
  email: CustomEMailIcon,
  telemarketing: CustomTelemarketingIcon,
  sms: CustomSmsIcon,
  'social media': CustomSocialMediaIcon,
  'paid display': CustomPaidDisplayIcon,
  'paid search': CustomPaidSearchIcon,
};

export default channelIconMapping;
