import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import AccountExitImage from "../../assets/Success.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";

const AccountExistsDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    {
      navigate("/login");
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open}
    //  onClose={handleClose}
     >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ mb: 10 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ p: 3, paddingBottom: 2 }}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  sx={{
                    color: "#414446",
                    fontFamily: "IBM Plex Serif",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "40px",
                  }}
                >
                  Account already exists
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  sx={{
                    color: "var(--col-12, #414446)",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "173.7%",
                  }}
                >
                  It looks like you’re already part of the Synergi family! Would
                  you like to log in instead?
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    textTransform: "capitalize",
                    width: "10rem",
                    height: "48px",
                    borderRadius: "6px",
                    background: "#852598",
                    marginTop: "2vh",
                  }}
                  onClick={handleGoToLogin}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sx={{ width: "241px", height: "153px", flexShrink: 0 }}
              >
                <img src={AccountExitImage} alt="success" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AccountExistsDialog;
