import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import Password from './component/Password';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';
import { clearAllCookies, clearItem, getItem, poolData } from '../../../Utils/CommonFunction/CommonFunction';
import { Profilestep2, CommonLanguage } from '../../../../Language/language';
import { SETTINGS_BASIC_PROFILE, SETTINGS_GET_PROFILE_PICTURE, SETTINGS_PROFILE_UPLOAD_PICTURE, SETTING_PROFILE_POST_SETTING_INFORMATION } from '../../../../common/config';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import defaultProfile from '../../../../assets/Profile.svg';

const Profile = () => {
  const { id } = useParams();
  let accessToken = getItem('accessToken');
  const [loading, setLoading] = useState(true);
  const [imgUploading, setImgUploading] = useState(false);
  const inputRef = useRef(null);
  const [image, setImage] = useState('');
  const [preasignedURl, setpreasignedURl] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [value, setValue] = useState({
    id: id,
    company_name: '',
    mobile: '',
    first_name: '',
    last_name: '',
    email: '',
    designation: '',
  });
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = new CognitoUser({
    Username: value?.email,
    Pool: userPool,
  });

  const handleImageClick = () => {
    inputRef.current.click();
  };
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,50}$/, 'Password must meet the criteria'),
    confirmPassword: Yup.string()
      .required('Confirm new password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const [isPassLoading, setIsPassLoading] = useState(false);
  const [isPassSuccess, setIsPassSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: () => {
      const authenticationData = {
        Username: cognitoUser.getUsername(),
        Password: formik.values.currentPassword,
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);
      setIsPassLoading(true);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          cognitoUser.changePassword(formik.values.currentPassword, formik.values.newPassword, (err) => {
            if (err) {
              setErrorMessage(err.message);
            } else {
              setSuccessMessage('Password changed successfully.');
              setTimeout(() => {
                setSuccessMessage('');
              }, 2000);
              setIsPassLoading(false);
              setIsPassSuccess(true);
              setErrorMessage('');
              formik.resetForm();
            }
          });
        },
        onFailure: (err) => {
          setSuccessMessage('');
          setErrorMessage(err.message);
        },
      });
    },
  });

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  //   if (file !== undefined) {
  //     const requestOptions = {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         'content-type': 'multipart/form-data',
  //       },
  //       url: SETTINGS_PROFILE_UPLOAD_PICTURE,
  //       data: image,
  //     };
  //     axios(requestOptions)
  //       .then((resp) => {
  //         setImage(resp);
  //         const data = resp?.data?.upload_data?.url;
  //         setpreasignedURl(data);
  //       })
  //       .catch((error) => {
  //         if (error?.response?.status == 401) {
  //           window.location.reload()
  //           clearAllCookies();
  //           clearItem();
  //         }
  //       });
  //   }
  // };

  const handleImageChange = (event) => {
    setImgUploading(true);
    const file = event.target.files[0];
    setImageURL(file ? URL?.createObjectURL(file) : imageURL ? imageURL : image);

    if (file !== undefined) {
      // First, fetch the pre-signed URL from your server or wherever it's available.
      // Replace 'FETCH_PRE_SIGNED_URL' with the actual endpoint to fetch the URL.
      axios
        .get(SETTINGS_PROFILE_UPLOAD_PICTURE, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'multipart/form-data',
          },
        })
        .then((response) => {
          const preSignedURL = response.data.url;
          setpreasignedURl(preSignedURL);
          const formData = {
            AWSAccessKeyId: response?.data?.fields?.AWSAccessKeyId,
            key: response?.data?.fields?.key,
            policy: response?.data?.fields?.policy,
            signature: response?.data?.fields?.signature,
          };
          const newFormData = new FormData();
          Object.entries(formData).forEach(([k, v]) => {
            newFormData.append(k, v);
          });
          newFormData.append('x-amz-security-token', response?.data?.fields['x-amz-security-token']);
          newFormData.append('file', file);
          const requestOptions = {
            method: 'POST', // Use PUT method for uploading to pre-signed URLs
            headers: {
              'Content-Type': file.type,
            },
            data: newFormData,
            url: preSignedURL,
          };

          axios(requestOptions)
            .then((uploadResponse) => {
              setImgUploading(false);
              // Handle the response after successfully uploading the image
            })
            .catch((uploadError) => {
              setImgUploading(false);
              // Handle any errors that may occur during the upload
              console.error('Error uploading image:', uploadError);
            });
        })
        .catch((error) => {
          setImgUploading(false);
          // Handle any errors that may occur when fetching the pre-signed URL
          console.error('Error fetching pre-signed URL:', error);
        });
    } else {
      setImgUploading(false);
    }
  };

  const handleUpdatedDataSubmit = (e) => {
    const newPassWordValue = formik.values;
    if (newPassWordValue.newPassword) {
      formik.handleSubmit();
    }
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEditProfile = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`, // accessToken should be defined in your component's scope
        'Content-Type': 'application/json', // Usually for POST requests with formData, but you might want to check if this is necessary for your API
      };

      const postData = {
        given_name: value.first_name,
        phone_number: value.mobile,
        family_name: value.first_name,
        designation: value.designation,
      };
      setIsLoading(true);
      // Replace 'YOUR_API_ENDPOINT' with the endpoint to which you are sending the request
      await axios
        .post(SETTING_PROFILE_POST_SETTING_INFORMATION, postData, { headers })
        .then((res) => {
          setIsSuccess(true);
          setIsLoading(false);
          return res;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {}
  };

  useEffect(() => {
    axios
      .get(SETTINGS_GET_PROFILE_PICTURE, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // Get the image URL from the response
        const imageUrl = response.data;

        // Set the image URL in the state
        setImageURL(imageUrl);
        setImage(imageURL);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  }, []);

  useEffect(() => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    axios
      .get(SETTINGS_BASIC_PROFILE, { headers })
      .then((res) => {
        setValue({
          ...value,
          email: res.data.email,
          designation: res.data.designation,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          mobile: res.data.mobile,
          company_name: res.data.company_name,
        });
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          window.location.reload();
          clearAllCookies();
          clearItem();
        }
      });
  }, []);

  return (
    <div>
      {/* <SettingFront /> */}
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '32px',
          }}
        >
          <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" />
        </div>
      ) : (
        <>
          <Typography
            style={{
              color: '#414446',
              fontFamily: 'IBM Plex Serif',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingBottom: '12px',
              marginTop: '25px',
            }}
          >
            {Profilestep2.headlineText1}
          </Typography>
          <Grid container>
            <Grid item sx={2}>
              <Box>
                {imgUploading ? (
                  <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" />
                ) : (
                  <>
                    <img
                      src={imageURL ? imageURL : defaultProfile}
                      alt=""
                      style={{
                        borderRadius: '50%',
                        width: '107px',
                        height: '107px',
                        objectFit: 'contain',
                      }}
                    />
                    <input type="file" ref={inputRef} style={{ display: 'none' }} accept="image/*" onChange={handleImageChange} />
                  </>
                )}
              </Box>
            </Grid>
            <Grid
              item
              sx={10}
              style={{
                paddingLeft: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography style={{ paddingBottom: '10px' }}>{Profilestep2.photomsg}</Typography>
              <Typography style={{ paddingBottom: '10px' }}>{Profilestep2.photosize}</Typography>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                sx={{
                  width: {
                    lg: '146px',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                  },
                }}
                onClick={handleImageClick}
              >
                {Profilestep2.uploadPhotobtn}
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: '#414446',
                  fontFamily: 'IBM Plex Serif',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  paddingBottom: '12px',
                  marginTop: '25px',
                }}
              >
                {Profilestep2.headlineText2}
              </Typography>
            </Grid>
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel1}
              </Typography>
              <TextField
                id="email"
                fullWidth
                size="medium"
                placeholder="Email"
                value={value.email}
                disabled
                // onChange={(e) => setValue({ ...value, email: e.target.value })}
              />
            </Grid>
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel2}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="First name"
                value={value.first_name}
                onChange={(e) => setValue({ ...value, first_name: e.target.value })}
              />
            </Grid>
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel3}
              </Typography>

              <TextField type="text" fullWidth size="medium" placeholder="Last name" value={value.last_name} onChange={(e) => setValue({ ...value, last_name: e.target.value })} />
            </Grid>{' '}
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel4}
              </Typography>

              <TextField
                type="email"
                fullWidth
                size="medium"
                placeholder="Email"
                value={value.email}
                disabled
                //  onChange={(e) => setValue({ ...value, email: e.target.value })}
              />
            </Grid>
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel5}
              </Typography>
              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Company name"
                value={value.company_name}
                disabled
                //  onChange={(e) =>
                //   setValue({ ...value, company_name: e.target.value })
                // }
              />
            </Grid>{' '}
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel6}
              </Typography>

              <TextField
                type="text"
                fullWidth
                size="medium"
                placeholder="Designation"
                value={value.designation}
                onChange={(e) => setValue({ ...value, designation: e.target.value })}
              />
            </Grid>{' '}
            <Grid xs={10} item>
              <Typography
                variant="h3"
                style={{
                  color: '#000',
                  fontFamily: 'Roboto,sans-serif',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  marginTop: '22px',
                  marginBottom: '9px',
                }}
              >
                {Profilestep2.inputlabel7}
              </Typography>

              <TextField
                type="text" // Changed to "tel" for better mobile compatibility
                fullWidth
                size="medium"
                placeholder="Mobile"
                value={value.mobile}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow empty input to enable deletion using backspace
                  if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
                    // If the input is numeric and less than or equal to 10 characters, update the state
                    setValue((prevState) => ({ ...prevState, mobile: inputValue }));
                  }
                }}
                onKeyDown={(e) => {
                  // Allow backspace key to clear the input
                  if (e.key === 'Backspace') {
                    setValue((prevState) => ({ ...prevState, mobile: e.target.value.slice(0, -1) }));
                  }
                }}
                inputProps={{
                  maxLength: 10,
                  pattern: /^\d*$/, // This pattern allows only numbers
                }}
              />
              <Grid xs={12} item style={{ paddingTop: '25px' }}>
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  onClick={handleEditProfile}
                  color="secondary"
                  fullWidth
                  sx={{
                    width: 'auto',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                  }}
                >
                  {isLoading ? 'Loading....' : 'Change Profile Information'}
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                borderTop: '1px solid #EBE8F1',
                marginTop: '50px',
                paddingTop: '10px',
              }}
            >
              <Password successMessage={successMessage} errorMessage={errorMessage} formik={formik} />
            </Grid>
            <Grid xs={12} item style={{ paddingTop: '25px' }}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleUpdatedDataSubmit}
                color="secondary"
                fullWidth
                disabled={isPassLoading}
                sx={{
                  width: '118px',
                  fontSize: '16px',
                  textTransform: 'capitalize',
                }}
              >
                {isPassLoading ? 'Loading...' : `${CommonLanguage.updatebtn}`}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {/* profile */}
      <Dialog open={isSuccess}>
        <div style={{ padding: '25px' }}>
          <DialogTitle>Profile Updated Successfully</DialogTitle>
          <DialogContent>
            <DialogContentText>Your profile information has been successfully updated. All changes have been saved and are now visible on your profile.</DialogContentText>
            <DialogContentText style={{ marginTop: '15px' }}>Thank you for keeping your details current!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSuccess(false)} color="secondary">
              Done
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* password */}
      <Dialog open={isPassSuccess}>
        <div style={{ padding: '25px' }}>
          <DialogTitle>Password Changed Successfully</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your password has been updated successfully. You will now use your new password to log in to your account. For your security, please remember to keep your password
              confidential and do not share it with others.
            </DialogContentText>
            <DialogContentText style={{ marginTop: '15px' }}>Thank you for ensuring the security of your account!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsPassSuccess(false)} color="secondary">
              Done
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Profile;
