import React, { useEffect, useState, useRef } from 'react';
import CustomAutocomplete from '../../../../Utils/CustomAutocomplete/CustomAutocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CustomArrowDown, CustomArrowUp, CustomEditIcon } from '../../../../Utils/icons/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DateTimeInput from '../../../../Utils/DateTimeInput/DateTimeInput';
import SpendingLimit from '../../../../Utils/SpendingLimit/SpendingLimit';
import CustomButton from '../../../../Utils/CustomButton/CustomButton';
import { CustomExclamationMark } from '../../../../Utils/icons/icons';
import { offersData as setOffersData } from '../../../GlobalStore';
import { useReactiveVar } from '@apollo/client';

import { Dialog, DialogTitle, DialogContent, Checkbox, Collapse, Select, MenuItem } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';

const ChannelPopUp2 = ({ open, handleClose, channelData, globalDateTime, globalSpendingLimit, globalBatchData, handleSave }) => {
  const segment_offers = channelData.segment_offers;
  const vendor_templates = [{ template_pk: 'Select', template_sk: 'Design', template_name: 'Template' }, ...channelData.vendor_templates];
  const defaultValue = `Select,Design,Template`;

  const offersData = useReactiveVar(setOffersData);
  const [editMode, setEditMode] = useState({});
  const [tempTracking, setTempTracking] = useState('');
  const [expandedSegment, setExpandedSegment] = useState(segment_offers[0]?.segment_sk);
  const inputRefs = useRef({});

  const matchingChannel = offersData?.find((offer) => offer.channel === channelData.channel);
  const initializeCheckedSegments = () => {
    const newCheckedSegments = [];

    matchingChannel?.segment_offers?.forEach((segment) => {
      const dateTimeDifferent =
        segment.segment_start_datetime !== `${globalDateTime.startDate} ${globalDateTime.startTime}` ||
        segment.segment_end_datetime !== `${globalDateTime.endDate} ${globalDateTime.endTime}`;

      const spendingLimitDifferent =
        segment.segment_no_limit !== globalSpendingLimit.noLimit ||
        segment.segment_budget_type !== globalSpendingLimit.period ||
        segment.segment_min_budget !== globalSpendingLimit.min ||
        segment.segment_max_budget !== globalSpendingLimit.max ||
        segment.segment_batch_size !== globalBatchData.batch_size ||
        segment.segment_batch_type !== globalBatchData.batch_type;

      if (dateTimeDifferent || spendingLimitDifferent) {
        newCheckedSegments.push(segment.segment_sk);
      }
    });

    return newCheckedSegments;
  };
  const [checkedSegments, setCheckedSegments] = useState(initializeCheckedSegments());

  const [selectedOffers, setSelectedOffers] = useState(
    matchingChannel?.segment_offers ||
      channelData.segment_offers.map((segment) => ({
        segment_name: segment.segment_name,
        segment_pk: segment.segment_pk,
        segment_sk: segment.segment_sk,
        segment_start_datetime: `${globalDateTime.startDate} ${globalDateTime.startTime}`,
        segment_end_datetime: `${globalDateTime.endDate} ${globalDateTime.endTime}`,
        segment_min_budget: globalSpendingLimit.min,
        segment_max_budget: globalSpendingLimit.max,
        segment_budget_type: globalSpendingLimit.period,
        segment_no_limit: globalSpendingLimit.noLimit,
        segment_batch_size: globalBatchData.batch_size,
        segment_batch_type: globalBatchData.batch_type,
        segment_tracking: segment.tracking,
        offers: [],
      }))
  );
  const handleCheckboxToggle = (segment_sk) => {
    setCheckedSegments((prevChecked) => {
      if (prevChecked.includes(segment_sk)) {
        return prevChecked.filter((sk) => sk !== segment_sk);
      }
      return [...prevChecked, segment_sk];
    });
  };

  const handleSegmentToggle = (segmentSk) => {
    if (expandedSegment === segmentSk) {
      setExpandedSegment(null);
    } else {
      setExpandedSegment(segmentSk);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#9c27b0',
      },
    },
  });

  const handleSelection = (segment, values) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment.segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      let updatedOffers = [];

      values.forEach((valueOffer) => {
        const offerIndex = updatedSegment?.offers?.findIndex((o) => o.offer_sk === valueOffer.offer_sk);

        const formattedValue = {
          offer_name: valueOffer.offer_name,
          offer_sk: valueOffer.offer_sk,
          offer_pk: valueOffer.offer_pk,
          offer_segment_count: valueOffer.offer_segment_count,
        };

        if (offerIndex !== -1) {
          updatedOffers.push({
            ...updatedSegment.offers[offerIndex],
            ...formattedValue,
          });
        } else {
          updatedOffers.push(formattedValue);
        }
      });

      updatedSegment.offers = updatedOffers;

      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;

      return newSelectedOffers;
    });
  };

  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  const handleVendorChange = (segment_sk, offer_sk, event) => {
    const { value } = event.target;
    if (value === null) {
      setSelectedOffers([...selectedOffers]);
      setSelectedOffers((prevOffers) => {
        const segmentIndex = prevOffers.findIndex((s) => s.segment_sk === segment_sk);

        if (segmentIndex !== -1) {
          const offerIndex = prevOffers[segmentIndex].offers.findIndex((offer) => offer.offer_sk === offer_sk);

          if (offerIndex !== -1) {
            const offerToUpdate = prevOffers[segmentIndex].offers[offerIndex];
            offerToUpdate.template_pk = '';
            offerToUpdate.template_sk = '';
            offerToUpdate.template_name = '';
          }
        }
        return [...prevOffers];
      });
    } else {
      const [selectedTemplatePk, selectedTemplateSk, selectedTemplateName] = value?.split(',');
      setSelectedOffers((prevOffers) => {
        const segmentIndex = prevOffers.findIndex((s) => s.segment_sk === segment_sk);

        if (segmentIndex !== -1) {
          const offerIndex = prevOffers[segmentIndex].offers.findIndex((offer) => offer.offer_sk === offer_sk);

          if (offerIndex !== -1) {
            const offerToUpdate = prevOffers[segmentIndex].offers[offerIndex];
            offerToUpdate.template_pk = selectedTemplatePk;
            offerToUpdate.template_sk = selectedTemplateSk;
            offerToUpdate.template_name = selectedTemplateName || '';
          }
        }
        return [...prevOffers];
      });
    }
  };

  const removeOffer = (segmentSk, offerToRemove) => {
    setSelectedOffers((prevOffers) => {
      const segmentIndex = prevOffers.findIndex((seg) => seg.segment_sk === segmentSk);

      const updatedSegment = { ...prevOffers[segmentIndex] };
      updatedSegment.offers = updatedSegment.offers.filter((offer) => offer.offer_sk !== offerToRemove.offer_sk);

      const updatedOffers = [...prevOffers];
      updatedOffers[segmentIndex] = updatedSegment;
      return updatedOffers;
    });
  };

  const formatDateAndTime = (dateTime) => {
    if (!dateTime) return {};
    const [date, time] = dateTime.split(' ');

    return { date, time };
  };

  const handleBatchSizeChange = (segment_sk, data) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      updatedSegment.segment_batch_size = data;
      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;

      return newSelectedOffers;
    });
  };

  const handleBatchPeriodChange = (segment_sk, data) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      updatedSegment.segment_batch_type = data;
      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;

      return newSelectedOffers;
    });
  };

  const handleDateTimeChange = (segment_sk, data) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      updatedSegment.segment_start_datetime = `${data.startDate} ${data.startTime}`;
      updatedSegment.segment_end_datetime = `${data.endDate} ${data.endTime}`;
      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;

      return newSelectedOffers;
    });
  };

  const handleLimitChange = (segment_sk, limitData) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      updatedSegment.segment_min_budget = limitData.min;
      updatedSegment.segment_max_budget = limitData.max;
      updatedSegment.segment_budget_type = limitData.period;
      updatedSegment.segment_no_limit = limitData.noLimit;
      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;
      return newSelectedOffers;
    });
  };

  const handleTrackingChange = (segment_sk, value) => {
    setSelectedOffers((prevState) => {
      const segmentIndex = prevState.findIndex((s) => s.segment_sk === segment_sk);

      const updatedSegment = { ...prevState[segmentIndex] };
      updatedSegment.segment_tracking = value;
      const newSelectedOffers = [...prevState];
      newSelectedOffers[segmentIndex] = updatedSegment;
      return newSelectedOffers;
    });
  };

  const getTrackingForSegment = (segment_sk) => {
    const segmentFromSelectedOffers = selectedOffers.find((s) => s.segment_sk === segment_sk);
    return segmentFromSelectedOffers ? segmentFromSelectedOffers.segment_tracking : null;
  };

  const saveChanges = (segment_sk) => {
    handleTrackingChange(segment_sk, tempTracking);
    setEditMode((prevState) => ({ ...prevState, [segment_sk]: false }));
  };

  const isDisabled = () => {
    if (!selectedOffers || selectedOffers.length === 0) return true;

    for (let segment of selectedOffers) {
      if (!segment.offers || segment.offers.length === 0) {
        return true;
      }

      for (let offer of segment.offers) {
        if (!offer.template_sk || offer.template_sk === 'Design') {
          return true;
        }
      }
    }

    return false;
  };

  const handleSaveClick = () => {
    handleSave(selectedOffers);
  };

  const getSelectedTemplateValue = (segmentSK, offerSK, offer) => {
    const matchedSegment = selectedOffers.find((seg) => seg.segment_sk === segmentSK);
    if (matchedSegment) {
      const matchedOffer = matchedSegment.offers.find((off) => off.offer_sk === offerSK);
      if (matchedOffer && matchedOffer.vendor_template) {
        return matchedOffer.vendor_template;
      }
    }
    if (offer.template_pk && offer.template_sk && offer.template_name) {
      return `${offer.template_pk},${offer.template_sk},${offer.template_name}`;
    } else {
      return defaultValue;
    }
  };

  const extractDateTime = (datetime) => {
    return {
      date: formatDateAndTime(datetime).date,
      time: formatDateAndTime(datetime).time,
    };
  };

  const getDateTimeData = (segmentSK) => {
    const matchedSegment = selectedOffers.find((seg) => seg.segment_sk === segmentSK);
    if (matchedSegment && matchedSegment.segment_start_datetime) {
      const startDateTime = extractDateTime(matchedSegment.segment_start_datetime);
      const endDateTime = extractDateTime(matchedSegment.segment_end_datetime);

      return {
        startDate: startDateTime.date,
        startTime: startDateTime.time,
        endDate: endDateTime.date,
        endTime: endDateTime.time,
      };
    }
    return globalDateTime;
  };

  const getSpendingLimitData = (segmentSK) => {
    const matchedSegment = selectedOffers.find((seg) => seg.segment_sk === segmentSK);
    if (matchedSegment && matchedSegment.segment_min_budget !== undefined) {
      return {
        period: matchedSegment.segment_budget_type,
        min: matchedSegment.segment_min_budget,
        max: matchedSegment.segment_max_budget,
        noLimit: matchedSegment.segment_no_limit,
      };
    }
    return globalSpendingLimit;
  };

  const getBatchData = (segmentSK) => {
    const matchedSegment = selectedOffers.find((seg) => seg.segment_sk === segmentSK);
    if (matchedSegment && matchedSegment.segment_min_budget !== undefined) {
      return {
        batch_size: matchedSegment.segment_batch_size,
        batch_type: matchedSegment.segment_batch_type,
      };
    }
    return globalSpendingLimit;
  };

  useEffect(() => {
    const doesChannelExist = offersData.some((offer) => offer.channel === channelData.channel);

    if (!doesChannelExist) {
      const updatedOffers = selectedOffers.map((segment) => {
        return {
          ...segment,
          segment_start_datetime: `${globalDateTime.startDate} ${globalDateTime.startTime}`,
          segment_end_datetime: `${globalDateTime.endDate} ${globalDateTime.endTime}`,
          segment_min_budget: globalSpendingLimit.min,
          segment_max_budget: globalSpendingLimit.max,
          segment_budget_type: globalSpendingLimit.period,
          segment_no_limit: globalSpendingLimit.noLimit,
          segment_batch_size: globalBatchData.batch_size,
          segment_batch_type: globalBatchData.batch_type,
        };
      });

      setSelectedOffers(updatedOffers);
    }
  }, [globalDateTime, globalSpendingLimit, globalBatchData]);

  return (
    <>
      <style>
        {`
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield; /* for Firefox */
          }
        `}
      </style>
      <Dialog
        open={open}
        // onClose={handleClose}
        onClick={stopEventPropagation}
        fullWidth
        maxWidth={false}
        sx={{
          '& .MuiDialog-paper': {
            width: '1310px',
            padding: '20px',
          },
        }}
      >
        <DialogTitle>
          <>
            <div
              className="headerText"
              style={{
                fontSize: '24px',
                fontStyle: 'IBM Plex Serif;',
                fontWeight: '400',
              }}
            >
              {channelData.channel} Offers
            </div>
            <div className="customHR"></div>
            <div
              className="normalText"
              style={{
                width: '700px',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            >
              Explore the auto-selected offers for this channel. Modify selections as desired, or proceed with AI-generated choices.
            </div>
          </>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: '715px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              border: '1px solid #D9D6E3',
              background: '#F0EAF1',
              padding: '15px',
            }}
          >
            <h2
              style={{
                marginLeft: '45px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              Segment & Offers
            </h2>
          </div>
          <div
            style={{
              width: '705px',
              borderLeft: '1px solid #D9D6E3',
              borderRight: '1px solid #D9D6E3',
              borderBottom: '1px solid #D9D6E3',
              background: '#FFF',
              padding: '20px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          >
            {segment_offers.map((segment, index) => (
              <React.Fragment key={segment.segment_sk}>
                <div key={segment.segment_sk} onClick={() => handleSegmentToggle(segment.segment_sk)}>
                  <h3
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ width: '40px', cursor: 'pointer' }}>
                      {expandedSegment === segment.segment_sk ? <CustomArrowUp style={{ marginLeft: '10px' }} /> : <CustomArrowDown style={{ marginLeft: '10px' }} />}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '85%',
                      }}
                    >
                      <div
                        style={{
                          marginTop: '2px',
                          fontWeight: '700',
                          color: '#852598',
                        }}
                      >
                        {segment.segment_name}
                      </div>

                      {expandedSegment !== segment.segment_sk &&
                        (selectedOffers.find((c) => c.segment_sk === segment.segment_sk)?.offers.length > 0 ? (
                          `Review ${selectedOffers.find((c) => c.segment_sk === segment.segment_sk)?.offers.length} offers selected`
                        ) : (
                          <h4
                            style={{
                              fontWeight: '600',
                              color: '#E08029',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '12px',
                            }}
                          >
                            <div
                              style={{
                                marginRight: '8px',
                                marginTop: '2px',
                              }}
                            >
                              <CustomExclamationMark />
                            </div>
                            Select Offers & Template
                          </h4>
                        ))}
                    </div>
                  </h3>
                  <h3 style={{ marginLeft: '40px' }}>Total Target Data: {segment.segment_total_records}</h3>
                </div>

                <Collapse in={expandedSegment === segment.segment_sk}>
                  <div style={{ width: '661px' }}>
                    <div
                      style={{
                        marginBottom: '15px',
                        marginLeft: '40px',
                        width: '70%',
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: '600',
                          color: '#000',
                          marginBottom: '15px',
                        }}
                      >
                        matched offers
                      </h3>
                      <ThemeProvider theme={theme}>
                        <CustomAutocomplete segment={segment} selectedOffers={selectedOffers} handleSelection={handleSelection} />
                      </ThemeProvider>
                    </div>

                    {(selectedOffers.find((seg) => seg.segment_sk === segment.segment_sk)?.offers || []).map((offer) => {
                      return <div
                      key={offer.offer_sk}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '76%',
                        marginBottom: '5px',
                      }}
                    >
                      <div className="matched-offer">
                        <h3 style={{ marginRight: '20px' }}>{offer.offer_name}</h3>
                        <div
                          onClick={() => removeOffer(segment.segment_sk, offer)}
                          style={{
                            borderRadius: '100%',
                            backgroundColor: '#b2b1b1',
                            color: '#fff',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            width: '16px',
                            height: '16px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          x
                        </div>
                      </div>
                      <ThemeProvider theme={theme}>
                        <Select
                          value={getSelectedTemplateValue(segment.segment_sk, offer.offer_sk, offer) || defaultValue}
                          onChange={(e) => handleVendorChange(segment.segment_sk, offer.offer_sk, e)}
                          style={{
                            width: '230px',
                            height: '31px',
                            marginLeft: '20px',
                            fontSize: '14px',
                          }}
                          // defaultValue={defaultValue}
                          placeholder="select template"
                        >
                          {vendor_templates.map((templateObj) => (
                            <MenuItem
                              key={templateObj.template_sk}
                              value={`${templateObj.template_pk},${templateObj.template_sk},${templateObj.template_name}`}
                              defaultValue={defaultValue}
                            >
                              {templateObj.template_name === 'Template' ? 'Select Design Template' : templateObj.template_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </ThemeProvider>
                    </div>
                    })}
                  </div>
                  <div style={{ marginLeft: '40px' }}>
                    <hr
                      style={{
                        marginTop: '15px',
                        marginBottom: '15px',
                      }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={checkedSegments.includes(segment.segment_sk)}
                        onClick={(e) => {
                          handleCheckboxToggle(segment.segment_sk);
                        }}
                        color="secondary"
                        style={{
                          padding: '0px',
                          marginLeft: '0px',
                          marginTop: '10px',
                          marginBottom: '10px',
                          marginRight: '10px',
                        }}
                        placeholder="Start typing or click to select up to three offers"
                      />
                      <h2 style={{ fontWeight: '400' }}>Apply Segment Settings</h2>
                    </div>
                    {checkedSegments.includes(segment.segment_sk) && (
                      <div style={{ marginTop: '15px' }}>
                        <DateTimeInput dateTimeData={getDateTimeData(segment.segment_sk)} onDateTimeChange={(data) => handleDateTimeChange(segment.segment_sk, data)} />
                        <hr
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '20px',
                            marginBottom: '20px',
                          }}
                        >
                          <div className="normalText">Send in Batches</div>
                          <input
                            value={getBatchData(segment.segment_sk).batch_size}
                            onChange={(event) => handleBatchSizeChange(segment.segment_sk, event.target.value)}
                            placeholder=" Batch size"
                            type="number"
                            style={{
                              marginLeft: '50px',
                              padding: '1px',
                              height: '31px',
                              width: '130px',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              appearance: 'none',
                            }}
                          />
                          <Select
                            value={getBatchData(segment.segment_sk).batch_type}
                            onChange={(event) => handleBatchPeriodChange(segment.segment_sk, event.target.value)}
                            className="normalText"
                            style={{
                              width: '130px',
                              height: '35px',
                              marginLeft: '20px',
                            }}
                          >
                            <MenuItem value="daily" className="normalText" style={{ fontSize: '10px' }}>
                              Daily
                            </MenuItem>
                            <MenuItem value="monthly" className="normalText" style={{ fontSize: '10px' }}>
                              Monthly
                            </MenuItem>
                            <MenuItem value="quarterly" className="normalText" style={{ fontSize: '10px' }}>
                              Quarterly
                            </MenuItem>
                          </Select>
                        </div>
                        <hr
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        />
                        <SpendingLimit onLimitChange={(data) => handleLimitChange(segment.segment_sk, data)} spendingLimit={getSpendingLimitData(segment.segment_sk)} />
                        <hr
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        />
                        <h3 style={{ marginBottom: '10px' }}>Tracking</h3>
                        <div>
                          {editMode[segment.segment_sk] ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                ref={(el) => (inputRefs.current[segment.segment_sk] = el)}
                                className="tracking"
                                value={tempTracking}
                                onChange={(e) => setTempTracking(e.target.value)}
                                onBlur={() => saveChanges(segment.segment_sk)}
                              />

                              <CheckCircle
                                color="secondary"
                                onClick={() => {
                                  setEditMode((prevState) => ({
                                    ...prevState,
                                    [segment.segment_sk]: false,
                                  }));
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <h4 className="tracking">{getTrackingForSegment(segment.segment_sk) || segment.tracking}</h4>
                              <div
                                onClick={() => {
                                  setTempTracking(getTrackingForSegment(segment.segment_sk));
                                  setEditMode((prevState) => ({
                                    ...prevState,
                                    [segment.segment_sk]: true,
                                  }));
                                  setTimeout(() => {
                                    if (inputRefs.current[segment.segment_sk]) {
                                      inputRefs.current[segment.segment_sk].focus();
                                    }
                                  }, 0);
                                }}
                              >
                                <CustomEditIcon />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Collapse>

                {index < segment_offers.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </div>
          <div style={{ marginTop: '20px' }}>
            <CustomButton
              isDisabled={false}
              onClick={handleClose}
              buttonValue="Cancel"
              style={{
                color: '#852598',
                backgroundColor: '#ffffff',
                border: '1px solid #852598',
                marginLeft: '0',
              }}
            />
            <CustomButton isDisabled={isDisabled()} buttonValue="Save" onClick={handleSaveClick} style={{}} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChannelPopUp2;
