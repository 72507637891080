import { Grid, Typography } from '@mui/material';
import React from 'react';

const AnalyzingSpreadsheet = () => {
  return (
    <>
      <Grid>
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'IBM Plex Serif',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '40px',
          }}
        >
          Analyzing Spreadsheet Data
        </Typography>
        <hr />
        <Typography
          sx={{
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
            textTransform: 'capitalize',
            mt: '30px',
          }}
        >
          Matching Insights
        </Typography>
        <Typography
          sx={{
            color: '#5D596C',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
            mt: '10px',
          }}
        >
          As we match extensive data signals to your rate sheet for optimal ROI, rest assured, your data remains secure within Synergi's framework and is not stored or shared
          externally.
          <br />
          <br /> This may take a moment. If you prefer not to wait, click 'Notify Me When Ready'
        </Typography>
        <Typography>
          <img src={require('../../../../assets/Progress-Bar.gif')} width="100%" />
        </Typography>
      </Grid>
    </>
  );
};

export default AnalyzingSpreadsheet;
