import React, { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import AboutAI from "./AboutAI/AboutAI";
import AboutManual from "./AboutManual/AboutManual";
import { aboutCampaignData as setAboutCampaignData } from "../../GlobalStore";

const Channel = ({ setCorrect, ObjectiveAPI, AIInput, manualInput }) => {
  const aboutCampaignData = useReactiveVar(setAboutCampaignData);
  const [selected, setSelected] = useState(
    aboutCampaignData?.channelType || "Ai"
  );

  const StyledBoxAI = styled(Box)(({ theme }) => ({
    marginLeft: "10px",
    width: "205px",
    cursor: "pointer",
    textAlign: "center",
    borderBottom: selected === "Ai" ? "3px solid #764F7D" : "",
  }));

  const StyledBoxManual = styled(Box)(({ theme }) => ({
    width: "205px",
    cursor: "pointer",
    textAlign: "center",
    borderBottom: selected === "manual" ? "3px solid #764F7D" : "",
  }));

  const handleSelection = (selection) => {
    setSelected(selection);
    const currentCampaignData = setAboutCampaignData();
    setAboutCampaignData({
      ...currentCampaignData,
      channelType: selection,
    });
  };

  useEffect(() => {
    if (!aboutCampaignData?.channelType) {
      const currentCampaignData = setAboutCampaignData();
      setAboutCampaignData({
        ...currentCampaignData,
        channelType: "Ai",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponent = () => {
    if (selected === "Ai") {
      return <AboutAI AIInput={AIInput} setCorrect={setCorrect} />;
    } else {
      setCorrect(true);
      return (
        <AboutManual manualInput={manualInput} ObjectiveAPI={ObjectiveAPI} />
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EBE8F1",
          borderRadius: "5px",
          height: "auto",
          display: "contents",
        }}
      >
        <Box display="flex" alignItems="center">
          <StyledBoxAI p={2} onClick={() => handleSelection("Ai")}>
            <h2
              variant="body1"
              style={{
                fontWeight: selected === "Ai" ? "500" : "400",
                color:
                  selected === "Ai"
                    ? "#764F7D"
                    : "var(--text-txt-primary, #112333)",
                fontFamily: "Roboto",
              }}
            >
              AI-Predictive Allocation
            </h2>
          </StyledBoxAI>

          <StyledBoxManual p={2} onClick={() => handleSelection("manual")}>
            <h2
              style={{
                fontWeight: selected === "manual" ? "500" : "400",
                color:
                  selected === "manual"
                    ? "#764F7D"
                    : "var(--text-txt-primary, #112333)",
                fontFamily: "Roboto",
              }}
            >
              Manual Allocation
            </h2>
          </StyledBoxManual>
        </Box>
        <div className="customHR"></div>
        <Box p={2} sx={{ overflow: "auto" }}>
          {renderComponent()}
        </Box>
      </Box>
    </>
  );
};

export default Channel;
