import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import bulbImg from '../../../assets/bulb.png';

const MarketingCard = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
      <Grid container>
        <Grid item xs={1}>
          {' '}
          <img src={bulbImg} alt="" sx={{ width: '20px' }} />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              color: '#414446',
              fontSize: '12px',
              fontStyle: 'normal',

              lineHeight: '18px',
            }}
          >
            Coming Soon
          </Typography>
          <Typography
            sx={{
              color: '#00000',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '18px',
            }}
          >
            Customer Journey Module
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'end' }}>
          <AddCircleOutlineIcon />
        </Grid>
        <Grid container sx={{ paddingTop: '14px' }}>
          <Grid item xs={6} sx={{ paddingRight: '12px' }}>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
          <Grid item xs={6}>
            <LinearProgress variant="determinate" value={0} />
          </Grid>
        </Grid>
        <Grid>
          <Typography
            sx={{
              color: '#414446',
              fontSize: '12px',
              fontStyle: 'normal',

              paddingTop: '17px',
              lineHeight: '18px',
            }}
          >
            This new tool will allow you to map, track, and optimize every customer interaction, providing deeper insights and further personalization at each stage of the customer
            lifecycle. With the potential to enhance your ROI by an additional 3x, the Customer Journey Module is set to revolutionize your marketing automation efforts.
          </Typography>
          <Typography
            sx={{
              color: '#414446',
              fontSize: '12px',
              fontStyle: 'normal',
              paddingTop: '14px',
              paddingBottom: '28px',
              lineHeight: '18px',
            }}
          >
            Stay tuned for this game-changing update!
          </Typography>
          <Button
            sx={{
              color: '#A35BB1',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '700',
              padding: '0px',
            }}
          >
            Add More
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketingCard;
