import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControlLabel, Checkbox, styled } from '@mui/material';

const SpendingLimit = ({ onLimitChange, spendingLimit, flag = true }) => {
  const [period, setPeriod] = useState(spendingLimit?.period || 'daily');
  const [min, setMin] = useState(spendingLimit?.min || 0);
  const [max, setMax] = useState(spendingLimit?.max || 0);
  const [noLimit, setNoLimit] = useState(spendingLimit?.noLimit);

  const StyledCheckbox = styled(Checkbox)(`
  color: #E08029;

  &.Mui-checked {
    color: #E08029;
  }
`);

  const handleNoLimitChange = (event) => {
    const noLimit = event.target.checked;
    onLimitChange({
      noLimit,
      period,
      min,
      max,
    });
    setNoLimit(noLimit);
  };

  const handlePeriodChange = (event) => {
    const period = event.target.value;
    setPeriod(period);
    onLimitChange({
      noLimit,
      period,
      min,
      max,
    });
  };

  const handleMinChange = (event) => {
    const min = event.target.value;
    setMin(min);
    onLimitChange({
      noLimit,
      period,
      min,
      max,
    });
  };

  const handleMaxChange = (event) => {
    const max = event.target.value;
    setMax(max);
    onLimitChange({
      noLimit,
      period,
      min,
      max,
    });
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <FormControlLabel control={<StyledCheckbox checked={noLimit} onChange={handleNoLimitChange} />} label="No spending limit" style={{ color: '#E08029', width: '187px' }} />

      <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
        {flag && (
          <Select
            value={period}
            onChange={handlePeriodChange}
            style={{
              width: '130px',
              height: '35px',
              borderRadius: '6px',
              background: '#FFF',
              marginRight: '20px',
              boxShadow: 'none',
              outline: 'none',
            }}
            MenuProps={{
              MenuListProps: {
                style: {
                  backgroundColor: '#f5f0f3',
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  boxShadow: 'none',
                },
              },
            }}
          >
            <MenuItem value="daily" style={{ fontSize: '10px' }}>
              Daily
            </MenuItem>
            <MenuItem value="monthly" style={{ fontSize: '10px' }}>
              Monthly
            </MenuItem>
            <MenuItem value="quarterly" style={{ fontSize: '10px' }}>
              Quarterly
            </MenuItem>
          </Select>
        )}

        <div style={{ position: 'relative' }}>
          <input
            type="number"
            value={min}
            onChange={handleMinChange}
            placeholder="Min"
            disabled={noLimit}
            style={{
              height: '33px',
              width: '150px',
              padding: '0 10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: '4px',
              color: noLimit ? '#aaa' : '#000',
            }}
          >
            USD
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            marginLeft: '20px',
          }}
        >
          <input
            type="number"
            value={max}
            onChange={handleMaxChange}
            placeholder="Max"
            disabled={noLimit}
            style={{
              height: '33px',
              width: '150px',
              padding: '0 10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: '4px',
              color: noLimit ? '#aaa' : '#000',
            }}
          >
            USD
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendingLimit;
