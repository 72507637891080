import axios from 'axios';
import { USERS_SETTING_URL } from '../common/config';
import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const UsersSettingService = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: USERS_SETTING_URL,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return error;
  }
};
