import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import FilterAndSearch from '../Components/FilterAndSearch';
import ImportedFiles from './ImportedFiles';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import Loader from '../Components/Loader';
import IntigrationPopup from '../Popups/IntigrationPopup';

const TOTAL_DISPLAY_ROW = 5;

const ManageImports = ({ importedFiles, setImportedFiles, selectedFilter, filterValue, handleFilter, isLoading }) => {
  const heading = 'Manage Imports';
  const [allData, setAllData] = useState();
  const [page, setPage] = React.useState(0);
  const [displatDataList, setDisplayDataList] = useState([]);
  const [massageBox, setMassageBox] = useState(true);

  const closeModal = () => {
    setMassageBox(false);
  };

  useEffect(() => {
    if (Array.isArray(importedFiles) && importedFiles.length > 0) {
      setAllData(importedFiles);
      const data = importedFiles.slice(page * TOTAL_DISPLAY_ROW, page * TOTAL_DISPLAY_ROW + TOTAL_DISPLAY_ROW);
      setDisplayDataList(data);
    }
  }, [importedFiles]);

  const handleViewMore = () => {
    setPage(page + 1);
    const data = allData.slice((page + 1) * TOTAL_DISPLAY_ROW, (page + 1) * TOTAL_DISPLAY_ROW + TOTAL_DISPLAY_ROW);
    setDisplayDataList([...displatDataList, ...data]);
  };

  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        marginBottom: '20px',
        borderRadius: '6px',
        boxShadow: 'none',
      }}
    >
      <Header heading={heading} />
      <section>
        <Box padding="1rem">
          <FilterAndSearch
            data={importedFiles}
            selectedFilter={selectedFilter}
            filterValue={filterValue}
            handleFilter={handleFilter}
            setData={setImportedFiles}
            onSearchResult={(results) => {
              setDisplayDataList(results);
              // setCurrentPage(1);
            }}
          />

          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '16.75rem',
                padding: '1rem',
              }}
            >
              <Loader />
            </Box>
          ) : !!displatDataList.length ? (
            <ImportedFiles importedFiles={displatDataList} />
          ) : (
            <Box>{massageBox && <IntigrationPopup closeModal={closeModal} />}</Box>
          )}

          {displatDataList.length >= 5 && (
            <Button
              style={{
                width: '100%',
                height: '4rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1rem',
                color: '#A35BB1',
              }}
              onClick={handleViewMore}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 'Medium',
                  fontSize: '0.7rem',
                  textTransform: 'capitalize',
                }}
              >
                View More
              </Typography>
              <SouthRoundedIcon />
            </Button>
          )}
        </Box>
      </section>
    </Paper>
  );
};

export default ManageImports;
