import React from 'react';
import { ToastContainer } from 'react-toastify';

const NotificationProvider = () => {
  return (
    <ToastContainer
      hideProgressBar
      pauseOnHover
      closeOnClick={true}
      theme="light"
      limit={10}
      style={{
        width: '24rem',
      }}
    />
  );
};

export default NotificationProvider;
