import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import youtube from '../../../assets/Youtube.svg';
import styled from '@emotion/styled';
import { intigrationpopupscreen, CommonLanguage } from '../../../Language/DialogBoxLanguage';


const IntigrationPopup = ({ closeModal }) => {

  const scrollIntoView = () => {
    const csvUpload = document.getElementById('csv_file_upload');
    csvUpload.scrollIntoView({ behavior: 'smooth' }, true);
  };

  const StyledCheckbox = styled(Checkbox)(`
  color: #A35BB1;

  &.Mui-checked {
    color: #A35BB1;
  }
`);
  return (
    <>
      <Card
        sx={{
          mb: 6, borderRadius: 2, padding: '6px', margin: {
            lg: "50px 150px",
            md: "0px 0px"
          }
        }}
        style={{ width: '674px', }}
      >
        <Grid container>
          <Grid container sx={{ padding: '20px 66px ' }}>
            <Grid item xs={12} sx={{ paddingRight: '80px' }}>
              <Typography
                style={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',
                  color: '#414446',
                }}
              >

                {intigrationpopupscreen.headlineText1}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  paddingTop: '18px',
                  fontSize: '14px',
                  color: '#414446',
                }}
              >
                {intigrationpopupscreen.subTitle1}
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  padding: '24px 0px',
                }}
              >
                <Typography style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      color: '#414446',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '173.7%',
                    }}
                  >
                    {CommonLanguage.unsuretext}
                  </Typography>
                </Typography>
                <Link href=" https://www.synergi.ai/howto/data-import" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '31px',
                    }}
                  >
                    <img src={youtube} alt="call icon" style={{ width: '20px' }} />
                    <Typography
                      style={{
                        paddingLeft: '15px',
                        color: '#A35BB1',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '151.188%',
                      }}
                    >
                      {CommonLanguage.introtext}
                    </Typography>
                  </Typography>
                </Link>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={scrollIntoView}
                fullWidth
                sx={{
                  width: ["137px", "137px", "150px", "150px"],
                  height: '48px',
                  marginTop: '20px',
                }}
              >
                {CommonLanguage.letsgobtn}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '25px' }}>
              <FormControlLabel control={<StyledCheckbox />} label="Don't show this again" style={{ color: '#000' }} />
            </Grid>
          </Grid>
        </Grid>
      </Card >
    </>
  );
};
export default IntigrationPopup;
