import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registration: {},
  forget: {},
};

const loginslice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setRegister(state, action) {
      state.registration = action.payload;
      
    },
    setForgetEmail(state, action) {
      state.forget = action.payload;
    },
  },
});

export const loginActions = loginslice.actions;
export const loginReducer = loginslice.reducer;
