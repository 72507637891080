import { clearAllCookies, clearItem, getItem } from '../view/Utils/CommonFunction/CommonFunction';
import { CAPABLE_CAMPAIGN_URL } from '../common/config';
import axios from 'axios';

const prepareHeaders = () => {
  let accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const CapableCampaign = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: CAPABLE_CAMPAIGN_URL,
      headers: prepareHeaders().headers,
    });
    return response;
  } catch (e) {
    if (e?.response?.status == 401) {
      window.location.reload()
      clearAllCookies();
      clearItem();
    }
    return e;
  }
};
