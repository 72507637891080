import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import Popup from './NewUser';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { UserListService } from '../../../../services/UserListService';
import { UserDeleteService } from '../../../../services/UserDeleteService';
import Loader from '../../../integration/Components/Loader';
import ErrorPage from '../../../../ErrorPage';
import SettingFront from '../../SettingFront';
import { useNavigate } from 'react-router-dom';
// import NouserDialog from '../../../offers/Components/Dialogs/NouserDialog';

const User = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [UserList, setUserList] = useState();
  const [SelectedUser, setSelectedUser] = useState();
  const [Loading, setLoading] = useState(false);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [deletedID, setdeletedID] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [errorModal, seterrorModal] = useState(false);
  const [nouserfoundmsg, setnouserfoundmsg] = useState();
  const [hiddenFlag, sethiddenFlag] = useState(1);
  const [nouserErrorModal, setnouserErrorModal] = useState(false);
  const closeErrorModal = () => {
    seterrorModal(false);
    navigate('/setting/profile');
  };
  const closeuserErrorModal = () => {
    setnouserErrorModal(false);
  };
  const [errormsg, seterrormsg] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await UserListService('');
      if (response?.status == 200) {
        setLoading(false);
        setUserList(response?.data);
      } else if (response?.response?.status === 400) {
        setLoading(false);
        setnouserErrorModal(true);
        setnouserfoundmsg(response?.response?.data?.error);
      } else {
        seterrormsg(response?.response?.data?.error);
        seterrorModal(true);
        setLoading(false);
      }
    })();
  }, []);

  const handleDelete = async () => {
    setdeleteLoading(true);
    const responseData = await UserDeleteService({ user_id: deletedID?.user_id, company_name: deletedID?.company_name });
    if (responseData?.status === 200) {
      const response = await UserListService('');
      if (response?.status == 200) {
        setdialogOpen(false);
        setdeleteLoading(false);
        setUserList(response?.data);
      } else {
        setdeleteLoading(false);
        setnouserErrorModal(true);
        setnouserfoundmsg(response?.response?.data?.error);
      }
    } else {
      seterrormsg(responseData?.response?.data?.error);
      seterrorModal(true);
      setdeleteLoading(false);
      setdialogOpen(false);
    }
  };

  return (
    <div>
      {/* <SettingFront /> */}
      <Grid container sx={{ marginBottom: '7px', display: 'flex', alignItems: 'center' }}>
        <Grid item xs={6}>
          <Typography
            style={{
              color: '#414446',
              fontFamily: 'IBM Plex Serif',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingBottom: '12px',
              marginTop: '18px',
            }}
          >
            Users
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            onClick={() => {
              setShowModal(true);
              setSelectedUser('');
              sethiddenFlag(1);
            }}
            fullWidth
            sx={{
              width: '123px',
              fontSize: '14px',
              textTransform: 'capitalize',
            }}
          >
            New User
          </Button>
        </Grid>
      </Grid>
      {!showModal && nouserfoundmsg ? (
        <>{nouserfoundmsg?.title}</>
      ) : Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {showModal ? (
            <Popup closeModal={closeModal} selectedUser={SelectedUser} setUserList={setUserList} />
          ) : (
            <>
              {UserList?.map((v, i) => {
                return (
                  <Grid
                    container
                    sx={{
                      borderTop: '1px solid #EBE8F1',
                      borderBottom: '1px solid #EBE8F1',
                      padding: '16px 0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid item lg={6}>
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <img src={require('../../../../assets/Fake profile.png')} alt="" />
                        </Grid>
                        <Grid item xs={10.5}>
                          {/*
                           */}
                          <Typography
                            sx={{
                              color: '#414446',
                              fontFamily: 'Roboto',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '173.7%',
                            }}
                          >
                            {v?.user_email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box>
                        <Typography
                          sx={{
                            width: '90px',
                            height: '26px',
                            color: '#A35BB1',
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '173.7%',
                            background: '#F6F5F8',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {v?.user_role}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            width: '73px',
                            height: '26px',
                            color: v?.user_status == 'inactive' ? 'red' : 'green',
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '173.7%',
                            background: '#F6F5F8',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {v?.user_status}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          type="submit"
                          fullWidth
                          sx={{
                            width: {
                              lg: '63px',
                            },
                            height: '33px',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            marginRight: '22px',
                            backgroundColor: 'transparent',
                            border: '1px solid #9C27B5',
                            color: '#9C27B5',
                          }}
                          onClick={(e) => {
                            setShowModal(true);
                            setSelectedUser(v);
                            sethiddenFlag(2);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          color="secondary"
                          type="submit"
                          fullWidth
                          sx={{
                            width: {
                              lg: '134px',
                              fontSize: '14px',
                              textTransform: 'capitalize',
                            },
                            height: '33px',
                            fontSize: '14px',
                            textTransform: 'capitalize',

                            backgroundColor: 'transparent',
                            border: '1px solid #9C27B5',
                            color: '#9C27B5',
                            margin: '5px 0px',
                          }}
                          onClick={() => {
                            setdeletedID(v);
                            setdialogOpen(true);
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          )}
        </>
      )}
      {errorModal && <ErrorPage closeModal={closeErrorModal} errormsg={errormsg} />}

      <Dialog
        open={dialogOpen}
        // onClose={closeDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {deleteLoading ? (
              <>
                <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
              </>
            ) : (
              <>Delete</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default User;
