import React, { useState, useEffect } from 'react';
import { Card, CardContent, Switch } from '@mui/material';
import channelIconMapping from '../../../Utils/icons/channelIconMapping';
import CardPopUp from './CardPopUp';

const AllCampaignsCard = ({ channelData, setCampaignData }) => {
  const [open, setOpen] = useState(false);
  const [isOn, setIsOn] = useState(true);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleSwitchChange = (event) => {
    event.stopPropagation();
    setIsOn(!isOn);
  };

  return (
    <>
      <Card
        sx={{
          width: '215.12px',
          height: '130.675px',
          cursor: 'pointer',
          borderRadius: '5px',
          border: '1px solid #EBE8F1',
          background: '#FFF',
          boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.06)',
        }}
        onClick={handleOpen}
      >
        <CardContent>
          <div
            className="headerText"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              textTransform: 'capitalize',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>{/* {React.createElement(channelIconMapping[channelData.channel])} */}</div>
              <div style={{ marginRight: '10px' }}>{channelData.channel}</div>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <Switch checked={isOn} onChange={handleSwitchChange} color="secondary" />
            </div>
          </div>
          <div className="customHR"></div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="card-number" style={{ marginRight: '5px', marginTop: '10px' }}>
              {channelData.total_offers}
            </div>
            <div className="normalText" style={{ marginLeft: '5px', marginTop: '10px' }}>
              matched offers
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}></div>
        </CardContent>

        <CardPopUp open={open} handleClose={handleClose} channelData={channelData.segment_offers} channel={channelData.channel} setCampaignData={setCampaignData} isOn={isOn} />
      </Card>
    </>
  );
};

export default AllCampaignsCard;
