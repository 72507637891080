import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import ViewIcon from '../../../../assets/View.svg';
import CopyIcon from '../../../../assets/Copy.svg';
import DeleteIcon from '../../../../assets/Delete.svg';
import EditIcon from '../../../../assets/Edit.svg';
import moment from 'moment-timezone';
import { deleteOffer } from '../../../../services/OffersService';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { offersActions } from '../../../../store/slices/offers';
import Data from './../../Data';
import ErrorPage from '../../../../ErrorPage';
import { useNavigate } from 'react-router-dom';
import ViewDialog from '../../UI-Components/ViewDialog';

const { setOfferList, setAICraftedClickedItems} = offersActions;

const AppNameCategoryConnection = ({ created, offerData, popoverRef, popover, handlePopoverOpen, handlePopoverClose, setIsPageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { offerList } = useSelector((state) => state.offers);
  const [dialogOpen, setdialogOpen] = useState(false);
  const closeDialog = () => setdialogOpen(false);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const handleDeleteOffer = async () => {
    setIsLoadingDelete(true);
    const payload = { event_sk: offerData.sk, event_pk: offerData.pk };

    const responseData = await deleteOffer(payload);
    if (responseData.status === 200) {
      const newOfferList = offerList.length > 0 && offerList.filter((offer) => offer.sk !== offerData.sk);
      dispatch(setOfferList({ offerList: newOfferList }));
      dispatch(setAICraftedClickedItems({ [offerData.sk]: false }));
      setIsLoadingDelete(false);
      closeDialog();
    } else {
      setIsLoadingDelete(false);
      window.alert('error while delete the offer');
    }
    setIsLoadingDelete(false);
  };

  const handleCopyOffer = async (event) => {
    navigate(`/copy-offer/${offerData.sk.split('#')[1]}/${offerData.pk.split('#')[1]}`);
  };

  const handleEditOffer = async (event) => {
    navigate(`/edit-offer/${offerData.sk.split('#')[1]}/${offerData.pk.split('#')[1]}`);
  };

  const isButttonHidden = offerData?.total_run_campaigns === 0 || !offerData?.total_run_campaigns;

  const toTitleCase = (str) => {
    return str
      ?.split('_') // Split the string at each underscore
      ?.map((word) => word.toUpperCase()) // Capitalize the first letter of each word
      ?.join(' '); // Join the words back together with spaces
  };

  return (
    <>
      <ViewDialog popoverRef={popoverRef} popover={popover} handlePopoverClose={handlePopoverClose} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Roboto',
          lineHeight: 1.5,
          width: '98%',
        }}
      >
        <span
          style={{
            color: '#852598',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            cursor: 'pointer',
          }}
          onClick={(event) => handlePopoverOpen(event, offerData)}
        >
          {offerData?.name}
        </span>
        <ViewDialog popoverRef={popoverRef} popover={popover} handlePopoverClose={handlePopoverClose} />

        <span
          style={{
            color: 'var(--col-8, #112333)',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '149.453%',
            marginTop: '10px',
          }}
        >
          {offerData?.description}
        </span>
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {offerData?.created_by_full_name
            ? `Created by ${offerData?.created_by_full_name} ${offerData?.created_datetime ? moment(offerData?.created_datetime).format('MMMM D, YYYY') : ''}`
            : ''}
        </span>
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {/* {offerData?.created_datetime ? `Created: ${offerData?.created_datetime}` : ''} */}
          {offerData?.modified_datetime ? `Modified At: ${moment(offerData?.modified_datetime).format('MMMM D, YYYY')}` : ''}
        </span>
        {/* <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {offerData?.updated_datetime ? `Updated: ${offerData?.updated_datetime}` : ''}
        </span> */}
        <span
          style={{
            color: 'var(--col-12, #414446)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '173.7%',
          }}
        >
          {offerData?.source ? `Source Data: ${toTitleCase(offerData?.source)}` : ''}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Roboto',
          lineHeight: 1.5,
          width: '100%',
        }}
      >
        <IconButton title="View Offer" onClick={(event) => handlePopoverOpen(event, offerData)}>
          <img src={ViewIcon} alt="add" />
        </IconButton>
        <IconButton title="Copy Offer" onClick={handleCopyOffer}>
          {!isLoadingCopy ? (
            <>
              <img src={CopyIcon} alt="add" />
            </>
          ) : (
            <>
              <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
            </>
          )}
        </IconButton>
        {isButttonHidden && (
          <>
            <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 0.5, width: '10px' }} />
            <IconButton title="Delete Offer" onClick={() => setdialogOpen(true)}>
              <img src={DeleteIcon} alt="add" />
            </IconButton>
            <IconButton title="Edit Offer" onClick={handleEditOffer}>
              {!isLoadingEdit ? (
                <>
                  <img src={EditIcon} alt="add" />
                </>
              ) : (
                <>
                  <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
                </>
              )}
            </IconButton>
          </>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteOffer} color="secondary" disabled={isLoadingDelete}>
            {isLoadingDelete ? (
              <>
                <img src={require('../../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
              </>
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {showModal && <ErrorPage closeModal={closeModal} />}
    </>
  );
};

const UsesCampaign = ({ totalCompaigns }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex',
      fontFamily: 'Roboto',
      width: '100%',
    }}
  >
    <span style={{ color: '#5D596C' }}>Uses</span>
    <div
      style={{
        display: 'flex',
        color: '#A35BB1',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <span style={{ fontFamily: 'IBM Plex Serif', fontSize: '24px' }}>{totalCompaigns}</span>
      <span style={{ fontSize: '12px', fontWeight: 'medium' }}>Campaigns</span>
    </div>
  </div>
);

const ConnectedAnalytics = ({ analytics }) => (
  <Box
    sx={{
      display: 'flex',
      border: '1px solid #ebe8f1',
      backgroundColor: '#f6f5f8',
      gap: '1rem 1.5rem',
      padding: '0.5rem',
      borderRadius: '5px',
      justifyContent: 'space-between',
    }}
  >
    {Object.keys(analytics).map((keys) => (
      <div
        style={{
          minWidth: '2.5rem',
        }}
      >
        <Typography
          sx={{
            color: '#5d596c',
            fontFamily: 'Roboto',
            fontSize: '0.7rem',
            textTransform: 'capitalize',
          }}
        >
          {keys}
        </Typography>
        <Typography
          sx={{
            color: '#414446',
            fontFamily: 'IBM Plex Serif',
            fontSize: '0.9',
          }}
        >
          {analytics[keys]}
        </Typography>
      </div>
    ))}
  </Box>
);

const IntegratedApp = ({ offerDataList, setIsLoading, setIsPageType }) => {
  const [popover, setPopover] = useState({
    open: false,
    anchorEl: null,
    content: '',
  });

  const popoverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopover({ open: false, anchorEl: null, content: '' });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popover]);

  const handlePopoverOpen = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    setPopover({ open: true, anchorEl: event.currentTarget, content });
  };

  const handlePopoverClose = () => {
    setPopover({ open: false, anchorEl: null, content: '' });
  };

  const getCreatedBy = (offerData) => {
    const parsedDate = moment.tz(offerData?.created_datetime, 'YYYY-MM-DD HH:mm:ss', 'America/New_York');

    const formattedDate = parsedDate.format('MM/DD/YY [at] hh:mma');
    const created = `Created by Synergi At ${formattedDate}`;
    return created;
  };

  return (
    <>
      <Table>
        <TableBody>
          {Array.isArray(offerDataList) &&
            offerDataList.length > 0 &&
            offerDataList.map((offerData) => (
              <TableRow sx={{ height: '100%' }}>
                <TableCell
                  sx={{
                    verticalAlign: 'top',
                    padding: '1rem 0',
                    width: '70%',
                  }}
                >
                  <AppNameCategoryConnection
                    created={() => getCreatedBy(offerData)}
                    offerData={offerData}
                    popoverRef={popoverRef}
                    popover={popover}
                    handlePopoverOpen={handlePopoverOpen}
                    handlePopoverClose={handlePopoverClose}
                    setIsLoading={setIsLoading}
                    setIsPageType={setIsPageType}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: 'top',
                    padding: '1rem 0',
                    width: '8rem',
                  }}
                >
                  <UsesCampaign totalCompaigns={offerData?.total_run_campaigns} />
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: 'top',
                    padding: '1rem 0',
                    width: '8rem',
                    pl: 2,
                  }}
                >
                  <Data value={offerData?.total_record} />
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: 'top',
                    padding: '1rem',
                    width: '16rem',
                  }}
                >
                  <ConnectedAnalytics analytics={offerData?.kpi} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default IntegratedApp;
