import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomerData from './CustomerData/CustomerData';
import { DataIntelligenceScreen } from '../../Language/language';

const DataIntelligence = () => {
  return (
    <div>
      <Grid container direction="column" sx={{ backgroundColor: '#FAF8F7' }}>
        <Grid item>
          <Grid container spacing={2} style={{ padding: '0px' }}>
            <Grid
              item
              xs={12}
              sm={8.6}
              md={8.6}
              lg={12}
              sx={{
                bgcolor: 'background.paper',
                m: 2,
                borderRadius: 2,
                padding: '0px!important',
              }}
            >
              <section>
                <Grid
                  container
                  style={{
                    borderBottom: '1px solid #EBE8F1',
                    padding: '11px 22px',
                  }}
                >
                  <Grid item xs={10}>
                    <Typography
                      style={{
                        fontFamily: 'IBM Plex',
                        fontSize: '24px',
                        lineHeight: '40px',
                        color: '#414446',
                      }}
                    >
                      {DataIntelligenceScreen.pagetitle}
                    </Typography>
                  </Grid>
                </Grid>
              </section>

              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                }}
              >
                <CustomerData />
              </section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DataIntelligence;
