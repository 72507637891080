/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import CustomButton from './CustomButton';
// import DropdownSvg from '../../assets/DropdownSvg';
import ArrowSvg from '../../assets/ArrowSvg';
import { mappedFields } from '../../../../services/Imports';
import ErrorPage from '../../../../ErrorPage';
import { onboarding2 } from '../../../../Language/language';

// Dummy Data
// const CSV_FIELD_LIST = [
//   'Client ID',
//   'Client',
//   'First Visit',
//   'Visit Location',
//   'Service Category',
//   'Visit Type',
//   'Pricing Option',
//   'Booking Method',
//   'Referral Type',
//   'Staff',
//   '# Visits since First Visit',
//   'Phone',
//   'Email',
// ];
// const TARGET_FIELD_LIST = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
// const MATCHED_LIST = { 'Visit Type': 'd', Email: 'e', Phone: 'h' };

const MapFields = ({
  onDataSelected,
  handleResponse,
  csvFieldList,
  targetFieldList,
  csvFieldToTargetField,
  documentId,
  setPageType,
  isDataUploading,
  setIsDataUploading,
  togglePopUp,
}) => {
  const [selectedFields, setSelectedFields] = useState({});
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();
  const [disabledFlag, setdisabledFlag] = useState(false);

  const formatField = (fieldName) => {
    return String(fieldName).replaceAll('_', ' ');
  };

  const handleChange = (event, csvField) => {
    setSelectedFields({
      ...selectedFields,
      [csvField]: event.target.outerText,
    });
  };

  const areAllFieldsSelected = () => csvFieldList.every((field) => selectedFields[field]);

  const handleClickNext = async () => {
    setdisabledFlag(true);
    setIsDataUploading(true);
    const response = await mappedFields(documentId, selectedFields);
    setIsDataUploading(false);
    if (response.status === 200) {
      setdisabledFlag(false);
      handleResponse(response.data.response);
      onDataSelected(selectedFields);
      setPageType(2);
    } else {
      setdisabledFlag(false);
      setShowModal(true);
      seterrormsg(response?.response?.data?.error);
    }
  };

  const handleClickBack = () => {
    togglePopUp(false);
    setPageType(1);
  };

  // Set initial selectedFields with matched values
  useEffect(() => {
    const initialValues = {};
    csvFieldList.forEach((field) => {
      initialValues[field] = csvFieldToTargetField[field] || '';
    });
    setSelectedFields(initialValues);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginTop: '0rem',
        height: '100%',
      }}
    >
      <Box sx={{ width: '100%', fontFamily: 'Roboto' }}>
        <Typography
          sx={{
            color: '#000000',
            fontWeight: 'Medium',
            textTransform: 'capitalize',
            fontSize: '1.2rem',
          }}
        >
          {onboarding2?.headlineText1}
        </Typography>
        <Typography
          sx={{
            color: '#5d596c',
            fontSize: '1rem',
            lineHeight: '1.5rem',
          }}
        >
          {onboarding2?.subTitle1}
        </Typography>
      </Box>
      <Box sx={{ width: '80%', flex: '1 1 0%', height: '100%' }}>
        <Box
          sx={{
            border: '1px solid #ebe8f1',
            borderRadius: '5px',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              borderBottom: '1px solid #ebe8f1',
              fontSize: '1.1rem',
              fontWeight: 'Medium',
              padding: '0 0.5rem',
              position: 'relative',
            }}
          >
            <Box sx={{ padding: '0.5rem', width: '45%' }}>{onboarding2?.headlineText2}</Box>
            <Box sx={{ padding: '0.5rem', width: '10%' }}></Box>
            <Box sx={{ padding: '0.5rem', width: '45%' }}>{onboarding2?.headlineText3}</Box>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                height: '45vh',
                overflowY: 'scroll',
                msOverflowY: 'scroll',
                padding: '0 0.5rem',
                fontSize: '0.9rem',
                position: 'relative',
              }}
            >
              {csvFieldList?.map((field, index) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: '45%',
                      padding: '0.5rem',
                      textTransform: 'capitalize',
                      position: 'relative',
                    }}
                  >
                    {formatField(field)}
                  </Box>
                  <Box sx={{ width: '10%', padding: '0.5rem' }}>
                    <Box
                      sx={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <ArrowSvg />
                    </Box>
                  </Box>

                  <Box sx={{ width: '45%', padding: '0.5rem', position: 'relative' }}>
                    <Box sx={{ overflow: 'hidden', position: 'relative', zIndex: 2 }}>
                      <Autocomplete
                        id={`autocomplete-${index}`}
                        options={targetFieldList}
                        value={selectedFields[field] || null}
                        onChange={(event, newValue) => {
                          handleChange(event, field);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select"
                            variant="outlined"
                            style={{
                              color: String(selectedFields[field]).trim() ? '#000000' : '#838383',
                              fontFamily: 'Roboto',
                              fontSize: '0.9rem',
                              width: '100%',
                              // height: '2rem',
                              // maxHeight: '1rem',
                              borderRadius: '6px',
                              background: '#ffffff',
                              textAlign: 'left',
                              textTransform: 'capitalize',
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      <CustomButton disabled={disabledFlag} pageType={1} handleClickBack={handleClickBack} handleClickNext={handleClickNext} isDataUploading={isDataUploading} />
    </Box>
  );
};

export default MapFields;
