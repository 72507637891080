import React from 'react';
import ReactPaginate from 'react-paginate';

function CustomPagination({handlePageChange, totalPages}) {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageChange}
        pageRangeDisplayed={3}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={'Custompagination'}
        subContainerClassName={'CustomPagesPagination'}
        pageClassName="CustomPageItem"
        pageLinkClassName="CustomPageLink"
        previousClassName="CustomPageItem"
        previousLinkClassName="CustomPageLink"
        nextClassName="CustomPageItem"
        nextLinkClassName="CustomPageLink"
        breakClassName="CustomPageItem"
        breakLinkClassName="CustomPageLink"
        activeClassName="CustomActivePagination"
      />
    </div>
  );
}

export default CustomPagination;
