import React from "react";
import FinalizeChannelCard from "./FinalizeChannelCard/FinalizeChannelCard";

const FinalizeOfferChannel = ({
  globalDateTime,
  globalSpendingLimit,
  setOfferData,
  channelRows,
}) => {
  const saveData = (channel, segementData) => {
    setOfferData((prevState) => ({
      ...prevState,
      [channel]: {
        ...segementData,
        isSaved: true,
      },
    }));
  };

  return (
    <div>
      {channelRows.map((rowChannels, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {rowChannels &&
            rowChannels.map((channelData, index) => (
              <div style={{ marginLeft: index === 0 ? "0px" : "20px" }}>
                <FinalizeChannelCard
                  key={channelData.channel}
                  channelData={channelData}
                  saveData={saveData}
                  channel={channelData.channel}
                  globalDateTime={globalDateTime}
                  globalSpendingLimit={globalSpendingLimit}
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default FinalizeOfferChannel;
