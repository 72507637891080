import React from 'react';
import { Grid, useTheme } from '@mui/material';
import MainHeader from './mainHeader/MainHeader';
import MainFooter from './mainFooter/MainFooter';
// import MainBody from "./mainBody/MainBody";
import Login from './login/Login';
import Registration from './register/Registration';
import { useState } from 'react';
import RegistrationStep1 from './register/RegistrationStep1';
import RegistrationStep2 from './register/RegistrationStep2';
// import { useNavigate } from 'react-router-dom';
import LoginCard from '../ui-components/cards/LoginCard';
import MFAScreenRegister from './register/MFAScreenRegister';
import { useLocation } from 'react-router-dom';
import background from '../assets/Login Graphic.png';

const RegistrationStepContent = ({ activeStep, handleNext }) => {
  const theme = useTheme();
  switch (activeStep) {
    case 0:
      return (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={7}
            sx={{
              marginRight: '175px',
              marginLeft: '0px',
              [theme.breakpoints.down('lg')]: {
                marginRight: '75px',
                marginLeft: '0px',
              },
              [theme.breakpoints.down('md')]: {
                marginRight: '55px',
                marginLeft: '55px',
              },
              [theme.breakpoints.down('sm')]: {
                marginRight: '25px',
                marginLeft: '25px',
              },
            }}
          >
            <LoginCard>
              <Registration handleNext={handleNext} />
            </LoginCard>
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={7}
            sx={{
              marginRight: '175px',
              marginLeft: '0px',
              [theme.breakpoints.down('lg')]: {
                marginRight: '75px',
                marginLeft: '0px',
              },
              [theme.breakpoints.down('md')]: {
                marginRight: '55px',
                marginLeft: '55px',
              },
              [theme.breakpoints.down('sm')]: {
                marginRight: '35px',
                marginLeft: '35px',
              },
            }}
          >
            <LoginCard>
              <RegistrationStep1 handleNext={handleNext} />
            </LoginCard>
          </Grid>
        </Grid>
      );
    case 3:
      return (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={7}
            sx={{
              marginRight: '175px',
              marginLeft: '0px',
              [theme.breakpoints.down('lg')]: {
                marginRight: '75px',
                marginLeft: '0px',
              },
              [theme.breakpoints.down('md')]: {
                marginRight: '55px',
                marginLeft: '55px',
              },
              [theme.breakpoints.down('sm')]: {
                marginRight: '35px',
                marginLeft: '35px',
              },
            }}
          >
            <LoginCard>
              <MFAScreenRegister handleNext={handleNext} />
            </LoginCard>
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={7}
            sx={{
              marginRight: '175px',
              marginLeft: '0px',
              [theme.breakpoints.down('lg')]: {
                marginRight: '75px',
                marginLeft: '0px',
              },
              [theme.breakpoints.down('md')]: {
                marginRight: '55px',
                marginLeft: '55px',
              },
              [theme.breakpoints.down('sm')]: {
                marginRight: '35px',
                marginLeft: '35px',
              },
            }}
          >
            <LoginCard>
              <RegistrationStep2 handleNext={handleNext} />
            </LoginCard>
          </Grid>
        </Grid>
      );
    default:
      throw new Error('Unknown step');
  }
};

const Synergi = () => {
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const [registerActivePage, setRegisterActivePage] = useState(0);

  const handleBack = () => {
    setRegisterActivePage(registerActivePage - 1);
  };

  const handleNext = () => {
    setRegisterActivePage(registerActivePage + 1);
  };

  // const pathname = window.location.pathname;

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <MainHeader />
      </Grid>
      <Grid
        className="backgroundChecking"
        item
        xs={12}
        sx={{
          backgroundColor: '#2B0433',
          backgroundImage: `url(${
            pathname === '/registration' ? `"${background}"` : 'https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/group-1000005273.png'
          })`,
          backgroundSize: '60vh 80vh',
          backgroundRepeat: 'no-repeat',
          paddingRight: { xs: '4%', sm: '10%', md: '10%', lg: '10%' },

          backgroundPosition: 'calc(100% - 25%) center', // This ensures the image is positioned on the right side
        }}
      >
        {(pathname === '/login' || pathname === '/') && <Login />}

        {pathname === '/registration' && <RegistrationStepContent activeStep={registerActivePage} handleBack={handleBack} handleNext={handleNext} />}
      </Grid>
      <Grid item xs={12}>
        <MainFooter />
      </Grid>
    </Grid>
  );
};

export default Synergi;
