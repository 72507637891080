import axios from 'axios';
import {
  DELETE_OFFER_SEGMENT_API,
  GET_ALL_AI_CREATED_SEGMENTS_OR_OFFERS,
  GET_DATA_SOURCE_CAPAIGN_DATA,
  POST_CREATE_OFFER_API,
  POST_OFFER_CUSTOM_UPDATE_ADD_START_END_TIME_API,
  SEGMENT_OFFER_AI_CREATE_V2,
  SEGMENT_OFFER_CONDITION_COLUMNS,
  SEGMENT_OFFER_CREATE,
  SEGMENT_OFFER_CUSTOM_ADD_CONDITION,
  SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION,
  SEGMENT_OFFER_CUSTOM_GET_INFO,
  SEGMENT_OFFER_SAVE,
  SEGMENT_OFFER_SOURCES_GET,
  SEGMENT_OFFER_SOURCE_CONFIG,
  SEGMENT_OFFER_SOURCE_TOTAL_RECORDS,
} from '../common/config';
import { getItem } from '../view/Utils/CommonFunction/CommonFunction';

const prepareHeaders = () => {
  const accessToken = getItem('accessToken');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const createOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_CREATE_OFFER_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const editOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const copyOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_EDIT_NAME_AND_DESCRIPTION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const addOfferCondition = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CUSTOM_ADD_CONDITION}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const saveOfferInfo = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${POST_OFFER_CUSTOM_UPDATE_ADD_START_END_TIME_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

//eslint-disable-line
export const getOfferData = async (baseUrl, parameter) => {
  try {
    const response = await axios({
      method: 'get',
      // url: `${GET_TOP_PERFORMING_OFFER_API}`,
      headers: prepareHeaders().headers,
      url: baseUrl,
      params: {
        parameter,
      },
    });
    return response;
  } catch (error) {
    console.log('Error:', error.message);
    return error;
  }
};

export const getOfferConditionColumn = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_CONDITION_COLUMNS}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: 'offer',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getAICraftedListForOffer = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_ALL_AI_CREATED_SEGMENTS_OR_OFFERS}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: 'offer',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getCampaignDataSourceDetails = async (sk, pk) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${GET_DATA_SOURCE_CAPAIGN_DATA}`,
      headers: prepareHeaders().headers,
      params: {
        campaign_pk: pk,
        campaign_sk: sk,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const createAICraftedForOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_AI_CREATE_V2}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const deleteOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${DELETE_OFFER_SEGMENT_API}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getOfferSource = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_SOURCES_GET}`,
      headers: prepareHeaders().headers,
      params: {
        event_type: 'offer',
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getOfferConfig = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_SOURCE_CONFIG}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getOfferSourceTotalRecords = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_SOURCE_TOTAL_RECORDS}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const reviewOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_CREATE}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const saveOffer = async (payload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${SEGMENT_OFFER_SAVE}`,
      headers: prepareHeaders().headers,
      data: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getOfferEditInfo = async (payload) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${SEGMENT_OFFER_CUSTOM_GET_INFO}`,
      headers: prepareHeaders().headers,
      params: payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};
