import React from "react";
import { Tooltip, Typography } from "@mui/material";

const TruncatedDescription = ({ description }) => {
  const truncateStyle = {
    whiteSpace: "break-spaces",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: "var(--col-8, #000)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "222.688%",
    cursor: "default"
  };

  return (
    <Tooltip title={description} placement="top">
      <Typography sx={truncateStyle}>{description}</Typography>
    </Tooltip>
  );
};

export default TruncatedDescription;