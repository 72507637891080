import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, FormControlLabel, Checkbox, Box } from '@mui/material';
import ReviewDataIntigration from './ReviewDataIntigration';
import ReviewModal from './ReviewModal';
import { CustomsetupAction } from '../../ReduxCmp/UserAction/Useraction';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { reviseintigration, CommonLanguage } from '../../Language/language';
import { RESET_IS_CUSTOM_INTIGRATION_SETUP } from '../../ReduxCmp/Constant';
import ErrorPage from '../../ErrorPage';

const ReviseInti = (props) => {
  const DATA_MANAGMENT_LIST = [
    'Web Analytics & Engagement Data',
    'Campaign Reporting Data',
    'Call Center Data',
    'Chat Data',
    'Lead Management & Performance Data',
    'Application Pipeline Data',
    'Servicing Loan Data Snapshots',
    'Third Party Lead Data',
    'Pricing Engine Data',
    'Internal Customer Data from Other Lines of Businesses',
    'Customer Engagement Data History',
  ];

  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [CustomerData, setCustomerData] = useState({
    customer_data: '',
    customer_database: '',
  });
  const [btnLoading, setbtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const navigate = useNavigate();
  const [errormsg, seterrormsg] = useState();

  const [loaderModal, setloaderModal] = useState(false);
  const closeloaderModal = () => setloaderModal(false);

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handlecustomer = (e) => {
    const { name, value } = e.target;
    setCustomerData((preve) => ({
      ...preve,
      [name]: value,
    }));
  };

  const handlesubmit = () => {
    setloaderModal(true);
    setbtnLoading(true);
    const details = {
      current_integration_setup: checkedItems,
      other_custom_data: CustomerData?.customer_data,
      size_customer_db: CustomerData?.customer_database,
    };
    props.CustomsetupAction(details);
  };

  useEffect(() => {
    if (props?.CustomsetupReducer?.data?.pk) {
      const _data = props.CustomsetupReducer?.data;
      navigate('/revisecontact-info');
      setbtnLoading(false);
    }
    if (props?.CustomsetupReducer?.success) {
      setShowModal(false);
      setbtnLoading(false);
      setloaderModal(false);
    } else {
      if (props?.CustomsetupReducer?.error) {
        navigate('/app-integrations');
        setShowModal(true);
        seterrormsg(props?.CustomsetupReducer?.errors);
        setbtnLoading(false);
        setloaderModal(false);
      }
    }
  }, [props.CustomsetupReducer]);

  useEffect(() => {
    props.resetintigrationsetup();
  }, [location?.pathname]);

  return (
    <div>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 2,
          padding: '0px!important',
        }}
      >
        <section>
          <Grid
            container
            sx={{
              borderBottom: '1px solid #EBE8F1',
              padding: { xl: '11px 22px', lg: '11px 22px', md: '11px 22px', sm: '0px 0px', xs: '11px 22px' },
            }}
            // style={{
            //   borderBottom: '1px solid #EBE8F1',
            //   padding: '11px 22px',
            // }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontFamily: 'IBM Plex',
                  fontSize: '24px',
                  lineHeight: '40px',
                  color: '#414446',
                  padding: { md: '0px', xs: '10px' },
                }}
              >
                {reviseintigration.pagetitle}
              </Typography>
            </Grid>
            {/* <Grid xs={2}>icon</Grid> */}
          </Grid>
        </section>
        <Box sx={{ padding: { xl: '20px 22px', lg: '20px 22px', md: '20px 22px', sm: '20px 22px', xs: '20px 22px' } }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              color: '#414446',
              paddingBottom: '15px',
            }}
          >
            {reviseintigration.subTitle1}
          </Typography>
          <Grid
            container
            sx={{
              flexDirection: { md: 'row', xs: 'column-reverse' },
            }}
          >
            <Grid item sm={12} md={6} lg={8.2} xl={8.4}>
              <section
              // style={{
              //   padding: '20px 22px',
              // }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: '#5D596C',
                    paddingBottom: '15px',
                  }}
                >
                  {CommonLanguage.fieldrequiredText}
                </Typography>
              </section>
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                  border: '1px solid #EBE8F1',
                  borderRadius: '5px',
                  height: { lg: '371px', md: '100%' },
                  width: { lg: '679px', md: '100%' },
                  marginLeft: { sm: '0px', lg: '22px', xl: '22px' },
                }}
              >
                <Grid container spacing={1} sx={{ padding: '25px' }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        paddingBottom: '25px',
                      }}
                    >
                      {reviseintigration.headlineText1}
                    </Typography>
                  </Grid>
                  {DATA_MANAGMENT_LIST.map((dataText, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      key={index}
                      style={{
                        paddingTop: '3px',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={checkedItems[dataText] || false}
                              onChange={handleCheckboxChange}
                              name={dataText}
                              style={{
                                color: checkedItems[dataText] ? '#4CAF50' : 'inherit',
                              }}
                            />
                          }
                          label={<Typography style={{ fontSize: '12px' }}>{dataText}</Typography>}
                          style={{
                            marginRight: '5px',
                            color: checkedItems[dataText] ? '#4CAF50' : 'inherit',
                          }}
                        />
                        {/* <label style={{  fontSize: "12px" }}>{dataText}</label> */}
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ paddingTop: { lg: '51px', xs: '20px' } }}>
                    <TextField type="text" name="customer_data" fullWidth size="medium" placeholder="Other Customer Data" onChange={handlecustomer} />
                  </Grid>
                </Grid>
              </section>
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                  border: '1px solid #EBE8F1',
                  borderRadius: '5px',
                  height: { lg: '137px', xs: '100%' },
                  width: { lg: '679px', md: '100%' },
                  marginLeft: { md: '22px', sm: '0px' },
                  marginTop: '18px',
                }}
              >
                <div>
                  <Grid container spacing={1} sx={{ padding: '25px' }}>
                    <Grid item xs={12} style={{ paddingTop: '0px' }}>
                      <Typography
                        variant="h5"
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                        }}
                      >
                        {reviseintigration.headlineText2}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: '20px' }}>
                      <TextField name="customer_database" type="text" fullWidth size="medium" placeholder="Aproximate GB" onChange={handlecustomer} />
                    </Grid>
                  </Grid>
                </div>
              </section>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                // sx={{ width: '179px', margin: '51px 22px 29px 20px' }}
                sx={{
                  margin: { xl: '20px 0px', lg: '20px 0px', md: '20px 0px', sm: '20px 0px', xs: '20px 0px' },
                  width: '179px',
                  // padding: { xl: '20px 22px', lg: '20px 22px', md: '20px 22px', sm: '0px 0px', xs: '20px 22px' },
                }}
                // onClick={() => setShowModal(true)}
                onClick={handlesubmit}
              >
                {btnLoading ? 'Loading...' : CommonLanguage.nextbtn}
              </Button>
              {loaderModal && <ReviewModal closeModal={closeloaderModal} />}
            </Grid>
            <Grid item sm={12} md={6} lg={3.8} xl={3.6} sx={{ marginBottom: '10px' }}>
              <ReviewDataIntigration />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} closingFlag={1} />}
    </div>
  );
};

const mapStateToProp = (state) => ({
  UserUploadedFileReducer: state.UserUploadedFileReducer.useruploadedfile,
  UserdashboardReducer: state.UserdashboardReducer.userdashboard,
  CustomsetupReducer: state.CustomsetupReducer.CustomsetupReducer,
});

const mapDispatchToProps = (dispatch) => ({
  CustomsetupAction: (details) => dispatch(CustomsetupAction(details)),
  resetintigrationsetup: () => dispatch({ type: RESET_IS_CUSTOM_INTIGRATION_SETUP }),
  // resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
});

export default connect(mapStateToProp, mapDispatchToProps)(ReviseInti);
