import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Grid } from '@mui/material';

const DateTimeInput = ({ onDateTimeChange, dateTimeData }) => {
  const currentDate = new Date().toISOString().slice(0, 10);
  const currentTime = new Date().toISOString().slice(11, 16);

  const [startDate, setStartDate] = useState(dateTimeData?.startDate);
  const [startTime, setStartTime] = useState(dateTimeData?.startTime);

  const [endDate, setEndDate] = useState(dateTimeData?.endDate);
  const [endTime, setEndTime] = useState(dateTimeData?.endTime);
  const [isEndDate, setIsEndDate] = useState(dateTimeData?.isEndDate ?? false);

  useEffect(() => {
    onDateTimeChange({ startDate, startTime, endDate, endTime });
  }, []);

  useEffect(() => {
    onDateTimeChange({ startDate, startTime, endDate, endTime, isEndDate });
  }, [startDate, startTime, endDate, endTime, isEndDate]);

  useEffect(() => {
    if (startDate === endDate && startTime > endTime) {
      setEndTime(startTime);
    }
  }, [endTime]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    if (endDate && newStartDate > endDate) {
      setEndDate(newStartDate);
      setEndTime(startTime);
    }
  };

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);

    if (startDate === endDate && endTime && newStartTime >= endTime) {
      setEndTime(newStartTime);
    }
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);

    if (newEndDate === startDate && startTime >= endTime) {
      setEndTime(startTime);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
        <div>
          <div className="normalText">Start Date</div>
          <input type="date" value={startDate} onChange={handleStartDateChange} min={currentDate} className="date-input" />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <div className="normalText">Start Time</div>
          <input
            type="time"
            style={{
              cursor: 'pointer',
            }}
            value={startTime}
            min={startDate === currentDate ? currentTime : undefined}
            onChange={handleStartTimeChange}
            className="date-input"
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEndDate}
                onChange={(e) => {
                  setIsEndDate(!isEndDate);
                }}
                color="secondary"
              />
            }
            label={<div className="normalText">Set An End Date</div>}
            style={{
              width: '187px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          />
        </div>
      </div>

      {isEndDate && (
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div>
            <div className="normalText">End Date</div>
            <input type="date" value={endDate} onChange={(e) => handleEndDateChange(e.target.value)} min={startDate} className="date-input" />
          </div>
          <div style={{ marginLeft: '20px' }}>
            <div className="normalText">End Time</div>
            <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} min={startDate === endDate ? startTime : undefined} className="date-input" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateTimeInput;
