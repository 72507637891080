import React, { useEffect, useState } from 'react';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography, IconButton, InputAdornment, Card, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { registerEmail } from '../../../../store/actions/login-actions';
import 'react-phone-number-input/style.css';
import AccountExistsDialog from '../../../register/AccountExistsDialog';
import { poolData, setItem } from '../../../Utils/CommonFunction/CommonFunction';
// import { CommonLanguage, RegistrationStep1Screen } from '../../Language/language';
import { CommonLanguage, RegistrationStep1Screen } from '../../../../Language/language';
import { UserConfirmService } from '../../../../services/UserConfirmService';
import { UsersSettingService } from '../../../../services/UsersService';
import Loader from '../../../integration/Components/Loader';
import ErrorPage from '../../../../ErrorPage';
import { useLocation, useNavigate } from 'react-router-dom';
import App from '../../../../App';
import MainHeader from '../../../mainHeader/MainHeader';
import MainFooter from '../../../mainFooter/MainFooter';
import VerifySuccessDailog from './VerifySuccessDailog';
// import UserRegisteredSuccessDialog from '../../../segments/Components/Dialogs/UserRegisteredSuccessDialog';

const phoneRegExp = /^\+1\d{10}$/;

const VerifyUser = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Paramsemail = params.get('email');
  const navigate = useNavigate();

  const [isAccountExistsDialog, setIsAccountExistsDialog] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [successuser, setsuccessuser] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const PasswordRex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
  // const handleIconClick = () => {
  //   setTooltipOpen(!isTooltipOpen);
  // };
  const theme = useTheme();

  const phoneRex = /^\+1\d{10}$/;

  const [state, setState] = useState({
    name: '',
    family_name: '',
    email: Paramsemail,
    password: '',
    phone_number: '',
    confirmPassword: '',
  });

  const [error, seterror] = useState({
    name: false,
    family_name: false,
    password: false,
    phone_number: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == 'phone_number') {
      if (!value.startsWith('+1')) {
        setState((preve) => ({
          ...preve,
          phone_number: '+1' + value,
        }));
      } else {
        setState((preve) => ({
          ...preve,
          phone_number: value,
        }));
      }
    } else {
      setState((preve) => ({
        ...preve,
        [name]: value,
      }));
    }
    switch (name) {
      case 'name':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            name: "Name can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            name: false,
          }));
        }
        break;
      case 'family_name':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            family_name: "Family name can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            family_name: false,
          }));
        }
        break;
      case 'password':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            password: "Password can't be empty",
          }));
        } else if (!PasswordRex.test(value)) {
          seterror((preve) => ({
            ...preve,
            password: 'Enter valid password',
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            password: false,
          }));
        }
        break;
      case 'confirmPassword':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            confirmPassword: "Confirm password can't be empty",
          }));
        } else if (!PasswordRex.test(value)) {
          seterror((preve) => ({
            ...preve,
            confirmPassword: 'Enter valid confirm Password',
          }));
        } else if (state?.password !== value) {
          seterror((preve) => ({
            ...preve,
            confirmPassword: "Password does't match",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            confirmPassword: false,
          }));
        }
        break;
      case 'phone_number':
        if (value === '') {
          seterror((preve) => ({
            ...preve,
            phone_number: "Phone number can't be empty",
          }));
        } else {
          seterror((preve) => ({
            ...preve,
            phone_number: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (state?.name === '') {
      seterror((preve) => ({
        ...preve,
        name: "Name Can't be empty",
      }));
    }
    if (state?.family_name === '') {
      seterror((preve) => ({
        ...preve,
        family_name: "family_name Can't be empty",
      }));
    }
    if (state?.password === '') {
      seterror((preve) => ({
        ...preve,
        password: "password Can't be empty",
      }));
    }
    if (state?.confirmPassword === '') {
      seterror((preve) => ({
        ...preve,
        confirmPassword: "confirmPassword Can't be empty",
      }));
    }
    if (state?.phone_number === '') {
      seterror((preve) => ({
        ...preve,
        phone_number: "phone_number Can't be empty",
      }));
    }

    if (!error?.name && !error?.family_name && !error?.password && !error?.confirmPassword && !error?.phone_number) {
      (async () => {
        setLoading(true);
        const responseData = await UserConfirmService(state);
        if (responseData?.status === 200) {
          setsuccessuser(true);
          setLoading(false);
          setTimeout(() => {
            setShowModal(false);
            navigate('/login');
          }, 3000);
        } else {
          seterrormsg(responseData?.response?.data?.error);
          setShowModal(true);
          setLoading(false);
        }
      })();
    }
  };

  return (
    <>
      {Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {isAccountExistsDialog && <AccountExistsDialog open={isAccountExistsDialog} handleClose={() => setIsAccountExistsDialog(false)} />}
          <Grid container direction="row">
            <Grid container direction="row">
              <Grid item xs={12}>
                <MainHeader />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: '#2B0433',
                  backgroundImage: `
              url("https://generation-sessions.s3.amazonaws.com/c2fdc9dc1907f3591108e718a8db0a65/img/group-1000005273.png")`,
                  backgroundSize: '85vh 86vh',
                  backgroundRepeat: 'no-repeat',
                  paddingRight: { xs: '4%', sm: '10%', md: '10%', lg: '10%' },

                  backgroundPosition: 'calc(100% - 25%) center', // This ensures the image is positioned on the right side
                }}
              >
                {Loading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <>
                    <Grid container direction="row">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={9}
                        lg={7}
                        sx={{
                          marginRight: '175px',
                          marginLeft: '0px',
                          [theme.breakpoints.down('lg')]: {
                            marginRight: '75px',
                            marginLeft: '0px',
                          },
                          [theme.breakpoints.down('md')]: {
                            marginRight: '55px',
                            marginLeft: '55px',
                          },
                          [theme.breakpoints.down('sm')]: {
                            marginRight: '25px',
                            marginLeft: '25px',
                          },
                        }}
                      >
                        <Card
                          sx={{
                            my: 2,
                            ml: 22,
                            [theme.breakpoints.down('md')]: {
                              ml: 0,
                            },
                            [theme.breakpoints.down('sm')]: {
                              ml: 0,
                            },
                            borderRadius: 3,
                          }}
                        >
                          {isAccountExistsDialog && <AccountExistsDialog open={isAccountExistsDialog} handleClose={() => setIsAccountExistsDialog(false)} />}
                          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ padding: '16px' }}>
                            <Grid item xs={12}>
                              <Typography variant="h5" color="secondary" sx={{ fontSize: { lg: 18, sm: 15, xs: 14, fontFamily: 'Roboto' } }}>
                                {CommonLanguage.joinsynergi}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: '6px' }}>
                              <Typography
                                variant="h4"
                                sx={{
                                  fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
                                  color: '#132529',
                                  fontFamily: 'IBM Plex Serif',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {RegistrationStep1Screen.headlineText1}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="h4"
                                sx={{
                                  fontSize: { lg: 28, md: 25, sm: 20, xs: 18 },
                                  color: '#132529',
                                  fontFamily: 'IBM Plex Serif',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {RegistrationStep1Screen.headlineText2}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: '15px' }}>
                              <form>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} style={{ paddingTop: '15px' }}>
                                    <TextField name="name" value={state?.name} type="text" fullWidth size="medium" placeholder="First Name" onChange={handleChange} />
                                    {error?.name && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                        }}
                                      >
                                        {error?.name}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6} style={{ paddingTop: '15px' }}>
                                    <TextField name="family_name" value={state?.family_name} type="text" fullWidth size="medium" placeholder="Last Name" onChange={handleChange} />
                                    {error?.family_name && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                        }}
                                      >
                                        {error?.family_name}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <TextField disabled id="email" type="text" fullWidth size="medium" placeholder="Email" value={Paramsemail} />
                                  </Grid>
                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <TextField
                                      id="password"
                                      name="password"
                                      type={showPassword ? 'text' : 'password'}
                                      value={state?.password}
                                      fullWidth
                                      size="medium"
                                      placeholder="Password"
                                      onChange={handleChange}
                                    />
                                    {error?.password && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                        }}
                                      >
                                        {error?.password}
                                      </Typography>
                                    )}
                                  </Grid>

                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <TextField
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      type={showPassword ? 'text' : 'password'}
                                      fullWidth
                                      size="medium"
                                      placeholder="Confirm Password"
                                      onChange={handleChange}
                                    />
                                    {error?.confirmPassword && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                        }}
                                      >
                                        {error?.confirmPassword}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <TextField
                                      fullWidth
                                      id="phone_number"
                                      name="phone_number"
                                      value={state?.phone_number}
                                      placeholder="+1 Enter phone number"
                                      onChange={handleChange}
                                      className="custom-phone-input"
                                    />
                                    {error?.phone_number && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                        }}
                                      >
                                        {error?.phone_number}
                                      </Typography>
                                    )}
                                  </Grid>

                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <Typography variant="body2" textAlign="start" sx={{ fontSize: '12px', fontFamily: 'Roboto' }}>
                                      {CommonLanguage.fieldrequiredText}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <Typography variant="body2" textAlign="start" sx={{ fontSize: '12px', fontFamily: 'Roboto' }}>
                                      {RegistrationStep1Screen.agreementText}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} style={{ paddingTop: '15px' }}>
                                    <Grid container direction="row">
                                      <Grid item>
                                        <Button variant="contained" size="large" color="secondary" onClick={handleSubmit}>
                                          {RegistrationStep1Screen.registrationbtnText}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </form>
                            </Grid>
                          </Grid>
                          {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
                          {successuser && <VerifySuccessDailog closeModal={closeModal} />}
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <MainFooter />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default VerifyUser;
