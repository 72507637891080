import { createSlice } from "@reduxjs/toolkit";

export const initialCustomeCreateSegment = {
  name: "",
  description: "",
  segmentSourceList: [],
  selectedSegmentSource: "segmentSource",
  segmentConfig: {},
  where_condition: "",
  sql_where_condition: "",
  totalRecords: 0
}

const initialState = {
  segmentList: [],
  segmentInfo: {},
  segmentAICrafetdList: [],
  aICraftedClickedItems: {},
  segmentAction: "Add",
  create: initialCustomeCreateSegment
};

const segmentsSlice = createSlice({
  name: "segments",
  initialState,
  reducers: {
    setSegmentList(state, action) {
      state.segmentList = action.payload.segmentList;
    },

    setAICraftedList(state, action) {
      state.segmentAICrafetdList = action.payload.segmentAICrafetdList;
    },

    setSegmentSourceTotalRecords(state, action) {
      state.totalRecords = action.payload.totalRecords;
    },

    setSegmentInfo(state, action) {
      state.segmentInfo = action.payload.response;
    },

    setCopySegmentInfo(state, action) {
      state.segmentInfo = action.payload.response;
    },

    setEditSegmentInfo(state, action) {
      state.segmentInfo = action.payload.response;
    },

    setAICraftedSegmentInfo(state, action) {
      state.segmentInfo = action.payload.response;
    },

    setCustomeSegmentInfo(state, action) {
      state.segmentInfo = action.payload.response;
    },

    setSegmentAction(state, action) {
      state.segmentAction = action.payload;
    },

    setAICraftedClickedItems(state, action) {
      state.aICraftedClickedItems = {
        ...state.aICraftedClickedItems,
        ...action.payload,
      };
    },

    setCreateSegmentInputFields(state, action) {
      const { name, value } = action.payload;
      state.create[name] = value;
    },

    setSegmentCreate(state, action) {
      state.create = action.payload.response;
    },

    resetSegment(state, action) {
      state.create = initialCustomeCreateSegment;
    }
  },
});

export const segmentsActions = segmentsSlice.actions;
export const segmentsReducer = segmentsSlice.reducer;
