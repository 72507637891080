/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const statusColorSelector = (status) => {
  switch (status) {
    case "ACTIVE":
      return "#158C6F";
    case "INPROGRESS":
      return "#E08029";
    case "INACTIVE":
      return "#5D596C";
    default:
      return "#158C6F";
  }
};

const File = ({
  name,
  description,
  serverFileName,
  createdBy,
  createdAt,
  status,
}) => {
  const [date, setDate] = useState("");

  useEffect(() => {
    const dateTime = new Date(createdAt);
    setDate(
      dateTime.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
      }}
    >
      <div>
        <Box
          sx={{
            height: "10px",
            width: "10px",
            borderRadius: "100%",
            backgroundColor: statusColorSelector(status),
            mt: "0.5rem",
            aspectRatio: "1",
          }}
        />
      </div>
      <div
        style={{
          fontFamily: "Roboto",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "Bold",
            marginBottom: "0.5rem",
          }}
        >
          {name}
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: 400,
              marginLeft: "0.5rem",
              textTransform: "capitalize",
            }}
          >
            ({status ? "Active" : "Unused"})
          </span>
        </Typography>
        <Typography
          sx={{
            color: "#852598",
            fontSize: "1rem",
            lineHight: "1.2rem",
            fontWeight: "Bold",
            // backgroundColor: "#852598  ",
          }}
        >
          File Type: {String(serverFileName).replaceAll("_", " ")}
        </Typography>
        <Typography
          sx={{
            color: "#112333",
            fontSize: "0.9rem",
            lineHight: "1.2rem",
          }}
        >
          {description}
        </Typography>

        <Typography
          style={{
            fontSize: "0.8rem",
            lineHeight: "1.2rem",
          }}
        >
          Created {/* By {createdBy} */} on {date}
        </Typography>
      </div>
    </Box>
  );
};

export default File;
