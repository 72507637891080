import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { SftpUserService } from '../../../../services/SftpUserService';
import ErrorPage from '../../../../ErrorPage';
import Loader from '../../../integration/Components/Loader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingFront from '../../SettingFront';

const SftpUser = () => {
  const [SftpUserData, setSftpUserData] = useState();
  const [Loading, setLoading] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => setshowPassword((show) => !show);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // ................................................................................

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await SftpUserService('');
      if (response?.status == 200) {
        setLoading(false);
        setSftpUserData(response?.data);
      } else if (response?.response?.status === 400) {
        setLoading(false);
        setShowModal(true);
        seterrormsg(response?.response?.data?.error);
      } else {
        setShowModal(true);
        seterrormsg(response?.response?.data?.error);
      }
    })();
  }, []);

  return (
    <>
      {/* <SettingFront /> */}
      <>
        {Loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {SftpUserData ? (
              <>
                <Grid container sx={{ marginBottom: '7px', display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        color: '#414446',
                        fontFamily: 'IBM Plex Serif',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        paddingBottom: '12px',
                        marginTop: '18px',
                      }}
                    >
                      SFTP Details
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ padding: '1rem' }}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '1rem',
                      borderBottom: '1px solid #ebe8f1',
                      marginBottom: '1rem',
                      fontWeight: 'Bold',
                    }}
                  >
                    <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>User Name</Box>
                    <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>User Host</Box>
                    <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>User Port</Box>
                    <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>Password</Box>
                  </Box>
                  <div>
                    {/* <TableRoW> */}
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0.5rem 0',
                      }}
                    >
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>{SftpUserData?.sftp_user}</Box>
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>{SftpUserData?.sftp_host}</Box>
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>{SftpUserData?.sftp_port}</Box>
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                        <TextField
                          type={showPassword ? 'text' : 'password'}
                          value={SftpUserData?.sftp_password}
                          disabled
                          sx={{
                            border: 'none',
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                    {/* </TableRoW> */}
                  </div>
                </Box>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {errormsg?.title}
                </Typography>
              </>
            )}
          </>
        )}
      </>

      {showModal && <ErrorPage closingFlag={2} closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

export default SftpUser;
