import { Box, Button, Grid, Slider, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from '../Component/BarChart';
import CachedIcon from '@mui/icons-material/Cached';
import { getScoreData } from '../../../services/LoanInterestRateService';
import { getCookie, getItem, setCookie, setItem } from '../../Utils/CommonFunction/CommonFunction';

const Score = () => {
  const theme = useTheme();
  const sliderFilterValue = getItem('sliderFilterValue');
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const scoreFiltersData = getItem('scoreFilterData');
  const scoreFilter = getCookie('scoreFilter');
  const [scoreFilterData, setScoreFilterData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const IsLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const [sliderValues, setSliderValues] = useState([]);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    if (!!scoreFiltersData) {
      const data = scoreFiltersData.map((data, index) => {
        return {
          [data.title]: sliderFilterValue[index],
        };
      });
      setSliderValues(data);
      // setstate(sliderFilterValue)
    }
  }, [scoreFiltersData]);

  useEffect(() => {
    if (!!sliderFilterValue) {
      setSliderData(sliderFilterValue);
    }
  }, [sliderFilterValue]);

  useEffect(() => {
    if (!!scoreFiltersData) {
      setScoreFilterData(scoreFiltersData);
    }
  }, [scoreFiltersData]);

  const handleSliderChange = (index, newValue) => {
    const _data = scoreFilterData.map((e, i) => {
      if (i == index) {
        return {
          [e.title]: newValue,
        };
      } else {
        return {
          [e.title]: sliderData[i],
        };
      }
    });

    setSliderValues(_data);

    const filterSlider = [...sliderData];
    filterSlider.splice(index, 1, newValue);
    setSliderData(filterSlider);
  };

  const handleResetClick = (index) => {
    const initialSliderValues = scoreFilterData.map(() => 500); // Set all values to 500
    const data = scoreFiltersData.map((data, index) => {
      return {
        [data.title]: initialSliderValues[index],
      };
    });
    setSliderData(initialSliderValues);
    setSliderValues(data);
    // setstate(initialSliderValues);
  };

  useEffect(() => {
    setSkeletonLoading(true);
    if (!scoreFilter) {
      (async () => {
        const response = await getScoreData();
        setScoreFilterData(response?.data?.result);
        const initialSliderValues = response?.data?.result?.map((item) => item.max / 2);
        setItem('scoreFilterData', response?.data?.result);
        setItem('sliderFilterValue', initialSliderValues);
        setCookie('scoreFilter', true, 1440);
        setSkeletonLoading(false);
        // setSliderValues(initialSliderValues);
      })();
    } else {
      setSkeletonLoading(false);
    }
  }, []);

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: '4px' }} direction={isMobile || isTablet || IsLarge ? 'column' : 'row'}>
        <Grid
          item
          xs={3}
          sm={3}
          md={5}
          lg={3}
          xl={3}
          sx={{
            marginRight: '2%',
          }}
        >
          {skeletonLoading ? (
            <Box sx={{ border: '1px solid #A35BB1', borderRadius: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #D3D3D3', padding: '17px 27px' }}>
                <Skeleton variant="text" width={180} height={28} />
                <Skeleton variant="rectangular" width={100} height={36} />
              </Box>
              <Box sx={{ padding: '23px' }}>
                <Grid container>
                  {Array.from(new Array(4)).map((_, index) => (
                    <Grid item xs={6} style={{ padding: '6px 15px' }} key={index}>
                      <Skeleton variant="text" width="70%" height={24} />
                      <Skeleton variant="rectangular" width="100%" height={58} style={{ marginBottom: '8px' }} />
                      <Skeleton variant="text" width="50%" height={24} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box sx={{ border: '1px solid #A35BB1', borderRadius: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #D3D3D3', padding: '17px 27px' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  Score Filters
                </Typography>
                <Button
                  onClick={handleResetClick}
                  sx={{ color: '#A35BB1', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', textTransform: 'capitalize' }}
                >
                  <CachedIcon /> Reset all
                </Button>
              </Box>
              <Box sx={{ padding: '23px' }}>
                <Grid container>
                  {scoreFilterData?.map((ScoreData, index) => {
                    return (
                      <Grid item xs={6} style={{ padding: '6px 15px' }} key={index}>
                        <Typography
                          sx={{
                            color: '#414446',
                            fontFamily: ' Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {ScoreData.title}
                        </Typography>
                        <Slider
                          value={sliderData?.[index]}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          valueLabelPlacement="bottom"
                          max={ScoreData.max}
                          min={ScoreData.min}
                          sx={{ color: '#852598', height: '7px' }}
                          onChangeCommitted={(event, newValue) => handleSliderChange(index, newValue)}
                        />
                        <Typography variant="body2" align="center">
                          {sliderData?.[index]}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={8.5} sm={8.5} md={6} lg={8.5} xl={8.5}>
          <BarChart dataColors='["#A35BB1", "#7F4D90", "#852598", "#7A4B84", "#DFBBE5", "--vz-info"]' isScore={true} sliderValues={sliderValues} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Score;
