/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography, Card, CardContent, IconButton, InputAdornment, Alert } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import Registration from '../register/Registration';
import {
  campaignPageType,
  aboutCampaignData,
  dataSourceData,
  segmetsData,
  offersData,
  dateTimeGlobal,
  spendingLimitGlobal,
  snackBarMsg,
  batchDataGlobal,
} from '../Campaigns/GlobalStore';
import MFAScreen from './MFAScreen';
import { getCookie, poolData, setCookie, setItem } from '../Utils/CommonFunction/CommonFunction';
import axios from 'axios';
import { UserUploadedFileAction, UserdashboardAction } from '../../ReduxCmp/UserAction/Useraction';
import { connect } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { LOGIN_AFTER_PAGES } from '../../common/config';
import { CommonLanguage, LoginScreen } from '../../Language/language';
import ErrorPage from '../../ErrorPage';
import ErrorIcon from '@mui/icons-material/Cancel';

const resetApolloVariables = () => {
  campaignPageType(1);
  aboutCampaignData({});
  dataSourceData({ manualData: [], AIData: [] });
  segmetsData({ manualData: [], AIData: [] });
  offersData([]);
  dateTimeGlobal({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  });
  spendingLimitGlobal({
    noLimit: true,
    period: 'daily',
    min: '',
    max: '',
  });
  snackBarMsg(false);
  batchDataGlobal({
    batch_size: '',
    batch_type: 'daily',
  });

  // uploadCSVpage(1);
  // open(false);
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required!')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address'),
  password: Yup.string().required('Password is required!'), // add this line
});

const userPool = new CognitoUserPool(poolData);

const Login = (props) => {
  const navigate = useNavigate();
  const accessToken = getCookie('accessToken');
  const [showPassword, setShowPassword] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userLogged, setUerLogged] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  // const [resp, setresp] = useState({
  //   is_mfa_configure: false,
  //   is_user_have_data: false,
  //   is_user_have_dashboard_data: false,
  // });
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setIsLoading(true);
      setUserEmail(values.email);
      setUserPassword(values.password);
      const authenticationDetails = new AuthenticationDetails({
        Username: values.email,
        Password: values.password,
      });

      const cognitoUser = new CognitoUser({
        Username: values.email,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resetApolloVariables();
          const accessToken = result.getIdToken().getJwtToken();
          const MFAValidation = async () => {
            try {
              const response = await axios.get(LOGIN_AFTER_PAGES, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              if (response?.status === 200) {
                // setmfaResp(response.data);
                navigate('/onboarding');
                setCookie('accessToken', accessToken, 1440);
                setItem('accessToken', accessToken);
              }
            } catch (error) {
              setIsLoading(false);
              setShowModal(true);
              seterrormsg(error?.response?.data?.error);
              console.error('Error :', error);
              return null;
            }
          };
          MFAValidation();
          return result;
        },
        onFailure: function (err) {
          console.error(err);
          setIsLoading(false);
          seterrormsg(err.message);
          formik.setFieldError('password', 'Oops! Incorrect username or password. Please try again.');
          return err;
        },
      });
      setSubmitting(false);
    },
  });
  let errorMessage = '';
  if (formik.touched.email && formik.errors.email && formik.touched.password && formik.errors.password) {
    errorMessage = 'email and password are required.';
  } else if (formik.touched.email && formik.errors.email) {
    errorMessage = formik.errors.email;
  } else if (formik.touched.password && formik.errors.password) {
    errorMessage = formik.errors.password;
  }

  const handleForgetPassword = () => {
    navigate('/password-recovery');
  };

  const handleChange = (e) => {
    e.preventDefault();
    navigate('/registration');
  };

  // useEffect(() => {
  //   setresp((preve) => ({
  //     ...preve,
  //     is_mfa_configure: mfaResp?.is_mfa_configure,
  //     is_user_have_data: mfaResp?.is_user_have_data,
  //     is_user_have_dashboard_data: mfaResp?.is_user_have_dashboard_data,
  //   }));
  // }, [mfaResp]);

  // useEffect(() => {
  //   if (mfaResp !== undefined) {
  //     if (resp.is_mfa_configure) {
  //       setUerLogged(true);
  //     } else if (!resp.is_user_have_data) {
  //       navigate('/onboarding');
  //     } else if (resp.is_user_have_data && !resp.is_user_have_dashboard_data) {
  //       navigate('/onboarding');
  //     } else if (resp.is_user_have_data && resp.is_user_have_dashboard_data) {
  //       navigate('/marketing');
  //     }
  //   }
  // }, [resp, navigate]);

  useEffect(() => {
    if (!!accessToken) {
      navigate('/marketing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <>
      {showRegister && <Registration open={showRegister} close={setShowRegister(false)} />}
      {userLogged ? (
        <MFAScreen userEmail={userEmail} userPassword={userPassword} />
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            pt: { xs: '10%', sm: '4%', md: '4%' },
            pl: '10%',
            pb: '3%',
          }}
        >
          <Card>
            <CardContent>
              <Grid
                container
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  pr: { lg: 10, md: 8, sm: 6, xs: 4 },
                  pl: { lg: 10, md: 8, sm: 6, xs: 4 },
                }}
              >
                <Grid item xs={12} sx={{ mt: '10%' }}>
                  <Typography variant="h5" color="secondary" sx={{ fontSize: { lg: 18, sm: 15, xs: 14, fontFamily: 'IBM Plex Serif' } }}>
                    {LoginScreen.welcomeBack}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: '5%' }} style={{ paddingTop: '5px' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { lg: 30, md: 25, sm: 20, xs: 18 },
                      fontFamily: 'IBM Plex Serif',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      color: '#132529',
                    }}
                  >
                    {LoginScreen.headlineText1}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h4"
                    sx={{
                      fontSize: { lg: 30, md: 25, sm: 20, xs: 18 },
                      fontFamily: 'IBM Plex Serif',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      color: '#132529',
                    }}
                  >
                    {LoginScreen.headlineText2}
                  </Typography>
                </Grid>

                <form onSubmit={formik.handleSubmit}>
                  <Grid container direction="column" justifyContent="flex-start">
                    <Grid item xs={12} sx={{ m: '3%' }}>
                      <TextField
                        id="email"
                        type="text"
                        fullWidth
                        size="medium"
                        sx={{
                          paddingRight: { xs: '30px', md: '0px' },
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '45vh',
                            lg: '45vh',
                          },
                        }}
                        placeholder="Email"
                        {...formik.getFieldProps('email')}
                        // error={formik.touched.email && Boolean(formik.errors.email)}
                        // helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ m: '3%' }}>
                      <TextField
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        size="medium"
                        sx={{
                          paddingRight: { xs: '30px', md: '0px', sm: '0' },
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '45vh',
                            lg: '45vh',
                          },
                          justifyContent: 'space-between',
                        }}
                        placeholder="Password"
                        {...formik.getFieldProps('password')}
                        // error={formik.touched.password && Boolean(formik.errors.password)}
                        // helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid sx={{ fontSize: { xs: 12, sm: 14, md: 14, lg: 16, fontFamily: 'IBM Plex Serif' } }}>
                      {errorMessage && (
                        <Alert severity="error" icon={<ErrorIcon style={{ color: 'error' }} />}>
                          {errorMessage}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ fontSize: { xs: 12, sm: 14, md: 14, lg: 16, fontFamily: 'IBM Plex Serif', textAlign: 'center' } }}>
                      <a href="" onClick={handleForgetPassword}>
                        {LoginScreen.forgetPassword}
                      </a>
                    </Grid>
                    <Grid item sx={{ mt: { xs: '2%', sm: '3%', lg: '4%' } }}>
                      <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ width: { lg: '45vh', md: '25vh' } }}>
                        {isLoading ? (
                          <LoadingButton
                            color="secondary"
                            loading
                            loadingPosition="end"
                            sx={{
                              textAlign: 'center',
                              fontFamily: 'Roboto',
                              fontSize: '0.9',
                              fontStyle: 'normal',
                              fontWeight: 'Bold',
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                              borderRadius: '6px',
                              color: 'white',
                            }}
                          >
                            <span style={{ marginRight: '0.5rem', color: 'white' }}>Loading...</span>
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            // disabled={!shouldEnableButton() || uploading}
                            sx={{
                              color: '#ffffff',
                              textAlign: 'center',
                              fontFamily: 'Roboto',
                              fontSize: '0.9',
                              fontStyle: 'normal',
                              fontWeight: 'Bold',
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                              width: 'fit-content',
                              borderRadius: '6px',

                              // background: !shouldEnableButton() ? '#d3abdb' : '#852598',
                              // ':disabled': {
                              //   color: '#ffffff',
                              // },
                            }}
                          >
                            {CommonLanguage.login}
                          </Button>
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: '3%', mb: '117px' }}>
                      <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 14, lg: 14, fontFamily: 'IBM Plex Serif' } }}>
                        {LoginScreen.dontHaveAccountText}
                        <a href="" onClick={handleChange}>
                          {CommonLanguage.register}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
    </>
  );
};

const mapStateToProp = (state) => ({
  UserUploadedFileReducer: state.UserUploadedFileReducer.useruploadedfile,
  UserdashboardReducer: state.UserdashboardReducer.userdashboard,
});

const mapDispatchToProps = (dispatch) => ({
  UserUploadedFileAction: (details) => dispatch(UserUploadedFileAction(details)),
  UserdashboardAction: (details) => dispatch(UserdashboardAction(details)),
  // resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Login);
