import { Grid, Typography } from '@mui/material';
import React from 'react';

const CustomerdataIntelligence = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          width: '385px',
          height: '226px',
          backgroundColor: '#F6F5F8',
          border: '1px solid #EBE8F1',
          borderRadius: '5px',
          marginLeft: '49px',
          marginTop: '19px',
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              borderBottom: '1px solid #D9D6E3',
              padding: '17px 22px 19px 17px',
            }}
          >
            What is Customer Data Intelligence?
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              padding: '15px 22px',
              color: '#414446',
            }}
          >
            With Data Warhorse, you gain access to a comprehensive Customer Data Platform (CDP) that collects, unifies, and organizes customer data from all digital touch points in
            real-time. By centralizing this data, Data Warhorse uncovers hidden patterns and trends, enabling precise predictive modeling and more effective, personalized
            marketing.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerdataIntelligence;
