import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { OneClickCreateService } from '../../../../services/CampaignOneClickService';

import {
  aboutCampaignData as setAboutCampaignData,
  dataSourceData as setDataSourceData,
  segmetsData as setSegmetsData,
  offersData as setOffersData,
} from '../../../../view/Campaigns/GlobalStore';
import { useNavigate } from 'react-router-dom';

const OneClickCampaign = ({ details, index }) => {
  const [loader, setLoader] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();
  const oneClickCategories = (campaign_pk, campaign_sk) => {
    (async () => {
      setLoader(true);
      const response = await OneClickCreateService(campaign_pk, campaign_sk);
      if (response?.status === 200) {
        setLoader(false);

        const sk = (response?.data?.sk).split('#')[1]
        const pk = (response?.data?.pk).split('#')[1]

        const offerDate = response?.data?.channel_segment_offers.map((item) => {
          return {
            channel: item?.channel,
            segment_offers: item?.segment_offers,
            total_offers: item?.totalOffers,
          };
        });
        setOffersData(offerDate);
        setAboutCampaignData({
          name: response?.data?.name,
          description: response?.data?.description,
          objective: response?.data?.objective,
          channelType: response?.data?.channel_type,
          campaign_pk: response?.data?.pk,
          campaign_sk: response?.data?.sk,
          manualData: response?.data?.vendors,
          segments_type: response?.data?.segments_type,
        });
        const segmentData = setSegmetsData();
        if (response?.data?.channel_type === 'manual') {
          segmentData.manualData = [...response?.data?.segments];
        } else {
          segmentData.AIData = [...response?.data?.segments];
        }
        setSegmetsData(segmentData);

        setDataSourceData({ AIData: response?.data?.data_sources, manualData: [], data_sources_type: response?.data?.data_sources_type });
        // setSegmetsData({ manualData: [], AIData: [] });
        // setOffersData([]);
        // setDateTimeGlobal({
        //   startDate: '',
        //   startTime: '',
        //   endDate: '',
        //   endTime: '',
        // });
        // setSpendingLimitGlobal({
        //   noLimit: true,
        //   period: 'daily',
        //   min: '',
        //   max: '',
        // });
        // setSnackBarMsg(false);
        // batchDataGlobal({
        //   batch_size: '',
        //   batch_type: 'daily',
        // });
        // navigate('/create-campaign');
        navigate(`/create-campaign/offer/${sk}/${pk}`);
      } else {
        alert('setoneclicksegmentData');
      }
    })();
  };

  const frontRef = useRef(null);
  const backRef = useRef(null);
  const btnRef = useRef(null);

  function handleFlip() {
    if (frontRef.current && backRef.current) {
      frontRef.current.classList.toggle('flipped');
      backRef.current.classList.toggle('flipped');
    }
  }

  return (
    <Grid item xs={3.8} key={index} className="card" sx={{ height: '300px' }}>
      <Box
        ref={frontRef}
        id={`front-${index}`}
        className="cardFront"
        sx={{
          backgroundColor: '#F6F5F8',
          padding: '16px 19px',
          height: '100%',
          borderRadius: '5px',
          // margin: '0px 27px',
          // display: 'flex',
          // flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '16px',
            // fontStyle: 'normal',
            // fontWeight: '400',
            // lineHeight: '24px',
            textTransform: 'capitalize',
            marginBottom: '17px',
          }}
        >
          {details?.name}
        </Typography>
        <Typography
          sx={{
            color: '#9853A6',
            fontFamily: 'Roboto Condensed',
            fontSize: '12px',
            // fontStyle: 'normal',
            // fontWeight: '400',
            // lineHeight: '24px',
            textTransform: 'capitalize',
            marginBottom: '10px',
          }}
        >
          {details?.second_title}
        </Typography>
        <Typography
          sx={{
            color: '#707070',
            fontFamily: 'Roboto',
            fontSize: '12px',
            // fontStyle: 'normal',
            // fontWeight: '400',
            // lineHeight: '24px',
            textTransform: 'capitalize',
            marginBottom: '10px',
          }}
        >
          <span
            style={{
              color: '#000',
              fontFamily: 'IBM Plex Serif',
              fontSize: '24px',
            }}
          >
            {details?.total_record}
          </span>{' '}
          prospects
        </Typography>
        <Typography
          sx={{
            color: '#707070',
            fontFamily: 'Roboto',
            fontSize: '14px',
            // fontStyle: 'normal',
            // fontWeight: '400',
            // lineHeight: '24px',
            textTransform: 'capitalize',
            marginBottom: '10px',
          }}
        >
          Segment: {details?.segment}
        </Typography>
        <Typography
          className="multiline-ellipsis"
          onClick={toggleExpansion}
          sx={{
            color: '#707070',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '18px',
            marginBottom: '23px',
            width: '100%', // Fixed width
            overflow: 'hidden',
            whiteSpace: isExpanded ? 'normal' : 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {details?.description}
        </Typography>
        <hr />

        {loader ? (
          <img
            src={require('../../../../assets/60-X-60_Loader.gif')}
            style={{
              width: '35px',
            }}
            alt='loader'
          />
        ) : (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: '#ffffff',
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 'Bold',
                textAlign: 'center',
                textTransform: 'capitalize',
                width: { lg: '15rem', sm: '10rem' },
                height: '48px',
                borderRadius: '6px',
                backgroundColor: '#852598',
                ':hover': {
                  backgroundColor: '#852598',
                },
              }}
              onClick={() => oneClickCategories(details?.pk, details?.sk)}
            >
              CREATE CAMPAIGN
            </Button>
            {/* <Button
              sx={{
                color: '#A35BB1',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                padding: '0px',
                marginBottom: '10px',
                justifyContent: 'start',
                mt: '10px',
              }}
             
            >
              
            </Button> */}
            <Button
              id="flip-btn"
              sx={{
                color: '#A35BB1',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                padding: '0px',
                marginBottom: '10px',
                justifyContent: 'start',
                mt: '10px',
              }}
              onClick={handleFlip}
            >
              Quick View
            </Button>
          </Grid>
        )}
      </Box>
      <Box
        ref={backRef}
        id={`back-${index}`}
        className="cardBack"
        sx={{
          backgroundColor: '#F6F5F8',
          padding: '16px 19px',
          height: '100%',
          borderRadius: '5px',
          // margin: '0px 27px',
          // display: 'flex',
          // flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '16px',
            // fontStyle: 'normal',
            // fontWeight: '400',
            // lineHeight: '24px',
            textTransform: 'capitalize',
            marginBottom: '17px',
          }}
        >
          {details?.name}
        </Typography>

        {loader ? (
          <img
            src={require('../../../../assets/60-X-60_Loader.gif')}
            style={{
              width: '35px',
            }}
            alt='loader'
          />
        ) : (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{
                color: '#A35BB1',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                padding: '0px',
                marginBottom: '10px',
                justifyContent: 'start',
                mt: '10px',
              }}
              onClick={() => oneClickCategories(details?.pk, details?.sk)}
            >
              CREATE CAMPAIGN
            </Button>
            <Button
              ref={btnRef}
              id="flip-btn"
              sx={{
                color: '#A35BB1',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                padding: '0px',
                marginBottom: '10px',
                justifyContent: 'start',
                mt: '10px',
              }}
              onClick={handleFlip}
            >
              Quick View
            </Button>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default OneClickCampaign;
