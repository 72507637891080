import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { forgetEmail } from '../../store/actions/login-actions';
import { useDispatch } from 'react-redux';
import { poolData } from '../Utils/CommonFunction/CommonFunction';
import { ForgotPasswordStep1 } from '../../Language/language';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserRegisteredService } from '../../services/UserRegisteredService';
import LoadingScreen from '../offers-and-segments/LoadingScreen/LoadingScreen';
import UserNotCapableModal from './UserNotCapableModal';

const userPool = new CognitoUserPool(poolData);

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required!')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address'),
});

const PasswordRecoveryStep1 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  useEffect(
    // setting initial state
    () => () => {
      formik.resetForm({
        email: '',
      });
    },
    []
  );

  const handleBackToLogin = () => {
    handleBack();
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      var userData = {
        Username: values.email,
        Pool: userPool,
      };
      const responseData = await UserRegisteredService({
        email: values.email,
      });
      if (responseData?.status == 200) {
        setLoading(false);
      } else {
        setLoading(false);
      }

      if (!responseData?.data) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }

      if (responseData?.data) {
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
          onSuccess: function (result) {
            setLoading(false);
            dispatch(forgetEmail(values.email));
            handleNext();
          },
          onFailure: function (err) {
            setLoading(false);
          },
        });
      }
    },
  });

  return (
    <Grid container justifyContent="center" textAlign="center" spacing={1}>
      <IconButton onClick={handleBackToLogin} sx={{ mr: '90%' }}>
        <ArrowBackIcon />
      </IconButton>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h6">
          {ForgotPasswordStep1.Passwordrecovery}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontSize: { lg: 30, md: 20, sm: 20, xs: 20 } }}>
          {ForgotPasswordStep1.headlineText1}
        </Typography>
        <Typography variant="h4" sx={{ fontSize: { lg: 30, md: 20, sm: 20, xs: 20 } }}>
          {ForgotPasswordStep1.headlineText2}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{ForgotPasswordStep1.subtitle}</Typography>
      </Grid>
      <form>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <TextField
            id="email"
            required
            label="Email"
            type="text"
            size="medium"
            sx={{
              paddingRight: { xs: '30px', md: '0px' },
              width: { xs: '100%', sm: '100%', md: '350px', lg: '350px' }, // Adjust width for md and lg
            }}
            {...formik.getFieldProps('email')}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item sx={{ mt: 3, mb: 25 }}>
          <Button variant="contained" disabled={Loading ? true : false} onClick={formik.handleSubmit} size="medium" fullWidth color="secondary">
            {ForgotPasswordStep1.send}
          </Button>
        </Grid>
      </form>
      {showModal && <UserNotCapableModal />}
    </Grid>
  );
};

export default PasswordRecoveryStep1;
