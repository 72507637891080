import { Drawer, Icon, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dashboard from '../../assets/360 Dashboard.svg';
import Dashboard_White from '../../assets/360 Dashboard_White.svg';
import Integration from '../../assets/Integration.svg';
import Integration_White from '../../assets/Integration_White.svg';
import Segments_White from '../../assets/Segments_White.svg';
import Segments from '../../assets/Segments.svg';
import Offers from '../../assets/Offers.svg';
import Offers_White from '../../assets/Offers_White.svg';
import Campaigns from '../../assets/Campaigns.svg';
import Campaigns_White from '../../assets/Campaigns_White.svg';
import Reports_White from '../../assets/Reports_White.svg';
import Reports from '../../assets/Reports.svg';
import { getItem } from '../Utils/CommonFunction/CommonFunction';
import AppMenu from '../../ui-components/SideBar/AppMenu';

const DrawerComponent = ({ links }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/segments' && location.state?.scrollToChannel) {
      const channelComponent = document.getElementById('channelComponent');
      if (channelComponent) {
        channelComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const getSubheaderTitle = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/campaigns':
        return 'All Campaigns';
      case '/create-campaign':
        return 'Campaigns';
      case '/app-integrations':
        return 'Integrations';
      case '/segments':
        return 'Segments';
      case '/create-segment':
        return 'Create new Segments';
      case '/offers':
        return 'Offers';
      case '/create-offer':
        return 'Offers';
      case '/onboarding':
        return 'How Synergi works';
      case '/CustmerDataintelligence':
        return 'Custmer Data Intelligence';
      case '/onboarding':
        return '360 Dashboard';
      case '/channels':
        return '360 Dashboard';
      case '/campaigns-dasboard':
        return '360 Dashboard';
      case '/industry-comparison':
        return '360 Dashboard';
      case '/setting':
        return 'Account Management';
      case '/portfolio':
        return 'Portfolio Analytics Dashboard';
      case '/marketing':
        return 'Marketing Analytics Dashboard';
      // Add more cases as needed...
      default:
        return 'Integration'; // Default title
    }
  };

  const subheaderTitle = getSubheaderTitle();

  const appMenuItems = [
    {
      title: '360 Dashboard',
      name: 'dashboard',
      link: '/onboarding',
      icon: <Icon icon={Dashboard} alt="Group Icon" />,
      whiteIcon: <Icon icon={Dashboard_White} alt="Reports Icon" />,
      items: [
        {
          title: 'Portfolio ',
          name: 'Portfolio ',
          link: '/portfolio',
          disabled: false,
        },
        {
          title: 'Marketing',
          name: 'Marketing',
          link: '/marketing',
          disabled: false,
        },
        {
          title: 'Sales',
          name: 'campaigns-dashboard',
          link: '/CampaignsDashboard',
          disabled: true,
        },
        {
          title: 'Cross-Sell',
          name: 'industry-comparison',
          link: '/IndustryComparison',
          disabled: true,
        },
      ],
    },
    {
      title: 'Integrations',
      name: 'integrations',
      link: '/app-integrations',
      icon: <Icon icon={Integration} alt="Group Icon" />,
      whiteIcon: <Icon icon={Integration_White} alt="Reports Icon" />,
      items: [
        {
          title: 'Automation',
          name: 'app-integrations',
          link: '/app-integrations',
          disabled: false,
        },
        { title: 'Data Import', name: 'imports', link: '/imports', disabled: false },
        {
          title: 'Data Export',
          name: 'exports',
          link: '/exports',
          disabled: false,
        },
      ],
    },
    {
      title: 'Segments',
      name: 'segments',
      link: '/segments',
      icon: <Icon icon={Segments} alt="Group Icon" />,
      whiteIcon: <Icon icon={Segments_White} alt="Reports Icon" />,
      items: [
        {
          title: 'All Segments',
          name: 'segments',
          link: '/segments',
          disabled: false,
        },
        {
          title: 'Create Segments',
          name: 'new-segments',
          link: '/create-segment',
          disabled: false,
        },
      ],
    },
    {
      title: 'Offers',
      name: 'offers',
      link: '/offers',
      icon: <Icon icon={Offers} alt="Group Icon" />,
      whiteIcon: <Icon icon={Offers_White} alt="Reports Icon" />,
      items: [
        {
          title: 'All Offers',
          name: 'offers',
          link: '/offers',
          disabled: false,
        },
        {
          title: 'Create Offers',
          name: 'new-offers',
          link: '/create-offer',
          disabled: false,
        },
      ],
    },
    {
      title: 'Campaigns',
      name: 'Campaigns',
      link: '/campaigns',
      icon: <Icon icon={Campaigns} alt="Group Icon" />,
      whiteIcon: <img src={Campaigns_White} alt="Group Icon" />,
      items: [
        {
          title: 'All Campaigns',
          name: 'campaigns',
          link: '/campaigns',
          disabled: false,
        },
        {
          title: 'New Campaign',
          name: 'create-campaign',
          link: '/create-campaign',
          disabled: false,
        },
        {
          title: 'Draft',
          name: 'draft',
          disabled: true,
        },
        {
          title: 'Completed',
          name: 'completed',
          disabled: true,
        },
      ],
    },
    {
      title: 'Reports(Coming Soon)',
      name: 'reports',
      icon: <Icon icon={Reports} alt="Group Icon" />,
      whiteIcon: <Icon icon={Reports_White} alt="Reports Icon" />,
    },
  ];

  const [selectedMenu, setSelectedMenu] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = getItem('accessToken');
    if (accessToken) setIsAuthenticated(true);
    // else navigate('/');
  }, []);
  const accessToken = getItem('accessToken');
  return (
    <div>
      {accessToken ? (
        <>
          <Drawer
            open={open}
            onClose={() => setOpen(false)}
            sx={
              {
                // lg: { display: "none" }
              }
            }
          >
            <List>
              <ListItemButton>
                <ListItemIcon>
                  <AppMenu
                    appMenuItems={appMenuItems}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                    selectedIndex={selectedIndex} // Pass the selected index
                    setSelectedIndex={setSelectedIndex} // Pass the setSelectedIndex function
                  />
                </ListItemIcon>
              </ListItemButton>
            </List>
          </Drawer>

          <IconButton
            className="sidebaricon"
            sx={{
              color: 'secondary ',
              // lg: { display: "none" }
            }}
            onClick={() => setOpen(!open)}
          >
            <MenuRoundedIcon />
          </IconButton>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default DrawerComponent;
