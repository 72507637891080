import { useState } from 'react';
import { Typography, Button, Grid, Divider, Paper, TextField, FormControl, Select, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { saveOffer } from '../../../../services/OffersService';
import { offersActions } from '../../../../store/slices/offers';
import { useSelector } from 'react-redux';
import DateTimeInput from '../../../Utils/DateTimeInput/DateTimeInput';
import ErrorPage from '../../../../ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ExpressionReview } from '../../query-builder/ExpressionReview';
import { LoadingScreen7ForOffer } from '../../../../Language/DialogBoxLanguage';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { getSegmentOfferReview } from '../../../../services/SegmentService';

const { setOfferDetails, setCreateOfferInputFields, resetOffer, setOfferCreate } = offersActions;

const ReviewOffer = ({ setIsPageType }) => {
  const { sk, pk } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [greatJob, setGreatJob] = useState(false);
  const { create, offerAction } = useSelector((state) => state.offers);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [errormsg, seterrormsg] = useState();

  const getofferData = async () => {
    const responseData = await getSegmentOfferReview({
      sk: `offer#${sk}`,
      pk: `offer#${pk}`,
    });
    if (responseData.status === 200) {
      dispatch(setOfferCreate({ response: responseData.data }));
    } else {
      seterrormsg(responseData?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (create.name === '') {
      getofferData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleDescriptionChange = (event) => {
    const name = 'description';
    const value = event.target.value;
    dispatch(setCreateOfferInputFields({ name, value }));
  };

  const handleOfferNameChange = (event) => {
    const name = 'name';
    const value = event.target.value;
    dispatch(setCreateOfferInputFields({ name, value }));
  };

  const toTitleCase = (str) => {
    return str
      ?.split('_') // Split the string at each underscore
      ?.map((word) => word.toUpperCase()) // Capitalize the first letter of each word
      ?.join(' '); // Join the words back together with spaces
  };

  const handleOfferSourceChange = (event) => {
    const name = 'selectedOfferSource';
    const value = event.target.value;
    dispatch(setCreateOfferInputFields({ name, value }));
  };

  const handleDateTimeChange = (event) => {
    const name = 'dateTime';
    const value = event;
    dispatch(setCreateOfferInputFields({ name, value }));
  };

  const handleOfferEdit = () => {
    navigate(`${'/edit-offer'}/${sk}/${pk}`);
  };

  const getProcessType = () => {
    let action = 'create';
    if (offerAction === 'Add') {
      action = 'create';
    }
    if (offerAction === 'Copy') {
      action = 'copy';
    }
    if (offerAction === 'Edit') {
      action = 'edit';
    }
    return action;
  };

  const handleSaveOfferInfo = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const processType = getProcessType();
    const payload = {
      pk: create.pk,
      sk: create.sk,
      entity_type: 'offer',
      process_type: processType,
    };

    const responseData = await saveOffer(payload);
    if (responseData.status === 200) {
      setIsLoading(false);
      setGreatJob(true);
      dispatch(setOfferDetails({ response: responseData.data }));
      dispatch(resetOffer());
    } else {
      setShowModal(true);
      seterrormsg(responseData?.response?.data?.error);
      // window.alert(responseData.error);
    }
    setIsLoading(false);
  };

  const handleBackToCreateCustomOffer = () => {
    navigate('/offers');
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          width: '100%',
          // height: '100%',
          borderRadius: '6px',
        }}
      >
        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: '#414446',
                    fontFamily: 'IBM Plex Serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '40px',
                  }}
                >
                  Review Your Offer
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography
                sx={{
                  color: 'var(--Col-9, #8E0000)',
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '151.188%',
                }}
              >
                *All fields are required
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography
                sx={{
                  color: 'var(--Col-8, #000)',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                Offer Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                    <TextField
                      id="offerName"
                      type="text"
                      fullWidth
                      size="medium"
                      value={create.name}
                      placeholder="Enter Offer name"
                      onChange={handleOfferNameChange}
                      disabled
                      sx={{ opacity: offerAction === 'Add' ? 1 : 0.5 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mr: 1 }}>
                    <TextField
                      id="description"
                      type="text"
                      fullWidth
                      size="medium"
                      multiline
                      rows={4}
                      value={create.description}
                      placeholder="Breif description"
                      disabled
                      sx={{ opacity: offerAction === 'Add' ? 1 : 0.5 }}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={create.selectedOfferSource}
                        onChange={handleOfferSourceChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        disabled
                        sx={{ opacity: offerAction === 'Add' ? 1 : 0.5 }}
                      >
                        <MenuItem value="offerSource" disabled>
                          Select Offer source
                        </MenuItem>
                        {Array.isArray(create.offerSourceList) &&
                          create.offerSourceList.length > 0 &&
                          create.offerSourceList.map((offer) => <MenuItem value={offer}>{`${toTitleCase(offer)}`}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6}></Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: 'var(--Col-8, #000)',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Total Data Set
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: '#414446',
                        fontFamily: 'IBM Plex Serif',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {create.total_record}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: 'var(--col-8, #000)',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      Offer Duration
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: 1 }}>
                    <DateTimeInput disabled onDateTimeChange={handleDateTimeChange} dateTimeData={create.dateTime} isdisabled={true} />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Typography
                      sx={{
                        color: 'var(--Col-12, #414446)',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '173.7%',
                        textTransform: 'capitalize',
                      }}
                    >
                      Contacts who match any of the following criteria;
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{ mt: 2 }}>
                    <div id="expression-logic-review" />
                    <ExpressionReview expression={create?.json_where_condition} queryConfig={create.offerConfig} />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={6}></Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      sx={{
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        marginTop: '2vh',
                      }}
                      onClick={handleOfferEdit}
                    >
                      Edit
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        background: '#852598',
                        marginTop: '2vh',
                        marginLeft: '1vw',
                      }}
                      onClick={handleSaveOfferInfo}
                    >
                      Save Offer
                    </Button>
                    {isLoading && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          fontSize: '32px',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          height: '100vh',
                        }}
                      >
                        <LoadingScreen gifType={5} />
                      </div>
                    )}{' '}
                    {greatJob && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          fontSize: '32px',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          height: '100vh',
                        }}
                      >
                        <LoadingScreen gifType={7} type="offers" loadingScreen7={LoadingScreen7ForOffer} />
                      </div>
                    )}
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      sx={{
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        width: '10rem',
                        height: '48px',
                        borderRadius: '6px',
                        marginLeft: '1vw',
                        marginTop: '2vh',
                      }}
                      onClick={handleBackToCreateCustomOffer}
                    >
                      Cancle
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {showModal && <ErrorPage closeModal={closeModal} errormsg={errormsg} />}
      </Paper>
    </>
  );
};

export default ReviewOffer;
