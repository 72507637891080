import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_HISTORICAL_EVENT_DATA, GET_TOP_PERFORMING_KPI } from '../../../../common/config';
import { useDispatch } from 'react-redux';
import IntegratedApp from './IntegratedApp';
import { getOfferData } from '../../../../services/OffersService';
import { offersActions } from '../../../../store/slices/offers';
import ErrorPage from '../../../../ErrorPage';
import { createofferScreen } from '../../../../Language/DialogBoxLanguage';
import { useNavigate } from 'react-router-dom';
import { offerScreen } from '../../../../Language/language';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import FilterAndSearch from '../../UI-Components/FilterAndSearch';
import CreatePopup from '../../UI-Components/CreatePopup';
import CustomPagination from '../../../../components/CustomPagination';

const { setOfferList, setOfferAction, resetOffer } = offersActions;

const OfferList = ({ setIsPageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allOfferData, setAllOfferData] = useState();
  const [selectedFilter, setSelectedFilter] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayDataList, setDisplayDataList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [massageBox, setMassageBox] = useState(true);
  const [Check, setCheck] = useState(true);
  const itemsPerPage = 5;
  const closeModal = () => {
    setShowModal(false);
    setMassageBox(false);
  };

  const _offerList = useSelector((state) => state.offers.offerList);

  useEffect(() => {
    if (_offerList.length !== 0) {
      setDisplayDataList(_offerList);
    }
  }, [_offerList]);
  useEffect(() => {
    offerData(GET_HISTORICAL_EVENT_DATA, 'offer');
    dispatch(resetOffer());
    dispatch(setOfferAction('Add'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (event) => {
    // window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    setCurrentPage(event.selected + 1);
  };

  const offerData = async (baseUrl, parameter) => {
    const response = await getOfferData(baseUrl, parameter);
    if (response.status === 200) {
      setCheck(false);
      dispatch(setOfferList({ offerList: response.data }));
      setAllOfferData(response.data);
      // setFilteredData(response.data);
      setDisplayDataList(response.data);
    } else {
      setCheck(false);
      seterrormsg(response?.response?.data?.error);
      setShowModal(true);
    }
  };

  const handleFilter = (filter, value) => {
    setSelectedFilter(filter);
    setFilterValue(value);
    if (filter === 'topPerforming') {
      offerData(GET_TOP_PERFORMING_KPI, 'CAC_offer');
    } else if (filter === 'historicalData') {
      offerData(GET_HISTORICAL_EVENT_DATA, 'offer');
    }
  };

  const handleCreateOffer = () => {
    navigate('/create-offer');
  };
  const totalPages = Math.ceil(displayDataList?.length / itemsPerPage);
  const currentData = displayDataList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        width: '100%',
        borderRadius: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          borderBottom: '1px solid #ebe8f1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: '#414446',
            lineHeight: '1.5',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'IBM Plex Serif',
              fontSize: '1.5rem',
            }}
          >
            {offerScreen.pagetitle}
          </Typography>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            color: '#ffffff',
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 'Bold',
            textAlign: 'center',
            textTransform: 'capitalize',
            width: { lg: '17rem', sm: '10rem' },
            height: '48px',
            borderRadius: '6px',
            backgroundColor: '#852598',
            ':hover': {
              backgroundColor: '#852598',
            },
          }}
          onClick={handleCreateOffer}
        >
          {offerScreen.createofferbtn}
        </Button>
      </div>
      <section>
        <Box padding="1rem">
          <FilterAndSearch
            data={allOfferData}
            selectedFilter={selectedFilter}
            filterValue={filterValue}
            handleFilter={handleFilter}
            onSearchResult={(results) => {
              setDisplayDataList(results);
              // setCurrentPage(1);
            }}
          />
          {Check ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: '32px',
              }}
            >
              <LoadingScreen gifType={5} />
            </div>
          ) : !!currentData?.length ? (
            <IntegratedApp offerDataList={currentData} setIsPageType={setIsPageType} />
          ) : (
            massageBox && <CreatePopup closeModal={closeModal} navigateType="/create-offer" popupConfig={createofferScreen} />
          )}
        </Box>

        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
          <CustomPagination totalPages={totalPages} handlePageChange={handlePageChange} />
        </div>
      </section>
      {showModal && <ErrorPage closingFlag={5} closeModal={closeModal} errormsg={errormsg} />}
    </Paper>
  );
};

export default OfferList;
