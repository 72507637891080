import { Box, Button, Grid, IconButton, Switch, ThemeProvider, Typography, createTheme } from '@mui/material';
import React from 'react';
import CopyIcon from '../../../assets/Copy.svg';
import DeleteIcon from '../../../assets/Delete.svg';
import EditIcon from '../../../assets/Edit.svg';
import { useState } from 'react';

const AllPersona = () => {
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const [switchStates, setSwitchStates] = useState({});

  const map = [
    { Title: 'Synergi local Users1' },
    { Title: 'Synergi local Users2' },
    { Title: 'Synergi local Users3' },
    { Title: 'Synergi local Users4' },
    { Title: 'Synergi local Users5' },
  ];
  const handleChange = (title) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <div>
      <Box sx={{ backgroundColor: '#ffffff', width: '100%', height: '100%', borderRadius: '6px', marginLeft: '18px' }}>
        <Grid
          container
          style={{
            borderBottom: '1px solid #EBE8F1',
            padding: '11px 22px',
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: 'IBM Plex',
                fontSize: '24px',
                lineHeight: '40px',
                color: '#414446',
              }}
            >
              All Personas
            </Typography>
          </Grid>
          {/* <Grid xs={2}>icon</Grid> */}
        </Grid>
        <Grid container>
          <Grid item sm={12} md={6} lg={7}>
            <section
              style={{
                padding: '20px 22px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: '#414446',
                  paddingBottom: '15px',
                }}
              >
                With Personas you can define different groups of people to target within key accounts.
              </Typography>
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: '#5D596C',
                  paddingBottom: '15px',
                }}
              >
                Drag & drop each Persona configuration to change the order in which they will appear in the search page filters.
              </Typography>
            </section>
            <section style={{ marginLeft: '12px' }}>
              {map.map((title) => {
                return (
                  <>
                    <Grid container style={{ border: '1px solid #EBE8F1', borderRadius: '5px', display: 'flex', padding: '22px 18px', margin: '10px' }}>
                      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require('../../../assets/persona_icon.png')} alt="" width={17} />
                        <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, { title })}>
                          <Switch checked={switchStates[title] || false} onChange={() => handleChange(title)} />
                        </ThemeProvider>
                        <Typography>{title.Title}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontFamily: 'Roboto',
                          lineHeight: 1.5,
                          width: '100%',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton title="Edit Segment">
                          {!isLoadingEdit ? (
                            <>
                              <img src={EditIcon} alt="add" />
                            </>
                          ) : (
                            <>
                              <img src={require('../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
                            </>
                          )}
                        </IconButton>
                        <IconButton title="Copy Segment">
                          {!isLoadingCopy ? (
                            <>
                              <img src={CopyIcon} alt="add" />
                            </>
                          ) : (
                            <>
                              <img src={require('../../../assets/60-X-60_Loader.gif')} alt="" width={25} />
                            </>
                          )}
                        </IconButton>
                        {/* {isButttonHidden && ( */}
                        <>
                          <IconButton title="Delete Segment">
                            <img src={DeleteIcon} alt="add" />
                          </IconButton>
                        </>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                sx={{
                  width: {
                    lg: '146px',
                    marginTop: '48px',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    marginLeft: '12px',
                  },
                }}
              >
                Add Persona
              </Button>
            </section>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AllPersona;

// import React, { useState } from 'react';
// import { Grid, Typography, Switch, IconButton, ThemeProvider, createTheme } from '@mui/material';

// const AllPersona = () => {
//   const [switchStates, setSwitchStates] = useState({});

//   const handleChange = (title) => {
//     setSwitchStates((prevState) => ({
//       ...prevState,
//       [title]: !prevState[title],
//     }));
//   };

//   const theme = createTheme({
//     components: {
//       MuiSwitch: {
//         styleOverrides: {
//           thumb: {
//             backgroundColor: (title) => (switchStates[title] ? '#4CAF50' : '#f44336'),
//           },
//           track: {
//             backgroundColor: (title) => (switchStates[title] ? '#81C784' : '#FFCDD2'),
//           },
//         },
//       },
//     },
//   });

//   const mapData = [
//     { Title: 'Title1' },
//     { Title: 'Title2' },
//     // Add more items as needed
//   ];

//   return (
//     <>
//       {mapData.map((titleObject) => {
//         const title = titleObject.Title;
//         return (
//           <Grid key={title} container style={{ border: '1px solid #EBE8F1', borderRadius: '5px', display: 'flex', padding: '22px 18px', margin: '10px' }}>
//             <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
//               <img src={require('../../../assets/persona_icon.png')} alt="" width={17} />
//               <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, { title })}>
//                 <Switch checked={switchStates[title] || false} onChange={() => handleChange(title)} />
//               </ThemeProvider>
//               <Typography>{title}</Typography>
//             </Grid>
//             <Grid
//               item
//               xs={3}
//               style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 fontFamily: 'Roboto',
//                 lineHeight: 1.5,
//                 width: '100%',
//                 justifyContent: 'flex-end',
//               }}
//             >
//               {/* Your IconButton components here */}
//             </Grid>
//           </Grid>
//         );
//       })}
//     </>
//   );
// };

// export default AllPersona;
